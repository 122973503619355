import { StateService } from '@uirouter/angularjs';
import { IPageStartService } from './../../shared/pageStartService';
import { IUserService } from './../../shared/userService';

import { ITranslationService } from './../i18n/translationService';

import { Planboard } from './../planboard/entities/planboard';
import { ActivityType } from './../planboard/entities/activitytype';
import { TimeSpan } from './../planboard/utils/timespan';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import * as Constants from './../utils/constants';
import { Dictionary } from './../utils/dictionary';
import * as Timezone from './../utils/timezone';

export class SolverManagementController {

    allSolverRequests = new Dictionary();
    userScenarios = new Dictionary();
    userResourceTypes = new Dictionary();
    userOrganizationUnits = new Dictionary();

    userActivityTypes = new Dictionary();

    writableLeafIds: number[];
    rootIdsForWritableLeaves: number[];

    userRootActivityTypes = new Dictionary();
    userLeafActivityTypes = new Dictionary();
    userResources = new Dictionary();
    visibleUserResources = new Dictionary();
    selectedSolverRequest: any = null;
    selectedSolverRequestId: number = -1;
    filterOrganizationUnitId: number = -1;
    planRules: Array<any> = [];
    addRequestOpen: boolean = false;
    sliderOptions: any = {
        connect: false,
        range: {
            min: 0,
            max: 4,
        },
    };
    sliderSkillLevel: number = 0;
    sliderEvenWorkloadDistribution: number = 0;
    sliderPlanningRule: number = 0;
    sliderPreparationCompletionTime: number = 0;


    private commonSvc: any;
    private date: Date = new Date();
    private today: Date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
    private selectedOrganizationUnits: Array<any> = [];

    private readonly apiUrl: string = "api/SolverRequests";
    private readonly scenarioApiUrl: string = "api/Scenarios/ForSolver";
    private readonly resourceTypeApiUrl: string = "api/ResourceTypes";
    private readonly organizationUnitApiUrl: string = "api/OrganizationUnits";
    private readonly activityTypeApiUrl: string = "api/ActivityTypes";
    private readonly planRuleApiUrl: string = "api/ActivityTypes/WorkloadRules";
    private readonly resourcesForOrganizationUnitsApiUrl: string = "api/Resources/OrganizationUnits";
    private readonly resourcesWithIdsApiUrl: string = "api/Resources/SolverResourcesByIds";
    private readonly dialogToken: string = "solvManInfo";
    private readonly permission: string = "SolverManagement";

    static $inject = [
        "$scope",
        "$filter",
        "$sce",
        "$state",
        "$timeout",
        "pageStartService",
        "translationService",
        "userService"
    ];

    constructor(
        public $scope: ITreeListScope,
        private $filter: ng.IFilterService,
        private $sce: ng.ISCEService,
        private $state: StateService,
        private $timeout: ng.ITimeoutService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private userService: IUserService

    ) {
        this.translationService.getTextLabels(this.$scope);
        this.pageStartService.subscribeToWebSocketEvent(this.$scope, "solverRequestEvent", () => { this.solverWebsocketEventCallback });
        this.commonSvc = this.pageStartService.initialize(this.$scope, this.permission, this.dialogToken);

        this.commonSvc.start(() => { this.loadData(); });
    }

    private loadData(): void {
        this.commonSvc.loadData(this.apiUrl, this.allSolverRequests,
            (success, loadInto) => {
                this.setSolverRequestStatusDescriptions(this.allSolverRequests);
                this.setSolverRequestReadOnly(this.allSolverRequests);
            }, null, true, true);
        this.commonSvc.loadData(this.scenarioApiUrl, this.userScenarios,
            (success) => {
            // Hide scenarios that are completely in the past.
                this.userScenarios.forEach((key, value) => {
                    value.selectable = !this.isScenarioInThePast(value);
            });
        }, null, true, true);
        this.commonSvc.loadData(this.resourceTypeApiUrl, this.userResourceTypes,
            (success) => {
            // Only make resource types selectable for which the user has write permissions
                this.userResourceTypes.forEach((key, value) => {
                    value.selectable = value.maxPermissionForCurrentUser >= 2;
            });
        }, null, true, true);
        this.commonSvc.loadData(this.organizationUnitApiUrl, this.userOrganizationUnits,
            (success) => {

                // Register all organization units as children to their parents.
                this.userOrganizationUnits.forEach((key, value) => {
                    value.selectable = value.maxPermissionForCurrentUser >= 2;
                    this.registerChildToParent(value);
                });
            }, null, true, true);
        this.commonSvc.loadData(this.activityTypeApiUrl, this.userActivityTypes,
            (success) => {
                // Only top level activity types that are not absence/daymarks are selectable.
                var sortedRootActivityTypes = [];
                this.userRootActivityTypes.clear();
                this.writableLeafIds = [];
                this.rootIdsForWritableLeaves = [];
                this.userActivityTypes.forEach((key, value) => {
                    if (this.isTopLevelActivityType(value) && !this.hasActivityTypeExpired(value)) {
                        value.originalDisplayName = value.displayName;
                        value.displayName = "[".concat(value.shortName, "] ", value.displayName);
                        if (value.resourceTypeIdList &&
                            value.resourceTypeIdList.length > 0 &&
                            value.maxPermissionForCurrentUser >= Constants.permWrite) {
                            if (this.rootIdsForWritableLeaves.indexOf(value.id) < 0) {
                                this.rootIdsForWritableLeaves.push(value.id);
                            }
                        }
                        this.userRootActivityTypes.add(value.id, value);
                        sortedRootActivityTypes.push(value);
                    } else if (this.isLeafActivityType(value) && !this.hasActivityTypeExpired(value)) {
                        const rootId = this.getRootId(value);
                        const root = this.userRootActivityTypes.value(rootId);
                        const rootShortName = root ? root.shortName : value.shortName;
                        value.displayNameWithRoot = "[".concat(rootShortName, "] ", value.displayName);
                        this.userLeafActivityTypes.add(value.id, value);

                        if (value.maxPermissionForCurrentUser >= Constants.permWrite) {
                            this.writableLeafIds.push(value.id);
                            if (this.rootIdsForWritableLeaves.indexOf(rootId) < 0) {
                                this.rootIdsForWritableLeaves.push(rootId);
                            }
                        }
                    }
                });

                this.setRootActivityTypeSelectability();
                this.setLeafActivityTypeSelectability();
                
                // sort by displayName by setting the order property of each item
                sortedRootActivityTypes = this.$filter('orderBy')(sortedRootActivityTypes, (item) => { return item.originalDisplayName });
                for (var i = 0; i < sortedRootActivityTypes.length; i++)
                    sortedRootActivityTypes[i].order = i + 1;
        }, null, true, true);
        this.commonSvc.loadData(this.planRuleApiUrl, this.planRules, null, null, true, true);
        // Select the previously selected solver request after all data has been loaded
        this.commonSvc.onAllDataLoaded(() => {
            var id = this.userService.getUserVariable("selectedSolverRequestId");
            if (id != undefined && this.allSolverRequests.value(id) != undefined)
                if (this.selectedSolverRequestId == undefined || this.selectedSolverRequestId !== id)
                    this.selectSolverRequest(id);
        });
    }

    /**
        * Check if the solver request can be submitted.
        */
    isSolverRequestSubmittable(): boolean {
        if (!this.selectedSolverRequest) return false;

        let validOrganizationUnits = this.selectedSolverRequest.additionalInformation.organizationUnitIds &&
            this.selectedSolverRequest.additionalInformation.organizationUnitIds.length > 0;

        let validResources = this.selectedSolverRequest.allResources ||
            (this.selectedSolverRequest.additionalInformation.resourceIds &&
            this.selectedSolverRequest.additionalInformation.resourceIds.length > 0);

        let validResourceTypes = this.selectedSolverRequest.allResourceTypes ||
            (this.selectedSolverRequest.additionalInformation.resourceTypeIds &&
            this.selectedSolverRequest.additionalInformation.resourceTypeIds.length > 0);

        let validRootActivityTypes = this.selectedSolverRequest.allActivityTypes ||
            (this.selectedSolverRequest.additionalInformation.rootActivityTypeIds &&
            this.selectedSolverRequest.additionalInformation.rootActivityTypeIds.length > 0);

        let validLeafActivityTypes = this.selectedSolverRequest.allLeafActivityTypes || this.selectedSolverRequest.allActivityTypes ||
            (this.selectedSolverRequest.additionalInformation.leafActivityTypeIds &&
            this.selectedSolverRequest.additionalInformation.leafActivityTypeIds.length > 0);


        let isSolverRequestSubmittable =
            !(!this.selectedSolverRequest.fromScenarioId || this.selectedSolverRequest.invalidStart || this.selectedSolverRequest.invalidEnd) &&
            validOrganizationUnits &&
            validResources &&
            validResourceTypes &&
            validRootActivityTypes &&
            validLeafActivityTypes;

        return isSolverRequestSubmittable;
    }

    onOrganizationUnitChanged(): void {
        if (!this.selectedSolverRequest) return;

        this.$timeout(() => {
                this.selectedOrganizationUnits = [];
                if (!this.selectedSolverRequest.additionalInformation.organizationUnitIds)
                    this.selectedSolverRequest.additionalInformation.organizationUnitIds = [];

                this.selectedOrganizationUnits =
                    this.selectedOrganizationUnits.concat(this.selectedSolverRequest.additionalInformation
                        .organizationUnitIds);

                this.setResourceTypeSelectability();
                this.commonSvc.post(this.resourcesForOrganizationUnitsApiUrl,
                    this.selectedOrganizationUnits,
                    (success) => {
                        this.updateUserResources(success.data);
                    },
                    null,
                    true);

            },
            0);
    }

    onResourceTypeSelectionChanged(): void {
        this.$timeout(() => {
            this.filterUserResources();
        }, 0);
    }

    onRootActivityTypeSelectionChanged(): void {
        this.setLeafActivityTypeSelectability();
    }

    onFromDateChanged(date: Date): void {
        var req = this.selectedSolverRequest;
        req.invalidStart = !date || date < this.today;
        req.invalidEnd = !req.endDate || (date && req.endDate < date);
    }

    onToDateChanged(date: Date): void {
        var req = this.selectedSolverRequest;
        req.invalidEnd = !date || (req.startDate && date < req.startDate);
    }

    planRuleSelectionChanged(rule, value): void {
        if (!this.selectedSolverRequest || !this.selectedSolverRequest.additionalInformation) return;
        var idList = this.selectedSolverRequest.additionalInformation.planRuleIds;
        if (idList == null) {
            idList = [];
            this.selectedSolverRequest.additionalInformation.planRuleIds = idList;
        }
        var index = idList.indexOf(rule.id);
        if (index < 0 && value) idList.push(rule.id);
        if (index >= 0 && !value) idList.splice(index, 1);
    }

    isPlanRuleVisible(rule): boolean {
        var result = this.selectedSolverRequest && this.selectedSolverRequest.isSubmittable
            ? true // when submitting a new solver request, make a rule visible if it can be selected
            : rule.selected; // make a rule visible if it is already selected
        // hide rules that are invisible because it is filtered
        if (result && this.isPlanRuleFilteredOut(rule)) result = false;
        return result;
    }

    getRuleText(rule): string {
        var text = this.parseRule(rule);
        return text === "" ? null : this.$sce.trustAsHtml(text);
    }

    getActivityText(rule): string {
        var actType = this.userActivityTypes.value(rule.activityTypeId);
        return actType ? actType.shortName : "";
    }

    getActivityBackColor(rule): string {
        var actType = this.userActivityTypes.value(rule.activityTypeId);
        return actType ? "#" + actType.backColor : "transparent";
    }

    getActivityTextColor(rule): string {
        var actType = this.userActivityTypes.value(rule.activityTypeId);
        return actType ? "#" + actType.textColor : "";
    }

    getTagColor = function (rule): string {
        // TODO: rework needed in future if we want to display multiple tags
        var color = "transparent"; // default if no tag
        var text = this.$scope.textLabels["PLANNING_RULE_TYPE_" + rule.ruleType.toString()];
        var firstTag = true; // ignore the first tag, this is the indicator if it is a generic or solver only rule

        while (text != undefined && text !== "" && text.indexOf("[tag-") > -1) {
            var startPos = text.indexOf("[");
            var endPos = text.indexOf("]");
            if (startPos < 0 || endPos < 0 || endPos < startPos) return text;
            var tag = text.substring(startPos, endPos + 1);
            var separatorPos = tag.indexOf(":");
            var tagType = tag.substring(1, separatorPos > 0 ? separatorPos : tag.length - 1).toLowerCase();

            // colored tag
            if (tagType.length > 3 && tagType.substring(0, 4) === "tag-") {
                text = text.substring(0, startPos) + text.substring(endPos + 1);
                if (!firstTag) {
                    color = tagType.substring(4);
                    break;
                }
                firstTag = false;
            }
        }
        return color;
    }

    getTagText(rule): string {
        // TODO: rework needed in future if we want to display multiple tags
        var text = this.$scope.textLabels["PLANNING_RULE_TYPE_" + rule.ruleType.toString()];
        var tagLabel = "";
        var firstTag = true; // ignore the first tag, this is the indicator if it is a generic or solver only rule

        while (text != undefined && text !== "" && text.indexOf("[tag-") > -1) {
            var startPos = text.indexOf("[");
            var endPos = text.indexOf("]");
            if (startPos < 0 || endPos < 0 || endPos < startPos) return text;
            var tag = text.substring(startPos, endPos + 1);
            var separatorPos = tag.indexOf(":");
            var tagType = tag.substring(1, separatorPos > 0 ? separatorPos : tag.length - 1).toLowerCase();
            tagLabel = separatorPos > 0 ? tag.substring(separatorPos + 1, tag.length - 1) : "";

            // colored tag
            if (tagType.length > 3 && tagType.substring(0, 4) === "tag-") {
                text = text.substring(0, startPos) + text.substring(endPos + 1);
                if (!firstTag)
                    break;
                firstTag = false;
            }
        }
        return tagLabel;
    }

    // Mark a clicked solver request as selected.
    selectSolverRequest(id: number): void {
        this.userService.setUserVariable("selectedSolverRequestId", id);
        this.selectedSolverRequestId = id;
        this.selectedSolverRequest = this.allSolverRequests.value(id);
        this.fillOutAdditionalInfo(this.selectedSolverRequest);
        this.setPlanRulesSelectedState(null);

        // Get the resources for this request.
        if (this.selectedSolverRequest.additionalInformation.resourceIds) {
            this.commonSvc.post(this.resourcesWithIdsApiUrl,
                this.selectedSolverRequest.additionalInformation.resourceIds,
                (success) => {
                    this.updateUserResources(success.data);
                },
                null,
                true);
        }
    }

    getClearDateText():string {
        return this.$scope.textLabels.NO_DATE;
    }

    showScenarioManagement(): void {
        if (this.selectedSolverRequest)
            this.userService.setUserVariable("selectedScenarioRequestId", this.selectedSolverRequest.toScenarioId);
        this.$state.transitionTo("scenarioManagement", {});
    }

    showScenario(scenarioId: number): void {
        this.userService.setDisplaySettingNumber("planboard.scenarioId", scenarioId);
        if (Planboard.scenarioId !== scenarioId) {
            Planboard.scenarioId = scenarioId;
            Planboard.clearData();
            Planboard.redrawAll();
        }
        this.$state.transitionTo("planboard", {});
    }

    showSolverResult(): void {
        if (this.selectedSolverRequest == null) return;
        var scenarioId = this.selectedSolverRequest.toScenarioId;
        if (scenarioId != null) this.showScenario(scenarioId);
    }

    addRequest(): void {
        var requestPreexisting = this.selectedSolverRequest ? true : false;
        // Calculate the last day of next week
        var lastDayNextWeek = TimeSpan.fromDateNoTime(this.today).addDays(7).toDate();
        var lastDayOfWeek = 0; // Sunday
        while (lastDayNextWeek.getDay() !== lastDayOfWeek) {
            lastDayNextWeek = TimeSpan.fromDateNoTime(lastDayNextWeek).addDays(1).toDate();
        }
        var defaultDuration =
            TimeSpan.getDayNr(lastDayNextWeek) - TimeSpan.getDayNr(this.today);
        var periodDuration = this.userService.getDisplaySettingNumber("solver.periodDuration", defaultDuration);

        this.addRequestOpen = true;
        this.selectedSolverRequestId = null;

        this.selectedSolverRequest = {
            writeable: true,
            isSubmittable: true,
            startDate: this.today,
            endDate: TimeSpan.fromDateNoTime(this.today).addDays(periodDuration).toDate(),
            allLeafActivityTypes: true
        };
        this.fillOutAdditionalInfo(this.selectedSolverRequest);
        this.setPlanRulesSelectedState(true);

        // If there was not any preexisting request, perform any actions depending on the selected organization units now.
        if (!requestPreexisting && this.selectedOrganizationUnits) {
            this.setResourceTypeSelectability();
            this.commonSvc.post(this.resourcesForOrganizationUnitsApiUrl,
                this.selectedOrganizationUnits,
                (success) => {
                    this.updateUserResources(success.data);
                },
                null,
                true);
        }
    }

    submitRequest(): void {
        this.updateAdditionalInformationPlanRules();
        var periodDuration = TimeSpan.getDayNr(this.selectedSolverRequest.endDate) -
            TimeSpan.getDayNr(this.selectedSolverRequest.startDate);
        this.selectedSolverRequest.startDate = Timezone.rollDateForWebApi(this.selectedSolverRequest.startDate);
        this.selectedSolverRequest.endDate = Timezone.rollDateForWebApi(this.selectedSolverRequest.endDate);
        if (this.selectedSolverRequest.allResources) this.selectedSolverRequest.additionalInformation.resourceIds = null;
        if (this.selectedSolverRequest.allResourceTypes) this.selectedSolverRequest.additionalInformation.resourceTypeIds = null;
        if (this.selectedSolverRequest.allActivityTypes) this.selectedSolverRequest.additionalInformation.rootActivityTypeIds = null;
        if (this.selectedSolverRequest.allActivityTypes || this.selectedSolverRequest.allLeafActivityTypes) this.selectedSolverRequest.additionalInformation.leafActivityTypeIds = null;

        var info = this.selectedSolverRequest.additionalInformation;
        // update with slider model
        info.skillLevelPriority = this.sliderSkillLevel;
        info.evenWorkloadDistributionPriority = this.sliderEvenWorkloadDistribution;
        info.planningRulePriority = this.sliderPlanningRule;
        info.preparationCompletionTimePriority = this.sliderPreparationCompletionTime;
        this.userService.setDisplaySettingNumber("solver.skillLevelPrio", info.skillLevelPriority);
        this.userService.setDisplaySettingNumber("solver.evenWorkloadDistributionPrio", info.evenWorkloadDistributionPriority);
        this.userService.setDisplaySettingNumber("solver.planningRulePrio", info.planningRulePriority);
        this.userService.setDisplaySettingNumber("solver.preparationCompletionTimePrio", info.preparationCompletionTimePriority);
        this.userService.setDisplaySettingNumber("solver.workloadExceedancePercentage", info.workloadExceedancePercentage);
        this.userService.setDisplaySettingSwitch("solver.activitySeriesInWeekends", info.consecutiveActivitySeriesContinueInWeekends);
        this.userService.setDisplaySettingSwitch("solver.checkLaborRules", info.checkLaborRules);
        this.userService.setDisplaySettingNumber("solver.periodDuration", periodDuration);

        this.commonSvc.post(this.apiUrl,
            this.selectedSolverRequest,
            (success) => {
                this.selectedSolverRequest = null;
                this.selectedSolverRequestId = null;
                success.data.allResources = !success.data.additionalInformation.resourceIds;
                success.data.allResourceTypes = !success.data.additionalInformation.resourceTypeIds;
                success.data.allActivityTypes = !success.data.additionalInformation.rootActivityTypeIds;
                success.data.allLeafActivityTypes = !success.data.additionalInformation.leafActivityTypeIds;
                this.allSolverRequests.add(success.data.id, success.data);
                this.addRequestOpen = false;
            },
            null,
            true);
    }

    deleteRequest(): void {
        this.resetPlanboardScenario(this.selectedSolverRequest.toScenarioId);
        this.commonSvc.deleteData(this.apiUrl + "/" + this.selectedSolverRequest.id,
            (success) => {
                this.allSolverRequests.remove(this.selectedSolverRequest.id);
                this.selectedSolverRequest = null;
                this.selectedSolverRequestId = null;
            }, null, true);
    }

    cancelRequest(): void {
        this.addRequestOpen = false;
        this.selectedSolverRequest.isSubmittable = false;
    }

    private getSelectedSolverRequestStatus(): string{
        if (this.selectedSolverRequest.status != null) return this.selectedSolverRequest.status.status;
        return null;
    }

    private getSingleRequest(requestId) {
        this.commonSvc.loadData(this.apiUrl + "/" + requestId,
            null,
            (success) => {
                this.allSolverRequests.add(requestId, success.data);
                this.setStatusDescr(success.data);
                this.correctTimeZoneInfoForRequest(success.data);
                this.setAllFlags(success.data);
            }, null, true, false);
    }

    private setSolverRequestStatusDescriptions(solverRequests): void {
        solverRequests.forEach((key, value) => {
            this.setStatusDescr(value);
            this.correctTimeZoneInfoForRequest(value);
            this.setAllFlags(value);
        });
    }

    private correctTimeZoneInfoForRequest(solverRequest): void {
        solverRequest.requestTime = Timezone.correctTimeZoneInfo(solverRequest.requestTime);
        solverRequest.completedTime = Timezone.correctTimeZoneInfo(solverRequest.completedTime);
        solverRequest.startDate = Timezone.correctTimeZoneInfo(solverRequest.startDate);
        solverRequest.endDate = Timezone.correctTimeZoneInfo(solverRequest.endDate);
    }

    private setAllFlags(solverRequest): void {
        solverRequest.allResources = !solverRequest.additionalInformation || !solverRequest.additionalInformation.resourceIds;
        solverRequest.allResourceTypes = !solverRequest.additionalInformation || !solverRequest.additionalInformation.resourceTypeIds;
        solverRequest.allActivityTypes = !solverRequest.additionalInformation || !solverRequest.additionalInformation.rootActivityTypeIds;
        solverRequest.allLeafActivityTypes = !solverRequest.additionalInformation || !solverRequest.additionalInformation.leafActivityTypeIds;
    }

    private fillOutAdditionalInfo(solverRequest) {
        if (solverRequest.additionalInformation == undefined) {
            solverRequest.additionalInformation = {
                skillLevelPriority: this.userService
                    .getDisplaySettingNumber("solver.skillLevelPrio", null),
                evenWorkloadDistributionPriority: this.userService
                    .getDisplaySettingNumber("solver.evenWorkloadDistributionPrio", null),
                planningRulePriority: this.userService
                    .getDisplaySettingNumber("solver.planningRulePrio", null),
                preparationCompletionTimePriority: this.userService
                    .getDisplaySettingNumber("solver.preparationCompletionTimePrio", null),
                organizationUnitIds: [],
                planRuleIds: [],
                resourceTypeIds: [],
                resourceIds: [],
                rootActivityTypeIds: [],
                workloadExceedancePercentage: this.userService
                    .getDisplaySettingNumber("solver.workloadExceedancePercentage", null),
                consecutiveActivitySeriesContinueInWeekends: this.userService
                    .getDisplaySettingSwitch("solver.activitySeriesInWeekends"),
                checkLaborRules: this.userService.getDisplaySettingSwitch("solver.checkLaborRules")
            };
        }
        // update slider model
        this.sliderSkillLevel = solverRequest.additionalInformation.skillLevelPriority;
        this.sliderEvenWorkloadDistribution = solverRequest.additionalInformation.evenWorkloadDistributionPriority;
        this.sliderPlanningRule = solverRequest.additionalInformation.planningRulePriority;
        this.sliderPreparationCompletionTime = solverRequest.additionalInformation.preparationCompletionTimePriority;
    }

    private setStatusDescr(solverRequest): void {
        switch (solverRequest.status) {
            case 0:
                solverRequest.statusDescr = this.$scope.textLabels.QUEUE_ITEM_PENDING;
                break;
            case 1:
                solverRequest.statusDescr =
                    isNaN(solverRequest.progress)
                    ? this.$scope.textLabels.QUEUE_ITEM_IN_PROGRESS // perhaps we could show a cached last known request percentage somewhere in the future?
                    : this.$filter("number")(solverRequest.progress, 2) + "%";
                break;
            case 2:
                solverRequest.statusDescr = this.$scope.textLabels.QUEUE_ITEM_COMPLETED;
                break;
            case 3:
                solverRequest.statusDescr = this.$scope.textLabels.QUEUE_ITEM_FAILED;
                break;
            case 4:
                solverRequest.statusDescr = this.$scope.textLabels.QUEUE_ITEM_CANCELLED;
                break;
            case 5:
                solverRequest.statusDescr = this.$scope.textLabels.QUEUE_ITEM_STARTING;
                break;
            case 6:
                solverRequest.statusDescr = this.$scope.textLabels.QUEUE_ITEM_FINISHING;
                break;
            default:
                solverRequest.statusDescr = "?";
        };
    }

    private setSolverRequestReadOnly(solverRequests): void {
        solverRequests.forEach((key, value) => { value.writable = false; });
    }

    private setResourceTypeSelectability(): void {
        this.userResourceTypes.forEach((key, value) => {
            var commonOrganizationUnits = this.selectedOrganizationUnits.filter((item) => {
                return value.validOrganizationUnitIds.indexOf(item) >= 0;
            });

            value.selectable = commonOrganizationUnits.length > 0 && value.maxPermissionForCurrentUser >= 2;
            var indexOfType =
                this.selectedSolverRequest.additionalInformation.resourceTypeIds.indexOf(value.id);
            if (!value.selectable && indexOfType >= 0) {
                this.selectedSolverRequest.additionalInformation.resourceTypeIds.splice(indexOfType, 1);
            }
        });
    }

    private setRootActivityTypeSelectability(): void {
        this.userRootActivityTypes.forEach((key, value) => {
            if (!this.isTopLevelActivityType(value)) {
                value.selectable = false;
                return;
            }

            value.selectable = !this.hasActivityTypeExpired(value) && this.rootIdsForWritableLeaves.indexOf(value.id) > -1;

            if (this.selectedSolverRequest) {
                var indexOfType =
                    this.selectedSolverRequest.additionalInformation.rootActivityTypeIds.indexOf(value.id);
                if (!value.selectable && indexOfType >= 0) {
                    this.selectedSolverRequest.additionalInformation.rootActivityTypeIds.splice(indexOfType, 1);
                }
            }
        });
    }

    private setLeafActivityTypeSelectability(): void {
        this.userLeafActivityTypes.forEach((key, value) => {
            if (!this.isLeafActivityType(value)) {
                value.selectable = false;
                return;
            }
            value.selectable =
                this.selectedSolverRequest &&
                this.selectedSolverRequest.additionalInformation.rootActivityTypeIds.indexOf(this.getRootId(value)) >= 0 &&
                !this.hasActivityTypeExpired(value) &&
                this.writableLeafIds.indexOf(value.id) > -1;

            // Set display name with [root] if it wasn't correctly set before
            if (value.selectable && value.displayNameWithRoot == "[".concat(value.shortName, "] ", value.displayName)) {
                var root = this.userRootActivityTypes.value(this.getRootId(value));
                var rootShortName = root ? root.shortName : value.shortName;
                value.displayNameWithRoot = "[".concat(rootShortName, "] ", value.displayName);
            }

            if (this.selectedSolverRequest && this.selectedSolverRequest.additionalInformation.leafActivityTypeIds) {
                var indexOfType =
                    this.selectedSolverRequest.additionalInformation.leafActivityTypeIds.indexOf(value.id);
                if (!value.selectable && indexOfType >= 0) {
                    this.selectedSolverRequest.additionalInformation.leafActivityTypeIds.splice(indexOfType, 1);
                }
            }
        });
    }

    private getRootId(activityType: any): number | null {
        let findRoot = activityType;
        while (findRoot != null && findRoot.parentId != null) {
            const parent = this.userActivityTypes.value(findRoot.parentId);
            if (parent == null) {
                break;
            }
            findRoot = parent;
        }
        return findRoot ? findRoot.id : null;
    }

    private filterUserResources(): void {
        if (this.selectedSolverRequest) {

            var resourceTypeIds = this.selectedSolverRequest.additionalInformation.resourceTypeIds;

            this.visibleUserResources.clear();
            this.userResources.forEach((key, value) => {
                if (resourceTypeIds.length === 0 || this.selectedSolverRequest.allResourceTypes ||
                    resourceTypeIds.filter((type) => {
                        return value.resourceTypeIds.indexOf(type) > -1;
                    }).length > 0) {
                    this.visibleUserResources.add(key, value);
                }
            });

            // now see if anything is in the selected resources that is not in the available resources and delete it
            var additionalInformation = this.selectedSolverRequest.additionalInformation;
            if (additionalInformation.resourceIds != null)
                for (var i = additionalInformation.resourceIds.length - 1; i >= 0; i--)
                    if (!this.visibleUserResources.containsKey(additionalInformation.resourceIds[i]))
                        additionalInformation.splice(additionalInformation.indexOf(additionalInformation.resourceIds[i]), 1);
        }
    }

    private updateUserResources(resources): void {

        // replace the dictionary so that the watch in the taglist occurs
        var tempDict = new Dictionary();
        for (var i = 0; i < resources.length; i++) {
            tempDict.add(resources[i]["id"], resources[i]);
        }

        this.userResources = tempDict;
        this.filterUserResources();
    }

    private resetPlanboardScenario(scenarioId): void {
        var savedId = this.userService.getDisplaySettingNumber("planboard.scenarioId", 1);
        if (savedId === scenarioId)
            this.userService.setDisplaySettingNumber("planboard.scenarioId", 1);
    }

    /**
    * Copy the state of $scope.selectedSolverRequest.additionalInformation.planRuleIds to $scope.planRules
    * @param selectAll when true/false intead of null, then instead of copying just select/deselect all rules
    */
    private setPlanRulesSelectedState(selectAll?: boolean): void {
        if (!this.selectedSolverRequest) return;
        var idList = this.selectedSolverRequest.additionalInformation &&
            this.selectedSolverRequest.additionalInformation.planRuleIds
            ? this.selectedSolverRequest.additionalInformation.planRuleIds
            : [];
        if (selectAll != null && idList.length > 0) idList.length = 0;
        for (var i = 0; i < this.planRules.length; i++) {
            if (selectAll != null) {
                this.planRules[i].selected = selectAll;
                if (selectAll === true) idList.push(this.planRules[i].id);
            } else
                this.planRules[i].selected = idList.indexOf(this.planRules[i].id) >= 0;
        }
    }

    private updateAdditionalInformationPlanRules(): void {
        if (!this.selectedSolverRequest || !this.selectedSolverRequest.additionalInformation) return;
        var idList = this.selectedSolverRequest.additionalInformation.planRuleIds;
        if (idList == null || idList.length === 0) return;
        for (var i = 0; i < this.planRules.length; i++) {
            var rule = this.planRules[i];
            var index = idList.indexOf(rule.id);
            if (index >= 0 && this.isPlanRuleFilteredOut(rule)) idList.splice(index, 1);
        }
    }

    private isPlanRuleFilteredOut(rule): boolean {
        if (!this.selectedSolverRequest || !this.selectedSolverRequest.additionalInformation) return false;

        // hide rules that are invisible because the selected resource type for the rule is filtered
        if (!this.selectedSolverRequest.allResourceTypes) {
            var resourceTypeIdList = this.selectedSolverRequest.additionalInformation.resourceTypeIds;
            if (resourceTypeIdList != null && rule.resourceTypeId != null && rule.resourceTypeId >= 0)
                if (resourceTypeIdList.indexOf(rule.resourceTypeId) < 0) return true;
        }

        // hide rules that are invisible because the activity type the rule belongs to is filtered
        if (!this.selectedSolverRequest.allActivityTypes) {
            var rootActivityTypeIdList = this.selectedSolverRequest.additionalInformation.rootActivityTypeIds;
            if (rootActivityTypeIdList != null && rule.activityTypeId != null && rule.activityTypeId >= 0) {
                var act = this.userActivityTypes.value(rule.activityTypeId);
                while (act != null && act.parentId != null && act.parentId >= 0)
                    act = this.userActivityTypes.value(act.parentId);
                if (act != null && rootActivityTypeIdList.indexOf(act.id) < 0) return true;
            }
        }

        return false;
    }

    private parseRule(rule): string {
        var text = this.$scope.textLabels["PLANNING_RULE_TYPE_" + rule.ruleType.toString()];
        var actType = this.userActivityTypes.value(rule.activityTypeId);

        while (text != undefined && text !== "") {
            var startPos = text.indexOf("[");
            var endPos = text.indexOf("]");
            if (startPos < 0 || endPos < 0 || endPos < startPos) return text;
            var tag = text.substring(startPos, endPos + 1);
            var separatorPos = tag.indexOf(":");
            var tagType = tag.substring(1, separatorPos > 0 ? separatorPos : tag.length - 1).toLowerCase();
            var tagLabel = separatorPos > 0 ? tag.substring(separatorPos + 1, tag.length - 1) : "";
            var tagValue = null, tagValueText = null;

            // colored tag --- skipping this now that we broke it into another function
            if (tagType.length > 3 && tagType.substring(0, 4) === "tag-") {
                text = text.substring(0, startPos) + text.substring(endPos + 1);
                continue;
            }

            // note: tagType is cast to lowerCase
            switch (tagType) {
                case "hours":
                    tagValue = rule.hours;
                    break;
                case "ranges":
                    tagValue = rule.ranges;
                    break;
                case "resourcetype":
                    tagValue = rule.resourceTypeId;
                    var resourceType = tagValue ? this.userResourceTypes.value(tagValue) : null;
                    tagValueText = resourceType ? resourceType.displayName : null;
                    break;
                case "integerparameter1":
                    tagValue = rule.integerParameter1;
                    break;
                case "integerparameter2":
                    tagValue = rule.integerParameter2;
                    break;
                case "timerangetype":
                    tagValue = rule.timeRangeType;
                    var nr = tagValue ? Number(tagValue) : 0;
                    if (nr === 1) tagValueText = this.$scope.textLabels.TIME_RANGE_TYPE_1;
                    if (nr === 2) tagValueText = this.$scope.textLabels.TIME_RANGE_TYPE_2;
                    break;
                case "activitytype":
                    tagValue = rule.activityTypeId;
                    if (actType) tagValueText = actType.displayName;
                    break;
                default:
                    tagValue = tagValueText = "";
            }
            if (tagValueText == null && tagValue != null) tagValueText = tagValue.toString();
            if ((tagValueText == null || tagValueText === "") && (tagValue == null || tagValue === ""))
                tagValueText = this.$scope.textLabels.NO_SELECTION;

            text = text.substring(0, startPos) + "<b>" + tagValueText + "</b>" + text.substring(endPos + 1);
        }
        return "";
    }

    // Registers an organization unit as child to its parent.
    private registerChildToParent (organizationUnit: any, childId?: number): void {
        if (!organizationUnit.childIds) organizationUnit.childIds = [];
        if (!organizationUnit.parentId) return;
        if (!childId) childId = organizationUnit.id;
        var parent = this.userOrganizationUnits.value(organizationUnit.parentId);
        if (parent) {
            if (!parent.childIds) parent.childIds = [childId];
            else parent.childIds.push(childId);
            this.registerChildToParent(parent, childId);
        }
    }

    private isTopLevelActivityType(activityType): boolean {
        return !activityType.parentId &&
            activityType.categoryId !== ActivityType.absenceCategoryId &&
            activityType.categoryId !== ActivityType.daymarkCategoryId;
    }

    private isLeafActivityType(activityType): boolean {
        return activityType.parentId &&
            activityType.resourceTypeIdList.length > 0 &&
            activityType.categoryId !== ActivityType.absenceCategoryId &&
            activityType.categoryId !== ActivityType.daymarkCategoryId;
    }

    private hasActivityTypeExpired(activityType): boolean {
        if (!activityType.validTo) return false;
        var atToDate = Timezone.parseDate(activityType.validTo);

        return atToDate < this.date;
    }

    private isScenarioInThePast(scenario): boolean {
        if (!scenario.end) return false;
        var scenEndDate = Timezone.parseDate(scenario.end);

        return scenEndDate < this.date;
    }

    //scenarioWebsocketEventCallback
    private solverWebsocketEventCallback(message) {
        var requestId = message.solverRequestId;
        switch (Number(message.event)) {
            case 1: // added
                if (!this.allSolverRequests.containsKey(requestId)) {
                    this.getSingleRequest(requestId);
                    break;
                }

            case 2: // modified
                var request = this.allSolverRequests.value(requestId);
                this.$timeout(() => {
                    request.status = Number(message.latestRequestStatus);
                    request.progress = Number(message.latestRequestPercentage);
                    request.toScenarioId = Number(message.toScenarioId);
                    this.setStatusDescr(request);
                }, 0);
                break;

            case 3: // deleted
                this.$timeout(() => {
                    this.allSolverRequests.remove(requestId);
                    this.selectedSolverRequest = null;
                    this.selectedSolverRequestId = null;
                }, 0);
                break;
        }
    }
 
}