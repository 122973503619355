import * as Constants from './../../utils/constants'
import { Dictionary, OrderedDictionary } from './../../utils/dictionary'

import { ObjectList } from './../utils/objectlist';
import * as Globals from './../utils/globals';
import { TimeSpan } from './../utils/timespan';
import { Timer } from './../utils/timer';

import { Activity, ActivityList } from './activity';
import { ActivityType } from './activitytype';
import { Planboard } from './planboard';
import { Resource } from './resource';
import { ResourcePlanningStatus } from './resourcePlanningStatus';

/**
    * for a specific resource: pointer to a group and the index within that group
    */
class ResourceIndex {
    id: number; // id of the resource
    groupIndex: number; // index of the group
    groupResIndex: number; // index of the resource in the group

    constructor(id: number, groupIndex: number, groupResIndex: number) {
        this.id = id;
        this.groupIndex = groupIndex;
        this.groupResIndex = groupResIndex;
    }
}

/**
    * for a specific day: array of resources with a list of activities.
    * also remembers the last timestamp that this day was read.
    */
class ActivitiesPerResourcePerDay {
    activitiesPerResource: ActivityList[];
    lastRequestTime: number;
    lastReceivedTime: number;
    viewRevision: number;

    constructor(requestTime: number) {
        this.activitiesPerResource = [];
        this.lastRequestTime = requestTime;
        this.lastReceivedTime = 0;
        this.viewRevision = Planboard.viewRevision - 1; // always start with one change
    }
}

/**
    * the resource counters for a resource
    */
export class ResourceCounters {
    balance: number;
    planned: number;
    required: number;
    absence: number;
    dirtyFlag: number; // 0 = dirty, 1 = pending, 2 = actual
    clear() {
        this.balance = this.planned = this.required = this.absence = null;
        this.dirtyFlag = 0;
    }
}

/**
    * the period for which counters are loaded
    */
export class ResourceCountersPeriod {
    weekNr: number;
    monthNr: number;
    yearNr: number;
    startDate: Date;
    endDate: Date;
}

/**
    * a resource belongs to a group of resources, data retrieval works per group per date period
    * for example: we request data from the webApi for a period of 2 weeks for a group of 20 resources
    */
export class ResourceGroup {
    groupIndex: number;
    resourceList: Resource[]; // list of resources in this group
    resourceViolations: number[]; // the number of violations for each resource in this group
    resourceCounters: ResourceCounters[]; // list of counters for each resource in this group
    resourceCountersPeriod: ResourceCountersPeriod; // the last period for counters that have been read
    dates: ObjectList; // list of ActivitiesPerResourcePerDay that have been read
    minRequestedDayNr: number; // the minimum requested day nr by the function requestData
    maxRequestedDayNr: number; // the maximum requested day nr by the function requestData

    /**
        * create a new group with a unique index.
        */
    constructor(groupIndex: number) {
        this.groupIndex = groupIndex;
        this.resourceList = [];
        this.resourceViolations = [];
        this.resourceCounters = [];
        this.resourceCountersPeriod = null;
        this.dates = new ObjectList();
        this.minRequestedDayNr = null;
        this.maxRequestedDayNr = null;
    }

    /**
        * clear all cached activities in this group.
        */
    clearActivities() {
        this.dates.clear();
        this.resourceCountersPeriod = null; // also clear the counters period
        this.minRequestedDayNr = null;
        this.maxRequestedDayNr = null;
    }

    /**
        * request activity data for a specific day.
        * @param dayNr since 1 January 1970, see the timespan class.
        */
    requestData(dayNr: number) {
        if (Planboard.activityTypesLoading || Planboard.resourcesLoading)
            return; // do not request data if activity types or resources are not finished receiving.
        const minDayNr = (dayNr >> 3) << 3;
        const maxDayNr = minDayNr + 8;
        const requestTime = new Date().getTime();
        this.minRequestedDayNr = this.minRequestedDayNr == null ? minDayNr : Math.min(this.minRequestedDayNr, minDayNr);
        this.maxRequestedDayNr = this.maxRequestedDayNr == null ? maxDayNr : Math.max(this.maxRequestedDayNr, maxDayNr);
        // mark days as requested
        let activitiesOnDay: ActivitiesPerResourcePerDay;
        for (let i = minDayNr; i < maxDayNr; i++) {
            activitiesOnDay = this.dates.getObject(i);
            if (activitiesOnDay == undefined) {
                activitiesOnDay = new ActivitiesPerResourcePerDay(requestTime);
                this.dates.addObject(i, activitiesOnDay);
            }
            activitiesOnDay.lastRequestTime = requestTime;
        }
        // call web api to actually read the days
        Planboard.readActivities(TimeSpan.dayNrToDate(minDayNr), TimeSpan.dayNrToDate(maxDayNr), this.groupIndex);
    }

    /**
        * get all activities that have already been loaded between two dates for a specific resource.
        * @param resIndex index of the resource
        * @param startDay start date (inclusive)
        * @param endDay end date (inclusive)
        */
    getLoadedActivitiesBetween(resIndex: number, startDay: Date, endDay: Date): ActivityList {
        const result = new ActivityList();
        let startDayNr = TimeSpan.getDayNr(startDay);
        const endDayNr = TimeSpan.getDayNr(endDay);
        for (let dayNr = startDayNr; dayNr <= endDayNr; dayNr++) {
            const activitiesOnDay: ActivitiesPerResourcePerDay = this.dates.getObject(dayNr);
            if (activitiesOnDay == undefined || resIndex >= activitiesOnDay.activitiesPerResource.length)
                continue;
            const activities = activitiesOnDay.activitiesPerResource[resIndex];
            if (activities != undefined && activities.items.length > 0)
                for (let actNr = 0; actNr < activities.items.length; actNr++)
                    result.items.push(activities.items[actNr]);
        }
        return result;
    }

    /**
        * get the index of a specific activity for a resource.
        * @param resIndex index of the resource 
        * @param activity the activity to find
        */
    getActivityArrayIndex(resIndex: number, activity: Activity): number {
        const startDayNr = TimeSpan.getDayNr(activity.startDate);
        const endDayNr = TimeSpan.getDayNr(activity.endDate);
        for (let dayNr = startDayNr; dayNr <= endDayNr; dayNr++) {
            const activitiesOnDay: ActivitiesPerResourcePerDay = this.dates.getObject(dayNr);
            if (activitiesOnDay == undefined) continue;
            if (activitiesOnDay.viewRevision !== Planboard.viewRevision) continue;
            if (resIndex >= activitiesOnDay.activitiesPerResource.length) continue;
            const activities = activitiesOnDay.activitiesPerResource[resIndex];
            if (activities == undefined || activities.changes) continue;
            for (let actNr = 0; actNr < activities.items.length; actNr++)
                if (activities.items[actNr].id === activity.id) return actNr;
        }
        return -1;
    }

    /**
        * sort activities by begin time, move absence to the front.
        * @param activities the activities to sort
        * @param resetLines true to reset all remembered line number for activities
        */
    sortActivityList(activities: ActivityList, resetLines: boolean) {
        let actNr = 0;
        let swap: Activity;
        while (actNr < activities.items.length) {
            const activityType = Planboard.activityTypes.getObject(activities.items[actNr].activityTypeId) as ActivityType;
            if (activityType != undefined && activityType.categoryId === ActivityType.absenceCategoryId) {
                activities.items[actNr].lineNr = -2;
                while (actNr > 0 && activities.items[actNr - 1].lineNr !== -2) { // move absence to the front of the list
                    swap = activities.items[actNr - 1];
                    activities.items[actNr - 1] = activities.items[actNr];
                    activities.items[actNr] = swap;
                    actNr--;
                }
            } else {
                if (resetLines && activities.items[actNr].id > 0) // ignore activities with a negative id
                    activities.items[actNr].lineNr = -1;
                if (actNr > 0 && activities.items[actNr - 1].lineNr !== -2) {
                    if (this.compareActivitiesSameDay(activities.items[actNr], activities.items[actNr - 1]) < 0) {
                        swap = activities.items[actNr - 1];
                        activities.items[actNr - 1] = activities.items[actNr];
                        activities.items[actNr] = swap;
                        actNr -= 2;
                    }
                }
            }
            actNr++;
        }
    }

    /**
        * get a list of activities for a resource for a specific date
        * @param resIndex index of the resource
        * @param startDay the date
        * @param offsetDay number of days to add or subtract from startDay
        * @param sortAbsences true to also sort the activities by begin time and move absence to the top
        * @param viewRevisionChangedAction the action to execute if there was a change in view revision, can be null
        */
    getActivityList(resIndex: number, startDay: Date, offsetDay, sortAbsences: boolean,
        viewRevisionChangedAction: (activities: ActivityList) => void): ActivityList {
        const dayNr = TimeSpan.getDayNr(startDay) + offsetDay; // convert total milliseconds to total days
        const activitiesOnDay: ActivitiesPerResourcePerDay = this.dates.getObject(dayNr);
        if (activitiesOnDay == undefined) {
            this.requestData(dayNr);
            return undefined;
        }
        if (activitiesOnDay.lastReceivedTime === 0) { // no data received yet for this day
            if (activitiesOnDay.lastRequestTime < new Date().getTime() - 20000) { // request was more than 20 seconds ago, request again
                this.requestData(dayNr);
            }
        }
        if (resIndex < activitiesOnDay.activitiesPerResource.length) {
            const activities = activitiesOnDay.activitiesPerResource[resIndex];
            if (activities != undefined && activities.items.length > 0) {
                if (activitiesOnDay.viewRevision !== Planboard.viewRevision) {
                    // set changed to true for all resources (in this group on this day) when the viewRevision changed, so that they will be resorted below
                    if (sortAbsences || viewRevisionChangedAction != null)
                        for (let i = 0; i < activitiesOnDay.activitiesPerResource.length; i++)
                            if (activitiesOnDay.activitiesPerResource[i] != undefined)
                                activitiesOnDay.activitiesPerResource[i].changes = true;
                    activitiesOnDay.viewRevision = Planboard.viewRevision;
                }
                if (activities.changes) {
                    // sort activities so that absence activities are in the front of the list
                    if (sortAbsences) this.sortActivityList(activities, true);
                    // execute callback action so that the caller can make changes to the list of activities before it is returned
                    if (viewRevisionChangedAction != null) {
                        activitiesOnDay.viewRevision = Planboard.viewRevision - 1;
                        viewRevisionChangedAction(activities);
                        activitiesOnDay.viewRevision = Planboard.viewRevision;
                    }
                    activities.changes = false;
                }
            }
            return activities;
        }
        return undefined;
    }

    /**
        * compare function to determine the order of activities.
        */
    compareActivitiesSameDay(act1: Activity, act2: Activity): number {
        if (act1.startDate < act2.startDate) return -1;
        if (act2.startDate < act1.startDate) return 1;
        if (act1.endDate > act2.endDate) return -1;
        if (act2.endDate > act1.endDate) return 1;
        return act1.id < act2.id ? -1 : 1;
    }

    /**
        * get the timestamp when last data was received for a specific day.
        */
    getLastReceived(dayNr: number): number {
        const activitiesOnDay: ActivitiesPerResourcePerDay = this.dates.getObject(dayNr);
        if (activitiesOnDay != undefined)
            return activitiesOnDay.lastReceivedTime;
        return 0;
    }

    /**
        * set the timestamp for when data was last received for a specific day.
        */
    setLastReceived(dayNr: number, receivedTime: number) {
        let activitiesOnDay: ActivitiesPerResourcePerDay = this.dates.getObject(dayNr);
        if (activitiesOnDay == undefined) {
            activitiesOnDay = new ActivitiesPerResourcePerDay(0);
            this.dates.addObject(dayNr, activitiesOnDay);
        }
        activitiesOnDay.lastReceivedTime = receivedTime;
    }

    /**
        * reset the lastReceived time for activities for resources on a day
        */
    private resetActivitiesPerResourcePerDay(activitiesOnDay: ActivitiesPerResourcePerDay, unloadActivities: boolean, resourceIdList: number[]) {
        activitiesOnDay.lastReceivedTime = 0;
        activitiesOnDay.lastRequestTime = 0;
        if (unloadActivities) {
            for (let i = 0; i < activitiesOnDay.activitiesPerResource.length; i++)
                if (activitiesOnDay.activitiesPerResource[i]) {
                    const activitiesForResource = activitiesOnDay.activitiesPerResource[i].items;
                    if (activitiesForResource)
                        for (let j = 0; j < activitiesForResource.length; j++)
                            activitiesForResource[j].setFlag(Constants.flagReloadActivity, true);
                    activitiesOnDay.activitiesPerResource[i].changes = true;
                }
            if (resourceIdList == null)
                activitiesOnDay.activitiesPerResource.length = 0; // actually removes the activities from the planboard
            else
                for (let i = 0; i < resourceIdList.length; i++) {
                    const index = Planboard.activities.getResourceIndexWithinGroup(resourceIdList[i]);
                    const forResourceOnDay = index == undefined ? undefined : activitiesOnDay.activitiesPerResource[index];
                    if (forResourceOnDay && forResourceOnDay.items && forResourceOnDay.items.length > 0) {
                        forResourceOnDay.items.length = 0;
                        forResourceOnDay.changes = true;
                    }
                }
        }
        activitiesOnDay.viewRevision = Planboard.viewRevision - 1;
    }

    /**
        * reset when data was last received for a range of days
        * @param fromDayNr day number or null for all days
        * @param toDayNr day number or null for all days
        * @param unloadActivities true to also unload existing activities
        * @param resourceIdList optional: resource Ids for who to unload existing activities
        */
    resetDaysReceived(fromDayNr: number, toDayNr: number, unloadActivities: boolean, resourceIdList: number[]) {
        if (fromDayNr == null && toDayNr == null) {
            this.dates.forEach((id, item) => {
                this.resetActivitiesPerResourcePerDay(item, unloadActivities, resourceIdList);
            });
            return;
        }
        if (fromDayNr == null) fromDayNr = this.dates.getMinBucketId();
        if (toDayNr == null) toDayNr = this.dates.getMaxBucketId();
        if (fromDayNr == null || toDayNr == null) return;
        while (fromDayNr <= toDayNr) {
            let activitiesOnDay: ActivitiesPerResourcePerDay = this.dates.getObject(fromDayNr);
            if (activitiesOnDay != undefined) {
                this.resetActivitiesPerResourcePerDay(activitiesOnDay, unloadActivities, resourceIdList);
            }
            fromDayNr++;
        }
    }

    /**
        * remove an activity from a resource.
        * @param resIndex the index of the resource
        * @param activityId the id of the activity
        * @param startDate start date to start searching for this activity
        * @param endDate end date to stop searching for this activity
        */
    removeActivity(resIndex: number, activityId: number, startDate: Date, endDate: Date) {
        if (this.resourceCounters[resIndex] != undefined) this.resourceCounters[resIndex].dirtyFlag = 0; // clear counters for this resource
        let startDay = TimeSpan.getDayNr(startDate); // convert startDate total milliseconds to total days
        const endDay = TimeSpan.getDayNrWithOffset(endDate, -1); // convert activity end total milliseconds to total days
        let activitiesOnDay: ActivitiesPerResourcePerDay;
        while (startDay <= endDay) {
            activitiesOnDay = this.dates.getObject(startDay);
            if (activitiesOnDay != undefined && activitiesOnDay.activitiesPerResource[resIndex] != undefined) {
                let i = 0;
                while (i < activitiesOnDay.activitiesPerResource[resIndex].items.length) {
                    if (activitiesOnDay.activitiesPerResource[resIndex].items[i].id === activityId)
                        activitiesOnDay.activitiesPerResource[resIndex].items.splice(i, 1);
                    else
                        i++;
                }
                activitiesOnDay.activitiesPerResource[resIndex].changes = true;
            }
            startDay++;
        }
    }

    /**
        * add an activity to a resource, without checking if the resource already has this activity.
        * @param resIndex index of the resource
        * @param a the activity to add
        */
    addActivity(resIndex: number, a: Activity) {
        if (this.resourceCounters[resIndex] != undefined) this.resourceCounters[resIndex].dirtyFlag = 0; // clear counters for this resource
        let startDay = TimeSpan.getDayNr(a.startDate); // convert activity start total milliseconds to total days
        const endDay = TimeSpan.getDayNrWithOffset(a.endDate, -1); // convert activity end total milliseconds to total days
        let activitiesOnDay: ActivitiesPerResourcePerDay;
        while (startDay <= endDay) {
            activitiesOnDay = this.dates.getObject(startDay);
            if (activitiesOnDay == undefined) { // this day was not yet initialized
                activitiesOnDay = new ActivitiesPerResourcePerDay(0);
                this.dates.addObject(startDay, activitiesOnDay);
            }
            let activitiesOnDayForResource = activitiesOnDay.activitiesPerResource[resIndex];
            if (activitiesOnDayForResource == undefined) {
                activitiesOnDayForResource = new ActivityList();
                activitiesOnDay.activitiesPerResource[resIndex] = activitiesOnDayForResource;
            }
            if (activitiesOnDayForResource.items.length < 500) { // only add if there are less than 500 activities on this day for this resource
                if (a.id > 0) a.lineNr = -1;
                activitiesOnDayForResource.items.push(a); // add to activity list for this resource for this day
                activitiesOnDayForResource.changes = true;
            }
            startDay++;
        }
    }
}

/**
    * static class for storing and retrieving activities per resource
    */
export class ResourceActivities {
    private resIdToIndex = new ObjectList(); // convert resource id to resourceIndex number
    private resourceIndex: ResourceIndex[] = []; // array of resource pointers to groups
    private resourceGroups: ResourceGroup[] = []; // array of groups
    private activities = new ObjectList(); // all activities as activity object
    private activityChilds = new ObjectList(); // childArray id lists per activity (only for activities that have children)
    private openActivities = new ResourceGroup(-1); // reuse ResourceGroup for open activities, here is only 1 resource that has all the activities
    private activityMemos = new ObjectList(); // all memos as string
    private activityDetails = new ObjectList(); // all details per activity
    private lazyLoadMemoIdList: Array<number> = []; // array of memo ids that should be lazy loaded
    private lazyLoadResourceIdList: Array<number> = []; // array of resource ids that should be lazy loaded
    private lazyLoadPropertiesResourceIdList: Array<number> = []; // array of resource ids for who to lazy load properties
    private lazyLoadResourceCountersForIds = new ObjectList(); // resource ids for who to lazy load counters
    private lazyLoadResourceCountersPeriod: ResourceCountersPeriod = null; // the last counters period that was used 
    private lazyLoadTimer: Timer;
    private lazyLoadRestartableTimer: Timer;
    private resourceSelections = new ObjectList(); // contains multiple resource id lists
    public activeResourceSelectionId = 0; // unique identifier for the active list of resources
    public notGroupedResources = new ObjectList(); // resources that are not in a group, but are planned on an activity

    /**
        * get additional information about resources from the WebApi, such as UserProperties, OrganizationUnits, PlanningStatus
        */
    private loadAdditionalResourceInformation() {
        const propertiesForResourceIdList = this.lazyLoadPropertiesResourceIdList;
        this.lazyLoadPropertiesResourceIdList = [];
        Planboard.readResourceProperties(propertiesForResourceIdList);
        window.setTimeout(() => Planboard.readResourceUnits(propertiesForResourceIdList), 100);
        window.setTimeout(() => Planboard.readResourcePlanningStatus(propertiesForResourceIdList), 200);
    }

    /**
        * initialize timer to load resource display names and memos when needed.
        */
    private initializeLazyLoadTimer() {
        if (!this.lazyLoadTimer) {
            this.lazyLoadTimer = new Timer(null, 1000);
            this.lazyLoadTimer.tick = (t: Timer) => {
                let enabled = false;
                if (this.lazyLoadMemoIdList.length > 0) {
                    enabled = true;
                    const memoIdList = this.lazyLoadMemoIdList;
                    this.lazyLoadMemoIdList = [];
                    Planboard.readActivityMemos(memoIdList);
                }
                if (this.lazyLoadResourceIdList.length > 0) {
                    enabled = true;
                    const resourceIdList = this.lazyLoadResourceIdList;
                    this.lazyLoadResourceIdList = [];
                    Planboard.readResourceDisplayNames(resourceIdList);
                }
                if (this.lazyLoadPropertiesResourceIdList.length > 0) {
                    enabled = true;
                    this.loadAdditionalResourceInformation();
                }
                this.lazyLoadTimer.enabled = enabled;
            }
        }
        this.lazyLoadTimer.enabled = true;
    }

    /**
        * initialize timer and restart it with a specific interval, currently used for resource counters
        */
    initializeRestartableTimer(interval: number) {
        if (!this.lazyLoadRestartableTimer) {
            this.lazyLoadRestartableTimer = new Timer(null, interval);
            this.lazyLoadRestartableTimer.tick = (t: Timer) => {
                let enabled = false;
                if (this.lazyLoadResourceCountersForIds.count > 0) {
                    enabled = true;
                    const countersResourceIdList = this.lazyLoadResourceCountersForIds.getKeys();
                    this.lazyLoadResourceCountersForIds.clear();
                    Planboard.readResourceCounters(countersResourceIdList, this.lazyLoadResourceCountersPeriod.weekNr,
                        this.lazyLoadResourceCountersPeriod.monthNr, this.lazyLoadResourceCountersPeriod.yearNr,
                        this.lazyLoadResourceCountersPeriod.startDate, this.lazyLoadResourceCountersPeriod.endDate);
                }
                this.lazyLoadRestartableTimer.enabled = enabled;
            }
        }
        this.lazyLoadRestartableTimer.restart(interval);
    }

    /**
        * remove loaded activity details
        * @param activityId the activity for which to remove the previously loaded activity details
        */
    removeActivityDetails(activityId: number) {
        this.activityDetails.removeObject(activityId);
    }

    /**
        * set details for an activity
        * @param activityId the id of the activity
        * @param details the details array
        */
    setActivityDetails(activityId: number, details: any[]) {
        this.activityDetails.addObject(activityId, details);
    }

    /**
        * get details for a specific activity
        * @param activityId the activity to get the details for
        * @param loadIfNotFound when true will load the details if they have not yet been loaded
        * @param loadAlways when true will load the details, even if they have already been loaded
        */
    getActivityDetails(activityId: number, loadIfNotFound: boolean, loadAlways: boolean) {
        const details = this.activityDetails.getObject(activityId);
        if ((details == undefined && loadIfNotFound) || loadAlways) Planboard.readActivityDetails(activityId);
        return details;
    }

    /**
        * add a memo to the list of lazy loading memos
        * @param memoId the id of the memo
        */
    lazyLoadMemo(memoId: number) {
        this.lazyLoadMemoIdList.push(memoId);
        if (this.lazyLoadMemoIdList.length > 100) {
            const memoIdList = this.lazyLoadMemoIdList;
            this.lazyLoadMemoIdList = [];
            Planboard.readActivityMemos(memoIdList);
        } else
            this.initializeLazyLoadTimer();
    }

    /**
        * returns resource properties for a resource, will load the properties for resources on demand
        * @param resource the resource
        */
    getResourceProperties(resource: Resource): Dictionary {
        if (resource.properties == null) {
            resource.properties = new Dictionary();
            resource.units = new OrderedDictionary();
            resource.lastMembershipUnit = null;
            resource.planningStatus = [];
            this.lazyLoadPropertiesResourceIdList.push(resource.id);
            if (this.lazyLoadPropertiesResourceIdList.length > 100)
                this.loadAdditionalResourceInformation();
            else
                this.initializeLazyLoadTimer();
        }
        return resource.properties;
    }

    /**
        * returns organization units for a resource, will load the organization units for resources on demand
        * @param resource the resource
        */
    getResourceUnits(resource: Resource): OrderedDictionary {
        this.getResourceProperties(resource); // re-use the mechanism for resource properties, these are always requested at the same time with the organization units in the planboard view
        return resource.units;
    }

    /**
        * returns planning status (currently only the latest published date) for a resource, will load the planning status for resources on demand
        * @param resource the resource
        */
    getResourcePlanningStatus(resource: Resource): ResourcePlanningStatus[] {
        this.getResourceProperties(resource); // re-use the mechanism for resource properties, these are always requested at the same time with the planning status in the planboard view
        return resource.planningStatus;
    }

    /**
        * get a memo text
        * @param memoId the id of the memo
        * @param loadIfNotFound when true will lazy load the memo if it has not yet been loaded
        * @param loadAlways when true will lazy load the memo, even if it has already been loaded
        */
    getMemoText(memoId: number, loadIfNotFound: boolean, loadAlways: boolean) {
        let memoText = this.activityMemos.getObject(memoId);
        if (memoText == undefined && loadIfNotFound) {
            memoText = "";
            this.activityMemos.addObject(memoId, memoText);
            this.lazyLoadMemo(memoId);
        } else if (loadAlways)
            this.lazyLoadMemo(memoId);
        return memoText;
    }

    /**
        * set a memo text
        * @param memoId the id of the memo
        * @param memoText the text of the memo
        */
    setMemoText(memoId: number, memoText: string) {
        this.activityMemos.addObject(memoId, memoText);
    }

    /**
        * clears all memo cache
        */
    clearAllMemoTexts() {
        this.activityMemos.clear();
    }

    /**
        * cancels timer to load resource display names and memos
        */
    cancelLazyLoadTimer() {
        if (this.lazyLoadTimer) this.lazyLoadTimer.enabled = false;
    }

    /**
        * make a resource selection active in the planboard
        * @param selectionId the id of the selection
        */
    activateResourceSelection(selectionId: number, refreshView: boolean) {
        if (!this.getResourceIdsInSelection(selectionId)) this.setResourceIdsInSelection(selectionId, []);
        this.activeResourceSelectionId = selectionId;
        if (refreshView) {
            Planboard.updateResourceRows();
            Planboard.redrawAll();
            Planboard.timedRefresh();
        }
    }

    /**
        * set or replace a resource id list.
        * @param selectionId unique identifier for this list of resource ids
        * @param resourceIdList list of resource ids
        */
    setResourceIdsInSelection(selectionId: number, resourceIdList: Array<number>) {
        this.resourceSelections.addObject(selectionId, resourceIdList);
        this.addResources(resourceIdList);
    }

    /**
        * returns if a current selection is identical to the selection in a list
        * @param selectionId unique identifier for current list of resource ids
        * @param resourceIdList new list of resource ids
        */
    isIdenticalResourceSelection(selectionId: number, resourceIdList: Array<number>): boolean {
        const currList = this.resourceSelections.getObject(selectionId);
        if (currList == null || resourceIdList == null || currList.length !== resourceIdList.length) return false;
        for (let i = 0; i < currList.length; i++) if (currList[i] !== resourceIdList[i]) return false;
        return true;
    }

    /**
        * get a selection of resource ids.
        * @param selectionId unique identifier for this list of resource ids
        */
    getResourceIdsInSelection(selectionId: number): Array<number> {
        return this.resourceSelections.getObject(selectionId);
    }

    /**
     * returns if any data was ever requested between two dates
     * @param startDay start date (inclusive)
     * @param endDay end date (inclusive)
     */
    isAnyDataRequested(startDay: Date, endDay: Date): boolean {
        const startDayNr = TimeSpan.getDayNr(startDay);
        const endDayNr = TimeSpan.getDayNr(endDay);
        for (let i = 0; i < this.resourceGroups.length; i++) {
            let resourceGroup = this.resourceGroups[i];
            if (resourceGroup.minRequestedDayNr != null &&
                endDayNr >= resourceGroup.minRequestedDayNr &&
                startDayNr <= resourceGroup.maxRequestedDayNr) {
                return true;
            }
        }
        return false;
    }

    /**
        * clear all cached activities
        */
    clearActivities() {
        Planboard.selectedActivity = null;
        this.lazyLoadMemoIdList.length = 0;
        this.activityMemos.clear();
        this.activityDetails.clear();
        this.activities.clear();
        this.activityChilds.clear();
        this.openActivities.clearActivities();
        for (let i = 0; i < this.resourceGroups.length; i++)
            this.resourceGroups[i].clearActivities();
    }

    clearOpenActivities() {
        this.openActivities.dates.forEach(
            (_, date) => {
                if (!date || !date.activitiesPerResource[0] || !date.activitiesPerResource[0].items) {
                    return;
                }

                const activities = date.activitiesPerResource[0].items as Activity[];
                for (let i = 0; i < activities.length; i++) {
                    this.activities.removeObject(activities[i].id);
                }
               
            });
        this.openActivities.clearActivities();
    }

    /**
        * clear all cached resources
        */
    clearResources() {
        this.lazyLoadResourceIdList.length = 0;
        this.notGroupedResources.clear();
        this.lazyLoadResourceCountersForIds.clear();
        this.resIdToIndex.clear();
        this.resourceIndex.length = 0;
        this.resourceGroups.length = 0;
        this.resourceSelections.clear();
    }

    /**
        * clear all cached extra resource data such as organization units, resource properties, planning status
        */
    clearResourceExtraData() {
        this.lazyLoadResourceIdList.length = 0;
        let i = this.resourceGroups.length;
        while (i-- > 0) {
            let resources = this.resourceGroups[i].resourceList;
            let j = resources == null ? 0 : resources.length;
            while (j-- > 0) {
                resources[j].properties = null;
                resources[j].units = null;
                resources[j].planningStatus = null;
            }
        }
    }

    /**
        * clear all cached resource counters data
        */
    clearResourceCounters() {
        this.lazyLoadResourceCountersForIds.clear();
        let i = this.resourceGroups.length;
        while (i-- > 0) this.resourceGroups[i].resourceCountersPeriod = null;
    }

    /**
        * clear all cached resource violation data (currently this is only the total number of violations for each resource)
        */
    clearResourceViolations() {
        let i = this.resourceGroups.length;
        while (i-- > 0) this.resourceGroups[i].resourceViolations = [];
    }

    /**
        * get the activity with a specific id, returns undefined if the activity can not be found
        * @param id the id of the activity
        */
    getActivity(id: number): Activity {
        return this.activities.getObject(id) as Activity;
    }

    /**
        * get the main activity for a specific activity
        * @param id one of the activity ids in the main activity
        */
    getMainActivity(id: number): Activity {
        let mainActivity = Planboard.activities.getActivity(id);
        while (mainActivity != undefined && mainActivity.parentId >= 0)
            mainActivity = Planboard.activities.getActivity(mainActivity.parentId);
        return mainActivity;
    }

    /**
        * get a list of child activity ids for a specific activity id
        * @param parentId the activity id
        */
    getActivityChilds(parentId: number): Array<number> {
        return this.activityChilds.getObject(parentId) as Array<number>;
    }

    /**
        * test (and set) if an activity and all child/parent activities are completely filled
        * @param id one of the activity ids in the group
        */
    checkFilled(id: number) {
        const mainAct = this.getMainActivity(id);
        if (mainAct == null) return;
        const actIdList: number[] = [];
        actIdList.push(mainAct.id);
        let index = 0;
        let filled = 1;
        while (index < actIdList.length) {
            let act = this.getActivity(actIdList[index]);
            if (act == undefined) break;
            act.filled = filled;
            // if the resource is not set then this could have child activities
            // if the activity has the status "not required" it must be a leaf and we can skip it
            if (act.resourceId == null) {
                const children = this.getActivityChilds(actIdList[index]);
                if (children != undefined && children.length > 0) {
                    for (let i = 0; i < children.length; i++) {
                        actIdList.push(children[i]);
                    }
                } else {
                    // there are no children, so this must already be a leaf activity
                    if (filled !== 0 && act.status !== Constants.StatusActivityNotRequired) {
                        filled = 0;
                        for (let i = 0; i <= index; i++) {
                            act = this.getActivity(actIdList[i]);
                            act.filled = filled;
                        }
                    }
                }
            }
            index++;
        }
    }

    /**
        * get the total number of resources
        * @param useActiveSelection true to use the active selection, false to use all resources
        */
    getResourceCount(useActiveSelection: boolean = true): number {
        if (useActiveSelection && this.activeResourceSelectionId !== 0) {
            var resourceIdList = this.getResourceIdsInSelection(this.activeResourceSelectionId);
            return resourceIdList == null ? 0 : resourceIdList.length;
        }
        return this.resourceIndex.length;
    }

    /**
        * get the id for a resource
        * @param index the index of the resource from 0 to resourceCount-1
        * @param useActiveSelection true to use the active selection, false to use all resources
        */
    getResourceId(index: number, useActiveSelection: boolean = true): number {
        if (index < 0) return -1; // bugfix, I have seen this code be reached with an index of -1 when refreshing the page, this should never happen.
        if (useActiveSelection && this.activeResourceSelectionId !== 0)
            return this.getResourceIdsInSelection(this.activeResourceSelectionId)[index];
        return this.resourceIndex[index] != undefined ? this.resourceIndex[index].id : -1;
    }

    /**
        * get the row index for a resource
        * @param resourceId the id of the resource
        * @param useActiveSelection true to use the active selection, false to use all resources
        */
    getResourceRowIndex(resourceId: number, useActiveSelection: boolean = true): number {
        if (resourceId == undefined || resourceId < 0) return -1;
        if (useActiveSelection && this.activeResourceSelectionId !== 0)
            return this.getResourceIdsInSelection(this.activeResourceSelectionId).indexOf(resourceId);
        let index = this.resIdToIndex.getObject(resourceId);
        if (index == undefined) index = -1;
        return index;
    }

    /**
        * get the number of violations for a resource
        * @param resourceId the id of the resource
        */
    getResourceViolations(resourceId: number): number {
        const index = this.resIdToIndex.getObject(resourceId);
        let violations = undefined;
        if (index != undefined)
            violations = this.resourceGroups[this.resourceIndex[index].groupIndex].resourceViolations[this.resourceIndex[index].groupResIndex];
        return violations == undefined ? 0 : violations;
    }

    /**
        * set the number of violations for a resource
        * @param resourceId the id of the resource
        * @param nrOfConflicts the number of violations to remember for this resource
        */
    setResourceViolations(resourceId: number, nrOfViolations: number) {
        const index = this.resIdToIndex.getObject(resourceId);
        if (index != undefined)
            this.resourceGroups[this.resourceIndex[index].groupIndex].resourceViolations[this.resourceIndex[index].groupResIndex] = nrOfViolations;
    }

    /**
        * get a resource object for a resource id
        * @param resourceId the id of the resource
        * @param includingNotGrouped when true, this will also return the resource object for resources that are not (yet) in a group
        */
    getResource(resourceId: number, includingNotGrouped: boolean = false): Resource {
        const index = this.resIdToIndex.getObject(resourceId);
        if (index != undefined)
            return this.resourceGroups[this.resourceIndex[index].groupIndex].resourceList[this.resourceIndex[index].groupResIndex];
        if (!includingNotGrouped) return null;
        return this.notGroupedResources.getObject(resourceId);
    }

    /**
        * get the group index for a resource id
        * @param resourceId the id of the resource
        */
    getResourceGroupIndex(resourceId: number): number {
        var index = this.resIdToIndex.getObject(resourceId);
        if (index != undefined) return this.resourceIndex[index].groupIndex;
        return undefined;
    }

    /**
        * get the index within a group for a resource id 
        * @param resourceId the id of the resource
        */
    getResourceIndexWithinGroup(resourceId: number): number {
        var index = this.resIdToIndex.getObject(resourceId);
        if (index != undefined) return this.resourceIndex[index].groupResIndex;
        return undefined;
    }

    /**
        * update an existing resource, can also use addResource(r,true).
        * @param r the resource to update
        */
    setResource(r: Resource) {
        const index = this.resIdToIndex.getObject(r.id);
        if (index != undefined)
            this.resourceGroups[this.resourceIndex[index].groupIndex].resourceList[this.resourceIndex[index].groupResIndex] = r;
    }

    /**
        * get an index for a resource group that can still use resources
        */
    private getFreeGroupIndex(): number {
        let groupIndex = this.resourceGroups.length - 1;
        if (groupIndex < 0 || // make a new group if there is no group
            this.resourceGroups[groupIndex].dates.count > 0 || // make a new group if the last group already has dates initialized
            this.resourceGroups[groupIndex].resourceList.length > 50) { // make a new group if the last group already has 50 resources
            groupIndex = this.resourceGroups.length;
            this.resourceGroups.push(new ResourceGroup(groupIndex));
        }
        return groupIndex;
    }

    /**
        * add a resource object to the total list of resources
        * @param r the resource object to add
        * @param replaceExisting true to replace the resource if it already exists
        */
    addResource(r: Resource, replaceExisting: boolean = false) {
        if (this.resIdToIndex.getObject(r.id) != undefined) { // already exists
            if (replaceExisting) this.setResource(r);
            return;
        }
        const groupIndex = this.getFreeGroupIndex();
        this.resourceGroups[groupIndex].resourceList.push(r);
        this.resourceGroups[groupIndex].clearActivities();
        this.resourceIndex.push(new ResourceIndex(r.id, groupIndex, this.resourceGroups[groupIndex].resourceList.length - 1));
        this.resIdToIndex.addObject(r.id, this.resourceIndex.length - 1);
    }

    /**
        * add a temporary list of resources, to be replaced by real resources later.
        * @param resourceIdList array with resource ids
        */
    addResources(resourceIdList: Array<number>) {
        for (let i = 0; i < resourceIdList.length; i++) {
            const r = new Resource(resourceIdList[i], "", `${resourceIdList[i]}`);
            this.addResource(r, false);
        }
    }

    /**
        * get all resources in a resource group
        * @param groupIndex the group
        */
    getResourcesInGroup(groupIndex: number): Resource[] {
        if (groupIndex >= 0)
            return this.resourceGroups[groupIndex].resourceList;
        return [];
    }

    /**
        * set the received time for a range of dates
        * @param fromDate start date (inclusive)
        * @param toDate end date (exclusive)
        * @param groupIndex the group to set the received time for
        * @param receivedTime the received time stamp in total milliseconds
        */
    setDaysReceivedTime(fromDate: Date, toDate: Date, groupIndex: number, receivedTime: number) {
        let startDay = TimeSpan.getDayNr(fromDate); // convert startDate total milliseconds to total days
        const endDay = TimeSpan.getDayNr(toDate); // convert endDate total milliseconds to total days
        while (startDay < endDay) {
            if (groupIndex >= 0)
                this.resourceGroups[groupIndex].setLastReceived(startDay, receivedTime);
            else  // openActivities groupIndex is -1
                this.openActivities.setLastReceived(startDay, receivedTime);
            startDay++;
        }
    }

    /**
        * reset that data was received for a specific date range for a specific resource (or for all resources)
        * @param fromDate optional start date, null for earliest read date
        * @param toDate optional end date, null for latest read date
        * @param resourceId optional resource id, null for all resources
        * @param groupIndex optional index of the resource group
        * @param includingOpenActivities true to also reset received for open activities
        * @param unloadActivities true to also unload existing activities
        * @param resourceIdList optional: resource Ids for who to unload existing activities
        */
    resetDaysReceived(fromDate: Date, toDate: Date, resourceId: number, groupIndex: number, includingOpenActivities: boolean, unloadActivities: boolean, resourceIdList: number[]) {
        const startDay = fromDate == null ? null : TimeSpan.getDayNr(fromDate);
        const endDay = toDate == null ? null : TimeSpan.getDayNr(toDate);
        if (resourceId != null && groupIndex == null) {
            const index = this.resIdToIndex.getObject(resourceId);
            if (index != undefined) groupIndex = this.resourceIndex[index].groupIndex;
        }
        let fromGroupIndex = groupIndex == null ? 0 : groupIndex;
        const toGroupIndex = groupIndex == null ? this.resourceGroups.length - 1 : groupIndex;
        while (fromGroupIndex != undefined && fromGroupIndex <= toGroupIndex && fromGroupIndex >= 0) {
            this.resourceGroups[fromGroupIndex].resetDaysReceived(startDay, endDay, unloadActivities, resourceIdList);
            fromGroupIndex++;
        }
        if (includingOpenActivities)
            this.openActivities.resetDaysReceived(startDay, endDay, unloadActivities, null);
    }

    /**
        * add a resource object that should be lazy loaded
        * @param resourceId the id of the resource
        */
    addNotGroupedResource(resourceId: number) {
        let r = this.notGroupedResources.getObject(resourceId);
        if (r) { // already exists, or was already requested
            if (r.displayName !== "") return; // already received
            if (r.lastRequestTime > 0 && r.lastRequestTime > new Date().getTime() - 9000) return; // was recently requested
        }
        r = new Resource(resourceId, "", "");
        r.lastRequestTime = new Date().getTime(); // remember when this was last requested
        this.notGroupedResources.addObject(resourceId, r);
        this.lazyLoadResourceIdList.push(resourceId);
        if (this.lazyLoadResourceIdList.length > 100) {
            const idList = this.lazyLoadResourceIdList;
            this.lazyLoadResourceIdList = [];
            Planboard.readResourceDisplayNames(idList);
        } else
            this.initializeLazyLoadTimer();
    }

    /**
        * add an activity to the list
        * @param a the activity to add
        * @param replaceExisting when true the existing activity will be removed and the new activity will be reinserted
        */
    addActivity(a: Activity, replaceExisting: boolean) {
        const oldActivity = this.activities.getObject(a.id) as Activity;
        if (oldActivity != undefined) {
            const unloadActivity = oldActivity.getFlag(Constants.flagReloadActivity);
            if (unloadActivity) {
                // the activity should be unloaded, in this case we will replace it anyway and reset the unload flag to false
                oldActivity.setFlag(Constants.flagReloadActivity, false);
            }
            else if (!replaceExisting) {
                // we are not replacing the existing, but if the resource was previously not grouped, we need to check if it is grouped now
                if (oldActivity.resourceId != null && !oldActivity.getFlag(Constants.flagResourceIsGrouped)) {
                    const index = this.resIdToIndex.getObject(oldActivity.resourceId);
                    if (index != undefined) {
                        oldActivity.setFlag(Constants.flagResourceIsGrouped, true);
                        this.resourceGroups[this.resourceIndex[index].groupIndex]
                            .addActivity(this.resourceIndex[index].groupResIndex, oldActivity);
                    }
                }
                return;
            }
            this.removeActivity(oldActivity);
        }
        if (a.resourceId != null) {
            const index = this.resIdToIndex.getObject(a.resourceId);
            if (index != undefined)
                this.resourceGroups[this.resourceIndex[index].groupIndex]
                    .addActivity(this.resourceIndex[index].groupResIndex, a);
            else {
                this.addNotGroupedResource(a.resourceId);
                a.setFlag(Constants.flagResourceIsGrouped, false);
            }
        } else {
            const activityType = Planboard.activityTypes.getObject(a.activityTypeId);
            if (activityType && activityType.isLeaf) // only for leaf nodes
                this.openActivities.addActivity(0, a);
        }
        this.activities.addObject(a.id, a);
        if (a.parentId >= 0) { // add to parents list of child ids
            let lst = this.activityChilds.getObject(a.parentId) as Array<number>;
            if (lst == undefined) {
                lst = [];
                lst.push(a.id);
                this.activityChilds.addObject(a.parentId, lst);
            } else
                lst.push(a.id);
        }
    }

    /**
        * remove an activity from the list
        * @param a the activity to remove
        * @param removeChildList true to remove the list of child activities, default this is false
        */
    removeActivity(a: Activity, removeChildList: boolean = false) {
        if (a.resourceId == null) {
            const activityType = Planboard.activityTypes.getObject(a.activityTypeId);
            if (activityType && activityType.isLeaf) // only for leaf nodes
                this.openActivities.removeActivity(0, a.id, a.startDate, a.endDate);
            this.activities.removeObject(a.id);
        } else {
            const index = this.resIdToIndex.getObject(a.resourceId);
            if (index != undefined)
                this.resourceGroups[this.resourceIndex[index].groupIndex]
                    .removeActivity(this.resourceIndex[index].groupResIndex, a.id, a.startDate, a.endDate);
            this.activities.removeObject(a.id);
        }
        if (a.parentId >= 0) { // remove from parents list of child ids
            const lst = this.activityChilds.getObject(a.parentId) as Array<number>;
            if (lst != undefined) {
                const i = lst.indexOf(a.id);
                if (i >= 0) lst.splice(i, 1);
            }
        }
        if (removeChildList) this.activityChilds.removeObject(a.id);
    }

    /**
        * get resource counters for a specific resource
        * if weekNr is specified then counters per week will be returned
        * if monthNr is specified and weekNr is not specified then counters per month will be returned
        * if yearNr is specified and both weekNr and monthNr are not specified then counters per year will be returned
        * @param resourceId the resource id
        * @param weekNr the week number, or -1 or null if the counters are not per week
        * @param monthNr the month number, or -1 or null if the counters are not per month
        * @param yearNr the year number, always required
        * @param startDate optional, if not null then the startDate will be used instead of weekNr/monthNr/yearNr
        * @param endDate optional, if not null then the endDate will be used instead of weekNr/monthNr/yearNr
        */
    getResourceCounters(resourceId: number, weekNr: number, monthNr: number, yearNr: number, startDate: Date, endDate: Date): ResourceCounters {
        const index = this.resIdToIndex.getObject(resourceId);
        if (index == undefined) return undefined;
        const group = this.resourceGroups[this.resourceIndex[index].groupIndex];
        let counters: ResourceCounters = undefined;
        if (group.resourceCountersPeriod != undefined && group.resourceCountersPeriod.weekNr === weekNr &&
            group.resourceCountersPeriod.monthNr === monthNr && group.resourceCountersPeriod.yearNr === yearNr &&
            group.resourceCountersPeriod.startDate === startDate && group.resourceCountersPeriod.endDate === endDate) {
            this.lazyLoadResourceCountersPeriod = group.resourceCountersPeriod;
            counters = group.resourceCounters[this.resourceIndex[index].groupResIndex];
            if (counters == undefined || counters.dirtyFlag === 0) {
                // counters for this specific resource have been cleared, maybe also for more resources in this group
                // make a list of all resources in this group where the counters have been cleared
                for (let i = 0; i < group.resourceList.length; i++)
                    if (group.resourceCounters[i] == undefined || group.resourceCounters[i].dirtyFlag === 0) {
                        this.lazyLoadResourceCountersForIds.addObject(group.resourceList[i].id, 1);
                        if (group.resourceCounters[i] == undefined) group.resourceCounters[i] = new ResourceCounters();
                        group.resourceCounters[i].dirtyFlag = 1;
                    }
                this.initializeRestartableTimer(Globals.resourceCountersRefreshDelay);
            }
        }
        else {
            if (group.resourceCountersPeriod == undefined) group.resourceCountersPeriod = new ResourceCountersPeriod();
            group.resourceCountersPeriod.weekNr = weekNr;
            group.resourceCountersPeriod.monthNr = monthNr;
            group.resourceCountersPeriod.yearNr = yearNr;
            group.resourceCountersPeriod.startDate = startDate;
            group.resourceCountersPeriod.endDate = endDate;
            this.lazyLoadResourceCountersPeriod = group.resourceCountersPeriod;
            // make a list of all resources in this group
            for (let i = 0; i < group.resourceList.length; i++) {
                this.lazyLoadResourceCountersForIds.addObject(group.resourceList[i].id, 1);
                if (group.resourceCounters[i] == undefined) group.resourceCounters[i] = new ResourceCounters();
                group.resourceCounters[i].clear(); // do not keep previous values if the period changed
                group.resourceCounters[i].dirtyFlag = 1;
            }
            this.initializeRestartableTimer(Globals.resourceCountersInitialDelay);
        }
        return counters;
    }

    /**
        * set the value of counters for a specific resource
        * @param resourceId the resource id
        */
    setCountersForResource(resourceId: number, balance: number, planned: number, required: number, absence: number) {
        const index = this.resIdToIndex.getObject(resourceId);
        if (index == undefined) return;
        const group = this.resourceGroups[this.resourceIndex[index].groupIndex];
        let counters = group.resourceCounters[this.resourceIndex[index].groupResIndex];
        if (!counters) {
            counters = new ResourceCounters();
            group.resourceCounters[this.resourceIndex[index].groupResIndex] = counters;
        }
        counters.balance = Math.round(balance * 100) / 100;
        counters.planned = Math.round(planned * 100) / 100;
        counters.required = Math.round(required * 100) / 100;
        counters.absence = Math.round(absence * 100) / 100;
        counters.dirtyFlag = 2;
    }

    /**
        * clear the counters for one specific resource
        * @param resourceId the resource id
        */
    clearCountersForResource(resourceId: number) {
        const index = this.resIdToIndex.getObject(resourceId);
        if (index == undefined) return;
        const group = this.resourceGroups[this.resourceIndex[index].groupIndex];
        const resIndex = this.resourceIndex[index].groupResIndex;
        if (group.resourceCounters[resIndex] != undefined) group.resourceCounters[resIndex].dirtyFlag = 0;
    }

    /**
        * get an array of activities that are already loaded, between two dates for a specific resource
        * @param resourceId the resource id
        * @param startDate start day
        * @param endDate end day
        */
    getLoadedActivitiesBetween(resourceId: number, startDate: Date, endDate: Date): ActivityList {
        const index = this.resIdToIndex.getObject(resourceId);
        if (index == undefined) return undefined;
        return this.resourceGroups[this.resourceIndex[index].groupIndex]
            .getLoadedActivitiesBetween(this.resourceIndex[index].groupResIndex, startDate, endDate);
    }

    /**
        * get an array of activities that a resource has on a specific day
        * returns undefined if there are no activities for this resource on this day
        * @param resourceId the resource id
        * @param startDate start day
        * @param offsetDay the number of days to add or subtract from startDate
        * @param sortAbsences if absence activities should be sorted to the front
        */
    getActivityList(resourceId: number, startDate: Date, offsetDay: number, sortAbsences: boolean): ActivityList {
        const index = this.resIdToIndex.getObject(resourceId);
        if (index == undefined) return undefined;
        return this.resourceGroups[this.resourceIndex[index].groupIndex]
            .getActivityList(this.resourceIndex[index].groupResIndex, startDate, offsetDay, sortAbsences, null);
    }

    /**
        * get an array of open activities that still need a resource on a specific day
        * @param startDate start day
        * @param offsetDay the number of days to add or subtract from startDate
        * @param viewRevisionChangedAction action to do on the ActivityList before a result is returned, can be null
        */
    getOpenActivityList(startDate: Date, offsetDay: number,
        viewRevisionChangedAction: (activities: ActivityList) => void): ActivityList {
        return this.openActivities.getActivityList(0, startDate, offsetDay, false, viewRevisionChangedAction);
    }

    /**
        * return the array index of an open activity, if an activity is already sorted this will return the final position in the array
        * returns -1 if the array still needs to be sorted, or needs to be sorted again
        * @param activity the activity to get the index for
        */
    getOpenActivityArrayIndex(activity: Activity): number {
        return this.openActivities.getActivityArrayIndex(0, activity);
    }

    /**
        * return when data was last received for a resource on a specific day
        * return 0 if data was never received
        * @param resourceId the resource id
        * @param startDate start day
        * @param offsetDay the number of days to add or subtract from startDate
        */
    getLastReceived(resourceId: number, startDate: Date, offsetDay: number): number {
        const index = this.resIdToIndex.getObject(resourceId);
        if (index == undefined) return 0;
        const dayNr = TimeSpan.getDayNr(startDate) + offsetDay; // convert total milliseconds to total days
        return this.resourceGroups[this.resourceIndex[index].groupIndex].getLastReceived(dayNr);
    }

    /**
        * return when data was last received for a specific day
        * return 0 if data was never received
        * @param startDate start day
        * @param offsetDay the number of days to add or subtract from startDate
        */
    getLastReceivedOpenActivities(startDate: Date, offsetDay: number): number {
        const dayNr = TimeSpan.getDayNr(startDate) + offsetDay; // convert total milliseconds to total days
        return this.openActivities.getLastReceived(dayNr);
    }

}