import { isDictionary } from './../components/utils/dictionary';
import * as Constants from './../components/utils/constants';

export interface IPageStartService {
    /**
        * Initialize a new pageStartService object.
        * @param callerScope the callers scope (scope of the view)
        * @param checkPermission the user permission to check
        * @param dialogToken the unique dialog token
        */
    initialize(callerScope: any, checkPermission: string, dialogToken: string);

    /**
        * Returns if the current user has a specific permission.
        * @param permissionName name of the permission
        * @param callerScope scope that calls this function
        * @param callBackAction optional, the callback function to call when permissions have been received, function parameter is a boolean indicating if the user has the requested permission
        * @returns true if the current user has the permission, false if the user does not have the permission or if permissions have not been received
        */
    userHasPermission(permissionName: string, callerScope: any, callBackAction: any): boolean;

    /**
        * Subscribes a controller to an event. This executes the specified action function when the event is raised.
        * @param callerScope the callers scope (scope of the view)
        * @param eventName unique name of the event
        * @param eventActionCallback the callbackfunction to execute when a message is received
        */
    subscribeToWebSocketEvent(callerScope: any, eventName: string, eventActionCallback: any);

    /**
        * Pad the parents name to the name of an element if other elements with the same name exist
        * TODO: currently unused - candidate for cleanup
        * @param elements an array, an object, or a dictionary with elements
        * @param nameProperty the property of an element that contains the name, default: displayName
        * @param idProperty the property of an element that contains the id, default: id
        * @param parentIdProperty the property of an element that contains the parent id, default: parentId
        */
    padParentsToAmbiguousNames(elements: any, nameProperty: string, idProperty: string, parentIdProperty: string);

    /**
        * Do a webapi post action.
        * @param url the webapi url
        * @param postObject the object to send
        * @param successAction action to call when the post was a success, can be undefined to do nothing
        * @param errorAction action to call when there was an error, can be undefined to show a default error dialog
        * @param showPending true to show a pending dialog (getting data)
        */
    post(url: string, postObject: any, successAction: (any) => void, errorAction: (any) => void, showPending: boolean);

    /**
        * Show a dialog with custom title and text.
        */
    showDialog(title: string, text: string, buttonText: string, buttonAction: () => void);
}

export var pageStartService = [
    "$http", "$timeout", "translationService", "permissionService", "modalConfirmationWindowService", "notificationService", "userService",
    function ($http, $timeout, translationService, permissionService, modalConfirmationWindowService, notificationService, userService) {
        var svc = this;
        var permissionScopeSeen = Object.create(null); // the timestamp when last permissions were read for a specific scope

        /**
         * This service as an object. This is so the private variables for the dialog are not overwritten.
         * All common functions that do something with dialogs should be defined here in this object. 
         * Functions that do nothing with dialogs can be declared directly on the service (see below at the end of this file).
         * @param scope the callers scope (scope of the view)
         * @param permission the user permission to check
         * @param token the unique dialog token
         */
        var serviceObject = function (scope, permission, token) {
            var me = this;
            var automaticSaveDelay = 5000;
            var callerScope = scope;
            var dialogToken = token;
            var checkPermission = permission;
            var pendingWebApiRequests = 0;
            var callbackOnAllDataLoaded = null; // callback function to call after there are no more pending actions
            var onAllDataLoadedInitialInterval = false; // indicates if the onAllDataLoadedTimer is still in it's first (initial) interval

            /**
             * Hides the pending WebAPI operation information dialog.
             */
            me.hidePendingOperationInfo = function () {
                if (pendingWebApiRequests > 0) pendingWebApiRequests--;
                if (pendingWebApiRequests <= 0) {
                    modalConfirmationWindowService.closeModalWindow(dialogToken);
                }
            }

            /**
             * Triggers "on all data loaded" event, but only if there are no pending operations.
             */
            me.triggerOnAllDataLoaded = function () {
                if (pendingWebApiRequests <= 0) {
                    if (callbackOnAllDataLoaded != null && !onAllDataLoadedInitialInterval) {
                        // if onAllDataLoaded is used, call it now
                        var callbackAction = callbackOnAllDataLoaded;
                        callbackOnAllDataLoaded = null;
                        callbackAction();
                    }
                }
            }

            /**
             * Increment the number of pending operations.
             */
            me.newPendingOperation = function () {
                pendingWebApiRequests++;
            }

            /**
             * timer to periodically check if data is still pending
             */
            var onAllDataLoadedTimer = function (initialInterval, periodicInterval) {
                $timeout(function () {
                    if (pendingWebApiRequests <= 0) {
                        // no more pending actions, call callback if it has not yet been reset to null
                        if (callbackOnAllDataLoaded != null) {
                            var callbackAction = callbackOnAllDataLoaded;
                            callbackOnAllDataLoaded = null;
                            callbackAction();
                        }
                    } else if (callbackOnAllDataLoaded != null) {
                        onAllDataLoadedInitialInterval = false;
                        // replace initialInterval with periodicInterval and restart the timer
                        onAllDataLoadedTimer(periodicInterval, periodicInterval);
                    }
                },
                    initialInterval);
            }

            /**
             * call a callbackAction when there are no more pending actions
             * @param callbackAction the callback function to call
             * @param initialInterval initial wait time before testing if there are pending actions, default = 20ms
             * @param periodicInterval periodic wait time between testing for pending actions, default = 5000ms
             */
            me.onAllDataLoaded = function (callbackAction, initialInterval, periodicInterval) {
                callbackOnAllDataLoaded = callbackAction;
                onAllDataLoadedInitialInterval = true;
                onAllDataLoadedTimer(initialInterval ? initialInterval : 20,
                    periodicInterval ? periodicInterval : 5000);
            }

            /**
             * Called to display the error message from an http response.
             * @param response http response to display the error message from.
             * @param buttonAction Action executed after dismissing the dialog.
             */
            me.httpErrorResponse = function (response, buttonAction) {
                me.hidePendingOperationInfo();
                me.triggerOnAllDataLoaded();

                if (response.status !== 401) {
                    const errorMessage = translationService.translateErrorMessage(response);
                    modalConfirmationWindowService.showModalInfoDialog(
                        callerScope.textLabels.ERROR_OCCURRED,
                        errorMessage,
                        callerScope.textLabels.OK,
                        buttonAction,
                        0,
                        dialogToken + "_error");
                }
            }

            /**
             * Show the getting data dialog after a short time.
             */
            me.showGettingDataDialog = function () {
                modalConfirmationWindowService.showModalInfoDialog(
                    callerScope.textLabels.GETTING_DATA_TITLE,
                    callerScope.textLabels.GETTING_DATA_TEXT,
                    "",
                    null,
                    Constants.modalWaitDelay,
                    dialogToken);
            }

            /**
             * Show a dialog with custom title and text.
             */
            me.showDialog = function (title, text, buttonText, buttonAction) {
                var uniqueToken = new Date().getTime().toString();
                modalConfirmationWindowService.showModalInfoDialog(
                    title,
                    text,
                    buttonText,
                    function () {
                        modalConfirmationWindowService.closeModalWindow(dialogToken + uniqueToken);
                        if (buttonAction != null) buttonAction();
                    },
                    Constants.modalWaitDelay,
                    dialogToken + uniqueToken);
            }

            /**
             * Show a dialog with custom title and text and a yes button and a no button
             */
            me.showYesNoDialog = function (title, text, buttonYesAction, buttonNoAction) {
                modalConfirmationWindowService.showModalDialog(title, text, buttonYesAction, buttonNoAction);
            }

            /**
             * Show a dialog with custom title and text and custom buttons
             */
            me.showModalQuestionDialog = function (title, text, buttonTextArray, buttonActionArray) {
                modalConfirmationWindowService.showModalQuestionDialog(title, text, buttonTextArray, buttonActionArray);
            }

            /**
             * Show a dialog with custom title and text and custom buttons and a text input field
             */
            me.showModalTextDialog = function (title, text, buttonTextArray, buttonActionArray, textValue, multiLine) {
                modalConfirmationWindowService.showModalTextDialog(title, text, buttonTextArray, buttonActionArray, textValue, multiLine);
            }

            /**
             * Refresh all data.
             */
            me.refreshData = function (loadDataCallback) {
                pendingWebApiRequests = 0;
                // open the pending dialog
                me.showGettingDataDialog();
                // callback to let the controller load additional data
                if (loadDataCallback) loadDataCallback(callerScope);
                // make sure we close the getting data dialog at some point (in case the controller loads no data at all)
                (function (me) {
                    $timeout(function () {
                        me.newPendingOperation();
                        me.hidePendingOperationInfo();
                        me.triggerOnAllDataLoaded();
                    },
                    Constants.modalWaitDelay / 2);
                })(me);
            }

            /**
             * Check if the user has the desired permission and start loading data.
             * @param loadDataCallback function to call to start loading data
             */
            me.start = function (loadDataCallback) {
                callerScope.verificationStatus = { pending: true, failed: false, hasPermission: false };

                // No permission, so no checking.
                if (!checkPermission) {
                    callerScope.verificationStatus.hasPermission = true;
                    callerScope.verificationStatus.pending = false;
                    me.refreshData(loadDataCallback);
                    return;
                }

                permissionService.userHasPermission(checkPermission, callerScope.verificationStatus, callerScope).then(function () {
                    if (callerScope.verificationStatus.hasPermission) me.refreshData(loadDataCallback);
                });
            }

            /**
             * Do a wepapi delete action
             * @param url the webapi url
             * @param successAction action to call when the post was a success, can be undefined to do nothing
             * @param errorAction action to call when there was an error, can be undefined to show a default error dialog
             * @param showPending true to show a pending dialog (getting data)
             */
            me.deleteData = function (url, successAction, errorAction, showPending) {
                (function (me, url, successAction, errorAction, showPending) {

                    userService.setLogoffWaitTime(dialogToken, automaticSaveDelay);

                    if (showPending) {
                        me.newPendingOperation();
                        me.showGettingDataDialog();
                    }
                    $http.delete(url)
                        .then(function (response) {
                                try {
                                    if (showPending) me.hidePendingOperationInfo();
                                    if (successAction) successAction(response);
                                    me.triggerOnAllDataLoaded();
                                    userService.setLogoffWaitTime(dialogToken, 0);
                                } catch (err) {
                                    console.log("Error in deleteData: ", err);
                                }
                            },
                            function (response) {
                                try {
                                if (showPending) me.hidePendingOperationInfo();
                                if (errorAction) errorAction(response);
                                else me.httpErrorResponse(response, function () { });
                                me.triggerOnAllDataLoaded();
                                    userService.setLogoffWaitTime(dialogToken, 0);
                                } catch (err) {
                                    console.log("Error in deleteData: ", err);
                                }
                            });

                })(me, url, successAction, errorAction, showPending); // create copies of function parameters
            }

            /**
             * Do a webapi put action.
             * @param url the webapi url
             * @param postObject the object to send
             * @param successAction action to call when the post was a success, can be undefined to do nothing
             * @param errorAction action to call when there was an error, can be undefined to show a default error dialog
             * @param showPending true to show a pending dialog (getting data)
             */
            me.putData = function (url, postObject, successAction, errorAction, showPending) {
                (function (me, url, postObject, successAction, errorAction, showPending) {

                    userService.setLogoffWaitTime(dialogToken, automaticSaveDelay);

                    if (showPending) {
                        me.newPendingOperation();
                        me.showGettingDataDialog();
                    }
                    $http.put(url, postObject)
                        .then(function (response) {
                                try {
                                    if (showPending) me.hidePendingOperationInfo();
                                    if (successAction) successAction(response);
                                    me.triggerOnAllDataLoaded();
                                            userService.setLogoffWaitTime(dialogToken, 0);
                                } catch (err) {
                                    console.log("Error in putData: ", err);
                                }
                        },
                            function (response) {
                                try {
                                    if (showPending) me.hidePendingOperationInfo();
                                    if (errorAction) errorAction(response);
                                    else me.httpErrorResponse(response, function () { });
                                    me.triggerOnAllDataLoaded();
                                        userService.setLogoffWaitTime(dialogToken, 0);
                                } catch (err) {
                                    console.log("Error in putData: ", err);
                                }
                            });

                })(me, url, postObject, successAction, errorAction, showPending); // create copies of function parameters
            }

            /**
             * Do a webapi post action.
             * @param url the webapi url
             * @param postObject the object to send
             * @param successAction action to call when the post was a success, can be undefined to do nothing
             * @param errorAction action to call when there was an error, can be undefined to show a default error dialog
             * @param showPending true to show a pending dialog (getting data)
             */
            me.post = function (url, postObject, successAction, errorAction, showPending) {
                (function (me, url, postObject, successAction, errorAction, showPending) {

                    userService.setLogoffWaitTime(dialogToken, automaticSaveDelay);

                    if (showPending) {
                        me.newPendingOperation();
                        me.showGettingDataDialog();
                    }

                    $http.post(url, postObject)
                        .then(function (response) {
                                try {
                                    if (showPending) me.hidePendingOperationInfo();
                                    if (successAction) successAction(response);
                                    me.triggerOnAllDataLoaded();
                                            userService.setLogoffWaitTime(dialogToken, 0);
                                } catch (err) {
                                    console.log("Error in postData: ", err);
                                }
                        },
                            function (response) {
                                try {
                                    if (showPending) me.hidePendingOperationInfo();
                                    if (errorAction) errorAction(response);
                                    else me.httpErrorResponse(response, function () { });
                                    me.triggerOnAllDataLoaded();
                                        userService.setLogoffWaitTime(dialogToken, 0);
                                } catch (err) {
                                    console.log("Error in postData: ", err);
                                }
                            });

                })(me, url, postObject, successAction, errorAction, showPending); // create copies of function parameters
            }

            /**
             * Do a webapi get action.
             * @param url the webapi url
             * @param loadInto the array or object or dictionary to store the data in (must already been created to have a reference)
             * @param successAction action to call when the get was a success, can be undefined to do nothing
             * @param errorAction action to call when there was an error, can be undefined to show a default error dialog
             * @param showPending true to show a pending dialog (getting data)
             * @param clearLoadInto (optional) true to first clear the array or object or dictionary
             * @param propId (optional) the unique identifier propertyname for an element, default is "id"
             */
            me.loadData = function (url, loadInto, successAction, errorAction, showPending, clearLoadInto, propId) {
                (function (me, url, loadInto, successAction, errorAction, showPending, clearLoadInto, propId) {

                    if (showPending) {
                        me.newPendingOperation();
                        me.showGettingDataDialog();
                    }
                    $http.get(url)
                        .then(function (response) {
                                try {
                                    // set order property if there was not one in the objects received
                                    var i = 0, len = 0;
                                    if (response.data && response.data.length > 0 && response.data[0].order == null && response.data[0] instanceof Object)
                                        for (i = 0, len = response.data.length; i < len; i++)
                                            response.data[i].order = i;
                                    // load into dictionary, object, or array
                                    if (loadInto != null) {
                                        if (propId == undefined) propId = "id";
                                        if (isDictionary(loadInto)) { // dictionary
                                            if (clearLoadInto) loadInto.clear();
                                            for (i = 0, len = response.data.length; i < len; i++)
                                                loadInto.add(response.data[i][propId], response.data[i]);
                                        } else if (loadInto.length == undefined) { // object
                                            if (clearLoadInto) for (var key in loadInto) delete loadInto[key];
                                            for (i = 0, len = response.data.length; i < len; i++)
                                                loadInto[response.data[i][propId]] = response.data[i];
                                        } else { // array
                                            if (clearLoadInto) loadInto.length = 0;
                                            for (i = 0, len = response.data.length; i < len; i++)
                                                loadInto.push(response.data[i]);
                                        }
                                    } else // return the data as we get it, only usable by successAction because there is no reference to the object
                                        loadInto = response.data;
                                    if (showPending) me.hidePendingOperationInfo();
                                    if (successAction) successAction(response, loadInto);
                                            me.triggerOnAllDataLoaded();
                                } catch (err) {
                                    console.log("Error in loadData: ", err);
                                }
                        },
                            function (response) {
                                try {
                                    if (showPending) me.hidePendingOperationInfo();
                                    if (errorAction) errorAction(response);
                                    else me.httpErrorResponse(response, function () { });
                                        me.triggerOnAllDataLoaded();
                                } catch (err) {
                                    console.log("Error in loadData: ", err);
                                }
                            });

                })(me, url, loadInto, successAction, errorAction, showPending, clearLoadInto, propId); // create copies of function parameters
            }
        }

        /**
         * Initialize a new pageStartService object.
         * @param callerScope the callers scope (scope of the view)
         * @param checkPermission the user permission to check
         * @param dialogToken the unique dialog token
         */
        svc.initialize = function (callerScope, checkPermission, dialogToken) {
            return new serviceObject(callerScope, checkPermission, dialogToken);
        }

        /**
         * All permissions.
         */
        svc.userPermissions = { granted: [], lastScopeId: -1, pending: false }

        /**
         * Returns if the current user has a specific permission.
         * @param permissionName name of the permission
         * @param callerScope scope that calls this function
         * @param callBackAction optional, the callback function to call when permissions have been received, function parameter is a boolean indicating if the user has the requested permission
         * @returns true if the current user has the permission, false if the user does not have the permission or if permissions have not been received
         */
        svc.userHasPermission = function (permissionName, callerScope, callBackAction) {
            if (callerScope && svc.userPermissions.lastScopeId !== callerScope.$id) {
                // we are in a different scope, request a new state of permissions from the webApi
                svc.userPermissions.lastScopeId = callerScope.$id;
                var lastTimeStamp = permissionScopeSeen[svc.userPermissions.lastScopeId.toString()];
                var currentTimeStamp = new Date().getTime();
                // test if we already did this recently for the new scope
                if (lastTimeStamp == null || currentTimeStamp - lastTimeStamp > 60000) {
                    permissionScopeSeen[svc.userPermissions.lastScopeId.toString()] = currentTimeStamp;
                    var verificationStatus = {};
                    permissionService.getAllPermissions(svc.userPermissions, verificationStatus, callerScope)
                        .then(function () {
                            var hasPermission = svc.userPermissions.granted.indexOf(permissionName) > -1;
                            if (callBackAction) callBackAction(hasPermission);
                        });
                }
            }
            var result = svc.userPermissions.granted.indexOf(permissionName) > -1;
            if (callBackAction) {
                if (!svc.userPermissions.pending) callBackAction(result);
                else // permissions were still pending, try again in one second
                    $timeout(function () { svc.userHasPermission(permissionName, callerScope, callBackAction); }, 1000);
            }
            return result;
        }

        /**
         * Subscribes a controller to an event. This executes the specified action function when the event is raised.
         * @param callerScope the callers scope (scope of the view)
         * @param eventName unique name of the event
         * @param eventActionCallback the callbackfunction to execute when a message is received
         */
        svc.subscribeToWebSocketEvent = function (callerScope, eventName, eventActionCallback) {
            (function (callerScope, eventName, eventActionCallback) {

                notificationService.subscribeToWebSocketEvent(callerScope, eventName,
                    function (event, message) {
                        //console.log("Received notification: ", message);
                        if (message.hasOwnProperty("event"))
                            eventActionCallback(message);
                        else
                            console.log("Not the type of message the page subscribed to");
                    });

                // add eventName to the clientinfo the webSocket sends
                notificationService.addStringToClientInfo(eventName);

                // remove the eventName from the clientinfo when callerScope is destroyed
                callerScope.$on("$destroy",
                    function () {
                        console.log("removeStringFromClientInfo", eventName);
                        notificationService.removeStringFromClientInfo(eventName);
                    });

            })(callerScope, eventName, eventActionCallback); // create copies of function parameters
        }

        // helper method for: padParentsToAmbiguousNames
        var padParentName = function (element, nameList, elements, nameProperty, parentIdProperty) {
            var newName = element[nameProperty];
            var parent = element;
            while (parent != undefined && (parent === element || nameList[newName] != undefined)) {
                parent = elements[parent[parentIdProperty]];
                if (parent != undefined) {
                    var parentName = parent[nameProperty] + " » ";
                    if (newName.indexOf(parentName) === -1) newName = parentName + newName;
                }
            }
            element[nameProperty] = newName;
            nameList[element[nameProperty]] = element;
            return newName;
        }

        // helper for: padParentsToAmbiguousNames: sort an object with tree structure by depth
        var sortElementsByTreeDepth = function (elements, parentIdProperty) {
            var sorted = [];
            for (var key in elements) sorted.push(elements[key]);
            sorted.sort(function (item1, item2) {
                var levelItem1 = 0;
                var levelItem2 = 0;
                if (item1.treeDepth != undefined) levelItem1 = item1.treeDepth;
                else {
                    var item = item1;
                    while (item != undefined) {
                        levelItem1++;
                        item = elements[item[parentIdProperty]];
                    }
                    item1.treeDepth = levelItem1;
                }
                if (item2.treeDepth != undefined) levelItem2 = item2.treeDepth;
                else {
                    var item = item2;
                    while (item != undefined) {
                        levelItem2++;
                        item = elements[item[parentIdProperty]];
                    }
                    item2.treeDepth = levelItem2;
                }
                return levelItem1 - levelItem2;
            });
            return sorted;
        }

        /**
         * Pad the parents name to the name of an element if other elements with the same name exist
         * TODO: currently unused - candidate for cleanup (along with the helper functions above)
         * @param elements an array, an object, or a dictionary with elements
         * @param nameProperty the property of an element that contains the name, default: displayName
         * @param idProperty the property of an element that contains the id, default: id
         * @param parentIdProperty the property of an element that contains the parent id, default: parentId
         */
        svc.padParentsToAmbiguousNames = function (elements, nameProperty, idProperty, parentIdProperty) {
            if (nameProperty == undefined) nameProperty = "displayName";
            if (idProperty == undefined) idProperty = "id";
            if (parentIdProperty == undefined) parentIdProperty = "parentId";

            if (isDictionary(elements)) { // dictionary
                elements = elements.getStore();
            } else if (elements.length == undefined) { // object
            } else { // array
                var elementsObject = Object.create(null);
                for (var i = 0; i < elements.length; i++) elementsObject[elements[i][idProperty]] = elements[i];
                elements = elementsObject;
            }

            var nameList = Object.create(null);
            var sorted = sortElementsByTreeDepth(elements, parentIdProperty);
            for (var i = 0; i < sorted.length; i++) {
                var element = sorted[i];
                var name = element[nameProperty];
                var collidingElement = nameList[name];
                if (collidingElement == undefined) nameList[name] = element;
                else {
                    if (element.treeDepth >= collidingElement.treeDepth)
                        padParentName(element, nameList, elements, nameProperty, parentIdProperty);
                    if (collidingElement.treeDepth >= element.treeDepth)
                        padParentName(collidingElement, nameList, elements, nameProperty, parentIdProperty);
                }
            }
        }

    }
];