import { Dictionary } from './../../utils/dictionary';
import * as Constants from './../../utils/constants';

import { AreaControl } from './../controls/areaControl';

import { ActivityInfo } from './../drawing/activityInfo';
import * as CachedObjectIds from './../drawing/cachedObjectIds';
import * as Symbols from './../drawing/symbols';
import * as Drawing from './../drawing/timeline';

import * as Globals from './../utils/globals';
import { TimeSpan } from './../utils/timespan';

import { Activity, ActivityList } from './../entities/activity';
import { ActivityType } from './../entities/activitytype';
import { Planboard } from './../entities/planboard';

import { PlannedActivities } from './plannedActivities';

export class OpenActivities {
    /**
        * Order and filter of activityTypes in the open activities.
        * If an activity type is not in this dictionary, or if it's value is undefined then the activity with that type will not be shown.
        */
    static activityTypeOrder: Dictionary;

    /**
        * Final order and filter of activityTypes.
        * This is the combination of all activityType filters, starting with activityTypeOrder and applying all filters from activityTypeIdFilters.
        * Initially this is the same reference as activityTypeOrder, but will be recreated when activating a secondary filter.
        */
    static combinedActivityTypeOrder: Dictionary;

    /**
        * Named filters with arrays of activityTypeIds that should be visible.
        * Used to build the combinedActivityTypeOrder by filtering out any remaining activityTypes from activityTypeOrder.
        */
    static activityTypeIdFilters: Dictionary;

    /**
        * Indicates if the combined activity type filter should be applied to open activities.
        */
    static activityTypeFilterActive = false;

    /**
        * Indicates if activities that overlap multiple days should be moved to the top row.
        * When true, this will also try to keep the same activity on the same row.
        * The sort order of activity types in activityTypeOrder can no longer be guaranteed if this option is set to true.
        */
    static multiDayActivitiesToTop = true;

    /**
        * Get if an activity type is visible according to combinedActivityTypeOrder dictionary.
        * @param activityType the activity type to test
        * @param applyFilter if the combinedActivityTypeOrder should be applied
        */
    static activityTypeVisible(activityType: ActivityType, applyFilter: boolean) {
        if (activityType && activityType.categoryId === ActivityType.daymarkCategoryId) {
            return false;
        }
        return activityType != null && (!applyFilter ||
            (this.combinedActivityTypeOrder != null && this.combinedActivityTypeOrder.value(activityType.id) != null));
    }

    /**
        * Add or replace an activityType filter and recreate combinedActivityTypeOrder
        * @param filter the filters name
        * @param activityTypeIdList the list of visible activityTypeIds according to this filter
        */
    static applyActivityTypeFilter(filter: string, activityTypeIdList: number[]) {
        if (filter != null) this.activityTypeIdFilters.add(filter, activityTypeIdList);
        this.combinedActivityTypeOrder = this.activityTypeOrder;
        let filtered = false;
        let idList: number[];
        let i: number;
        let actType: ActivityType;
        this.activityTypeIdFilters.forEach((filterKey, value) => {
            if (value != null) {
                idList = value;
                if (!filtered) { // copy all items from activityTypeOrder that are also in idList
                    filtered = true;
                    this.combinedActivityTypeOrder = new Dictionary();
                    for (i = 0; i < idList.length; i++) {
                        actType = this.activityTypeOrder.value(idList[i]);
                        if (actType != null) this.combinedActivityTypeOrder.add(idList[i], actType);
                    }
                } else // remove items from combinedActivityTypeOrder that are not in idList
                    this.combinedActivityTypeOrder.forEach((key, actTypeValue) => {
                        if (idList.indexOf(parseInt(key)) < 0) this.combinedActivityTypeOrder.remove(key);
                    });
            }
        });
        this.activityTypeFilterActive = true; // actually use the filter
        Planboard.viewChanged();
    }

    /**
        * Remove an activityType filter and recreate combinedActivityTypeOrder
        * @param filter the filters name
        */
    static removeActivityTypeFilter(filter: string) { this.applyActivityTypeFilter(filter, null); }

    /**
        * Create and initialize all events for the grid area for activities that still need a resource.
        */
    static initialize() {
        this.activityTypeOrder = new Dictionary();
        this.activityTypeIdFilters = new Dictionary();
        this.combinedActivityTypeOrder = this.activityTypeOrder;
        this.createAreaControl();
        this.initDrawCell();
        this.initMouseDownCell();
        this.initMouseMoveCell();
        this.initMouseLeave();
        this.initMouseUpCell();
        this.initMouseDblClick();
    }

    /**
        * Create the grid control.
        */
    private static createAreaControl() {
        const areaToPlan = Planboard.areaToPlan = new AreaControl(Planboard.split2.getSplitArea(0, 1), 0, 0, 100, 100, 3000, 2000);
        areaToPlan.name = "areaToPlan";
        areaToPlan.setUseBackbuffer(true);
        areaToPlan.setAlign(0, 0, 0, 0);
        areaToPlan.dragDropCell = Planboard.areaMain.dragDropCell;
        areaToPlan.dragDropCancel = Planboard.areaMain.dragDropCancel;
        areaToPlan.scrollEnd = Planboard.areaMain.scrollEnd;
        areaToPlan.backcolor = Globals.windowColor;
        areaToPlan.gridColor = Globals.gridLinesColor;
        areaToPlan.clearColor = areaToPlan.backcolor;
        areaToPlan.cols.setSize(Planboard.areaMain.cols.count - 1, Planboard.areaMain.cols.getSize(0), 16);
        areaToPlan.rows.setSize(99, Globals.fontHeight + 1, 0);
        areaToPlan.linkHorizontal(Planboard.areaMain);
        areaToPlan.linkHorizontal(Planboard.areaDate);
        areaToPlan.hBarVisible = false;
        areaToPlan.enableDragging = false;
    }

    private static initMouseDblClick() {
        Planboard.areaToPlan.mouseDblClick = (x: number, y: number, button: number) => { };
    }

    /**
        * Initialize mouseleave and wheelscrollstart events.
        */
    private static initMouseLeave() {
        Planboard.areaToPlan.mouseLeave = Planboard.areaMain.mouseLeave;
        Planboard.areaToPlan.wheelScrollingStart = Planboard.areaMain.wheelScrollingStart;
    }

    /**
        * Get screen coordinates for an activity
        * @param activity the activity
        * @param dayNr the day number
        * @param colWidth the width of the column
        */
    private static getActivityScreenStartEnd(activity: Activity, dayNr: number, colWidth: number): Array<number> {
        let startX = Drawing.dateTimeToX(activity.startDate, dayNr, colWidth, false);
        let endX = Drawing.dateTimeToX(activity.endDate, dayNr, colWidth, true);
        // after visible area -> make it fit
        if (startX >= colWidth) startX = colWidth - 16;
        // before visible area -> make it fit
        if (endX < 0) endX = 15;
        // to small to be seen -> make it larger
        if (endX < startX + 15) {
            startX = Math.min(startX, colWidth - 8);
            endX = startX + 15;
        }
        return [startX, endX];
    }

    /**
        * Get the activity in a specific cell.
        * @param col column number
        * @param row row number
        */
    private static getActivityInCell(col: number, row: number): Activity {
        const activities = Planboard.activities.getOpenActivityList(Planboard.leftDate, col,
            (actList: ActivityList) => {
                this.sortActivityList(actList, this.combinedActivityTypeOrder);
            });
        if (activities == undefined) {
            if (Planboard.activities.getLastReceivedOpenActivities(Planboard.leftDate, col) === 0) {
                // data was not yet received for this day
                // todo: make it somehow visible that data request has been submitted for this day
            }
            return null;
        }
        if (activities.items.length === 0) return null; // no open activities on this day
        if (row < 0 || row >= activities.items.length) return null; // nothing on this row

        const activity = activities.items[row];
        if (Planboard.controller.dragDrop && Planboard.selectedActivity != null && Planboard.selectedActivity === activity)
            return null; // do not show the selected activity if it is being dragDropped
        if (activity.status === Constants.StatusActivityNotRequired)
            return null; // the activity does not require a resource, do not show it

        return activity;
    }

    /**
        * Get all visible activities in a specific cell as an ActivityList (note, there can be only 1 activity in a cell).
        * @param col column number
        * @param row row number
        */
    static getActivitiesInCell(col: number, row: number): ActivityList {
        const activity = this.getActivityInCell(col, row);
        const activityType = activity == null || activity.id < 0
            ? undefined
            : Planboard.activityTypes.getObject(activity.activityTypeId) as ActivityType;
        if (this.activityTypeVisible(activityType, this.activityTypeFilterActive)) {
            const activityList = new ActivityList();
            activityList.items.push(activity);
            return activityList;
        }
        return null;
    }

    /**
        * Initialize the mouse move event.
        */
    private static initMouseMoveCell() {
        Planboard.areaToPlan.mouseMoveCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            PlannedActivities.moveIndicators(t, mouseX, mouseY);
            if (button === 0) {
                PlannedActivities.mouseMoveResizeActivity(t, col, celX, mouseX, mouseY, false);
            } else if (button < 0) {
                const activity = this.getActivityInCell(col, row);
                const activityType = activity == null
                    ? undefined
                    : Planboard.activityTypes.getObject(activity.activityTypeId) as ActivityType;
                if (!this.activityTypeVisible(activityType, this.activityTypeFilterActive)) {
                    ActivityInfo.hide();
                    return;
                }

                const dayNr = TimeSpan.getDayNr(Planboard.leftDate) + col;
                const colWidth = t.cols.getSize(col);
                const [startX, endX] = this.getActivityScreenStartEnd(activity, dayNr, colWidth);

                let readOnly = Planboard.readOnly;
                if (activity.status === Constants.StatusActivityLocked) readOnly = true;
                let mouseoverActivity: Activity = null;
                if (celX >= startX && celX < endX)
                    mouseoverActivity = activity;
                if (colWidth >= 128 && !readOnly)
                    if ((celX >= startX - 2 && celX < startX + 4) || (celX >= endX - 4 && celX < endX + 2))
                        t.controller.mouseCursor = "w-resize";

                if (mouseoverActivity == null)
                    ActivityInfo.hide();
                else
                    ActivityInfo.show(Planboard.toolTipControl, mouseoverActivity.id,
                        t.screenPos.left + mouseX, t.screenPos.top + mouseY,
                        Planboard.toolTipControl.visible ? 0 : 500);
            }
        }
    }

    /**
        * Initialize the mouse up event.
        */
    private static initMouseUpCell() {
        Planboard.areaToPlan.mouseUpCell = Planboard.areaMain.mouseUpCell;
    }

    /**
        * Initialize the mouse down event.
        */
    private static initMouseDownCell() {
        Planboard.areaToPlan.mouseDownCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            const colWidth = t.cols.getSize(col);
            const prevSelected = Planboard.selectedActivity;
            Planboard.selectedActivity = null;
            Planboard.resizeActivity = null;
                
            let multiSelectChanged = PlannedActivities.mouseActionClearMultiselect(button);

            PlannedActivities.setMouseDownDate(colWidth, col, row, celX, mouseX, mouseY);

            const activity = this.getActivityInCell(col, row);
            const activityType = activity == null
                ? undefined
                : Planboard.activityTypes.getObject(activity.activityTypeId) as ActivityType;
            // activity type not visible according to activityTypeOrder?
            if (this.activityTypeVisible(activityType, this.activityTypeFilterActive)) {

                const dayNr = TimeSpan.getDayNr(Planboard.leftDate) + col;
                const [startX, endX] = this.getActivityScreenStartEnd(activity, dayNr, colWidth);

                if (celX >= startX && celX < endX) {
                    Planboard.selectedActivity = activity;
                    Planboard.selectedActivityRow = row;
                    Planboard.selectedActivityPos = [celX - startX, celY];
                    Planboard.dragDropControl.position.width = 4 + Math.min(Math.max(endX - startX, 4), colWidth);
                    Planboard.dragDropControl.position.height = 4 + Globals.fontHeight - 2;
                    if (Planboard.resizeActivity !== Planboard.selectedActivity)
                        Planboard.resizeActivity = null;
                    if (PlannedActivities.mouseActionToggleMultiselect(button, prevSelected))
                        multiSelectChanged = true;
                }
                if (colWidth >= 128 && Planboard.resizeActivity == null) {
                    if ((celX >= startX - 2 && celX < startX + 4) || (celX >= endX - 4 && celX < endX + 2)) {
                        Planboard.resizeActivity = activity;
                        Planboard.selectedActivityRow = row;
                        Planboard.selectedActivityPos = [celX - startX, celY];
                    }
                }
            }

            const mouseDownActivity = Planboard.resizeActivity != null ? Planboard.resizeActivity : Planboard.selectedActivity;
            if (mouseDownActivity != null) {
                PlannedActivities.mouseDownActivityStart = mouseDownActivity.startDate;
                PlannedActivities.mouseDownActivityEnd = mouseDownActivity.endDate;
            }

            if (prevSelected !== Planboard.selectedActivity || multiSelectChanged) {
                Planboard.areaMain.redrawAll();
                Planboard.areaToPlan.redrawAll();
                Planboard.controller.redraw();
            }

            Planboard.onMouseDownCell(t, col, row, celX, celY, button, mouseX, mouseY);
        }
    }

    /**
        * helper function for drawCell
        */
    private static drawActivity(t: AreaControl, ctx: CanvasRenderingContext2D, col: number, row: number, colWidth: number, rowHeight: number, contextX: number, contextY: number, activity: Activity, activityType: ActivityType) {
        const dayNr = TimeSpan.getDayNr(Planboard.leftDate) + col;
        const [startX, endX] = this.getActivityScreenStartEnd(activity, dayNr, colWidth);
        const mainAct = Planboard.activities.getMainActivity(activity.id);

        activityType.draw(ctx, contextX + startX, contextY + 1, endX - startX, Globals.fontHeight - 2, false);
        if (startX < 0 || endX >= colWidth) {
            // activity does not fit, it could overlap multiple days so draw indicator arrows left/right
            const arrowSize = Math.max((Globals.fontHeight >> 1) - 1, 11);
            const ypos = Math.floor(contextY + (Globals.fontHeight >> 1) - (arrowSize >> 1));
            if (startX < 0) Symbols.drawArrowTriangle(CachedObjectIds.triangleLeftId, ctx, contextX, ypos, arrowSize, false, true, Globals.darker3DColor);
            if (endX >= colWidth) Symbols.drawArrowTriangle(CachedObjectIds.triangleRightId, ctx, contextX + colWidth - arrowSize - 1, ypos, arrowSize, false, false, Globals.darker3DColor);
        }

        // memo corner
        if (mainAct && mainAct.memoId != null || activity.memoId) {
            const cornerSize = (Globals.fontHeight >> 1) - 1; // memo corner size
            Symbols.drawCorner(CachedObjectIds.memoCornerId, ctx, contextX + Math.min(endX - 1 - cornerSize, colWidth - 1 - cornerSize), contextY + Globals.fontHeight - 2 - cornerSize, cornerSize, true, true, "rgb(255,15,15)", "rgb(239,0,0)");
        }
        // selected?
        if ((Planboard.selectedActivity != null && Planboard.selectedActivity.id === activity.id) ||
            (Planboard.multiSelectedActivities != null && Planboard.multiSelectedActivities.containsKey(activity.id))) {
            Symbols.drawBorder(contextX + startX, contextY, endX - startX, Globals.fontHeight, 2, Globals.windowTextColor, ctx);
        }
    }

    /**
        * Initialize the draw cell event.
        */
    private static initDrawCell() {
        Planboard.areaToPlan.drawCell = (t: AreaControl, ctx: CanvasRenderingContext2D, col: number, row: number, colWidth: number, rowHeight: number, contextX: number, contextY: number) => {
            const dayOfWeek = (col + Planboard.firstDayOfWeek) % 7; // 0 = sunday, 1 = monday, etc...
            if (dayOfWeek === 0 || dayOfWeek === 6) {
                ctx.fillStyle = Globals.light3DColor;
                ctx.fillRect(contextX, contextY, colWidth - 1, rowHeight - 1);
            }
            const activity = this.getActivityInCell(col, row);
            const activityType = activity == null
                ? undefined
                : Planboard.activityTypes.getObject(activity.activityTypeId) as ActivityType;

            // only draw activities with a positive id, this will skip a new activity that is being created
            if (this.activityTypeVisible(activityType, this.activityTypeFilterActive) && activity != null && activity.id > 0)
                this.drawActivity(t, ctx, col, row, colWidth, rowHeight, contextX, contextY, activity, activityType);

            // if a new activity is being created, it will have a negative id, draw it on the row that was selected
            if (Planboard.selectedActivity != null && Planboard.selectedActivity.id < 0 &&
                (Planboard.selectedActivity.resourceId == null || Planboard.selectedActivity.resourceId < 0) &&
                row === Planboard.selectedActivityRow) {
                const dayNr = TimeSpan.getDayNr(Planboard.leftDate) + col;
                const startDay = TimeSpan.getDayNr(Planboard.selectedActivity.startDate);
                const endDay = TimeSpan.getDayNrWithOffset(Planboard.selectedActivity.endDate, -1);
                if (dayNr >= startDay && dayNr <= endDay)
                    this.drawActivity(t, ctx, col, row, colWidth, rowHeight, contextX, contextY, Planboard.selectedActivity,
                        Planboard.activityTypes.getObject(Planboard.selectedActivity.activityTypeId) as ActivityType);
            }
        }
    }

    /**
        * Get the order number of an activity type.
        * @param activityTypeId id of the activity type
        * @param activityTypeOrder the dictionary containing order numbers for all activity types
        * @param defaultValue the value to return if the dictionary does not contain the activity type or if the order number is undefined
        */
    private static getActivityTypeOrder(activityTypeId, activityTypeOrder: Dictionary, defaultValue: number): number {
        const value = activityTypeOrder == null ? undefined : activityTypeOrder.value(activityTypeId);
        if (value != undefined) return value;
        return defaultValue;
    }

    /**
        * Compare activities for sorting.
        * Returns -1 if activity1 is before activity2.
        * Returns 1 if activity1 is after activity2.
        * @param activity1 one activity
        * @param activity2 the other activity.
        * @param activityTypeOrder the dictionary with as key activityTypeId and as value the order number
        */
    private static compareActivities(activity1: Activity, activity2: Activity, activityTypeOrder: Dictionary): number {
        if (activity1.id < 0) return 1;
        if (activity2.id < 0) return -1;

        let sortByActivityTypeOrder = true;

        // activities with the same parent can be compared directly by their activity type
        // in case the parents differ we need to do additional checks
        if (activity1.parentId !== activity2.parentId || activity1.parentId <= 0) {
            // one or both are roots -> do not compare activity types
            if (activity1.parentId <= 0 || activity2.parentId <= 0) {
                sortByActivityTypeOrder = false;
            }
            else {
                let parent1 = activity1.parentId <= 0 ? null : Planboard.activities.getActivity(activity1.parentId);
                let parent2 = activity2.parentId <= 0 ? null : Planboard.activities.getActivity(activity2.parentId);
                // parent activity not found -> can not compare based on activity type
                if (parent1 == null || parent2 == null) {
                    sortByActivityTypeOrder = false;
                }
                // parent1 and activity2 have the same parent? -> then compare those two
                else if (parent1.parentId >= 0 && parent1.parentId === activity2.parentId) {
                    activity1 = parent1;
                }
                // parent2 and activity1 have the same parent? -> then compare those two
                else if (parent2.parentId >= 0 && parent2.parentId === activity1.parentId) {
                    activity2 = parent2;
                }
                // parent1 and parent2 have the same parent? -> then compare those two
                else if (parent1.parentId >= 0 && parent1.parentId === parent2.parentId) {
                    activity1 = parent1;
                    activity2 = parent2;
                }
                // do not compare based on activity type
                else {
                    sortByActivityTypeOrder = false;
                }
            }
        }

        // compare based on activity type order
        if (sortByActivityTypeOrder) {
            // we need to get the order from the original dictionary (this.activityTypeOrder), not the filtered one
            const actTypeOrder1 = this.activityTypeOrder == null ? 0 : this.activityTypeOrder.value(activity1.activityTypeId) || 0;
            const actTypeOrder2 = this.activityTypeOrder == null ? 0 : this.activityTypeOrder.value(activity2.activityTypeId) || 0;
            if (actTypeOrder1 < actTypeOrder2) return -1;
            if (actTypeOrder1 > actTypeOrder2) return 1;
        }

        // compare based on activity time
        if (activity1.startDate < activity2.startDate) return -1; // same type order, but earlier start time
        if (activity1.startDate > activity2.startDate) return 1; // same type order, but later start time
        if (activity1.endDate > activity2.endDate) return -1; // same start time, but longer end time
        if (activity1.endDate < activity2.endDate) return 1; // same start time, but shorter end time
        return activity1.id < activity2.id ? -1 : 1;
    }

    /**
        * Returns if an activity is visible and overlaps multiple days
        * @param activity the activity
        * @param activityTypeOrder the dictionary with as key activityTypeId and as value the order number
        */
    private static isMultiDayActivity(activity: Activity, activityTypeOrder: Dictionary): boolean {
        if (activity.status === Constants.StatusActivityNotRequired) return false;
        if (TimeSpan.sameDayNr(activity.startDate, activity.endDate)) return false;
        if (this.getActivityTypeOrder(activity.activityTypeId, activityTypeOrder, Globals.maxInt) === Globals.maxInt) return false;
        return true;
    }

    /**
        * Move an activity from one place in the array to another place in the array.
        */
    private static moveToIndex(activities: ActivityList, fromIndex: number, toIndex: number) {
        while (fromIndex !== toIndex) {
            if (toIndex < fromIndex) {
                const swap = activities.items[fromIndex - 1];
                activities.items[fromIndex - 1] = activities.items[fromIndex];
                activities.items[fromIndex] = swap;
                fromIndex--;
            } else {
                if (fromIndex + 1 >= activities.items.length) return;
                const swap = activities.items[fromIndex + 1];
                activities.items[fromIndex + 1] = activities.items[fromIndex];
                activities.items[fromIndex] = swap;
                fromIndex++;
            }
        }
    }

    /**
        * Sort a list of activities based upon an order in a dictionary.
        * If an activity type is not in the activityTypeOrder dictionary it will be placed at the end.
        * If an activity type is in the dictionary but the value is undefined it will also be placed at the end.
        * @param activities the list of activities to sort
        * @param activityTypeOrder the dictionary with as key activityTypeId and as value the order number
        * @param onlyTypeOrderSort only sort by order in activityTypeOrder, ignore any other sorting conditions
        */
    static sortActivityList(activities: ActivityList, activityTypeOrder: Dictionary, onlyTypeOrderSort: boolean = false) {
        let multiDayActivities = false;
        let index = 0;
        while (index < activities.items.length) {
            // remember if one of the activities in this list overlaps multiple days
            if (this.multiDayActivitiesToTop && !onlyTypeOrderSort && !multiDayActivities &&
                this.isMultiDayActivity(activities.items[index], activityTypeOrder))
                multiDayActivities = true;
            // sort based on the order in the activityTypeOrder dictionary
            while (index > 0 &&
                this.compareActivities(activities.items[index], activities.items[index - 1], activityTypeOrder) < 0) {
                this.moveToIndex(activities, index, index - 1);
                index--;
            }
            index++;
        }
        // move invisible activities to the bottom
        index = activities.items.length;
        while (index--) {
            const activityType = Planboard.activityTypes.getObject(activities.items[index].activityTypeId) as ActivityType;
            if (activities.items[index].status === Constants.StatusActivityNotRequired ||
                !this.activityTypeVisible(activityType, this.activityTypeFilterActive) ||
                this.getActivityTypeOrder(activities.items[index].activityTypeId, activityTypeOrder, Globals.maxInt) === Globals.maxInt) {
                activities.items.push(activities.items.splice(index, 1)[0]); // remove at index and push removed one at the end
            }
        }
        if (!multiDayActivities) return;

        // move activities that overlap multiple days to the front
        index = 0;
        while (index < activities.items.length) {
            if (this.isMultiDayActivity(activities.items[index], activityTypeOrder)) {
                // the activity might already have a fixed index because another column was sorted
                const fixedIndex = Planboard.activities.getOpenActivityArrayIndex(activities.items[index]);
                if (fixedIndex >= 0) {
                    this.moveToIndex(activities, index, fixedIndex);
                } else
                    while (index > 0) {
                        // previous is also multi day? (and it was already sorted by type order and begin time)
                        if (this.isMultiDayActivity(activities.items[index - 1], activityTypeOrder))
                            break;
                        this.moveToIndex(activities, index, index - 1);
                        index--;
                    }
            }
            index++;
        }
    }

}