/**
    * Clone request object.
    */
export class CloneRequest {

    /**
        * Id of the entity to clone.
        */
    id: number;

    /**
        * Display name to be given to the cloned entity.
        */
    displayName: string;
}