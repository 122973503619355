export interface IPlanningStatusService {
    subscribe(scope: any, callback: () => void): void;

    updatePlanningStatus(didUpdate: boolean): void;
}

export var planningStatusService = [
    "$rootScope",
    function ($rootScope) {
        this.didPlanningStatusUpdate = false;

        this.subscribe = function (scope, callback) {
            var handler = $rootScope.$on('planning-status-did-update', callback);
            scope.$on('$destroy', handler);

        }

        this.updatePlanningStatus = function (didUpdate) {
            this.didPlanningStatusUpdate = didUpdate;
            if (didUpdate)
                $rootScope.$emit('planning-status-did-update');
        }
    }
]