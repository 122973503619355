/**
    * Membership period for a resource.
    */
export class Cooperation {
    /**
        * Id of this membership period.
        */
    id: number;

    /**
        * Id of the first resource of this cooperation.
        */
    resourceId: number;

    /**
        * Id of the second resource of this cooperation.
        */
    otherResourceId: number;

    /**
        * Display name of the first resource of this cooperation.
        */
    resourceDisplayName: string;

    /**
        * Display name of the other resource of this cooperation.
        */
    otherResourceDisplayName: string;

    /**
        * Value for this cooperation: 1 = preferred, 2 = avoid
        */
    value: number;

    /**
        * Flag that denotes whether this cooperation has been added.
        */
    added: boolean;

    /**
        * Flag that denotes whether this cooperation has been deleted.
        */
    deleted: boolean;

    /**
        * Flag that denotes at which time the addition or deletion was done.
        */
    addedOrDeletedTimestamp: number;
}