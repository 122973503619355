import { StateService } from '@uirouter/angularjs';
import { INumberService } from './../../shared/numberService';
import { IPageStartService } from './../../shared/pageStartService';
import { IConfigurationService } from './../../shared/configurationService';

import { ITranslationService } from './../i18n/translationService';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import { Dictionary } from './../utils/dictionary';
import { IModalConfirmationWindowService } from './../utils/modalConfirmationWindowService';
import PermissionService = require("app/components/permissions/permissionService");
import IPermissionService = PermissionService.IPermissionService;
import UserService = require("app/shared/userService");
import IUserService = UserService.IUserService;
import * as Timezone from './../utils/timezone';

export class ExchangeUpdateConfigurationController {

    userOrganizationUnits = new Dictionary();
    organizationUnitTree: Array<any> = []; // for the tree template
    userResourceTypes = new Dictionary();
    categories = new Dictionary(); // dictionary with activity type categories
    activityTypes: Array<any> = []; // all activity types
    availableActivityTypes: Array<any> = []; // all available activity types to be filtered
    filteredActivityTypes: Array<any> = []; // activity types filtered by categories
    modifiedActivityTypes: Array<any> = []; // activity type settings modified
    configurations: Array<any> = [];
    selectedConfigId: string = "0"; // string due to input
    selectedConfig: any;
    daysOfWeek = new Dictionary();
    extraEmails: Array<any> = [];
    activityTypeFilter = "";
    activePageNr: number = 1;
    pageNrList: Array<number> = [];
    resultsPerPage = 10;
    visibleActivityTypes: Array<any> = [];
    activePages: Array<number> = [];
    updatingActivityTypeSettings: boolean = false;
    existingActivityTypeSettings: Array<any> = [];

    private automaticSaveDelay: number = 5000;
    private commonSvc: any;
    private saveDataTimer: any = null;
    private saveActivityTypeSettingsTimer: any = null;
    private newConfigId: number = -1; // decreased for every newly added configuration
    private extraEmailsTemp: Array<any> = []; // emails that are not valid to send to api
    private extraEmailOrgUnits: Dictionary = new Dictionary(); // filtered by selected org unit ids for configuration
    private noSelectedConfig: any = {
        id: "0",
        displayName: "",
        sendEmail: true,
        makeAppointment: true,
        updatePublishedAppointments: true,
        updatePublishedAppointmentsTime: "",
        bufferEmail: true,
        bufferAppointments: false,
        bufferTime: "",
        emailBeginPeriod: null,
        emailEndPeriod: null,
        appointmentBeginPeriod: null,
        appointmentEndPeriod: null,
        organizationUnitIds: [],
        changedResourceTypeIds: [],
        notifiedResourceTypeIds: [],
        syncTime: "",
        syncDaysOfWeek: [],
        synchronizationPeriods: [],
        pauseSynchronization: 0,
        pauseSynchronizationCheck: false,
        abortSynchronizationCheck: false,
        pauseSynchronizationStartTime: "", 
        pauseSynchronizationEndTime: "",
        pauseSynchronizationDays: [],
        extraEmails: []
    };
    private useActivityProcessedTable = false;
    private exchangeConfigMailPeriodMaxYearsDifference = null;
    private activityTypesFilterTimer: any = null;

    private readonly apiUrl: string = "api/ExchangeInterfaceUpdateConfiguration";
    private readonly urlGetActivityTypeCategories: string = "api/ActivityTypes/Categories";
    private readonly urlGetActiveMainActivityTypes = "api/ActivityTypes/MainActivityTypes";
    private readonly organizationUnitApiUrl: string = "api/OrganizationUnits";
    private readonly urlActivityTypeSettings: string = "api/ExchangeInterfaceUpdateConfiguration/ActivityTypeSettings";
    private readonly resourceTypeApiUrl: string = "api/ResourceTypes";
    private readonly dialogToken: string = "exchangeUpdateConfig";
    private readonly permission: string = "ExchangeInterface";

    private readonly disabledPauseAbortSync = 0;
    private readonly enabledPauseSync = 1;
    private readonly enabledAbortSync = 2;

    static $inject = [
        "$scope",
        "$filter",
        "$timeout",
        "modalConfirmationWindowService",
        "numberService",
        "pageStartService",
        "translationService",
        "configurationService"
    ];

    constructor(
        public $scope: ITreeListScope,
        private readonly $filter: ng.IFilterService,
        private readonly $timeout: ng.ITimeoutService,
        private readonly modalConfirmationWindowService: IModalConfirmationWindowService,
        private readonly numberService: INumberService,
        private readonly pageStartService: IPageStartService,
        private readonly translationService: ITranslationService,
        private readonly configurationService: IConfigurationService,
    ) {
        this.translationService.getTextLabels(this.$scope);

        this.configurationService.getLimitSettings(() => {
            this.exchangeConfigMailPeriodMaxYearsDifference = this.configurationService.limitSettings.exchangeConfigMailPeriodMaxYearsDifference;
            this.useActivityProcessedTable = this.configurationService.appConfiguration.featureManagement.useActivityProcessedTable;
        });


        this.commonSvc = this.pageStartService.initialize(this.$scope, this.permission, this.dialogToken);
        this.selectedConfig = this.noSelectedConfig;

        this.$scope.$on("$destroy", () => {
            this.saveActivityTypeSettingsWithTimeOut(0);
            this.saveDataWithTimeOut(0);
        });

        this.commonSvc.start(() => { this.loadData(); });
    }

    /**
     * Called after a config has been loaded from the web api.
     */
    private afterConfigLoaded(config: any) {
        config.subjectOptionString = config.subjectOption.toString();
        config.showProductTitlePrefix = !config.excludeProductTitlePrefix;
        config.pauseSynchronizationCheck = config.pauseSynchronization === this.enabledPauseSync;
        config.abortSynchronizationCheck = config.pauseSynchronization === this.enabledAbortSync;

        if (config.appointmentBeginPeriod) config.appointmentBeginPeriod = Timezone.correctTimeZoneInfo(config.appointmentBeginPeriod);
        if (config.appointmentEndPeriod) config.appointmentEndPeriod = Timezone.correctTimeZoneInfo(config.appointmentEndPeriod);
        if (config.emailBeginPeriod) config.emailBeginPeriod = Timezone.correctTimeZoneInfo(config.emailBeginPeriod);
        if (config.emailEndPeriod) config.emailEndPeriod = Timezone.correctTimeZoneInfo(config.emailEndPeriod);
    }

    /**
     * Called before a config is send to the web api.
     */
    private beforeConfigSaved(config: any) {
        config.subjectOption = parseInt(config.subjectOptionString);
        config.excludeProductTitlePrefix = !config.showProductTitlePrefix;
        config.pauseSynchronization =
            config.pauseSynchronizationCheck ? this.enabledPauseSync
                : config.abortSynchronizationCheck ? this.enabledAbortSync
                    : this.disabledPauseAbortSync;

        // Correct timezone info.
        if (config.appointmentBeginPeriod) config.appointmentBeginPeriod = Timezone.rollDateForWebApi(config.appointmentBeginPeriod);
        if (config.appointmentEndPeriod) config.appointmentEndPeriod = Timezone.rollDateForWebApi(config.appointmentEndPeriod);
        if (config.emailBeginPeriod) config.emailBeginPeriod = Timezone.rollDateForWebApi(config.emailBeginPeriod);
        if (config.emailEndPeriod) config.emailEndPeriod = Timezone.rollDateForWebApi(config.emailEndPeriod);
    }

    private loadData(): void {
        this.initializeDaysOfWeek();

        this.commonSvc.loadData(this.resourceTypeApiUrl, this.userResourceTypes, null, null, true, true);

        this.commonSvc.loadData(this.organizationUnitApiUrl, this.userOrganizationUnits,
            (success) => {
                this.userOrganizationUnits.forEach((key, value) => {
                    value.selectable = value.maxPermissionForCurrentUser >= 2;
                    this.registerChildToParent(value);
                });
                // Create organization unit tree for tree template.
                this.organizationUnitTree = [];
                this.userOrganizationUnits.forEach((key, value) => {
                    var parentEntity = null;
                    var entityArray = this.organizationUnitTree;
                    if (value.nodes == null) value.nodes = [];
                    if (value.parentId != null) parentEntity = this.userOrganizationUnits.value(value.parentId);
                    if (parentEntity != null) {
                        if (parentEntity.nodes == null) parentEntity.nodes = [];
                        entityArray = parentEntity.nodes;
                    }
                    entityArray.push(value);
                });
            }, null, true, true);

        // load activity type categories
        this.commonSvc.loadData(this.urlGetActivityTypeCategories, this.categories,
            (success) => {
                this.getActivityTypes();
            }, null, true, true);

        this.commonSvc.loadData(this.apiUrl, null,
            (success, loadInto) => {
                for (var i = 0; i < loadInto.length; i++) {
                    loadInto[i].id = loadInto[i].id.toString(); // convert to string for the select options in the view
                    loadInto[i].changed = false;
                    this.afterConfigLoaded(loadInto[i]);
                    this.configurations.push(loadInto[i]);
                }
            }, null, true, true);
    }

    onActivityTypeSettingsChanged(actType: any): void {
        var foundIndex = this.modifiedActivityTypes.findIndex(activityType => activityType.id === actType.id);

        foundIndex > -1 ? this.modifiedActivityTypes[foundIndex] = actType : this.modifiedActivityTypes.push(actType);

        if (!this.updatingActivityTypeSettings) {
            this.saveActivityTypeSettingsWithTimeOut(this.automaticSaveDelay);
        }

        this.updatingActivityTypeSettings = true;
    }

    onConfigChanged(): void {
        this.selectedConfig.changed = true;
        this.prepareExtraEmailsForApi();
        this.saveDataWithTimeOut(this.automaticSaveDelay);
    }

    onSelectConfig(): void {
        var foundConfig = null;
        for (var i = 0; i < this.configurations.length; i++)
            if (this.configurations[i].id === this.selectedConfigId) {
                foundConfig = this.configurations[i];
                break;
            }
        this.selectedConfig = foundConfig ? foundConfig : this.noSelectedConfig;

        this.extraEmailsTemp = [];
        this.extraEmails = [];
        for (var i = 0; i < this.selectedConfig.extraEmails.length; i++)
            this.extraEmails.push(this.selectedConfig.extraEmails[i]);

        this.getActivityTypes();
    }

    onAddConfigurationClick() {
        this.newConfigId--;
        this.configurations.push({
            id: this.newConfigId.toString(),
            displayName: this.$scope.textLabels.EXCHANGE_NEW_CONFIGURATION,
            sendEmail: true,
            makeAppointment: true,
            updatePublishedAppointments: true,
            updatePublishedAppointmentsTime: "",
            bufferEmail: true,
            bufferAppointments: false,
            bufferTime: "",
            emailBeginPeriod: null,
            emailEndPeriod: null,
            appointmentBeginPeriod: null,
            appointmentEndPeriod: null,
            organizationUnitIds: [],
            changedResourceTypeIds: [],
            notifiedResourceTypeIds: [],
            syncTime: "",
            subjectOption: 0,
            subjectOptionString: "0",
            showProductTitlePrefix: true,
            excludeProductTitlePrefix: false,
            useActivityTypeShortName: false,
            activityTypeCategoryIds: [],
            syncDaysOfWeek: [],
            synchronizationPeriods: [],
            pauseSynchronization: 0,
            pauseSynchronizationCheck: false,
            abortSynchronizationCheck: false,
            pauseSynchronizationStartTime: "",
            pauseSynchronizationEndTime: "",
            pauseSynchronizationDays: [],
            extraEmails: []
        });
        this.selectedConfigId = this.newConfigId.toString();
        this.onSelectConfig();
    }

    onDeleteConfigurationClick(): void {
        this.modalConfirmationWindowService.showModalDialog(this.$scope.textLabels.EXCHANGE_UPDATE_REMOVE_CONFIGURATION,
            this.$scope.textLabels.EXCHANGE_UPDATE_REMOVE_CONFIRM,
            () => {
                var i = this.configurations.length;
                while (i > 0) {
                    i--;
                    if (this.configurations[i].id === this.selectedConfigId) {
                        this.configurations.splice(i, 1);
                    }
                }
                var id = parseInt(this.selectedConfigId);
                if (!isNaN(id) && id > 0)
                    this.commonSvc.deleteData(this.apiUrl + "/" + id.toString(), null, null, false);
                this.selectedConfigId = "0";
                this.selectedConfig = this.noSelectedConfig;
            }, null);
    }

    filterTextTime($event: any, oldValue: string): void {
        this.numberService.filterTextTime($event, oldValue);
    }

    filterTextValue($event: any, oldValue: string): void {
        this.numberService.filterTextValue($event, oldValue, false, 5);
    }

    getClearDateText(): string {
        return this.$scope.textLabels.NO_DATE;
    }

    getWeekNrText(): string {
        return this.$scope.textLabels.WEEK.toLowerCase();
    }

    /**
     * test if a date is valid, using the exchangeConfigMailPeriodMaxYearsDifference limit setting
     * @param date the date to test
     */
    isDateValid(date: Date): boolean {
        if (date == null) return true; // date not set
        if (this.exchangeConfigMailPeriodMaxYearsDifference == null) return true; // limit setting not known yet
        if (!date.getTime) date = new Date(date); // convert string to date
        return date.getFullYear() - new Date().getFullYear() <= this.exchangeConfigMailPeriodMaxYearsDifference;
    }

    /**
     * test if a period is valid, using the exchangeConfigMailPeriodMaxYearsDifference limit setting
     * @param beginDate the begin date of the period
     * @param endDate the end date of the period
     */
    isPeriodValid(beginDate: Date, endDate: Date): boolean {
        if (beginDate != null && !beginDate.getTime) beginDate = new Date(beginDate); // convert string to date
        if (endDate != null && !endDate.getTime) endDate = new Date(endDate); // convert string to date
        if (beginDate != null && endDate != null && endDate.getTime() < beginDate.getTime()) return false; // end date before start date
        return this.isDateValid(endDate);
    }

    getCheckListNodeEnabled(node: any): boolean { // Tree checklist template
        if (!this.selectedConfig || this.selectedConfig === this.noSelectedConfig) return false; // no entity selected
        if (!node) return false; // node is null
        var result = true;
        if (node.maxPermissionForCurrentUser != null) if (node.maxPermissionForCurrentUser < 2) result = false; // node says the user has no write permission
        if (node.selectable != null) if (node.selectable === false) result = false; // node is not selectable
        return result;
    }

    getCheckListNodeState(node: any): boolean { // Tree checklist template
        if (this.selectedConfig && this.selectedConfig.organizationUnitIds && this.selectedConfig.organizationUnitIds.length > 0)
            return this.selectedConfig.organizationUnitIds.indexOf(node.id) >= 0;
        return false;
    }

    setCheckValue(node: any, value: boolean, autoSelectAllLeafs = false): void { // Tree checklist template
        if (node == null || node.maxPermissionForCurrentUser == null || node.maxPermissionForCurrentUser < 2) return;
        var unitList = this.selectedConfig.organizationUnitIds;
        const index = unitList.indexOf(node.id);
        if (index < 0 && value)
            unitList.push(node.id);
        else if (index >= 0 && !value)
            unitList.splice(index, 1);
        if (autoSelectAllLeafs && node.nodes && node.nodes.length > 0) {
            for (var i = 0; i < node.nodes.length; i++)
                this.setCheckValue(node.nodes[i], value, true);
        }
        else if (node.nodes && node.nodes.length > 0) {
            this.commonSvc.showYesNoDialog(this.$scope.textLabels.PROPOGATE_ORGANIZATION_SELECTION_TITLE,
                this.$scope.textLabels.PROPOGATE_ORGANIZATION_SELECTION_QUESTION,
                () => { // Yes, auto toggle al leafs
                    for (var i = 0; i < node.nodes.length; i++)
                        this.setCheckValue(node.nodes[i], value, true);
                },
                null);
        }
    }

    toggleCheckListNode(node: any): void { // Tree checklist template
        if (!this.getCheckListNodeEnabled(node)) return; // test if the node is enabled
        var unitList = this.selectedConfig.organizationUnitIds;
        if (!unitList) return;
        this.setCheckValue(node, unitList.indexOf(node.id) < 0);
        this.onConfigChanged();
    }

    onEmailOrgUnitChanged(): void {
        this.$timeout(() => {
            this.onConfigChanged();
        }, 1000);
    }

    onEmailAddressChanged(): void {
        this.onConfigChanged();
    }

    onActivityTypeCategoriesChanged(): void {
        this.onConfigChanged();
        this.getActivityTypes();
    }

    onAddSyncPeriod(): void {
        var newPeriod = {
            periodLengthWeeks: 1,
            synchronizationDays: [],
            syncTime: "00:00"
        }
        if (this.selectedConfig.synchronizationPeriods == null) {
            this.selectedConfig.synchronizationPeriods = [];
        }
        this.selectedConfig.synchronizationPeriods.push(newPeriod);
        this.onConfigChanged();
    }

    onDeleteSyncPeriod(index: number): void {
        this.selectedConfig.synchronizationPeriods.splice(index, 1);
        this.onConfigChanged();
    }

    onAddExtraEmail(): void {
        var newEmail = {
            address: "",
            organizationUnitId: -1,
        }
        if (!this.extraEmails)
            this.extraEmails = [];
        this.extraEmails.push(newEmail);
        this.onConfigChanged();
    }

    onDeleteExtraEmail(index: number): void {
        this.extraEmails.splice(index, 1);
        this.onConfigChanged();
    }

    buildExtraEmailOrgUnits(): any {
        this.extraEmailOrgUnits.clear();
        for (var i = 0; i < this.selectedConfig.organizationUnitIds.length; i++) {
            var id = this.selectedConfig.organizationUnitIds[i];
            if (this.userOrganizationUnits.containsKey(id))
                this.extraEmailOrgUnits.add(id, this.userOrganizationUnits.value(id));
        }
        return this.extraEmailOrgUnits;
    }

    onSwitchPage(pageNr: number): void {
        const prevPageNr = this.activePageNr;
        this.activePageNr = Math.max(Math.min(pageNr, this.pageNrList.length), 1);
        if (prevPageNr !== this.activePageNr) {
            this.setVisibleResults();
            this.calculateActivePages();
        }
    }

    statusCheckboxOnCreateAppointmentChanged(createAppointment: boolean, checkboxStatus: boolean): boolean {
        return createAppointment ? checkboxStatus : false;
    }

    private setVisibleResults(): void {
        this.visibleActivityTypes = [];
        var resourceIndex = (this.activePageNr - 1) * this.resultsPerPage;
        for (var i = resourceIndex; i < this.filteredActivityTypes.length && i < (this.resultsPerPage + resourceIndex); i++) {
            this.visibleActivityTypes.push(this.filteredActivityTypes[i]);
        }
    }

    private initializePagination(): void {
        this.filterActivityTypes();
        if (this.filteredActivityTypes.length === 0) {
            this.filteredActivityTypes = this.availableActivityTypes;
        }

        this.pageNrList = [];
        this.activePageNr = 1;
        var amountOfPages = Math.ceil(this.filteredActivityTypes.length / this.resultsPerPage);
        for (var i = 0; i < amountOfPages; i++)
            this.pageNrList.push(i + 1);
        this.setVisibleResults();
        this.calculateActivePages();
    }

    private filterActivityTypes(): void {
        this.filteredActivityTypes = [];
        if (this.activityTypeFilter != null && this.activityTypeFilter !== "") {
            for (let i = 0; i < this.availableActivityTypes.length; i++) {
                var filter = this.activityTypeFilter.trim().toLowerCase(); // filter to lower
                if (this.availableActivityTypes[i].displayName != null && this.availableActivityTypes[i].displayName.trim().toLowerCase().indexOf(filter) >= 0) {
                    this.filteredActivityTypes.push(this.availableActivityTypes[i]);
                }
                else if (this.availableActivityTypes[i].shortName != null && this.availableActivityTypes[i].shortName.trim().toLowerCase().indexOf(filter) >= 0) {
                    this.filteredActivityTypes.push(this.availableActivityTypes[i]);
                }
            }
        }
    }

    private calculateActivePages(): void {
        var startPage, endPage;
        const totalPages = this.pageNrList.length;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (this.activePageNr <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (this.activePageNr + 5 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = this.activePageNr - 5;
                endPage = this.activePageNr + 5;
            }
        }

        // create an array of pages to ng-repeat in the pager control
        this.activePages = new Array(endPage + 1 - startPage).fill(0).map((d, i) => i + startPage);
    }

    onActivityTypeFilterChanged(): void {
        if (this.activityTypesFilterTimer) this.$timeout.cancel(this.activityTypesFilterTimer);
        this.activityTypesFilterTimer = this.$timeout(() => {
            this.initializePagination();
        }, 1000);
    }

    private isEmailAddressValid(email: any): boolean {
        var indexAt = email.address.indexOf("@");
        var atIsValid = false;
        if (indexAt >= 1 && indexAt < email.address.length - 1)
            atIsValid = true;

        if (email.address && email.address.trim() != "" && atIsValid && email.organizationUnitId > -1)
            return true;
        return false;
    }

    private getActivityTypes(): void {
        if (parseInt(this.selectedConfigId) === 0) {
            return;
        }
        this.commonSvc.loadData(this.urlGetActiveMainActivityTypes, this.activityTypes,
            (success) => {
                if (parseInt(this.selectedConfigId) > 0) {
                    this.commonSvc.loadData(this.urlActivityTypeSettings + `/${this.selectedConfigId}`, this.existingActivityTypeSettings, (success) => {
                        this.setAvailableActivityTypes();
                    }, null, true, true);
                } else {
                    this.existingActivityTypeSettings = [];
                    this.setAvailableActivityTypes();
                }
            }, null, true, true);
    }

    private setAvailableActivityTypes() {
        this.availableActivityTypes = this.activityTypes.slice()
            .filter(actType => this.selectedConfig != undefined && this.selectedConfig.activityTypeCategoryIds.includes(actType.categoryId))
            .map(actType => {
                var currentActTypeSetting = this.existingActivityTypeSettings.find(setting => setting.activityTypeId === actType.id);

                var settings = currentActTypeSetting ? currentActTypeSetting : {
                    sendEmail: true,
                    createAppointment: true,
                    markAsBusy: true,
                    allDayEvent: false
                }

                return {
                    ...actType,
                    ...settings,
                };
            });
        this.initializePagination();
    }

    private buildExtraEmails(): void {
        this.extraEmails = [];
        var unorderedEmails = [];
        for (var i = 0; i < this.selectedConfig.extraEmails.length; i++) {
            unorderedEmails.push(this.selectedConfig.extraEmails[i]);
        }
        this.extraEmails = this.$filter("orderBy")(unorderedEmails, "address"); // only order the emails that are stored in db
        for (var i = 0; i < this.extraEmailsTemp.length; i++) {
            this.extraEmails.push(this.extraEmailsTemp[i]); // append the emails that are not yet / no longer stored at bottom of list
        }
        this.extraEmailsTemp = [];
    }

    /**
    * validates the extra email fields and removes the problem items before sending to api
    * the removed items are temporarily stored to be appended after receiving the response
    */
    private prepareExtraEmailsForApi(): void {
        this.selectedConfig.extraEmails = [];
        this.extraEmailsTemp = [];
        for (var i = 0; i < this.extraEmails.length; i++) {
            var email = {
                address: this.extraEmails[i].address,
                organizationUnitId: this.extraEmails[i].organizationUnitId
            };
            // Email address must be parsed, the org unit must be selected, and the selected org unit must be a part of the config's settings
            if (this.isEmailAddressValid(email) && email.organizationUnitId > -1 && this.selectedConfig.organizationUnitIds.indexOf(email.organizationUnitId) > -1)
                this.selectedConfig.extraEmails.push(email);
            else
                this.extraEmailsTemp.push(email);
        }
    }

    private saveDataWithTimeOut(timeout?: number): void {
        if (this.saveDataTimer) { this.$timeout.cancel(this.saveDataTimer); this.saveDataTimer = null; }
        if (timeout == null || timeout <= 0) {
            this.saveData();
            return;
        }
        this.saveDataTimer = this.$timeout(() => {
            this.saveData();
        }, timeout);
    }

    private saveData(): void {
        for (var i = 0; i < this.configurations.length; i++)
            if (this.configurations[i].changed && this.isConfigValid(this.configurations[i])) {
                this.configurations[i].changed = false;
                this.saveOneConfig(this.configurations[i]);
            }
    }

    /**
     * Helper function to return if one configuration is valid to be saved
     */
    private isConfigValid(config): boolean {
        if (!this.isPeriodValid(config.emailBeginPeriod, config.emailEndPeriod)) return false;
        if (!this.isPeriodValid(config.appointmentBeginPeriod, config.appointmentEndPeriod)) return false;
        return true;
    }

    private saveOneConfig(config): void {
        this.beforeConfigSaved(config);
        this.commonSvc.putData(this.apiUrl, config,
            (success) => {
                if (success && success.data && success.data.id != null) {
                    var oldId = config.id;
                    config.id = success.data.id.toString();

                    // Replace the object we sent with the object we received (to show anything corrected by the backend).
                    var idx = this.configurations.indexOf(config);
                    if (idx >= 0) this.configurations[idx] = success.data;
                    config = success.data;
                    config.id = success.data.id.toString();
                    this.afterConfigLoaded(config);

                    // if this is a new configuration, change the selectedConfigId to the newly received Id
                    if (oldId === this.selectedConfigId && config.id !== oldId) {
                        this.selectedConfigId = config.id;
                        this.selectedConfig = config;
                        this.buildExtraEmails();
                    }
                    else if (this.selectedConfigId === config.id) { // replace the selectedConfig object with the newly received object
                        this.selectedConfig = config;
                        this.buildExtraEmails();
                    }
                }
            }, null, false);
    }

    private saveActivityTypeSettings(): void {
        var activityTypeSettings = [];
        this.modifiedActivityTypes.forEach(actType => activityTypeSettings.push({
            activityType: actType,
            activityTypeId: actType.id,
            exchangeUpdateConfigurationId: this.selectedConfigId,
            allDayEvent: actType.allDayEvent,
            markAsBusy: actType.markAsBusy,
            createAppointment: actType.createAppointment,
            sendEmail: actType.sendEmail
        }));

        this.commonSvc.putData(this.urlActivityTypeSettings, activityTypeSettings,
            (success) => {
                if (success && success.data && success.data.id != null) {
                    this.updatingActivityTypeSettings = false;
                    this.modifiedActivityTypes = [];
                }
            },
            (error) => {
                this.getActivityTypes();
                this.commonSvc.httpErrorResponse(error, () => { });

                this.updatingActivityTypeSettings = false;
                this.modifiedActivityTypes = [];
            },
            false);
    }

    private saveActivityTypeSettingsWithTimeOut(timeout?: number): void {
        if (this.saveActivityTypeSettingsTimer) { this.$timeout.cancel(this.saveActivityTypeSettingsTimer); this.saveActivityTypeSettingsTimer = null; }
        if (timeout == null || timeout <= 0) {
            this.saveActivityTypeSettings();
            this.updatingActivityTypeSettings = false;
            this.modifiedActivityTypes = [];
            return;
        }
        this.saveActivityTypeSettingsTimer = this.$timeout(() => {
            this.saveActivityTypeSettings();
            this.updatingActivityTypeSettings = false;
            this.modifiedActivityTypes = [];
        }, timeout);

        return;
    }

    private registerChildToParent(organizationUnit: any, childId?: number): void {
        if (!organizationUnit.childIds) organizationUnit.childIds = [];
        if (!organizationUnit.parentId) return;
        if (!childId) childId = organizationUnit.id;
        var parent = this.userOrganizationUnits.value(organizationUnit.parentId);
        if (parent) {
            if (!parent.childIds) parent.childIds = [childId];
            else parent.childIds.push(childId);
            this.registerChildToParent(parent, childId);
        }
    }

    private initializeDaysOfWeek(): void {
        for (var i = 0; i < 7; i++) {
            var day = {
                id: i,
                displayName: this.$scope.textLabels["DAY_OF_WEEK_" + i]
            }
            this.daysOfWeek.add(i, day);
        }
    }

}