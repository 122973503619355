export var omrpCollapsible = function () {
    return {
        // can be used as an attribute or element or class
        restrict: "AEC",

        // declare the directives private scope
        scope: {
        },

        // the compile function is only called once, this is the initialization function
        compile: function (element, attributes) {

            var clickingCallback = function () {
                this.classList.toggle("active");
                this.nextElementSibling.classList.toggle("show");
                // trigger a window resize event just in case a controller needs this
                $(window).trigger("resize");
            };
            // bind the onClick of this element to a function
            element.bind("click", clickingCallback);

            // the link function is called every time the element is to be bound to data in the $scope object
            var linkFunction = function (scope, linkElement, linkAttributes) {
            }

            return linkFunction;
        }
    };
};