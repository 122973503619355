import { StateService, StateParams } from '@uirouter/angularjs';
import { IPageStartService } from './../../shared/pageStartService';

import { ITranslationService } from './../i18n/translationService';


import { IConfigurationService } from '../../shared/configurationService';

import { Planboard } from './../planboard/entities/planboard';
import { ActivityType } from '../planboard/entities/activitytype';
import { ObjectList } from './../planboard/utils/objectlist';
import { TimeSpan } from './../planboard/utils/timespan';

import { ITreeListScope } from './../treeListController/ITreeListScope';
import { Dictionary } from './../utils/dictionary';

export class ReplaceResourceController {

    readonly activityHeight: number = 24; // also in site.css
    readonly activityMarginTop: number = 2; // also in site.css
    selectedResource: any = null;
    visibleReplacementResources: Array<any> = [];
    activePageNr: number = 1;
    pageNrList: Array<number> = [];
    resourceTypes: Array<object> = [{ id: "null", displayName: this.$scope.textLabels.FILTER_NONE }];
    grayTd1Width: number = 0;
    whiteTdWidth: number = 0;
    grayTd2Width: number = 0;
    selectedResourceTypeId: string = "null";
    selectedActivityColor: string = null;
    selectedActivityBackground: string = null;
    selectedActivityShortText: string = null;
    selectedActivityResourceName: string = null;
    showDropdownWithResourceTypes: boolean = false;

    private commonSvc: any;
    private planboard = Planboard;
    private planboardActivityTypes: ObjectList = this.planboard.activityTypes;
    private selectedActivity: any = null;
    private plannedColumnStartDate: Date = null;
    private plannedColumnEndDate: Date = null;
    private replacementResources: Array<any> = [];
    private resourceTypesLoaded: Array<any> = [];

    private readonly dialogToken: string = "replaceResource";
    private readonly plannedColumnWidth: number = 480;
    private readonly plannedColumnHours: number = 20;
    private readonly pixelsPerHour: number = this.plannedColumnWidth / this.plannedColumnHours;
    private readonly resultsPerPage = 10;

    static $inject = [
        "$scope",
        "$filter",
        "$stateParams",
        "$timeout",
        "pageStartService",
        "translationService",
        "configurationService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $filter: ng.IFilterService,
        private $stateParams: StateParams,
        private $timeout: ng.ITimeoutService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private configurationService: IConfigurationService,
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.commonSvc = this.pageStartService.initialize(this.$scope, null, this.dialogToken);
        this.commonSvc.start(() => {
            this.getActivityWithId();
        }); 
    }

    private getActivityWithId() {
        this.commonSvc.loadData("api/Activities/GetActivity/" + this.$stateParams.activityId,
            null,
            (success) => {
                if (success && success.data) {
                    this.selectedActivity = success.data;
                    this.selectedActivityColor =
                        this.getActivityTextColor(
                            this.planboardActivityTypes.getObject(this.selectedActivity.activityTypeId));
                    this.selectedActivityBackground =
                        this.getActivityBackColor(
                            this.planboardActivityTypes.getObject(this.selectedActivity.activityTypeId));
                    this.selectedActivityShortText =
                        this.getActivityShortText(this.selectedActivity);
                    this.calculatePlannedColumnStyle();
                    this.getSelectedActivityResourceName();
                    this.getResourcesForActivity(success.data);
                    this.getResourceTypes();
                }
            },
            null, true, false);
    }

    private getResourceTypes() {
        this.commonSvc.loadData("api/ResourceTypes/ForPlanningBoard", null, (response) => {
            this.resourceTypesLoaded = response.data;
            if (this.resourceTypes.length <= 1) {
                this.getResourceTypesForActivity(this.selectedActivity);
            }
        }, null, true, false);
    }

    onSwitchPage(pageNr: number): void {
        var prevPageNr = this.activePageNr;
        this.activePageNr = Math.max(Math.min(pageNr, this.pageNrList.length), 1);
        if (prevPageNr !== this.activePageNr) {
            this.setVisibleResults();
            this.clearResourceSelection();
        }
    }

    onResourceSelected(resource: any): void {
        if (this.selectedResource && this.selectedResource !== resource)
            this.selectedResource.selected = false;
        resource.selected = !resource.selected;
        resource.selected ? this.selectedResource = resource : this.selectedResource = null;
    }

    onRemoveIconClick(activityIndex: number): void {
        var activity = this.selectedResource.activities[activityIndex];
        this.selectedResource.activities.splice(activityIndex, 1);
        this.selectedResource.activities.push(activity);
    }

    onPlanButtonClick(): void {
        if (!this.selectedResource) return;
        var resource = this.selectedResource;
        // If resource has activities planned and their max occupation is reached
        if (resource.activities.length > 0 && (resource.currentOccupation / resource.maxOccupation) >= 1) {
            // remove resource from the last index (bottom)
            var activityToUnplan = resource.activities[resource.activities.length - 1];
            this.updateActivity(this.formatActivityForApi(activityToUnplan, null));
        } else {
            // No activity to remove, then simply update the selectedActivity with the selectedResource
            this.updateActivity(this.formatActivityForApi(this.selectedActivity, resource.id));
        }
    }

    getActivityShortText(activity: any): string {
        var activityType = this.planboardActivityTypes.getObject(activity.activityTypeId);
        if (activityType)
            return activityType.shortText;

        return "-";
    }

    getResourceTypesForActivity(activity: any): Array<number> {
        var activityType = this.planboardActivityTypes.getObject(activity.activityTypeId) as ActivityType;
        if (activityType && activityType.resourceTypeIdList.length > 1) {
                this.showDropdownWithResourceTypes = true;
                activityType.resourceTypeIdList.forEach((resourceTypeId: number) => {
                    const resourceType = this.resourceTypesLoaded.find(resType => resType.id === resourceTypeId);
                    if (resourceType) {
                        this.resourceTypes.push({ id: resourceType.id, displayName: resourceType.displayName });
                    }
                });

            return activityType.resourceTypeIdList;
        }

        return [];
    }

    getActivityStyle(activity: any): any {
        var activityType = this.planboardActivityTypes.getObject(activity.activityTypeId);
        var activityColor = this.getActivityTextColor(activityType);
        var activityBackground = this.getActivityBackColor(activityType);

        var activityStartDate = new Date(activity.startDate);
        var activityEndDate = new Date(activity.endDate);

        var activityDuration = this.calculateDurationInHours(
            new Date(Math.max(activityStartDate.getTime(), this.plannedColumnStartDate.getTime())),
            new Date(Math.min(activityEndDate.getTime(), this.plannedColumnEndDate.getTime())));

        var activityWidth = Math.round(activityDuration * this.pixelsPerHour);
        activityWidth = Math.min(activityWidth, this.plannedColumnWidth);

        var leftDuration = this.calculateDurationInHours(this.plannedColumnStartDate, activityStartDate);
        var activityLeft = Math.round(leftDuration * this.pixelsPerHour);
        activityLeft = Math.max(0, activityLeft);

        var activityWidthStr = activityWidth + "px";
        var activityLeftStr = activityLeft + "px";

        return { 'color': activityColor, 'background': activityBackground, 'width': activityWidthStr, 'margin-left': activityLeftStr };
    }

    showResourcesBasedOnSelectedResorceType(): void {
        this.selectedActivity.resourceTypeId = this.selectedResourceTypeId;
        this.selectedActivity.searchPeriodStartDate = this.plannedColumnStartDate;
        this.selectedActivity.searchPeriodEndDate = this.plannedColumnEndDate;
        this.commonSvc.post("api/Resources/ReplacementForActivity/", this.selectedActivity,
            (success) => {
                this.replacementResources = [];
                for (var i = 0; i < success.data.length; i++) {
                    var resource = success.data[i];
                    resource.selected = false;
                    this.replacementResources.push(resource);
                }
                this.replacementResources = this.$filter("orderBy")(this.replacementResources, ["-skilled", "currentOccupation"]);
                this.initializePagination();
            }, null, true);
    }

    private getSelectedActivityResourceName(): void {
        var resource = this.planboard.activities.getResource(this.selectedActivity.resourceId, true);
        if (resource != null) {
            this.selectedActivityResourceName = resource.displayName;
        } else {
            var resourceIds = [this.selectedActivity.resourceId];
            if (resourceIds.length > 0) {
                var resources = { idList: resourceIds }
                this.commonSvc.post("api/Resources/WithId",
                    resources,
                    (success) => {
                        if (success.data && success.data.length > 0 && success.data[0]) {
                            this.selectedActivityResourceName = success.data[0].displayName;
                        }
                    },
                    null, false);
            }
        }
    }

    private getResourcesForActivity(activity: any): void {
        var activityType = this.planboardActivityTypes.getObject(activity.activityTypeId) as ActivityType;
      
        if (activityType?.resourceTypeIdList.length > 1) {
            activity.resourceTypeId = null;
        }
        activity.searchPeriodStartDate = this.plannedColumnStartDate;
        activity.searchPeriodEndDate = this.plannedColumnEndDate;
      
        this.commonSvc.post("api/Resources/ReplacementForActivity/", activity,
            (success) => {
                this.replacementResources = [];
                for (var i = 0; i < success.data.length; i++) {
                    var resource = success.data[i];
                    resource.selected = false;
                    this.replacementResources.push(resource);
                }
                this.replacementResources = this.$filter("orderBy")(this.replacementResources, ["-skilled", "currentOccupation"]);
                this.initializePagination();
            }, null, true);
    }

    private updateActivity(activity): void {
        if (!this.selectedResource || !this.selectedActivity) return;
        this.commonSvc.post("api/Activities/ChangeActivity",
            activity,
            (success) => {
                if (activity.resourceId) { // We planned the selected resource
                    this.refreshResourcesInPlanboard([this.selectedActivity.resourceId, this.selectedResource.id], activity);
                    this.$timeout(() => {
                        this.planboard.showMessage(this.$scope.textLabels.FIND_REPLACEMENT_RESOURCE,
                            this.$scope.textLabels.FIND_REPLACEMENT_RESOURCE_SUCCESS,
                            this.$scope.textLabels.OK,
                            () => {
                                window.history.back();
                            });

                    }, 100);
                } else { // We removed the selected resource from an activity, now try to plan the resource
                    this.updateActivity(this.formatActivityForApi(this.selectedActivity, this.selectedResource.id));
                }
            },
            (error) => {
                var failureReasons = this.planboard.getActivityFailureReasons(error && error.data ? error.data : error);

                this.commonSvc.showDialog(this.$scope.textLabels.ACTIVITY_SAVE_FAILED,
                    failureReasons,
                    this.$scope.textLabels.OK,
                    () => {
                        this.getActivityWithId();
                    });
            }, true);
    }

    private calculateDurationInHours(startDate: Date, endDate: Date): number {
        var totalMilliseconds = endDate.getTime() - startDate.getTime();
        var minutes = 1000 * 60;
        var hours = minutes * 60;
        return totalMilliseconds / hours;
    }

    private calculatePlannedColumnStyle(): void {
        if (!this.selectedActivity) return;
        var activityDuration = this.calculateDurationInHours(new Date(this.selectedActivity.startDate), new Date(this.selectedActivity.endDate));
        var activityWidth = Math.round(activityDuration * this.pixelsPerHour);
        this.whiteTdWidth = Math.min(activityWidth, this.plannedColumnWidth);
        this.grayTd1Width = Math.round((this.plannedColumnWidth - this.whiteTdWidth) / 2);
        this.grayTd2Width = this.plannedColumnWidth - this.grayTd1Width - this.whiteTdWidth;

        // calculate start and end time of the planning column viewport
        var activityStartDate = new Date(this.selectedActivity.startDate);
        this.plannedColumnStartDate = TimeSpan.fromDate(activityStartDate)
            .addMinutes((activityDuration / 2 - this.plannedColumnHours / 2) * 60).toDate();
        this.plannedColumnEndDate =
            TimeSpan.fromDate(this.plannedColumnStartDate).addMinutes(this.plannedColumnHours * 60).toDate();
    }

    private getActivityBackColor(activityType: any): string {
        if (activityType)
            return activityType.backColor;

        return "#eeeeee";
    }

    private getActivityTextColor(activityType: any): string {
        if (activityType)
            return activityType.textColor;

        return "#222222";
    }

    private formatActivityForApi(activity: any, resourceId: number): any {
        var formattedActivity = {
            ignoreResourceRestrictions: false,
            ignoreSkillCheck: true,
            scenarioId: activity.scenarioId,
            id: activity.id,
            parentId: (activity.parentId < 0 ? null : activity.parentId),
            memoId: activity.memoId,
            status: activity.status,
            activityTypeId: activity.activityTypeId,
            startDate: activity.startDate,
            endDate: activity.endDate,
            resourceId: resourceId,
            resourceTypeId: activity.resourceTypeId == null || activity.resourceTypeId < 0 ? null : activity.resourceTypeId
        }
        return formattedActivity;
    }

    private refreshResourcesInPlanboard(resourceIds: Array<number>, activityToAdd: any): void {
        var groupIndexDict = new Dictionary();
        for (var i = 0; i < resourceIds.length; i++) {
            var resourceId = resourceIds[i];
            if (!resourceId || resourceId < 0) continue;
            var groupIndex = this.planboard.activities.getResourceGroupIndex(resourceId);
            if (groupIndexDict.containsKey(groupIndex)) {
                var resourceIdList = groupIndexDict.value(groupIndex);
                resourceIdList.push(resourceId);
                groupIndexDict.add(groupIndex, resourceIdList);
            } else {
                groupIndexDict.add(groupIndex, [resourceId]);
            }
        }

        groupIndexDict.forEach((groupIndex, resourceIdList) => {
            this.planboard.activities.resetDaysReceived(null, null, null, groupIndex, groupIndex < 0, true, resourceIdList);
        });

        this.planboard.addActivitiesToMemory([activityToAdd], true);
        this.planboard.viewChanged();
        this.planboard.redrawAll();

    }

    private clearResourceSelection(): void {
        if (this.selectedResource) {
            this.selectedResource.selected = false;
            this.selectedResource = null;
        }
    }

    private setVisibleResults(): void {
        this.visibleReplacementResources = [];
        var resourceIndex = (this.activePageNr - 1) * this.resultsPerPage;
        for (var i = resourceIndex; i < this.replacementResources.length && i < (this.resultsPerPage + resourceIndex); i++) {
            this.visibleReplacementResources.push(this.replacementResources[i]);
        }
    }

    private initializePagination(): void {
        this.pageNrList = [];
        this.activePageNr = 1;
        var amountOfPages = Math.ceil(this.replacementResources.length / this.resultsPerPage);
        for (var i = 0; i < amountOfPages; i++)
            this.pageNrList.push(i + 1);
        this.setVisibleResults();
    }

    
}