export interface IEnvironmentProvider {
    baseUrl: string;
}

export function environmentProvider() {
    var baseUrl: string;

    var provider = {
        $get: $get,
        baseUrl: baseUrl,
    };

    return provider;

    function $get() {
        return {
            getBaseUrl: function () {
                return baseUrl;
            }
        };
    }
};