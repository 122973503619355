import { ITreeListScope } from './../treeListController/ITreeListScope';

export interface ITranslationService {

    /**
        * Retrieve text labels from the server and load them onto the translation service's scope.
        * @param language The language to retrieve the text labels for. Will default to the user's preferred language/the system language if this parameter is not specified.
        * @returns {} Promise chain.
        */
    retrieveTextLabels(language?: string): any;

    /**
        * Load text labels from the service onto the scope of a controller. Also subscribes the scope to changed text labels (e.g. when switching languages)
        * and takes care of cleaning up this subscription on scope destroy.
        * @param controllerScope Scope onto which the language labels are loaded and which is subscribed.
        * @returns {} Nothing.
        */
    getTextLabels(controllerScope: ITreeListScope): void;

    /**
        * Extracts the status code (in the form of F0000) from a status text.
        * @param {} statusText The status text to extract the status code from.
        * @returns {} The status code contained within the status text.
        */
    extractStatusCodeFromStatusText(statusText: string): string;

    /**
    * Returns the translation for a status code.
    * @param {} statusCode The status code to get the translation for.
    * @returns {} The translation for the specified status code.
    */
    getTranslationForStatusCode(statusCode: string): string;

    /**
        * Translates the error message from an http response.
        * @param {} httpResponse The http response to translate the error message for.
        * @returns {} 
        */
    translateErrorMessage(httpResponse: any): string;

    /**
        * Switches the language to the logged in user's language as stored at the backend.
        * @returns {} 
        */
    switchToUserLanguage(): void;

    /**
        * Gets the language code of the currently selected language.
        * @returns The language code of the currently selected language.
        */
    getCurrentLanguage(): string;

    /**
        * Gets the path to the help page, depending on the current language of the user
        * @returns The path of the help page
        */
    getHelpUrlPath(): string;

    /**
        * Sets the path to the help page, depending on the current language of the user
        * @returns {}
        */
    setHelpUrlPath(): void;
}

export var translationService = [
    "$http", "$rootScope", "$cookies", "$q", "$window", "tmhDynamicLocale",
    function ($http: angular.IHttpService, $rootScope: angular.IRootScopeService, $cookies, $q: angular.IQService, $window: ng.IWindowService, tmhDynamicLocale) {
        var svc = this;

        svc.language = null;
        svc.textLabels = null;
        svc.systemLanguage = null; // will only be set if actually needed (when falling back to the system language in case of user language problems)
        svc.helpUrlPath = null;

        // Call this to set the text labels on a controller's scope, reading from the service's textLabels object.
        // Also sets the language variable on $scope so that a controller can see the language code for the labels.
        var getTextLabels = function (controllerScope) {

            controllerScope.textLabels = svc.textLabels;
            controllerScope.language = svc.language;

            // Register the calling controller to the changed text labels event.
            var listenerCleanup = $rootScope.$on("lang:newLabels",
                function (event, data) {
                    controllerScope.textLabels = data.labels;
                    controllerScope.language = data.language;
                });

            // Execute listener cleanup code upon destruction of the controller.
            controllerScope.$on("$destroy",
                function () {
                    listenerCleanup();
                });
        };

        // Get the system language through a WebAPI call. This function returns a promise, due to the WebAPI call.
        var getSystemLanguage = function () {
            return $http.get("api/i18n/AvailableLanguages/SystemLanguage")
                .then(function (response) {
                    svc.language = response.data;
                    svc.systemLanguage = svc.language; // store this for reference in case fallback to the system language is needed
                    console.log("Systemlanguage as returned by the API: ", svc.language);
                    setDocumentLanguage(svc.language);
                    return response;
                });
        };

        /**
         * Gets the language code of the currently selected language.
         * @returns The language code of the currently selected language.
         */
        var getCurrentLanguage = function () {
            return svc.language;
        }

        // Function to change Angular's locale to what corresponds to the chosen language.
        var changeLocale = function (language) {
            var locale = language.split("+")[0]; // peel off customer-specific language suffix to get the locale
            tmhDynamicLocale.set(locale);
        }

        // Loads the text labels into the textLabels object of this service.
        // Only loads text labels when either a language is supplied or no prior language labels have been loaded.
        var retrieveTextLabels = function (language) {

            // We need to chain calls for reading the language labels' json with a promise, as reading the system
            // language is asynchronous. This means that cases where reading the system language is not needed need
            // to yield promises as well, as chaining would not be possible otherwise. This function will need to
            // return a promise as well, which is resolved before controllers are loaded (otherwise we would have
            // controllers without text labels, which would not be very nice).

            // Simple promise: nothing needs to be done, language labels are already in place. Directly return it.
            if (!language && svc.textLabels) return $q(function (resolve, reject) {
                var msg = "No loading of labels needed.";
                resolve(msg);
            });

            // No text labels, no specified language. See if we can get the language from the user's cookie.
            if (!language) {
                language = $cookies.get("userLanguage");
                //if (language) language = language + "bla"; // line to force a problem getting the user's preferred language
            }

            // Store language in cookie. Also re-store it after reading it to push the expiration date back.
            if (language) {
                var expireDate = new Date();
                expireDate.setDate(expireDate.getDate() + 365);
                $cookies.put("userLanguage", language, { "expires": expireDate, secure: true, samesite: "strict" });
            }

            // This promise will be used to chain getting the json to. It will either be the WebAPI
            // call for getting the system language or a simple promise that will directly be resolved
            // using the specified language/language read from the cookie.
            var langSettingPromise;

            if (language) {
                console.log("Language code used to retrieve text labels: ", language);
                setDocumentLanguage(language);
                // Language from parameter value or cookie: simple promise.
                langSettingPromise = $q( // TODO: there should be a better way to branch between function argument
                    function (resolve, reject) { // and system setting than making this promise.
                        svc.language = language;
                        var msg = "Set language to " + svc.language + ".";
                        resolve(msg);
                    });
            } else {
                // No language parameter or cookie: get the system language.
                langSettingPromise = getSystemLanguage();
            }

            return langSettingPromise
                .then(
                    function () {
                        // Read text labels from json file and broadcast changed labels to all subscribed controllers.
                        const languageFilePath = "omrp/assets/i18n/" + svc.language + ".json";
                        return $http.get(languageFilePath)
                            .then(
                                function (response) {
                                    svc.textLabels = response.data;
                                    changeLocale(svc.language);
                                    $rootScope.$broadcast("lang:newLabels", { language: svc.language, labels: response.data });
                                },
                                function () {
                                    // Failure: fall back to system language. If the problem was getting the system language,
                                    // display modal error message.
                                    if (svc.language === svc.systemLanguage) {
                                        $rootScope.$broadcast("severeError",
                                            {
                                                errorTitle: "Error reading language labels.",
                                                errorText: "Could not read language labels for " + svc.language + "."
                                            });
                                    } else {
                                        getSystemLanguage()
                                            .then(function () {
                                                retrieveTextLabels(svc.systemLanguage);
                                            });
                                    }
                                });
                    },
                    function () {
                        // This means that reading the system language from the WebAPI failed.
                        $rootScope.$broadcast("severeError", {
                            errorTitle: "Error reading system language.",
                            errorText: "Could not determine system language."
                        });
                    });
        };

        /**
         * Sets the path to the help page, depending on the current language of the user
         * @returns {}
         */
        var setHelpUrlPath = function () {
            if (svc.helpUrlPath === null) {
                const getHelpUrlPath = "api/Configuration/GetHelpURL/" + getCurrentLanguage();
                $http.get(getHelpUrlPath).
                    then(
                        function (response) {
                            svc.helpUrlPath = response.data;
                        },
                        function () {
                            svc.helpUrlPath = '';
                        });
            }
        }

        var getHelpUrlPath = function () {
            return svc.helpUrlPath;
        }

        var setDocumentLanguage = function (languageCode: string) {
            let shortLangcode = languageCode.substring(0, 2);
            if (!shortLangcode) {
                shortLangcode = 'en';
            }
            $window.localStorage.setItem('ngStorage-language', shortLangcode);
            console.log("Document language will be set to: ", shortLangcode);
            document.documentElement.setAttribute('lang', shortLangcode);
        }

        /**
         * Refreshes a user's assigned path to a help page correspondent to their language
         * @returns {}
         */
        var refreshHelpUrlPath = function () {
            svc.helpUrlPath = null;
            setHelpUrlPath();
        }


        /**
         * Switches the language to the logged in user's language as stored at the backend.
         * @returns {} 
         */
        var switchToUserLanguage = function () {
            $http.get("api/i18n/AvailableLanguages/UserLanguage")
                .then(function (response) {
                    svc.language = response.data;
                    refreshHelpUrlPath();
                    retrieveTextLabels(svc.language);
                });
        }

        /**
         * Extracts the status code (in the form of F0000) from a status text.
         * @param {} statusText The status text to extract the status code from.
         * @returns {} The status code contained within the status text.
         */
        var extractStatusCodeFromStatusText = function (statusText) {
            if (statusText != null && statusText.length >= 4) {
                return statusText.substring(0, 5); // status code is F + the 4 letter code in the statusText
            }

            return null;
        }

        /**
         * Returns the translation for a status code.
         * @param {} statusCode The status code to get the translation for.
         * @returns {} The translation for the specified status code.
         */
        var getTranslationForStatusCode = function (statusCode) {
            if (svc.textLabels.hasOwnProperty(statusCode)) {
                return svc.textLabels[statusCode];
            }

            return null;
        }

        /**
         * Translates the error message from an http response.
         * @param {} httpResponse The http response to translate the error message for.
         * @returns {} 
         */
        var translateErrorMessage = function (httpResponse) {
            if (httpResponse == null) return null;

            var statusText = httpResponse.data.statusText;

            return getTranslationForStatusCode(extractStatusCodeFromStatusText(statusText));
        }

        // Expose two functions to the outside world: retrieving text labels and putting text labels on scope.
        return {
            retrieveTextLabels: retrieveTextLabels,
            getTextLabels: getTextLabels,
            extractStatusCodeFromStatusText: extractStatusCodeFromStatusText,
            getTranslationForStatusCode: getTranslationForStatusCode,
            translateErrorMessage: translateErrorMessage,
            switchToUserLanguage: switchToUserLanguage,
            getCurrentLanguage: getCurrentLanguage,
            setHelpUrlPath: setHelpUrlPath,
            refreshHelpUrlPath: refreshHelpUrlPath,
            getHelpUrlPath: getHelpUrlPath
        };
    }
];