import { StateParams } from '@uirouter/angularjs';
import { ITranslationService } from './../i18n/translationService';
import { ITreeListScope } from './../treeListController/ITreeListScope';

export class SingleReportController {
    static $inject = [
        "$scope",
        "$stateParams",
        "translationService",
        "$timeout"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $stateParams: StateParams,
        private translationService: ITranslationService,
        private $timeout: ng.ITimeoutService
    ) {
        this.translationService.getTextLabels(this.$scope);

        this.doResize();
        $(window).on("resize.doResize", this.doResize.bind(this));

        // remove resize event handler
        this.$scope.$on("$destroy",
            function () {
                $(window).off("resize.doResize");
            });

        if (this.$stateParams.uri != null) {
            (<HTMLIFrameElement>$("#iframeReport")[0]).src = $stateParams.uri;
        }

    }

    doResize() {
        this.$timeout(function () {
            var navbar = $("#mainHeader"); // TODO: find alternative to jquery
            var reportsFrame = $("#iframeReport"); // TODO: find alternative to jquery

            var newTop = navbar.outerHeight(false);
            var newWidth = Math.floor(window.innerWidth);
            var newHeight = Math.floor(window.innerHeight - newTop);

            reportsFrame.css("top", "" + newTop + "px");
            reportsFrame.css("height", "" + newHeight + "px");
            reportsFrame.css("width", "" + newWidth + "px");
        }, 0);
    }
}