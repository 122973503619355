export class ResourcePropertyValue {

    /**
        * Id of the resource property value record.
        */
    id: number;

    /**
        * Id of the associated resource.
        */
    resourceId: number;

    /**
        * Id of the associated resource property.
        */
    resourcePropertyId: number;

    /**
        * Value of the resource property.
        */
    value: string;

    /**
        * Flag that indicates whether this resource property value has changed.
        */
    modified: boolean;

    /**
        * Flag that indicates whether this specified resource property value is readonly or not.
        */
    readonly: boolean;
}