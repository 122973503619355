import { INumberService } from '../../shared/numberService';

import { IPageStartService } from '../../shared/pageStartService';

import { IUserService } from '../../shared/userService';

import { ITranslationService } from '../i18n/translationService';

import { Planboard } from '../planboard/entities/planboard';

import { ITreeListScope } from '../treeListController/ITreeListScope';

import { Dictionary } from '../utils/dictionary';

export class ActivityTypeFilterAndSortingController {

    sortDescending = false;
    dayHoursStartList = new Dictionary();
    dayHoursEndList = new Dictionary();
    dayStartHour: number = Planboard.dayStartHour;
    dayEndHour: number = Planboard.dayEndHour;
    applyActivityTypeFilterAndSorting = true;
    selectAllHiddenActivityTypesCheck = false;
    selectAllVisibleActivityTypesCheck = false;
    filteredActivityTypesTree: Array<any> = []; // array of activity types based on user-entered filter
    allHiddenActivityTypes: Array<any> = []; // array of activity types based on user-entered filter
    allVisibleActivityTypesSorted: Array<any> = []; // array of activity types based on user-entered filter
    activityTypeFilterPermission: boolean;
    categories = [];
    userSelectedFilterAndSortingActivityTypes = [];
    visibleActivityTypesOfSelectedType: Array<any> = [];
    hiddenActivityTypesOfSelectedType: Array<any> = [];
    selectedCategoryId = 1;
    filterByLeafActivityTypes = false;
    filteredLeafActivityTypes: Array<any> = [];
    selectAllLeafActivityTypesCheck = false;
    visibleLeafActivityTypes: Array<any> = [];
    allActivityTypes = new Dictionary();

    private commonSvc: any;
    private saveChangesTimer: angular.IPromise<any> = null;
    private saveChangesTimerRunning = false;
    private anyChanges = false;
    private nrOfChangesPending = 0;
    private activityTypesTree: Array<any> = [];// list of all root activity types
    private selectedActivityTypeIds: Array<number> = [];
    private sortingOfSelectedActivityTypeIds = new Dictionary();
    private readyToSetSelectedActivityTypes = false;
    private leafActivityTypesByRootId = new Dictionary();

    private readonly dialogToken: string = "userResPref";
    private readonly automaticSaveDelay: number = 5000;
    private readonly urlGetActiveMainActivityTypes: string = "api/ActivityTypes/ActiveMainActivityTypes/WithUserGroupReadPermissions";
    private readonly urlUserSelectedActivityTypes: string = "api/Users/SelectedActivityTypesFilterAndSorting";

    static $inject = [
        "$scope",
        "$timeout",
        "numberService",
        "pageStartService",
        "translationService",
        "userService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $timeout: ng.ITimeoutService,
        private numberService: INumberService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private userService: IUserService
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.commonSvc = this.pageStartService.initialize(this.$scope, null, this.dialogToken);
        this.activityTypeFilterPermission = this.pageStartService.userHasPermission("ActivityTypeFilters", this.$scope, (hasPermission) => { this.activityTypeFilterPermission = hasPermission })
        this.commonSvc.start(() => { this.loadData(); });

        this.$scope.$on("documentClicked", (inner, target) => {
            // If is not "#HiddenActivityTypeDiv", clear all hidden activity type selections
            if (!$(target[0]).is("#HiddenActivityTypeDiv") &&
                !($(target[0]).parents("#HiddenActivityTypeDiv").length > 0))
                this.clearActivityTypeCheckmarks(this.hiddenActivityTypesOfSelectedType);

            // If is not "#VisibleActivityTypeDiv", clear all hidden activity type selections
            if (!$(target[0]).is("#VisibleActivityTypeDiv") &&
                !($(target[0]).parents("#VisibleActivityTypeDiv").length > 0))
                this.clearActivityTypeCheckmarks(this.hiddenActivityTypesOfSelectedType);
        });

        $scope.$on("$destroy", () => {
            this.userService.deleteUserVariables("preferences."); // delete all variables that start with "preferences."
            this.stopSaveChangesTimer();
            this.saveChanges();
        });
    }

    private loadData() {
        // hours in a day
        this.dayHoursStartList.clear();
        this.dayHoursEndList.clear();
        for (var i = 0; i <= 24; i++) {
            this.dayHoursStartList.add(i, { id: i, order: i, text: i.toString(), visible: true });
            this.dayHoursEndList.add(i, { id: i, order: i, text: i.toString(), visible: true });
        }

        // load categories
        this.commonSvc.loadData("api/ActivityTypes/Categories",
            null, (success, loadInto) => {
                for (var i = 0, len = loadInto.length; i < len; i++) {
                    loadInto[i].displayName = this.$scope.textLabels[loadInto[i].jsonName];

                    this.categories.push(loadInto[i]);
                }
            }, null, true, true);

        // load user selected filtering and sorting of activity types
        this.commonSvc.loadData("api/Users/SelectedActivityTypesFilterAndSorting",
            this.userSelectedFilterAndSortingActivityTypes,
            null, null, true, true);
        
        // load selected activity type ids and store in scope variable
        this.commonSvc.loadData(this.urlUserSelectedActivityTypes,
            this.sortingOfSelectedActivityTypeIds,
            (success, loadInto) => {
                // load root activity types
                this.commonSvc.loadData(this.urlGetActiveMainActivityTypes,
                    this.activityTypesTree,
                    (success, loadInto) => {
                        this.setSelectedActivityTypes();
                    }, null, true, true);
                // load all activity types - for leaf activity types
                if (Planboard.activityTypes == null || Planboard.activityTypes.count <= 0) {
                    this.commonSvc.loadData("api/ActivityTypes/Active",
                        this.allActivityTypes,
                        (success) => {
                            this.setSelectedActivityTypes();
                        }, null, true, true);
                } else {
                    Planboard.activityTypes.forEach((id, item) => {
                        this.allActivityTypes.add(item.id, item);
                    });
                    this.setSelectedActivityTypes();
                }
            }, null, true, true);

        this.applyActivityTypeFilterAndSorting = this.userService.getDisplaySettingSwitch("planboard.applyActivityTypeFilterSort");

        this.sanitizeValues();
    }

    onSettingsChanged(cachedSetting = null, cachedValue = null, cachedType = null) {
        if (cachedValue != null && cachedType != null)
            if (cachedType === 'switch')
                this.userService.setDisplaySettingSwitch(cachedSetting, !cachedValue, true);
            else
                this.userService.setDisplaySettingNumber(cachedSetting, cachedValue, true);

        this.anyChanges = true;
        this.startSaveChangesTimer();
        this.$timeout(() => { this.sanitizeValues(); }, 1);
    }

    onSelectAllHiddenActivityTypesCheckChanged() {
        // Use all hidden activity type length
        for (var i = 0; i < this.allHiddenActivityTypes.length; i++) {
            this.changeActivityTypeSelectedState(this.allHiddenActivityTypes[i], this.selectAllHiddenActivityTypesCheck, false, null, true);
        }
    }

    onSelectAllVisibleActivityTypesCheckChanged() {
        for (var i = 0; i < this.allVisibleActivityTypesSorted.length; i++) {
            this.changeActivityTypeSelectedState(this.allVisibleActivityTypesSorted[i], this.selectAllVisibleActivityTypesCheck, false, null, true);
        }
    }

    onSelectActivityTypeCheckChanged(activityType) {
        this.$timeout(() => {
            this.changeActivityTypeSelectedState(activityType, activityType.selected, true, () => { this.updateSelectAllActivityTypesCheck(); }, true);
        }, 0);
    }

    onAddToVisible() {
        // move-to-left button click
        var activityTypesToMove = []
        this.allHiddenActivityTypes.forEach((item) => {
            if (item.selected) {
                activityTypesToMove.push(item);
            }
        })

        var i = 0;
        activityTypesToMove.forEach((item) => {
            this.allVisibleActivityTypesSorted.push(item);
            this.sortingOfSelectedActivityTypeIds.add(item.id, i);
            this.allHiddenActivityTypes = this.allHiddenActivityTypes.filter(at => at !== item);
            i++;
        })

        if (this.selectAllHiddenActivityTypesCheck) {
            this.selectAllHiddenActivityTypesCheck = false;
            this.onSelectAllHiddenActivityTypesCheckChanged();
        }

        this.onSettingsChanged();
        this.updateSelectAllActivityTypesCheck();
    }

    onAddToHidden() {
        // move-to-right button click
        var activityTypesToMove = []
        this.allVisibleActivityTypesSorted.forEach((item) => {
            if (item.selected) {
                activityTypesToMove.push(item);
            }
        })

        activityTypesToMove.forEach((item) => {
            this.allHiddenActivityTypes.push(item);
            this.allVisibleActivityTypesSorted = this.allVisibleActivityTypesSorted.filter(at => at !== item)
            this.sortingOfSelectedActivityTypeIds.remove(item.id)
        })

        for (var i = 0; i < activityTypesToMove.length; i++) {
            this.changeActivityTypeSelectedState(activityTypesToMove[i], false, false, null, true);
        }

        if (this.selectAllVisibleActivityTypesCheck) {
            this.selectAllVisibleActivityTypesCheck = false;
            this.onSelectAllVisibleActivityTypesCheckChanged();
        }

        this.onSettingsChanged();
        this.updateSelectAllActivityTypesCheck();
    }

    onCategorySelected(id) {
        if (id == this.selectedCategoryId)
            return;
        this.selectedCategoryId = id

        // Set select all check boxes to false
        this.selectAllHiddenActivityTypesCheck = false;
        this.onSelectAllHiddenActivityTypesCheckChanged();
        this.selectAllVisibleActivityTypesCheck = false;
        this.onSelectAllVisibleActivityTypesCheckChanged();

        this.loadData();
        this.createVisibleandHiddenActivityTypeList();
        this.onSettingsChanged();
    }

    getUserVariable(name, defaultValue) {
        var value = this.userService.getUserVariable(name);
        if (value == null && defaultValue != null) return defaultValue;
        return value;
    }

    selectLeafActivityTypeCheckChanged(leafActivityType) {
        this.$timeout(() => {
            this.changeActivityTypeSelectedState(leafActivityType, leafActivityType.selected, true, () => { this.updateSelectAllLeafActivityTypesCheck(); });
        }, 0);
    }

    onMoveToTop() {
        var selectedActivityTypes = [];
        var visibleArrayLength = this.allVisibleActivityTypesSorted.length;
        if (visibleArrayLength == 0)
            return;
        for (var i = visibleArrayLength - 1; i >= 0; i--) {
            var activityType = this.allVisibleActivityTypesSorted[i];
            if (activityType.selected)
                selectedActivityTypes.push(activityType);
        }
        if (selectedActivityTypes.length == 0)
            return;
        for (var i = 0; i < selectedActivityTypes.length; i++) {
            var activityType = selectedActivityTypes[i];
            var index = this.allVisibleActivityTypesSorted.indexOf(activityType);
            if (index != -1)
                this.shiftDictionaryItem(this.sortingOfSelectedActivityTypeIds, index, 0);
            this.shiftArrayItem(this.allVisibleActivityTypesSorted, index, 0);
        }
        this.scrollToTop("#VisibleActivityTypeDiv");
        this.onSettingsChanged();
    }

    onMoveUp() {
        var selectedActivityTypes = [];
        var visibleArrayLength = this.allVisibleActivityTypesSorted.length;
        if (visibleArrayLength == 0)
            return;
        for (var i = 0; i < visibleArrayLength; i++) {
            var activityType = this.allVisibleActivityTypesSorted[i];
            if (activityType.selected)
                selectedActivityTypes.push(activityType);
        }
        if (selectedActivityTypes.length == 0)
            return;
        for (var i = 0; i < selectedActivityTypes.length; i++) {
            var activityType = selectedActivityTypes[i];
            var index = this.allVisibleActivityTypesSorted.indexOf(activityType);
            if (index != 0 && index != -1) // not at bound 
            {
                this.shiftDictionaryItem(this.sortingOfSelectedActivityTypeIds, index, index - 1);
                this.shiftArrayItem(this.allVisibleActivityTypesSorted, index, index - 1);
            }
        }
        this.onSettingsChanged();
    }

    onMoveDown() {
        var selectedActivityTypes = [];
        var visibleArrayLength = this.allVisibleActivityTypesSorted.length;
        if (visibleArrayLength == 0)
            return;
        for (var i = visibleArrayLength - 1; i >= 0; i--) {
            var activityType = this.allVisibleActivityTypesSorted[i];
            if (activityType.selected)
                selectedActivityTypes.push(activityType);
        }
        if (selectedActivityTypes.length == 0)
            return;
        for (var i = 0; i < selectedActivityTypes.length; i++) {
            var activityType = selectedActivityTypes[i];
            var index = this.allVisibleActivityTypesSorted.indexOf(activityType);
            if (index != visibleArrayLength - 1 && index != -1) // not at bound
            {
                this.shiftDictionaryItem(this.sortingOfSelectedActivityTypeIds, index, index + 1);
                this.shiftArrayItem(this.allVisibleActivityTypesSorted, index, index + 1);
            } 
        }
        this.onSettingsChanged();
    }

    onMoveToBottom() {
        var selectedActivityTypes = [];
        var visibleArrayLength = this.allVisibleActivityTypesSorted.length;
        if (visibleArrayLength == 0)
            return;
        for (var i = 0; i < visibleArrayLength; i++) {
            var activityType = this.allVisibleActivityTypesSorted[i];
            if (activityType.selected)
                selectedActivityTypes.push(activityType);
        }
        if (selectedActivityTypes.length == 0)
            return;
        for (var i = 0; i < selectedActivityTypes.length; i++) {
            var activityType = selectedActivityTypes[i];
            var index = this.allVisibleActivityTypesSorted.indexOf(activityType);
            if (index != -1) {
                this.shiftDictionaryItem(this.sortingOfSelectedActivityTypeIds, index, this.allVisibleActivityTypesSorted.length - 1)
                this.shiftArrayItem(this.allVisibleActivityTypesSorted, index, this.allVisibleActivityTypesSorted.length - 1);
            }
        }
        this.scrollToBottom("#VisibleActivityTypeDiv");
        this.onSettingsChanged();
    }

    sortAlphabetically() {
        var selectedActivityTypes = [];
        var selectedActivityTypeIds = [];
        var visibleArrayLength = this.allVisibleActivityTypesSorted.length;
        if (visibleArrayLength == 0)
            return;
        for (var i = 0; i < visibleArrayLength; i++) {
            var activityType = this.allVisibleActivityTypesSorted[i];
            if (activityType.selected) {
                selectedActivityTypes.push(activityType);
                selectedActivityTypeIds.push(activityType.id);
            }
        }

        var newOrder = [];

        // Add the non selected activity types to the top
        var nonSelectedActivityTypes = this.allVisibleActivityTypesSorted.filter(ats => !selectedActivityTypeIds.includes(ats.id));

        nonSelectedActivityTypes.forEach(item => {
            newOrder.push(item);
        });

        // Order the selected activity types and add them on the bottom
        this.filteredActivityTypesTree.forEach((item) => {
            if (selectedActivityTypeIds.includes(item.id) && this.allVisibleActivityTypesSorted.includes(item)) {
                newOrder.push(item);
            }
        });

        this.allVisibleActivityTypesSorted = newOrder;
        this.onSettingsChanged();
    }

    private saveChanges() {
        if (!this.anyChanges) return;
        this.anyChanges = false;

        this.userService.setDisplaySettingSwitch("planboard.applyActivityTypeFilterSort", this.applyActivityTypeFilterAndSorting);

        // save list of selected activity type ids
        this.incrementChangePending();

        var safeActivityTypeFilterAndSorting = new Dictionary();

        var i = 0;
        this.allVisibleActivityTypesSorted.forEach(activityType => {
            safeActivityTypeFilterAndSorting.add(activityType.id, i);
            i++;
        })
        this.commonSvc.putData("api/Users/SelectedActivityTypesFilterAndSorting/" + this.categories[this.selectedCategoryId].id, safeActivityTypeFilterAndSorting.getStore(),
            (success) => { this.decrementChangePending(); }, null, false);

        this.updateSelectAllActivityTypesCheck();
    }

    private startSaveChangesTimer() {
        this.stopSaveChangesTimer();
        if (this.anyChanges) {
            this.userService.setLogoffWaitTime(this.dialogToken, this.automaticSaveDelay);
            this.saveChangesTimer = this.$timeout(() => { this.saveChanges(); }, this.automaticSaveDelay);
            this.saveChangesTimerRunning = true;
        }
    }

    private stopSaveChangesTimer() {
        if (this.saveChangesTimerRunning) this.$timeout.cancel(this.saveChangesTimer);
        this.saveChangesTimerRunning = false;
    }

    private incrementChangePending() {
        this.nrOfChangesPending++;
    }

    private decrementChangePending() {
        this.nrOfChangesPending--;
        if (this.nrOfChangesPending <= 0) {
            this.userService.setLogoffWaitTime(this.dialogToken, 0);
        }
    }

    private updateSelectAllActivityTypesCheck() {
        // Select all functionality is set to select/deselect all activitytypes from filtered results for visible activity types

        if (this.allVisibleActivityTypesSorted.length > 0) {
            for (var i = 0; i < this.allVisibleActivityTypesSorted.length; i++) {
                if (!this.allVisibleActivityTypesSorted[i].selected) {
                    this.selectAllVisibleActivityTypesCheck = false;
                    break;
                } else {
                    this.selectAllVisibleActivityTypesCheck = true;
                }
            }
        }

        // Select all functionality is set to select/deselect all activitytypes from filtered results for hidden activity types
        if (this.allHiddenActivityTypes.length > 0) {
            for (var i = 0; i < this.allHiddenActivityTypes.length; i++) {
                if (!this.allHiddenActivityTypes[i].selected) {
                    this.selectAllHiddenActivityTypesCheck = false;
                    break;
                } else {
                    this.selectAllHiddenActivityTypesCheck = true;
                }
            }
        }
    }

    private createFilteredActivityTypeList() {
        this.filteredActivityTypesTree = [];

        for (var i = 0; i < this.activityTypesTree.length; i++) {
            var activityType = this.activityTypesTree[i];
            this.filteredActivityTypesTree.push(activityType);
        }
    }

    private createVisibleandHiddenActivityTypeList() {
        this.allVisibleActivityTypesSorted = [];
        this.allHiddenActivityTypes = [];
        var allVisibleActivityTypes = [];
        var selectedActivityTypeIds = [];
        var sortOrder = new Dictionary; 
        var lengthSelectedActivityTypes = 0;

        // Create Array of the activity types to show and a dictionary of the sort order
        this.userSelectedFilterAndSortingActivityTypes.forEach((item) => {
            if (item.categoryId == this.categories[this.selectedCategoryId].id) {
                selectedActivityTypeIds.push(item.activityTypeId);
                sortOrder.add(item.activityTypeId, item.sortOrder);
                lengthSelectedActivityTypes++;
            }
        });

        // Filter activity types
        this.filteredActivityTypesTree.forEach((item) => {
            if (this.selectedCategoryId === -1) {
                return
            }
            var category = this.categories[this.selectedCategoryId];
            if (item.categoryId === category.id) {
                if (selectedActivityTypeIds.includes(item.id)) {
                    item.selected = false;
                    allVisibleActivityTypes.push(item);
                }
                else {
                    this.allHiddenActivityTypes.push(item);
                }
            }
        });

        // Sort activity types
        var i = 0;
        while (i < lengthSelectedActivityTypes) {
            var filter = Object.entries(sortOrder.getStore()).filter(([k, v]) => v == i);
            i++;

            filter.forEach((item) => {
                var activityTypeIdPosition = 0;
                var activityType = allVisibleActivityTypes.filter(at => at.id == Number(item[activityTypeIdPosition]))
                this.allVisibleActivityTypesSorted.push(activityType[0]);
            })
        }
    }

    private clearActivityTypeCheckmarks(activityTypeArray) {
        this.$timeout(() => {
            for (var i = 0; i < activityTypeArray.length; i++)
                activityTypeArray[i].selected = false;
        }, 0);
    }

    private scrollToTop(div) {
        this.$timeout(() => {
            document.querySelector(div).scrollTop = 0;
        }, 0);
    }

    private scrollToBottom(div) {
        // TODO: could get div length instead of hardcode (in the event that div length > 10000)
        this.$timeout(() => {
            document.querySelector(div).scrollTop = 10000;
        }, 0);
    }

    /**
    * Shift an array item from one index to another
    * @param array the array we are making changes to
    * @param fromIndex the initial index of the item
    * @param toIndex the desired index for the of the item
    */
    private shiftDictionaryItem(dictionary, fromSortOrder, toSortorder) {
        for (var activityTypeId in dictionary.dict) {
            var sortOrder = dictionary.dict[activityTypeId];

            if (sortOrder === fromSortOrder) {
                dictionary.dict[activityTypeId] = toSortorder;
            }

            if (sortOrder === toSortorder) {
                dictionary.dict[activityTypeId] = fromSortOrder;
            }
        }
    }

    /**
    * Shift an array item from one index to another
    * @param array the array we are making changes to
    * @param fromIndex the initial index of the item
    * @param toIndex the desired index for the of the item
    */
    private shiftArrayItem(array, fromIndex, toIndex) {
        array.splice(toIndex, 0, array.splice(fromIndex, 1)[0]);
    }

    /**
    * make sure that all entered values make sense
    */
    private sanitizeValues() {
        // adjust start and end hours
        if (this.dayStartHour >= this.dayEndHour) {
            this.dayEndHour = Math.min(this.dayStartHour + 1, 24);
            this.dayStartHour = Math.max(this.dayEndHour - 1, 0);
        }
        // set what start and end hours are visible in the dropdown lists
        for (var i = 0; i <= 24; i++) {
            this.dayHoursStartList.value(i).visible = i < this.dayEndHour;
            this.dayHoursEndList.value(i).visible = i > this.dayStartHour;
        }
    }

    /**
    * Change the selected state of an activity type
    * @param activityType the object that has been selected/deselected
    * @param selected flag of selected state
    * @param shouldUpdateSelectAll flag that indicates when to call updateSelectAllActivityTypesCheck
            when true, it indicates that a single activityType was selected
            when false, it indicates that the select all toggle triggered the function call
    */
    private changeActivityTypeSelectedState(activityType: any, selected: boolean, shouldUpdateSelectAll: boolean, updateCallback: () => void = null, isRoot: boolean = false) {

        if (selected) {
            // Add selected activity id to list only if it wasn't already in the list
            if (shouldUpdateSelectAll || !activityType.selected) {
                if (this.selectedActivityTypeIds.indexOf(activityType.id) < 0) {
                    this.selectedActivityTypeIds.push(activityType.id);
                    if (isRoot)
                        this.addLeafActivityTypesByRootId(activityType.id, true);
                }
            }
        }
        else {
            // Remove unselected activity id from list if it exists in list
            var indexToRemove = this.selectedActivityTypeIds.indexOf(activityType.id);
            if (indexToRemove !== -1) {
                this.selectedActivityTypeIds.splice(indexToRemove, 1);
                if (isRoot)
                    this.removeLeafActivityTypesByRootId(activityType.id);
            }
        }

        if (shouldUpdateSelectAll)
            updateCallback();

        // Apply selected state to the activity type object
        activityType.selected = selected;
    }

    /**
    * when both the activity types and the selected ids have been loaded, set the selected values into the activity type objects
    */
    private setSelectedActivityTypes() {
        if (this.readyToSetSelectedActivityTypes) {
            var selectedCounter = 0;
            for (var i = 0; i < this.selectedActivityTypeIds.length; i++) {
                for (var j = 0; j < this.activityTypesTree.length; j++) {
                    if (this.activityTypesTree[j].id == this.selectedActivityTypeIds[i]) {
                        this.activityTypesTree[j].selected = true;
                        selectedCounter++;
                        this.addLeafActivityTypesByRootId(this.activityTypesTree[j].id, false);
                        break;
                    }
                }
            }
            this.createFilteredActivityTypeList();
            this.createVisibleandHiddenActivityTypeList();
            this.createFilteredLeafActivityTypeList();
            return;
        }
        this.readyToSetSelectedActivityTypes = true;
    }

    private formatActivityType(activityType) {
        // due to difference in activitytype.ts params and params returned from api
        if (activityType.backColor.indexOf('#') < 0)
            activityType.backColor = "#" + activityType.backColor;
        if (activityType.textColor.indexOf('#') < 0)
            activityType.textColor = "#" + activityType.textColor;
        if (!activityType.shortName)
            activityType.shortName = activityType.shortText;
        return activityType;
    }

    private getRootId(activityType) {
        let parentId = activityType.parentId;
        let rootId = parentId ? parentId : activityType.id;
        while (parentId != null) {
            const parent = this.allActivityTypes.value(parentId);
            if (!parent) // if parent not present in allActivityTypes, then no permission on parent
                return null;
            parentId = parent.parentId;
            if (parentId) rootId = parentId;
        }
        return rootId;
    }

    private addLeafActivityTypesByRootId(rootId, shouldSelect) {
        var leafArray = [];
        this.allActivityTypes.forEach((id, activityType) => {
            // Has given rootId, has parentId, has resourceTypeIdList
            if (this.getRootId(activityType) == rootId && activityType.parentId != null && activityType.resourceTypeIdList && activityType.resourceTypeIdList.length > 0) {
                activityType.selected = false;
                activityType = this.formatActivityType(activityType);
                // if root was just selected or if leaf id is in selected list
                if (shouldSelect || this.selectedActivityTypeIds.indexOf(activityType.id) > -1) {
                    activityType.selected = true;
                    this.selectLeafActivityTypeCheckChanged(activityType);
                }
                leafArray.push(activityType);
            }
        });
        this.leafActivityTypesByRootId.add(rootId, leafArray);
        this.createFilteredLeafActivityTypeList();
    }

    private removeLeafActivityTypesByRootId(rootId) {
        var leafActivitiesToRemove = this.leafActivityTypesByRootId.value(rootId);
        if (leafActivitiesToRemove)
            for (var i = 0; i < leafActivitiesToRemove.length; i++) {
                var indexToRemove = this.selectedActivityTypeIds.indexOf(leafActivitiesToRemove[i].id);
                if (indexToRemove !== -1)
                    this.selectedActivityTypeIds.splice(indexToRemove, 1);
            }

        this.leafActivityTypesByRootId.remove(rootId);
        this.createFilteredLeafActivityTypeList();

    }

    private updateSelectAllLeafActivityTypesCheck() {
        // Select all functionality is set to select/deselect all leaf activity types from filtered results
        for (var i = 0; i < this.filteredLeafActivityTypes.length; i++) {
            if (!this.filteredLeafActivityTypes[i].selected) {
                this.selectAllLeafActivityTypesCheck = false;
                return;
            }
        }
        this.selectAllLeafActivityTypesCheck = true;
    }

    private createFilteredLeafActivityTypeList() {
        this.filteredLeafActivityTypes = [];

        this.leafActivityTypesByRootId.forEach((rootId, leafActivityTypes) => {
            for (var i = 0; i < leafActivityTypes.length; i++) {
                var activityType = leafActivityTypes[i];
                this.filteredLeafActivityTypes.push(activityType);
            }
        });

        this.updateSelectAllLeafActivityTypesCheck();
    }

}