import { INumberService } from './../../shared/numberService';
import { IPageStartService } from './../../shared/pageStartService';
import { IUserService } from './../../shared/userService';
import { IConfigurationService } from './../../shared/configurationService';

import { ITranslationService } from './../i18n/translationService';

import { Planboard } from './../planboard/entities/planboard';
import { ActivityType } from './../planboard/entities/activitytype';
import * as Globals from './../planboard/utils/globals';
import { TimeSpan } from './../planboard/utils/timespan';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import { Dictionary } from './../utils/dictionary';
import * as Timezone from './../utils/timezone';

export class MultiSelectController {
    private date: Date = new Date();
    private today: Date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());

    userScenarios  = new Dictionary();
    userResourceTypes  = new Dictionary();
    userWriteResourceTypes  = new Dictionary();
    userOrganizationUnits  = new Dictionary();
    userRootActivityTypes  = new Dictionary();
    userResources  = new Dictionary();
    visibleUserResources  = new Dictionary();
    visibleUserResourcesAction  = new Dictionary();
    resourceDisplayNames  = new Dictionary();
    filterOrganizationUnitId: number = -1;
    filterScenarioId: number = -1;
    filterInvalidEnd: boolean = false;
    filterStartDate = this.today;
    filterEndDate = this.today;
    filterResourceDate = this.today;
    filterAllResourceTypes: boolean = false;
    filterResourceTypeIds: Array<number> = [];
    filterAllResources: boolean = false;
    filterAllActivityTypes: boolean = false;
    filterActivityTypeIds: Array<number> = [];
    filterResourceIds: Array<number> = [];
    filterOpenActivities: boolean = false; // include activities without resource that still need a resource
    showResourceTaglistSpinner: boolean = true;
    resourcesItemsVersion: number = 0;
    searchResults: Array<any> = []; // search results with activities
    showOnlyLeafResults: boolean = false;
    filterCriteriaChanged: boolean = false;
    selectAllCheck: boolean = true; // checkbox for selecting/deselecting all search results
    selectedResourceTypeId: number = -1;
    selectedResourceId: number = -1;
    replaceAnyAssignedResource: boolean = true;
    cancellationReasonText: string = "";
    weekDays: Array<any> = []; 
    selectedActionId: string = "1"; // string do to input
    selectedRepetitionPeriod: string = "1"; // string do to input
    copyToDate: Date = new Date(this.date.getTime());
    copyUpToIncludingDate: Date = new Date(this.date.getTime());
    copyCount: string = "1"; // string do to input
    copyToInvalidEnd: boolean = false;
    periodValue: string = "1"; // string do to input
    periodMonthSpecification: string = "1"; // string do to input
    periodWeekDaySpecification: string = "1"; // string do to input
    actions: Array<any>;
    repetitionPeriods: Array<any>;
    repetitionDayOfMonth: Array<any>;
    selectedScenarioIdFromPlanboard: number;
    filterInvalidFromDate = false; // indicates if filter from date is invalid based on limit setting;
    filterInvalidToDate = false; // indicates if filter from date is invalid based on limit setting;
    filterInvalidResourceFromDate = false; // indicates if filter from date for resources is invalid based on limit setting;
    copyToDateInvalidStartDate = false; // indicates if copy to date has invalid start date based on limit setting;
    copyToDateInvalidEndDate = false; // indicates if copy to date has invalid end date based on limit setting;
    copyToResourceInvalidStartDate = false; // indicates if copy to resource has invalid start date based on limit setting;
    copyToResourceInvalidEndDate = false; // indicates if copy to resource has invalid end date based on limit setting;

    private tomorrow: Date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()); // setDate in constructor
    private commonSvc: any;
    private loadResourcesTimer: any = null;
    private userActivityTypes = new Dictionary(); // dictionary with activity types for the current user
    private userResourcesRequested: boolean = false; // indicates that the total list of resources for the selected organization unit has already been requested
    private userResourcesReceived: boolean = false; // indicates that the total list of resources for the selected organization unit has been received
    private userResourcesFiltered: boolean = false; // indicates that the total list of resources has not yet been filtered into visibleUserResources
    private activityDict: Dictionary = null; // dictionary with search results with activities grouped by main activity
    private searched: boolean = false; // indicates if search has been used
    private rememberedActivitySelected: any = Object.create(null); // remembered selected state for activities
    private actionPending: boolean = false; // indicates if there is currently an action pending
    private actionRequiresNewSearch: boolean = false; // indicates if an action can only be activated after a new search
    private filterDateMaxYearsDifference = null;
    private copyToDateMaxYearsDifference = null;
    private copyToResourceMaxYearsDifference = null;

    private readonly dialogToken = "multiSelectInfo";
    private readonly permission = "MultiSelect";
    private readonly multiSelectActionCopyToDate = 0;
    private readonly multiSelectActionVacateResources = 1;
    private readonly multiSelectActionCancelActivities = 2;
    private readonly multiSelectActionRemoveActivities = 3;
    private readonly multiSelectMoveToDate = 4;
    private readonly multiSelectActionAssignResource = 5;
    private readonly scenarioApiUrl = "api/Scenarios";
    private readonly resourceTypeApiUrl = "api/ResourceTypes";
    private readonly organizationUnitApiUrl = "api/OrganizationUnits";
    private readonly activityTypeApiUrl = "api/ActivityTypes";
    private readonly urlGetActivityTypeCategories = this.activityTypeApiUrl + "/Categories";
    private readonly resourcesForOrganizationUnitsApiUrl = "api/Resources/OrganizationUnitsInPeriod";

    static $inject = [
        "$scope",
        "$filter",
        "$timeout",
        "numberService",
        "pageStartService",
        "translationService",
        "userService",
        "configurationService"
    ];

    constructor(
        public $scope: ITreeListScope,
        private $filter: ng.IFilterService,
        private $timeout: ng.ITimeoutService,
        private numberService: INumberService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private userService: IUserService,
        private configurationService: IConfigurationService
    ) {
        this.translationService.getTextLabels(this.$scope);

        this.configurationService.getLimitSettings(() => {
            this.filterDateMaxYearsDifference = this.configurationService.limitSettings.multiSelectFilterMaxYearsDifference;
            this.copyToDateMaxYearsDifference = this.configurationService.limitSettings.multiSelectCopyToDateMaxYearsDifference;
            this.copyToResourceMaxYearsDifference = this.configurationService.limitSettings.multiSelectCopyToResourceMaxYearsDifference;
        });

        this.commonSvc = this.pageStartService.initialize(this.$scope, this.permission, this.dialogToken);

        this.tomorrow.setDate(this.today.getDate() + 1);

        // actions possible on selected activities
        this.actions = [
            { id: "1", name: this.$scope.textLabels.COPY_TO_DATE },      // only on root
            { id: "3", name: this.$scope.textLabels.CANCEL_ACTIVITIES }, // only on root
            { id: "4", name: this.$scope.textLabels.REMOVE_ACTIVITIES }, // only on root
            { id: "5", name: this.$scope.textLabels.VACATE_ACTIVITIES },  // only on leaf
            { id: "6", name: this.$scope.textLabels.ASSIGN_RESOURCE }, // only on leaf
            { id: "7", name: this.$scope.textLabels.MOVE_TO_DATE } // only on root
        ];

        // repetition periods
        this.repetitionPeriods = [
            { id: "1", name: this.$scope.textLabels.TIME_RANGE_TYPE_1 }, // days
            { id: "2", name: this.$scope.textLabels.TIME_RANGE_TYPE_2 }, // weeks
            { id: "3", name: this.$scope.textLabels.TIME_RANGE_TYPE_3 }, // months
            { id: "4", name: this.$scope.textLabels.TIME_RANGE_TYPE_4 }, // years
            { id: "5", name: this.$scope.textLabels.DAY_OF_THE_MONTH }   // day of the month
        ];

        // day of the month repetition specification
        this.repetitionDayOfMonth = [
            { id: "1", name: this.$scope.textLabels.COPY_FIRST },
            { id: "2", name: this.$scope.textLabels.COPY_SECOND },
            { id: "3", name: this.$scope.textLabels.COPY_THIRD },
            { id: "4", name: this.$scope.textLabels.COPY_FOURTH },
            { id: "5", name: this.$scope.textLabels.COPY_LAST }
        ];

        this.filterAllResourceTypes = this.userService.getDisplaySettingSwitch("multiselect.filterAllResourceTypes");
        this.filterAllResources = this.userService.getDisplaySettingSwitch("multiselect.filterAllResources");
        this.filterAllActivityTypes = this.userService.getDisplaySettingSwitch("multiselect.filterAllActivityTypes");
        this.filterOpenActivities = this.userService.getDisplaySettingSwitch("multiselect.filterOpenActivities");
        this.showOnlyLeafResults = this.userService.getDisplaySettingSwitch("multiselect.showOnlyLeafResults");
        this.selectedScenarioIdFromPlanboard = this.userService.getDisplaySettingNumber("planboard.scenarioId", 1);

        this.$scope.$on("$destroy", () => {
            if (this.loadResourcesTimer) { this.$timeout.cancel(this.loadResourcesTimer); this.loadResourcesTimer = null; }
        });

        this.commonSvc.start(() => { this.loadData(); });
    }

    private loadData() {
        this.commonSvc.loadData(this.scenarioApiUrl, this.userScenarios,
            (success) => {
                // Add Planning scenario
                this.userScenarios.add(1, { id: 1, order: -1, displayName: this.$scope.textLabels.SCENARIO_TYPE_PLANNING });
                this.filterScenarioId = 1;
                // Hide scenarios that are completely in the past.
                this.userScenarios.forEach((key, value) => {
                    value.selectable = !this.isScenarioInThePast(value);
                });
            }, null, true, true);

        this.commonSvc.loadData(this.resourceTypeApiUrl, this.userResourceTypes,
            (success) => {
                // Fill a dictonary with resource types where the user has write permisions.
                var sortedResourceTypes = [];
                this.userWriteResourceTypes.clear();
                this.userResourceTypes.forEach((key, value) => {
                    if (value.maxPermissionForCurrentUser >= 2)
                        this.userWriteResourceTypes.add(key, value);
                    sortedResourceTypes.push(value);
                });

                sortedResourceTypes = this.$filter('orderBy')(sortedResourceTypes, (item) => { return item.displayName });

                for (var i = 0; i < sortedResourceTypes.length; i++)
                    sortedResourceTypes[i].order = i + 1;
            }, null, true, true);

        this.commonSvc.loadData(this.organizationUnitApiUrl, this.userOrganizationUnits,
            (success) => {
                // Register all organization units as children to their parents.
                this.userOrganizationUnits.forEach((key, value) => {
                    value.selectable = value.maxPermissionForCurrentUser >= 2;
                    this.registerChildToParent(value);
                });
                // Read user setting to see if user previously has selected any organization unit.
                var preselectedOrganizationUnitId = this.userService.getDisplaySettingNumber("multiselect.organizationUnitId", -1);
                if (preselectedOrganizationUnitId >= 0) {
                    this.onOrganizationUnitChanged(preselectedOrganizationUnitId);
                    this.filterOrganizationUnitId = preselectedOrganizationUnitId;
                }
            }, null, true, true);

        this.userRootActivityTypes.clear();
        // get all activity type categories
        this.commonSvc.loadData(this.urlGetActivityTypeCategories, null,
            (success, loadInto) => {
                for (var i = 0; i < loadInto.length; i++)
                    if (loadInto[i].id !== 3) { // ignore absence
                        loadInto[i].enabled = false; // may not be selected as a tag
                        loadInto[i].open = true; // default all are open

                        loadInto[i].displayName = this.$scope.textLabels[loadInto[i].jsonName]; //Set displayname with language setting user

                        this.userRootActivityTypes.add(loadInto[i].id + Globals.maxInt, loadInto[i]);
                    }
            }, null, true, true);

        this.commonSvc.loadData(this.activityTypeApiUrl, this.userActivityTypes,
            (success) => {
            // Only top level activity types that are not absence are selectable.
                var sortedActivityTypes = [];
                this.userActivityTypes.forEach((key, value) => {
                    if (this.isTopLevelActivityType(value) && !this.hasActivityTypeExpired(value)) {
                        value.parentId = value.categoryId + Globals.maxInt;
                        if (!this.userRootActivityTypes.value(value.parentId)) // temporary add a dummy category
                            this.userRootActivityTypes.add(value.parentId, { id: value.parentId, displayName: "", enabled: false });
                        value.originalDisplayName = value.displayName;
                        value.displayName = "[".concat(value.shortName, "] ", value.displayName);
                        this.userRootActivityTypes.add(key, value);
                        sortedActivityTypes.push(value);
                    }
                });
                sortedActivityTypes = this.$filter('orderBy')(sortedActivityTypes, (item) => { return item.originalDisplayName });
                for (var i = 0; i < sortedActivityTypes.length; i++)
                    sortedActivityTypes[i].order = i + 1;
        }, null, true, true);
    }

    onCopyToDateChanged(date: Date): void {
        this.calculateCopyUpToDate(date);

        switch (parseInt(this.selectedActionId)) {
            case 1: // copy to date action
                this.copyToDateInvalidStartDate = date.getFullYear() - new Date().getFullYear() > this.copyToDateMaxYearsDifference;
                break;
            case 2: // copy to resource action
                this.copyToResourceInvalidStartDate = date.getFullYear() - new Date().getFullYear() > this.copyToResourceMaxYearsDifference;
                break;
            default:
                return;
        }
    }

    onCopyCountChanged(): void {
        this.calculateCopyUpToDate(this.copyToDate);
    }

    onCopyUpToIncludingDateChanged(date: Date): void {
        this.copyToInvalidEnd = this.copyToDate && date && TimeSpan.getDayNr(date) < TimeSpan.getDayNr(this.copyToDate);

        switch (parseInt(this.selectedActionId)) {
            case 1: // copy to date action
                this.copyToDateInvalidEndDate = date.getFullYear() - new Date().getFullYear() > this.copyToDateMaxYearsDifference;
                break;
            case 2: // copy to resource action
                this.copyToResourceInvalidEndDate = date.getFullYear() - new Date().getFullYear() > this.copyToResourceMaxYearsDifference;
                break;
            default:
                return;
        }
        if (this.copyToInvalidEnd) this.copyCount = "0";
        else this.calculateCopyCount(date);
    }

    onSelectedResourceTypeChanged(): void {
        this.userResourcesFiltered = false;
        this.selectedResourceId = -1;
        this.visibleUserResourcesAction.clear();
    }

    onResourceSelectionDropdown(visible: boolean): void {
        if (visible && !this.userResourcesRequested) {
            if (this.loadResourcesTimer) { this.$timeout.cancel(this.loadResourcesTimer); this.loadResourcesTimer = null; }
            this.$timeout(() => { this.loadUserResources(this.filterOrganizationUnitId); }, 0);
        }
        if (visible && !this.userResourcesFiltered) this.$timeout(() => { this.filterUserResources() }, 0);
    }

    onOrganizationUnitChanged(organizationUnitId: number): void {
        this.filterChanged();
        // Save the selected organization unit to a user setting.
        this.userService.setDisplaySettingNumber("multiselect.organizationUnitId", organizationUnitId);
        // clear the resource dictionaries, so they will be reloaded when neccesary
        if (organizationUnitId !== this.filterOrganizationUnitId) {
            this.showResourceTaglistSpinner = true;
            this.userResourcesRequested = false;
            this.userResourcesReceived = false;
            this.filterResourceIds = [];
            this.userResources.clear();
            this.visibleUserResources.clear();

            // start a timeout to begin loading resources for the selected unit
            if (this.loadResourcesTimer) { this.$timeout.cancel(this.loadResourcesTimer); this.loadResourcesTimer = null; }
            this.loadResourcesTimer = this.$timeout(() => { this.loadUserResources(organizationUnitId); }, 1000);
        }
    }

    onResourceTypeSelectionChanged(): void {
        this.filterChanged();
        if (this.filterResourceIds.length === 0) {
            this.showResourceTaglistSpinner = true;
            this.userResourcesFiltered = false;
            this.visibleUserResources.clear();
            this.userResourcesReceived = false;

            // start a timeout to begin loading resources for organization unit
            if (this.loadResourcesTimer) { this.$timeout.cancel(this.loadResourcesTimer); this.loadResourcesTimer = null; }
            this.$timeout(() => { this.loadUserResources(this.filterOrganizationUnitId); }, 0);
        } else {
            this.filterUserResources();
            var i: number = this.filterResourceIds.length;
            while (i > 0) {
                i--;
                if (!this.visibleUserResources.value(this.filterResourceIds[i]))
                    this.filterResourceIds.splice(i, 1);
            }
        }
    }

    onResourceSelectionChanged(): void {
        this.userResourcesFiltered = false;
        this.filterChanged();
    }

    onActivityTypeSelectionChanged(): void {
        this.filterChanged();
    }

    onShowSearchResultsChanged(): void {
        this.$timeout(() => { this.rebuildResultList(); }, 0);
    }

    onFilterFromDateChanged(date: Date): void {
        this.filterChanged();
        this.filterInvalidEnd = this.filterEndDate && date && this.filterEndDate < date;
        this.filterInvalidFromDate = date.getFullYear() - new Date().getFullYear() > this.filterDateMaxYearsDifference;
        if (this.copyToDate > date) {
            this.copyToDate = new Date(date.getTime());
            this.onCopyToDateChanged(this.copyToDate);
        }
    }

    onFilterResourceDate(date: Date): void {
        this.filterChanged();
        this.filterInvalidResourceFromDate = date.getFullYear() - new Date().getFullYear() > this.filterDateMaxYearsDifference;
        this.filterResourceDate = date;
        this.loadUserResources(this.filterOrganizationUnitId);
    }

    onFilterToDateChanged(date: Date): void {
        this.filterChanged();
        this.filterInvalidEnd = date && this.filterStartDate && date < this.filterStartDate;
        this.filterInvalidToDate = date.getFullYear() - new Date().getFullYear() > this.filterDateMaxYearsDifference;
    }

    selectActivityCheckChanged (activity: any): void {
        this.$timeout(() => {
            this.changeSelectedState(activity, activity.selected);
        }, 0);
    }

    selectAllCheckChanged(): void {
        this.$timeout(() => {
            for (var i = 0; i < this.searchResults.length; i++) {
                this.changeSelectedState(this.searchResults[i], this.selectAllCheck);
            }
        }, 0);
    }

    getWeekNrText(): string {
        return this.$scope.textLabels.WEEK.toLowerCase();
    }

    getTooManyItemsText(): string {
        return this.$scope.textLabels.TOO_MANY_ITEMS;
    }

    getResourceDisplayNames(activity: any): string {
        if (this.activityIsNotRequired(activity))
            return this.$scope.textLabels.NOT_REQUIRED;

        var resourceIdList = activity.resourceIdList;

        // Add a placeholder for assigned resource when showing activities on resource level and resource displayName is ""
        if (resourceIdList.length === 1 && this.resourceDisplayNames.value(resourceIdList[0]) === "")
            return this.$scope.textLabels.RESOURCE_ASSIGNED_PLACEHOLDER;

        var result: string = "";

        for (var i = 0; i < resourceIdList.length; i++) {
            var name = this.resourceDisplayNames.value(resourceIdList[i]);
            if (name != null)
                result += (result === "" ? "" : "; ") + name;
        }
        // Made the choice to leave out the "assigned" placeholder if displaying the comma-separated list of resources
        // Could add a condition here if resourceIdList.length > 1 && result === "" then result = $scope.textLabels.RESOURCE_ASSIGNED_PLACEHOLDER

        return result;
    }

    canActivateAction(): boolean {
        if (this.actionPending || this.actionRequiresNewSearch) return false;
        if (!this.isActionEnabled(this.selectedActionId)) return false;
        var copyCount = parseInt(this.copyCount);
        if (isNaN(copyCount) || copyCount < 1) return false;
        if (parseInt(this.selectedActionId) === 6 && (this.selectedResourceTypeId === -1 || this.selectedResourceId === -1)) return false;
        return true;
    }

    isActionEnabled(actionIdStr: string): boolean {
        //The actions "copy to resource" (2), "vacate activities"(5), "Assign Resource"(6) are possible on selected leaf activities.
        //All actions except "vacate activities" (5) and "Move to Date" (7) are possible on selected root activities.
        var actionId = parseInt(actionIdStr);
        return ((this.showOnlyLeafResults && (actionId === 5 || actionId === 2 || actionId === 6)) || (!this.showOnlyLeafResults && actionId < 5) || (!this.showOnlyLeafResults && actionId === 7));
    }

    isDayOfMonthPeriod(): boolean {
        return parseInt(this.selectedRepetitionPeriod) === 5;
    }

    isRepetitionPeriodVisible(): boolean {
        return parseInt(this.selectedActionId) <= 2;
    }

    showWarningMessageForScenario(): boolean {
        return this.selectedScenarioIdFromPlanboard !==  this.filterScenarioId;
    }

    isResourceAndResourceTypeVisible(): boolean {
        return parseInt(this.selectedActionId) === 6;
    }

    isCancelReasonVisible(): boolean {
        return parseInt(this.selectedActionId) === 3;
    }

    isCopyToDateVisible(): boolean {
        return parseInt(this.selectedActionId) === 7;
    }

    isReplaceAnyAssignedResourcesVisible(): boolean {
        return parseInt(this.selectedActionId) === 6;
    }


    filterTextValue($event: any, oldValue: string, allowDecimal: boolean): void {
        this.numberService.filterTextValue($event, oldValue, allowDecimal, 4);
    }

    getWeekDays(): Array<any> {
        if (this.weekDays.length === 0) {
            var weekStart = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
            while (weekStart.getDay() !== 1) weekStart.setDate(weekStart.getDate() - 1);
            for (var i = 1; i <= 7; i++) {
                this.weekDays.push({
                    id: i,
                    name: this.$filter("date")(weekStart, "EEEE")
                });
                weekStart.setDate(weekStart.getDate() + 1);
            }
        }
        return this.weekDays;
    }

    calculateCopyUpToDate(copyToDate?: Date) {
        var copyCount: number = parseInt(this.copyCount);
        var periodValue: number = parseInt(this.periodValue);
        if (isNaN(copyCount) || isNaN(periodValue)) return;
        if (copyCount < 1) { copyCount = 1; }
        if (periodValue < 1) { periodValue = 1;}
        if (copyToDate == null) copyToDate = this.copyToDate;

        var upToIncluding = new Date(copyToDate.getTime());
        switch (parseInt(this.selectedRepetitionPeriod)) {
            case 1: // days
                upToIncluding.setDate(upToIncluding.getDate() + (copyCount - 1) * periodValue);
                break;
            case 2: // weeks
                upToIncluding.setDate(upToIncluding.getDate() + (copyCount - 1) * 7 * periodValue);
                break;
            case 3: // months
                upToIncluding.setMonth(upToIncluding.getMonth() + (copyCount - 1) * periodValue);
                break;
            case 4: // years
                upToIncluding.setFullYear(upToIncluding.getFullYear() + (copyCount - 1) * periodValue);
                break;
            case 5: // day of the month
                upToIncluding = this.dayOfMonthLoop(copyToDate, copyCount, null).upToIncluding;
                break;
            default:
                return;
        }

        // add the number of days in the filter selection
        var selectionDaysRange = (TimeSpan.getDayNr(this.filterEndDate) - TimeSpan.getDayNr(this.filterStartDate));
        if (selectionDaysRange > 0) upToIncluding.setDate(upToIncluding.getDate() + selectionDaysRange);

        this.copyUpToIncludingDate = upToIncluding;
        this.copyToInvalidEnd = false;

        switch (parseInt(this.selectedActionId)) {
            case 1: // copy to date action
                this.copyToDateInvalidEndDate = upToIncluding.getFullYear() - new Date().getFullYear() > this.copyToDateMaxYearsDifference;
                break;
            case 2: // copy to resource action
                this.copyToResourceInvalidEndDate = upToIncluding.getFullYear() - new Date().getFullYear() > this.copyToResourceMaxYearsDifference;
                break;
            default:
                return;
        }
    }

    onActionChanged(): void {
        // Make sure that the selected resource(type) is unselected
        this.selectedResourceId = null;
        this.selectedResourceTypeId = null;

        this.setValidatorsForActionId(this.selectedActionId);
    }

    startSearch(): void {
        this.saveLastSearchSettings();
        this.filterCriteriaChanged = false;
        this.searched = true;
        this.searchResults = [];

        var selectedResourceIds = [];
        if (this.filterAllResources) {
            this.visibleUserResources.forEach((key, value) => {
                selectedResourceIds.push(key);
            });
        } else {
            selectedResourceIds = this.filterResourceIds.slice();
        }

        var selectedResourceTypeIds = this.filterAllResourceTypes ? [] : this.filterResourceTypeIds.slice();
        var selectedActivityTypeIds = this.filterAllActivityTypes ? [] : this.filterActivityTypeIds.slice();
        if (selectedResourceIds.length === 0) selectedResourceIds = [0];
        if (!this.filterAllResourceTypes && selectedResourceTypeIds.length === 0) selectedResourceTypeIds = [0];
        if (!this.filterAllActivityTypes && selectedActivityTypeIds.length === 0) selectedActivityTypeIds = [0];
        var endDateExclusive = new Date(this.filterEndDate.getTime());
        endDateExclusive.setDate(endDateExclusive.getDate() + 1); // add one day, because $scope.filterEndDate is inclusive
        var activitySelection = {
            scenarioId: this.filterScenarioId,
            fromDateInclusive: Timezone.dateToStr(this.filterStartDate),
            toDateExclusive: Timezone.dateToStr(endDateExclusive),
            resourceIdList: selectedResourceIds,
            activitiesWithoutResource: this.filterOpenActivities,
            skipPlanningAbsences: true,
            activityTypeIdList: selectedActivityTypeIds,
            resourceTypeIdList: selectedResourceTypeIds
        }
        this.commonSvc.post("api/Activities/GetActivities", activitySelection,
            (success) => {
                // build a dictionary per parent activity and fill a list of leaf activities for each parent activity
                var activityDict = new Dictionary();
                for (var i = 0; i < success.data.length; i++) {
                    // webApi gives us all leaf activities, so we need to filter resources on leaf activities that we do not want to see
                    if (!this.filterAllResources && success.data[i].resourceId != null && success.data[i].resourceId > 0)
                        if (this.filterResourceIds.indexOf(success.data[i].resourceId) < 0)
                            continue;

                    success.data[i].activityType = this.userActivityTypes.value(success.data[i].activityTypeId);
                    success.data[i].startStr = this.dateToDisplayStr(Timezone.correctTimeZoneInfo(success.data[i].startDate), true, true);
                    success.data[i].endStr = this.dateToDisplayStr(Timezone.correctTimeZoneInfo(success.data[i].endDate), true, true);
                    success.data[i].leafList = [];

                    var activity = activityDict.value(success.data[i].id);
                    if (activity != null) success.data[i].leafList = activity.leafList;
                    activityDict.add(success.data[i].id, success.data[i]);

                    if (success.data[i].parentId != null && success.data[i].parentId > 0) {
                        activity = activityDict.value(success.data[i].parentId);
                        if (activity != null)
                            activity.leafList.push(success.data[i]);
                        else
                            activityDict.add(success.data[i].parentId, { leafList: [success.data[i]] });
                    }
                }

                // find rootId for each activity
                activityDict.forEach((key, value) => {
                    var root = value;
                    value.rootId = root.id; // default, activity is its own root activity
                    while (root != null && root.parentId != null && root.parentId > 0) root = activityDict.value(root.parentId);
                    if (root != null) value.rootId = root.id; // replace default with actual root activity id
                });

                // reset request resource Ids
                var resourceIdList = [];
                this.resourceDisplayNames.forEach((key, value) => {
                    if (value === "") resourceIdList.push(key);
                });
                for (var i = 0; i < resourceIdList.length; i++)
                    this.resourceDisplayNames.remove(resourceIdList[i]);

                this.activityDict = activityDict;
                this.rebuildResultList();
                this.calculateCopyUpToDate();
                this.actionRequiresNewSearch = false;
            },
            null, true);
    }

    activateAction(): void {
        if (this.actionPending || this.actionRequiresNewSearch) return;
        this.actionPending = true;

        // get the ids of the selected activities
        var selectedActivities = this.showOnlyLeafResults ? this.getSelectedLeafActivityList() : this.getSelectedRootActivityList();
        var selectedActivityIds = [];
        for (var i = 0; i < selectedActivities.length; i++) selectedActivityIds.push(selectedActivities[i].id);

        // get the id of the action to perform (see the MultiSelectAction enum)
        var actionId = 0;
        switch (parseInt(this.selectedActionId)) {
            case 3: // cancel activities
                actionId = this.multiSelectActionCancelActivities;
                this.actionRequiresNewSearch = true;
                break;
            case 4: // remove activities
                actionId = this.multiSelectActionRemoveActivities;
                this.actionRequiresNewSearch = true;
                break;
            case 5: // vacate activities (remove resources)
                actionId = this.multiSelectActionVacateResources;
                this.actionRequiresNewSearch = true;
                break;
            case 6: // Assign resource
                actionId = this.multiSelectActionAssignResource;
                this.actionRequiresNewSearch = true;
                break;
            case 7: // Move to date
                actionId = this.multiSelectMoveToDate;
                this.actionRequiresNewSearch = true;
                break;
            default: // 1: copy to date, 2: copy to date and resource
                actionId = this.multiSelectActionCopyToDate;
        }

        // construct the copy parameters
        var copyParams = {
            scenarioId: this.filterScenarioId,
            copyToDate: Timezone.dateToStr(this.copyToDate),
            copyUpToIncludingDate: Timezone.dateToStr(this.copyUpToIncludingDate),
            selectionStartDate: Timezone.dateToStr(this.filterStartDate),
            periodType: this.selectedRepetitionPeriod, // see CopyPeriodType enum
            periodEvery: this.periodValue, // for example, every 3 days
            dayOfMonthSpecification: {
                weekOfMonth: parseInt(this.periodMonthSpecification), // see WeekOfMonth enum
                dayOfWeek: parseInt(this.periodWeekDaySpecification) % 7 // see DayOfWeek enum (% 7 to convert sunday to 0)
            },
            repeat: Math.max(parseInt(this.copyCount), 1),
            resourceTypeId: this.selectedResourceTypeId <= 0 ? null : this.selectedResourceTypeId,
            resourceId: this.selectedResourceId <= 0 ? null : this.selectedResourceId,
            copyTypeAction: actionId === this.multiSelectActionCopyToDate ? this.selectedActionId : null,
            replaceAnyAssignedResource: this.replaceAnyAssignedResource
        }

        // construct the action DTO
        var actionDto = {
            scenarioId: this.filterScenarioId,
            activityIds: selectedActivityIds,
            action: actionId,
            copyParams: copyParams,
            cancellationReason: this.cancellationReasonText
        }

        // post the action
        this.commonSvc.post("api/Activities/MultiSelectAction", actionDto,
            (success) => {
                this.showActionResult(success);
            },
            (error) => {
                this.showActionResult(error);
            }, true);
    }

    private filterChanged(): void {
        if (this.searched) {
            this.searched = false;
            this.filterCriteriaChanged = true;
        }
    }

    private isTopLevelActivityType(activityType: any): boolean {
        return !activityType.parentId && activityType.categoryId !== ActivityType.absenceCategoryId;
    }

    private hasActivityTypeExpired(activityType: any): boolean {
        if (!activityType.validTo) return false;
        var atToDate = Timezone.parseDate(activityType.validTo);
        return atToDate < this.date;
    }

    private isScenarioInThePast(scenario: any): boolean {
        if (!scenario.end) return false;
        var scenEndDate = Timezone.parseDate(scenario.end);
        return scenEndDate < this.date;
    }

    private getAllResourcesOnActivityGroup(activity: any): Array<any> {
        var result = [];
        if (activity.resourceId != null && activity.resourceId > 0)
            result.push(activity.resourceId);
        for (var i = 0; i < activity.leafList.length; i++)
            result = result.concat(this.getAllResourcesOnActivityGroup(activity.leafList[i]));
        return result;
    }

    private anyActivityInGroupIsRequired(activity: any): boolean {
        if (activity.leafList.length === 0) {
            return this.activityIsRequired(activity);
        }
        else {
            for (var i = 0; i < activity.leafList.length; i++) {
                if (this.anyActivityInGroupIsRequired(activity.leafList[i])) {
                    return true;
                }
            }
        }
        return false;
    }

    private anyActivityInGroupIsNotRequired(activity: any): boolean {
        if (activity.leafList.length === 0) {
            return this.activityIsNotRequired(activity);
        }
        else {
            for (var i = 0; i < activity.leafList.length; i++) {
                if (this.anyActivityInGroupIsNotRequired(activity.leafList[i])) {
                    return true;
                }
            }
        }
        return false;
    }

    private activityIsNotRequired(activity: any): boolean {
        return activity.status == 5; // NotRequired status = 5
    }

    private activityIsRequired(activity: any): boolean {
        return activity.status !== 5; // NotRequired status = 5
    }

    private getAllLeafActivitiesOnActivityGroup(activity: any): Array<any> {
        var result = [];
        if (activity.activityType != null) {
            // only add if it has no leaf activities (to not include root activities)
            // When a root/parent is received and no leaf is received, then the root is added to the results even when this isn't correct
            // because leafList is built front-end based on the search results. Sometimes the search filters out the leaf, leaving you only with the parent
            // This is solved by also testing if the activityType needs any resourceTypeIds.
            if (activity.leafList.length === 0 &&
                activity.activityType.resourceTypeIdList != null && activity.activityType.resourceTypeIdList.length > 0)
                result.push(activity);
        }
        for (var i = 0; i < activity.leafList.length; i++)
            result = result.concat(this.getAllLeafActivitiesOnActivityGroup(activity.leafList[i]));
        return result;
    }

    private getSelectedLeafActivityList(): Array<any> {
        var selectedLeafActivityList = [];
        for (var i = 0; i < this.searchResults.length; i++)
            if (this.searchResults[i].selected) {
                selectedLeafActivityList = selectedLeafActivityList.concat(this.getAllLeafActivitiesOnActivityGroup(this.searchResults[i]));
            }
        return selectedLeafActivityList;
    }

    private getSelectedRootActivityList(): Array<any> {
        var selectedRootActivityList = [];
        for (var i = 0; i < this.searchResults.length; i++)
            if (this.searchResults[i].selected) {
                if (this.searchResults[i].parentId == null || this.searchResults[i].parentId < 0)
                    selectedRootActivityList.push(this.searchResults[i]);
            }
        return selectedRootActivityList;
    }

    private getSelectedState(activity: any): boolean {
        var remembered = this.rememberedActivitySelected[activity.id.toString()];
        if (remembered == null && activity.rootId !== activity.id) {
            // test remembered value of the root activity
            remembered = this.rememberedActivitySelected[activity.rootId.toString()];
        }
        // default there is nothing remembered, so we also return true if the value is null
        return remembered == null || remembered == true;
    }

    private changeSelectedState(activity, selected): void {
        activity.selected = selected;
        this.rememberedActivitySelected[activity.id.toString()] = selected;
        var root = this.activityDict.value(activity.rootId);
        if (!root) return;
        var leafList = this.getAllLeafActivitiesOnActivityGroup(root);
        var i = 0;
        if (root.id === activity.id) { // remember selected for all leaf activities
            for (i = 0; i < leafList.length; i++)
                this.rememberedActivitySelected[leafList[i].id.toString()] = selected;
            return;
        }
        // set remembered for all leaf activities
        var atLeastOneLeafSelected = false
        var leafSelected = true;
        for (i = 0; i < leafList.length; i++) {
            leafSelected = this.getSelectedState(leafList[i]);
            this.rememberedActivitySelected[leafList[i].id.toString()] = leafSelected;
            if (leafSelected) atLeastOneLeafSelected = true;
        }
        // also remember that the root is selected/deselected
        this.rememberedActivitySelected[activity.rootId.toString()] = atLeastOneLeafSelected;
    }

    private rebuildResultList(): void {
        if (!this.activityDict) return;

        // activities that are set to "not required" should only be included in the result if all of the following:
        // 1. The option for all resources is unchecked. (filterAllResources == false)
        // 2. The option for activities without resource is unchecked. (filterOpenActivities == false)
        // 3. There are no individual resources selected. (filterResourceIds is empty)
        var includeNotRequired = (!this.filterAllResources && !this.filterOpenActivities && this.filterResourceIds.length === 0);

        var searchResults = [];
        var resourceIdList = [];
        var leafActivityList = [];
        this.activityDict.forEach((key, value) => {
            if (value.activityType && (value.parentId == null || value.parentId < 0)) { // only iterate over root activities
                if (this.showOnlyLeafResults) {
                    // add each leaf activity with only the resource on the leaf activity
                    leafActivityList = this.getAllLeafActivitiesOnActivityGroup(value);
                    for (var i = 0; i < leafActivityList.length; i++) {
                        var activityIsNotRequired = this.activityIsNotRequired(leafActivityList[i]);
                        // only include the leaf in the result if it matches includeNotRequired
                        if (includeNotRequired !== activityIsNotRequired)
                            continue;

                        leafActivityList[i].resourceIdList = [];
                        if (leafActivityList[i].resourceId != null && leafActivityList[i].resourceId > 0)
                            leafActivityList[i].resourceIdList.push(leafActivityList[i].resourceId);
                        this.addAndSortByStartDate(searchResults, leafActivityList[i], resourceIdList, includeNotRequired);
                    }
                } else {
                    var activityIsNotRequired = this.activityIsNotRequired(value);
                    var includeRootInResult = false;
                    if (includeNotRequired) {
                        // include the root if any activity in the group is not required
                        includeRootInResult = this.anyActivityInGroupIsNotRequired(value);
                    }
                    else {
                        // include the root if any activity in the group is required
                        includeRootInResult = this.anyActivityInGroupIsRequired(value);
                    }

                    if (includeRootInResult) {
                        // add the root activity and include all resourceIds of all leaf activities
                        value.resourceIdList = this.getAllResourcesOnActivityGroup(value);
                        this.addAndSortByStartDate(searchResults, value, resourceIdList, includeNotRequired);
                    }
                }
            }
        });

        // determine if the selectAllCheck should be checked
        var allSelected = true;
        for (var i = 0; i < searchResults.length; i++)
            if (!searchResults[i].selected) {
                allSelected = false;
                break;
            }
        this.selectAllCheck = allSelected;

        // request new displaynames for resources
        if (resourceIdList.length > 0) {
            var resourceSelection = { idList: [].concat(resourceIdList) }
            this.commonSvc.post("api/Resources/WithId", resourceSelection,
                (success) => {
                    for (var i = 0; i < success.data.length; i++)
                        this.resourceDisplayNames.add(success.data[i].id, success.data[i].displayName);
                },
                null, false);
        }
        this.searchResults = searchResults;
    }

    private loadUserResources(forOrganizationUnitId: number): void {
        this.showResourceTaglistSpinner = true;
        this.userResourcesRequested = true;
        this.commonSvc.post(this.resourcesForOrganizationUnitsApiUrl + "/" + Timezone.dateToStr(this.filterResourceDate),
            [forOrganizationUnitId],
            (success) => {
                this.updateUserResources(success.data);
                this.showResourceTaglistSpinner = false;
                this.resourcesItemsVersion++;
            },
            null, false);
    }

    private addAndSortByStartDate(searchResults: Array<any>, value: any, resourceIdList: Array<number>, includeNotRequired: boolean) {
        if (this.filterOpenActivities || value.resourceIdList.length > 0 || includeNotRequired) {
            // try to find the resource displayname, add to resourceIdList if not found
            for (var i = 0; i < value.resourceIdList.length; i++)
                if (this.resourceDisplayNames.value(value.resourceIdList[i]) == null) {
                    resourceIdList.push(value.resourceIdList[i]);
                    this.resourceDisplayNames.add(value.resourceIdList[i], "");
                }

            // set remembered selected (checked) value
            value.selected = this.getSelectedState(value);

            // add and sort by startDate
            searchResults.push(value);
            var swap = null;
            var index = searchResults.length - 1;
            while (index > 0 && searchResults[index].startDate < searchResults[index - 1].startDate) {
                swap = searchResults[index];
                searchResults[index] = searchResults[index - 1];
                searchResults[index - 1] = swap;
                index--;
            }
        }
    }

    private registerChildToParent(organizationUnit: any, childId?: number): void {
        if (!organizationUnit.childIds) organizationUnit.childIds = [];
        if (!organizationUnit.parentId) return;
        if (!childId) childId = organizationUnit.id;
        var parent = this.userOrganizationUnits.value(organizationUnit.parentId);
        if (parent) {
            if (!parent.childIds) parent.childIds = [childId];
            else parent.childIds.push(childId);
            this.registerChildToParent(parent, childId);
        }
    }

    private saveLastSearchSettings(): void {
        this.userService.setDisplaySettingSwitch("multiselect.filterAllResourceTypes", this.filterAllResourceTypes);
        this.userService.setDisplaySettingSwitch("multiselect.filterAllResources", this.filterAllResources);
        this.userService.setDisplaySettingSwitch("multiselect.filterAllActivityTypes", this.filterAllActivityTypes);
        this.userService.setDisplaySettingSwitch("multiselect.filterOpenActivities", this.filterOpenActivities);
        this.userService.setDisplaySettingSwitch("multiselect.showOnlyLeafResults", this.showOnlyLeafResults);
    }

    private dateToDisplayStr(dt: Date, includeDate: boolean, includeTime: boolean): string {
        return includeDate ? this.$filter("date")(dt, "EEE") + " " +
            this.$filter("date")(dt, "mediumDate").replace(dt.getFullYear().toString(), "").replace(",", "").replace(".", "").trim() +
            (includeTime ? " " + this.$filter("date")(dt, "HH:mm") : "")
            : this.$filter("date")(dt, "HH:mm");
    }

    private updateUserResources (resources: Array<any>):void {
        var tempDict = new Dictionary();
        for (var i = 0; i < resources.length; i++) {
            this.resourceDisplayNames.add(resources[i].id, resources[i].displayName);
            tempDict.add(resources[i].id, resources[i]);
        }

        this.userResources = tempDict;
        this.userResourcesReceived = true;
        this.filterUserResources();

        let newfilterResourceTypeIds = [];
        this.filterResourceIds.forEach(resourceId => {
            if (this.userResources.containsKey(resourceId)) {
                newfilterResourceTypeIds.push(resourceId)
            }
        })
        this.filterResourceIds = newfilterResourceTypeIds;
    }

    private filterUserResources(): void {
        if (!this.userResourcesReceived) return; // there is nothing to filter
        this.userResourcesFiltered = true;
        var tempDict = new Dictionary();
        var tempDictAction = new Dictionary();
        var sortedValues = [];
        this.userResources.forEach((key, value) => {
            // add to selections for the filter
            if (this.filterAllResourceTypes || (this.filterResourceTypeIds.length === 0 && this.filterAllResources) ||
                this.filterResourceTypeIds.filter((type) => { return value.resourceTypeIds.indexOf(type) > -1; }).length > 0) {
                tempDict.add(key, value);
                sortedValues.push(value);
            }
            // add to selections for the action
            if (this.selectedResourceTypeId >= 0 && value.resourceTypeIds.indexOf(this.selectedResourceTypeId) > -1)
                tempDictAction.add(key, value);
        });

        sortedValues = this.$filter('orderBy')(sortedValues, (item) => { return item.displayName });
        for (var i = 0; i < sortedValues.length; i++) {
            sortedValues[i].order = i + 1;
        }

        this.visibleUserResources = tempDict;
        this.visibleUserResourcesAction = tempDictAction;
        this.showResourceTaglistSpinner = false;
        this.resourcesItemsVersion++;
    }

    private showActionResult(response): void {
        Planboard.activities.clearActivities();
        this.actionPending = false;

        if (response == null || response.data == null || response.status == 504) {
            // In case of a timeout there will not be a response.data object and no response.status value.

            if (response == null) {
                console.error("Unexpected response for showActionResult: the response object is empty.");
                response = {};
            }

            if (response.data == null) {
                console.error("Unexpected response for showActionResult: the data object in response is empty.", response);

                response.data = { statusText: "F2207" }; // F2207 translates: "A response summary of the action cannot be displayed. The action continues in the background. You can proceed with other activities in OMRP."
            }

            var text = this.translationService.translateErrorMessage(response);
        }
        else if (response.status == 500) {
            var text = this.translationService.translateErrorMessage(response);
        }
        else {
            var text = "";
            switch (Number(response.data.action)) {
                case this.multiSelectActionCopyToDate: // & copy to resource on date
                    text = this.$scope.textLabels.COPY_TO_DATE;
                    if (response.data.copyParams && response.data.copyParams.resourceId != null && response.data.copyParams.resourceId > 0)
                        text = this.$scope.textLabels.COPY_TO_RESOURCE;
                    break;
                case this.multiSelectActionVacateResources:
                    text = this.$scope.textLabels.VACATE_ACTIVITIES;
                    break;
                case this.multiSelectActionCancelActivities:
                    text = this.$scope.textLabels.CANCEL_ACTIVITIES;
                    break;
                case this.multiSelectActionRemoveActivities:
                    text = this.$scope.textLabels.REMOVE_ACTIVITIES;
                    break;
                case this.multiSelectActionAssignResource:
                    text = this.$scope.textLabels.ASSIGN_RESOURCE;
                    break;
                case this.multiSelectMoveToDate:
                    text = this.$scope.textLabels.MOVE_TO_DATE;
                    break;
                default:
                    text = "";
            }

            // loop over all failure reasons
            if (response.data && response.data.failureReasonsPerItem) {
                for (var itemId in response.data.failureReasonsPerItem) {
                    var reasons = response.data.failureReasonsPerItem[itemId];
                    if (reasons && reasons.length > 0)
                        for (var i = 0; i < reasons.length; i++) {
                            var reasonText =
                                this.$scope.textLabels["MULTISELECT_ACTION_FAILED_" + reasons[i].toString()];
                            if (reasonText) text += "\n" + reasonText;
                        }
                }
            }

            var successCount = 0;
            var failedCount = 0;
            for (var itemId in response.data.successPerItem)
                successCount += Number(response.data.successPerItem[itemId]);
            for (var itemId in response.data.failedPerItem)
                failedCount += Number(response.data.failedPerItem[itemId]);
            if (successCount > 0) text += "\n\n" + this.$scope.textLabels.MULTISELECT_SUCCESS_COUNT + ": " + successCount.toString();
            if (failedCount > 0) {
                text += "\n\n" + this.$scope.textLabels.MULTISELECT_FAILED_COUNT + ": " + failedCount.toString();
                // loop over all individual failure reasons
                if (response.data && response.data.itemCopiesPerFailureReason) {
                    for (var itemId in response.data.itemCopiesPerFailureReason) {
                        var reasons = response.data.itemCopiesPerFailureReason[itemId];
                        for (var reasonId in reasons) {
                            var reasonText = this.$scope.textLabels["MULTISELECT_ACTION_FAILED_" + reasonId.toString()];
                            if (reasonText) text += "\n• " + reasonText;
                            else continue;
                            var dates = reasons[reasonId];
                            for (var i = 0; i < dates.length; i++)
                                text += " " + this.$filter("date")(dates[i], "mediumDate") + ".";
                        }
                    }
                }
            }
        }
        this.commonSvc.showDialog(this.$scope.textLabels.MULTISELECT_PAGE_TITLE,
            text,
            this.$scope.textLabels.OK,
            () => {
                if (this.actionRequiresNewSearch) this.startSearch();
            });
    }

    private calculateCopyCount(copyUpToIncludingDate: Date): void {
        var periodValue = parseInt(this.periodValue);
        if (periodValue < 1) { periodValue = 1; this.periodValue = "1"; }
        var copyCount = 1;

        switch (parseInt(this.selectedRepetitionPeriod)) {
            case 1: // days
                var fromDayNr = TimeSpan.getDayNr(this.copyToDate);
                var toDayNr = TimeSpan.getDayNr(copyUpToIncludingDate);
                copyCount = 1 + Math.floor((toDayNr - fromDayNr) / periodValue);
                break;
            case 2: // weeks
                var fromDayNr = TimeSpan.getDayNr(this.copyToDate);
                var toDayNr = TimeSpan.getDayNr(copyUpToIncludingDate);
                copyCount = 1 + Math.floor((toDayNr - fromDayNr) / (periodValue * 7));
                break;
            case 3: // months
                var fromMonthNr = this.copyToDate.getMonth() + this.copyToDate.getFullYear() * 12;
                var toMonthNr = copyUpToIncludingDate.getMonth() + copyUpToIncludingDate.getFullYear() * 12;
                copyCount = 1 + Math.floor((toMonthNr - fromMonthNr) / periodValue);
                break;
            case 4: // years
                var fromYearNr = this.copyToDate.getFullYear();
                var toYearNr = copyUpToIncludingDate.getFullYear();
                copyCount = 1 + Math.floor((toYearNr - fromYearNr) / periodValue);
                break;
            case 5: // day of the month
                copyCount = this.dayOfMonthLoop(this.copyToDate, null, copyUpToIncludingDate).copyCount;
                break;
            default:
                return;
        }

        this.copyCount = copyCount.toString();

        // replace the copy up to date with an exact value
        this.$timeout(() => { this.calculateCopyUpToDate(this.copyToDate); }, 0);
    }

    private dayOfMonthLoop(copyToDate: Date, copyCount: number, maxDate: Date): any {
        var upToIncluding = new Date(copyToDate.getFullYear(), copyToDate.getMonth(), 1); // first date of the month
        var monthSpec = parseInt(this.periodMonthSpecification); // value 1-5: every first/second/third/fourth/last weekday of the month
        var weekDaySpec = parseInt(this.periodWeekDaySpecification); // value 1-7: weekday of the month, monday/tuesday/etc...
        var occurancePerMonth = 0; // how many times has a day of the week been seen during the month
        var nrOfCopies = 0; // number of copies counted
        var lastMatchingDate = null; // the last mathcing day of the week during the month

        if (weekDaySpec === 7) weekDaySpec = 0; // sunday = 0 for the date.getDay function

        while ((copyCount == null || nrOfCopies < copyCount) && // copyCount has a value, continue until nrOfCopies has reached this value
            (maxDate == null || upToIncluding <= maxDate) && // maxDate has a value, continue until upToIncluding has reached this date
            (copyCount != null || maxDate != null)) { // neither has a value, skip the while loop
            if (upToIncluding.getDay() === weekDaySpec) { // day of the week matches
                lastMatchingDate = new Date(upToIncluding.getTime()); // remember the matching date in case we need to paste on the last week of the month
                occurancePerMonth++;
                // only include if upToIncluding is on or after the date where pasting begins (=copyToDate)
                if (occurancePerMonth === monthSpec && monthSpec < 5 && upToIncluding >= copyToDate)
                    nrOfCopies++;
            }
            if (copyCount == null || nrOfCopies < copyCount) {
                upToIncluding.setDate(upToIncluding.getDate() + 1); // increment upToIncluding to the next day
                if (upToIncluding.getDate() === 1) { // next month reached
                    if (monthSpec === 5) { // last weekday of the month
                        if (lastMatchingDate >= copyToDate) // only include if lastMatchingDate is on or after the date where pasting begins (=copyToDate)
                            nrOfCopies++;
                        if (copyCount != null && nrOfCopies >= copyCount) // last copy has just been pasted -> adjust to lastMatchingDate
                            upToIncluding = lastMatchingDate;
                    }
                    occurancePerMonth = 0; // reset number of matching weekDays for next month
                }
            }
        }

        if (lastMatchingDate != null && lastMatchingDate >= copyToDate) {
            upToIncluding = lastMatchingDate;
        }

        return {
            copyCount: nrOfCopies,
            upToIncluding: upToIncluding
        }
    }

    /**
     * Set the validators for Copy to date and Up to and including based on the action id
     * @param {string} actionId
     */
    private setValidatorsForActionId(actionId) {
        switch (parseInt(actionId)) {
        case 1: // copy to date action
            this.copyToResourceInvalidStartDate = false;
            this.copyToResourceInvalidEndDate = false;
                this.copyToDateInvalidStartDate = this.copyToDate.getFullYear() - new Date().getFullYear() > this.copyToDateMaxYearsDifference;
                this.copyToDateInvalidEndDate = this.copyUpToIncludingDate.getFullYear() - new Date().getFullYear() > this.copyToDateMaxYearsDifference;
            break;
        case 2: // copy to resource action
            this.copyToDateInvalidStartDate = false;
            this.copyToDateInvalidEndDate = false;
                this.copyToResourceInvalidStartDate = this.copyToDate.getFullYear() - new Date().getFullYear() > this.copyToResourceMaxYearsDifference;
                this.copyToResourceInvalidEndDate = this.copyUpToIncludingDate.getFullYear() - new Date().getFullYear() > this.copyToResourceMaxYearsDifference;
            break;
        default:
            return;
        }
    }
}