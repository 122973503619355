import * as Constants from './../../utils/constants'

import { PictureControl } from './../controls/pictureControl';

import { DrawHelper } from './../drawing/drawing';
import * as Drawing from './../drawing/cachedObjectIds';

import * as Globals from './../utils/globals';
import { Rect } from './../utils/rect';
import { TimeSpan } from './../utils/timespan';
import { Timer } from './../utils/timer';

import { Activity, ActivityList } from './../entities/activity';
import { ActivityType } from './../entities/activitytype';
import { Planboard } from './../entities/planboard';

export class ActivityInfo {
    static activityId = -1;
    static width = -1;
    static height = -1;
    static showTimer: Timer = null;
    static showTimerRunning = false;
    static showControl: PictureControl = null;
    static showX: number;
    static showY: number;
    static showOpenActivities = true;
    static nrOfMemoLines: number;

    /**
        * Returns the activitytype for an activity, if it should be visible. Returns null if the activity should not be visible
        * @param act the activity
        */
    private static getActivityTypeIfVisible(act: Activity): ActivityType {
        if (!act) return null;
        if (act.resourceId == null && !this.showOpenActivities) return null;
        if (act.status == Constants.StatusActivityNotRequired) return null;
        const actType = Planboard.activityTypes.getObject(act.activityTypeId) as ActivityType;
        if (!actType || !actType.isLeaf) return null;
        return actType;
    }

    /**
        * split text with linebreaks into an array of strings
        * @param memoText the original text
        */
    private static getMemoLines(memoText: string): string[] {
        return memoText.split("\n"); 
    }

    /**
        * get padding derived from the height of the font in pixels (minimum 1) for a fontHeight of 24 this will result in a padding of 4 pixels.
        */
    private static getPaddingSize(): number {
        return Math.max(Math.floor(Globals.fontHeight / 6), 1);
    }

    private static drawAt(ctx: CanvasRenderingContext2D, destX: number, destY: number, width: number, height: number) {
        const padding = this.getPaddingSize();
        const halfFontHeight = Math.floor(Globals.fontHeight * 0.5);

        // draw shadow
        ctx.globalAlpha = 0.2;
        ctx.fillStyle = Globals.dark3DColor;
        ctx.fillRect(destX + 4, destY + height - 4, width - 4, 4);
        ctx.fillRect(destX + width - 4, destY + 4, 4, height - 8);
        ctx.fillRect(destX + 5, destY + height - 4, width - 6, 3);
        ctx.fillRect(destX + width - 4, destY + 5, 3, height - 9);
        // fill
        ctx.globalAlpha = 0.9;
        ctx.fillStyle = Globals.windowColor;
        ctx.fillRect(destX, destY, width - 4, height - 4);
        // draw border
        ctx.globalAlpha = 1;
        ctx.fillStyle = Globals.dark3DColor;
        ctx.fillRect(destX, destY, width - 4, 1);
        ctx.fillRect(destX, destY, 1, height - 4);
        ctx.fillStyle = Globals.darker3DColor;
        ctx.fillRect(destX, destY + height - 5, width - 4, 1);
        ctx.fillRect(destX + width - 5, destY, 1, height - 4);

        // search main activity
        let activity = Planboard.activities.getActivity(ActivityInfo.activityId);
        let mainActivity = Planboard.activities.getMainActivity(ActivityInfo.activityId);
        if (!mainActivity) mainActivity = Planboard.activities.getActivity(ActivityInfo.activityId);
        if (!mainActivity) return;
        // main activity memo
        const memoText = mainActivity && mainActivity.memoId && mainActivity.memoId > 0
            ? Planboard.activities.getMemoText(mainActivity.memoId, true, false): undefined;
        // get all activity details
        const details = mainActivity ? Planboard.activities.getActivityDetails(mainActivity.id, true, false) : undefined;
        // build list of all activities in this main activity
        let actLst = Planboard.getAllActivityIdsInGroup(mainActivity.id, true); // retrieve in sorted order based on activity types sortOrder

        // activitytype text
        ctx.fillStyle = Globals.windowTextColor;
        ctx.textAlign = Globals.alignLeft;
        ctx.textBaseline = Globals.alignMiddle;
        let y = Math.floor(destY + padding + halfFontHeight);
        let actType = Planboard.activityTypes.getObject(activity.activityTypeId) as ActivityType;
        let mainText = actType ? actType.displayName : "";
        let descriptionText: "";
        let imposesRisk = false;
        let nrOfSubjects = "";
        if (details && details.length > 0)
            for (let i = 0; i < details.length; i++) {
                if (details[i].detailType === Constants.activityDetailRisk) {
                    imposesRisk = details[i].value !== "0";
                } else if (details[i].detailType === Constants.activityNumberOfSubjects) {
                    nrOfSubjects = details[i].value;
                } else if (details[i].detailType === Constants.activityDetailDescription) {
                    if (details[i].value !== "") descriptionText = details[i].value;
                }
            }
        // start and end datetime
        let multipleDays = TimeSpan.getDayNr(mainActivity.startDate) !== TimeSpan.getDayNr(mainActivity.endDate);
        let text = Globals.dateToDisplayStr(mainActivity.startDate, true, true) + " - " +
            Globals.dateToDisplayStr(mainActivity.endDate, multipleDays, true);
        let x = Math.floor(destX + width - padding * 2 - ctx.measureText(text).width);
        if (destX + padding + ctx.measureText(mainText).width >= x) {
            // mainText overlaps with text, remove date component from text
            text = (multipleDays ? Globals.dateFormat(mainActivity.startDate, "EEE") + " " : "") +
                Globals.dateToDisplayStr(mainActivity.startDate, false, true) + " - " +
                (multipleDays ? Globals.dateFormat(mainActivity.endDate, "EEE") + " " : "") +
                Globals.dateToDisplayStr(mainActivity.endDate, false, true);
            x = Math.floor(destX + width - padding * 2 - ctx.measureText(text).width);
        }
        ctx.fillText(text, Math.floor(x), Math.floor(y));
        // draw mainText with a maximum width, so it will not overlap the time
        while (mainText !== "" && destX + padding + ctx.measureText(mainText).width >= x - padding)
            mainText = mainText.substring(0, mainText.length - 1);
        ctx.fillText(mainText, Math.floor(destX + padding), Math.floor(y));

        // draw memo of current activity
        if (activity.memoId && activity.id !== mainActivity.id) {
            const currentText = Planboard.activities.getMemoText(activity.memoId, true, false);
            y += Globals.fontHeight + padding;
            ctx.fillText(currentText, Math.floor(destX + padding), Math.floor(y));
        }

        // draw description text
        if (descriptionText && descriptionText !== "") {
            y += Globals.fontHeight + padding;
            ctx.fillText(descriptionText, Math.floor(destX + padding), Math.floor(y));
        }
            
        // convert activity id list to activity entity list
        let activities = new ActivityList();
        for (let i = 0; i < actLst.length; i++) activities.items.push(Planboard.activities.getActivity(actLst[i]));

        // for every individual activity
        let mainActType = Planboard.activityTypes.getObject(mainActivity.activityTypeId) as ActivityType;
        if (mainActType && mainActType.categoryId === ActivityType.daymarkCategoryId && actLst.length > 1) {
            activities.items = activities.items.filter(item => item.id !== mainActivity.id);
        }
        const activityTypeWidth = 80; // todo: determine width dynamically, see also the determineSize function
        y = y + padding + halfFontHeight;
        for (let i = 0; i < activities.items.length; i++) {
            let act = activities.items[i];
            mainActType = this.getActivityTypeIfVisible(act);
            if (mainActType) {
                mainActType.draw(ctx, destX + padding, y, activityTypeWidth, Globals.fontHeight - 2, act.resourceId != null);
                if (act.resourceId != null) {
                    let resource = Planboard.activities.getResource(act.resourceId, true);
                    let nameIsVisible = resource && resource.displayName !== "";
                    let nameIsReadable = act.resourceTypeId === null || act.resourceTypeId === -1;

                    if (!nameIsReadable) {
                        Planboard.resourceTypes.forEach((key, value) => { ((value.id == act.resourceTypeId && value.maxPermissionForCurrentUser >= 1) || nameIsReadable) ? nameIsReadable = true : nameIsReadable = false });
                    }

                    let name = nameIsVisible && nameIsReadable ? (resource.displayName) : Planboard.getTextLabel("RESOURCE_ASSIGNED_PLACEHOLDER");
                    ctx.fillText(name, Math.floor(destX + activityTypeWidth + padding * 2), Math.floor(y + halfFontHeight));
                }
                y += Globals.fontHeight;
            }
        }

        // memo
        if (memoText) {
            y += padding;
            const memoLines = this.getMemoLines(memoText);
            if (ActivityInfo.nrOfMemoLines !== memoLines.length) {
                this.determineSize(ActivityInfo.activityId, true);
                this.showTimerCallback(null);
                return;
            }
            for (let i = 0; i < memoLines.length; i++) {
                ctx.fillText(memoLines[i], Math.floor(destX + padding), Math.floor(y + halfFontHeight));
                y += Globals.fontHeight;
            }
        }

        // imposes risk or number of subjects
        if (imposesRisk || nrOfSubjects !== "") {
            y += padding;
            if (nrOfSubjects !== "") {
                text = Planboard.getTextLabel("NR_OF_SUBJECTS") + ": " + nrOfSubjects;
                x = Math.floor(destX + width - padding * 2 - ctx.measureText(text).width);
                ctx.fillText(text, Math.floor(x), Math.floor(y + halfFontHeight));
            }
            if (imposesRisk) {
                ctx.fillStyle = Globals.imposesRiskColor;
                ctx.fillText(Planboard.getTextLabel("EXCHANGE_TEXT_RISK"), Math.floor(destX + padding), Math.floor(y + halfFontHeight));
                ctx.fillStyle = Globals.windowTextColor;
            }
        }
    }

    private static determineSize(activityId: number, refresh: boolean) {
        if (!refresh && activityId === ActivityInfo.activityId) return; // already done previously

        const padding = this.getPaddingSize();

        DrawHelper.eraseStoredObject(Drawing.tooltipId);
        ActivityInfo.activityId = activityId;
        ActivityInfo.width = 0;
        ActivityInfo.height = 0;
        const mainActivity = Planboard.activities.getMainActivity(activityId);
        const actLst = Planboard.getAllActivityIdsInGroup(activityId);
        ActivityInfo.height = padding * 3 + 4 + Globals.fontHeight; // padding*3 (top, bottom, and between title and leaf activities) + extra 4 pixels for the shadow at the bottom

        ActivityInfo.width = 400;

        // reserve multiple lines for the memo text
        if (mainActivity && mainActivity.memoId && mainActivity.memoId > 0) {
            const memoText = Planboard.activities.getMemoText(mainActivity.memoId, true, false);
            const memoLines = memoText ? this.getMemoLines(memoText) : null;
            ActivityInfo.nrOfMemoLines = memoText ? memoLines.length : 1;
            ActivityInfo.height += Math.floor(ActivityInfo.nrOfMemoLines * Globals.fontHeight) + padding;
            if (memoLines)
                for (let i = 0; i < memoLines.length; i++)
                    ActivityInfo.width = Math.max(Math.min(
                            DrawHelper.sharedBuffer.ctx.measureText(memoLines[i]).width + padding * 2 + 4, // padding*2 (left and right) + extra 4 pixels for the shadow on the right
                            DrawHelper.sharedBuffer.maxWidth),
                        ActivityInfo.width);
        }

        // reserve the bottom line for imposes risk (on the left) and number of subjects (on the right)
        const details = mainActivity ? Planboard.activities.getActivityDetails(mainActivity.id, false, false) : undefined;
        let imposesRisk = false;
        let nrOfSubjects = "";
        let hasDescription = false;
        if (details && details.length > 0)
            for (let i = 0; i < details.length; i++) {
                if (details[i].detailType === Constants.activityDetailRisk) {
                    imposesRisk = details[i].value !== "0";
                } else if (details[i].detailType === Constants.activityNumberOfSubjects) {
                    nrOfSubjects = details[i].value;
                } else if (details[i].detailType === Constants.activityDetailDescription) {
                    if (details[i].value !== "") hasDescription = true;
                }
            }
        if (imposesRisk || nrOfSubjects !== "") ActivityInfo.height += Globals.fontHeight + padding;
        if (hasDescription) ActivityInfo.height += Globals.fontHeight + padding;

        for (let i = 0; i < actLst.length; i++) {
            const act = Planboard.activities.getActivity(actLst[i]);
            // reserve space if the hovered activity has a memo
            if (act.id === activityId && act.memoId && mainActivity && mainActivity.id !== act.id) {
                ActivityInfo.height += Globals.fontHeight + padding;
            }
            const actType = this.getActivityTypeIfVisible(act);
            // reserve lines for each visible leaf activity
            if (actType) ActivityInfo.height += Globals.fontHeight;
        }
    }

    private static showTimerCallback(t: Timer) {
        if (t != null) {
            t.enabled = false;
            ActivityInfo.showTimerRunning = false;
        }
        let r = new Rect(ActivityInfo.showX + 16, ActivityInfo.showY + 16, ActivityInfo.width, ActivityInfo.height);
        if (r.right >= ActivityInfo.showControl.controller.ctx.canvas.width)
            r.left = Math.max(0, ActivityInfo.showX - 16 - ActivityInfo.width);
        if (r.bottom >= ActivityInfo.showControl.controller.ctx.canvas.height)
            r.top = Math.max(0, ActivityInfo.showY - 16 - ActivityInfo.height);
        if (r.width <= 0 || r.height <= 0) return;
        ActivityInfo.showControl.visible = true;
        Planboard.controller.moveControl(ActivityInfo.showControl, r);
    }

    static refresh() {
        if ((this.showControl != null && this.showControl.visible) || this.showTimerRunning) {
            this.determineSize(this.activityId, true);
            if (this.showControl != null && this.showControl.visible) this.showTimerCallback(null);
        } else
            this.activityId = -1;
    }

    static hide() {
        if (this.showTimer != null)
            this.showTimer.enabled = false;
        if (this.showControl != null && this.showControl.visible) {
            this.showControl.visible = false;
            this.showControl.controller.redraw();
        }
    }

    static show(tooltipControl: PictureControl, activityId: number, screenX: number, screenY: number, waitMiliseconds: number) {
        this.determineSize(activityId, false);
        this.showControl = tooltipControl;
        this.showX = Math.floor(screenX);
        this.showY = Math.floor(screenY);
        if (waitMiliseconds > 0) {
            this.showTimerRunning = true;
            if (this.showTimer == null) {
                this.showTimer = new Timer(tooltipControl, waitMiliseconds);
                this.showTimer.tick = this.showTimerCallback;
            }
            this.showTimer.restart(waitMiliseconds);
        } else
            this.showTimerCallback(null);
    }

    static draw(destCtx: CanvasRenderingContext2D, destX: number, destY: number) {
        const ctx = DrawHelper.sharedBuffer.ctx;
        if (ActivityInfo.width < 0 || ActivityInfo.height < 0 || ActivityInfo.activityId < 0) return;
        if (ActivityInfo.width >= ctx.canvas.width || ActivityInfo.height >= ctx.canvas.height) {
            ActivityInfo.drawAt(destCtx, destX, destY, ActivityInfo.width, ActivityInfo.height);
            return;
        }
        const stored = DrawHelper.getStoredObject(Drawing.tooltipId, ActivityInfo.width, ActivityInfo.height);
        const r = stored[1];
        if (!stored[2]) // it was not stored so we need to draw it
            ActivityInfo.drawAt(ctx, r.left, r.top, r.width, r.height);
        destCtx.drawImage(ctx.canvas, r.left, r.top, r.width, r.height, destX, destY, r.width, r.height);
    }
}