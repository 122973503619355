export class Activity {
    id: number;
    parentId: number;
    startDate: Date;
    endDate: Date;
    activityTypeId: number;
    resourceTypeId: number;
    resourceId: number;
    status: number;
    flag: number; // flag 1 = resource grouped, flag 2 = reload, 4/8/16/32/64 = unused 
    memoId: number;
    filled: number; // 1 = the activities slots are completely filled, 0 = not filled, 2 = unknown
    lineNr: number; // where in the cell the activity should be shown
    origin: string;

    constructor(id: number, startDate: Date, endDate: Date, activityTypeId: number, resourceId: number, memoId: number, status: number, origin: string = "") {
        this.id = id;
        this.parentId = -1;
        this.startDate = new Date(startDate.getTime() + startDate.getTimezoneOffset() * 60000);
        this.endDate = new Date(endDate.getTime() + endDate.getTimezoneOffset() * 60000);
        this.activityTypeId = activityTypeId;
        this.resourceId = resourceId;
        this.flag = 1; // 1 = resource is grouped
        this.memoId = memoId;
        this.status = status;
        this.filled = 2;
        this.lineNr = -1;
        this.origin = origin;
    }

    /**
        * get the value for a flag for this activity
        * @param flag 1 = resource is grouped, 2 = this activity should be reloaded, 4/8/16/32/64 = unused
        */
    getFlag(flag: number): boolean { return (this.flag & flag) === flag; }

    /**
        * set the value for a flag for this activity
        * @param flag 1 = resource is grouped, 2 = this activity should be reloaded, 4/8/16/32/64 = unused
        * @param value true or false
        */
    setFlag(flag: number, value: boolean) {
        this.flag = this.flag | flag; // set with bitwise or
        if (!value) this.flag = this.flag ^ flag; // clear with bitwise xor
    }
}

export class ActivityList {
    items: Activity[] = []; 
    changes = false;
}