import { StateService } from '@uirouter/angularjs';
import { IPageStartService } from './../../../shared/pageStartService';
import { IUserService } from './../../../shared/userService';

import { ITranslationService } from './../../i18n/translationService';
import { IPermissionService } from './../../permissions/permissionService';

import { EntityTreeHelpers } from './../../treeListController/EntityTreeHelpers';
import { ITreeListScope } from './../../treeListController/ITreeListScope';
import { TreeEntity } from './../../treeListController/TreeEntity';
import { TreeListController } from './../../treeListController/TreeListController';
import { VerificationStatus } from './../../treeListController/TreeListScope';

import { Dictionary } from './../../utils/dictionary';
import { IModalConfirmationWindowService } from './../../utils/modalConfirmationWindowService';

import { OrganizationUnit } from './../organizationUnits/organizationUnit';

import { ISkillsScope } from './ISkillsScope';
import { Skill } from './skill';

export class SkillsController extends TreeListController {
    scope: ISkillsScope;

    static $inject = [
        "$http",
        "$q",
        "$scope",
        "$state",
        "$timeout",
        "$filter",
        "$window",
        "permissionService",
        "modalConfirmationWindowService",
        "translationService",
        "pageStartService",
        "userService"
    ];

    constructor(
        $http: ng.IHttpService,
        $q: ng.IQService,
        $scope: ISkillsScope,
        $state: StateService,
        $timeout: ng.ITimeoutService,
        $filter: ng.IFilterService,
        $window: ng.IWindowService,
        permissionService: IPermissionService,
        modalConfirmationWindowService: IModalConfirmationWindowService,
        translationService: ITranslationService,
        protected pageStartService: IPageStartService,
        userService: IUserService) {

        super($http, $q, $scope, $state, $timeout, $filter, permissionService, modalConfirmationWindowService, translationService, pageStartService, userService);

        $window.location.href = "/angularomrp/program-management/skills";

        this.apiUrl = "api/Skills";
        this.selectedUnitSetting = "skills.selectedOrganizationUnit";
        this.includeChildOrganizationUnitsSetting = "skills.includeChildOrganizationUnit";

        this.scope.relatedResourceTypeDict = new Dictionary();
        this.scope.relatedOrganizationUnitDict = new Dictionary();
        this.scope.relatedSkillLevelDict = new Dictionary();
        this.scope.filterOrganizationUnitDict = new Dictionary();

        this.userService = userService;

        // See whether the currently logged in user has the "Skills" permission and set flags accordingly.
        $scope.verificationStatus = new VerificationStatus();
        permissionService.userHasPermission("Skills", $scope.verificationStatus, $scope);

        // get data necessary for this model & controller
        this.getRelatedOrganizationUnits();
        this.getEntities(true);
        this.getRelatedResourceTypes();
        this.getRelatedSkillLevels();
    }

    /**
        * get the checked state of a node in a tree checklist
        * @param node the node, in this case node is always an organization unit
        */
    protected getCheckListNodeState(node: any): boolean {
        if (this.scope.selectedItem && (this.scope.selectedItem as Skill).validOrganizationUnitIds)
            return (this.scope.selectedItem as Skill).validOrganizationUnitIds.indexOf(node.id) >= 0;
        return false;
    }

    /**
        * set the checked value of an organization unit and all children of that unit
        */
    protected setCheckValue(node: any, value: boolean) {
        if (node == null || node.maxPermissionForCurrentUser == null || node.maxPermissionForCurrentUser < 2) return;
        let unitList = (this.scope.selectedItem as Skill).validOrganizationUnitIds;
        const index = unitList.indexOf(node.id);
        if (index < 0 && value)
            unitList.push(node.id);
        else if (index >= 0 && !value)
            unitList.splice(index, 1);
        if (node.nodes && node.nodes.length > 0)
            for (var i = 0; i < node.nodes.length; i++)
                this.setCheckValue(node.nodes[i], value);
    }

    /**
        * will trigger when an entity was restored because an error occured while saving the entity
        */
    protected onEntityRestored() {
        // rebuild the organizationUnitTree to clear weird angular repeater states
        this.scope.organizationUnitTree = [];
        this.timeout(() => {
            this.scope.relatedOrganizationUnitDict.forEach((key, value) => {
                value.nodes = null;
                delete value.$$hashKey;
            });
            this.scope.organizationUnitTree = EntityTreeHelpers
                .entityDictionaryToTree(this.scope.relatedOrganizationUnitDict, this.scope,
                this.setEntityDefaultValues, null) as OrganizationUnit[];
        }, 0);
    }

    /**
        * toggle the checked state of a node in a tree checklist
        * @param node the node, in this case node is always an organization unit
        */
    protected toggleCheckListNode(node: any) {
        if (!this.getCheckListNodeEnabled(node)) return; // test if the node is enabled
        if (!this.isSelectedItemWritable()) return; // no permission to edit this skill
        let unitList = (this.scope.selectedItem as Skill).validOrganizationUnitIds;
        if (!unitList) return;
        this.setCheckValue(node, unitList.indexOf(node.id) < 0);
        this.onOrganizationUnitSelectionChanged();
    }

    protected getRelatedOrganizationUnits() {
        super.getRelatedOrganizationUnits(() => this.onOrganizationUnitsLoaded(this.scope));
    }

    /**
        * Flag to see if organization units have been loaded. This necessary to see if the filter can be applied.
        */
    private organizationUnitsLoaded = false;

    private onOrganizationUnitsLoaded(myScope: ISkillsScope) {
        // Organization units may have come in after resource types, in that case, we can now set resource type selectability.
        if (myScope.relatedResourceTypeDict != undefined && myScope.relatedResourceTypeDict.count !== 0) {
            this.setResourceTypeSelectability(myScope.relatedResourceTypeDict, myScope);
        }
        // Convert the organization unit dictionary to a tree.
        myScope.organizationUnitTree = EntityTreeHelpers
            .entityDictionaryToTree(myScope.relatedOrganizationUnitDict, myScope,
            this.setEntityDefaultValues, null) as OrganizationUnit[];

        // Register all organization units as children to their parents. This is needed for being able to filter on units including children.
        this.scope.relatedOrganizationUnitDict.forEach((key, value) => {
            this.registerChildToParent(value, null);
        });

        // Restore settings for selected unit and include children checkbox.
        this.scope.filterOrganizationUnitId = this.userService.getDisplaySettingNumber(this.selectedUnitSetting, 0);
        this.scope.filterOnChildOrganizationUnits = this.userService.getDisplaySettingSwitch(this.includeChildOrganizationUnitsSetting);

        this.organizationUnitsLoaded = true;
        if (this.skillsLoaded) this.filterOrganizationUnitChanged(0);
    }

    /**
        * Triggers when the user makes a different filter selection
        */
    protected filterOrganizationUnitChangedTimed(itemId: number) {
        this.timeout(() => { this.filterOrganizationUnitChanged(itemId); }, 0);
    }

    /**
    * Event handler for a changed organization unit selection. Updates the user group ids for which skills should be shown.
    * @param itemId Item id to filter on. Set to 0 or leave undefined to have this directly read from the control.
    */
    protected filterOrganizationUnitChanged(itemId: number) {

        if (itemId == undefined || itemId < 1) itemId = this.scope.filterOrganizationUnitId;

        //console.log("Selected unit:", itemId);

        // Set the selected filter organization units to the one selected in the control.
        this.scope.filterOrganizationUnitDict.clear();
        this.scope.filterOrganizationUnitDict.add(itemId, itemId);

        const unit = this.scope.relatedOrganizationUnitDict.value(itemId) as OrganizationUnit;

        if (unit == null) return;

        // Clear the array of recently created entities.
        this.scope.recentlyModifiedEntityIds.clear();

        if (this.scope.filterOnChildOrganizationUnits) {

            // Add any children of the selected unit to the selection.
            for (let childId of unit.childIds) {
                //console.log("Child:", childId);

                this.scope.filterOrganizationUnitDict.add(childId, childId);
            }
        }

        // Save new selection to user settings.
        this.userService.setDisplaySettingNumber(this.selectedUnitSetting, itemId);
        this.userService.setDisplaySettingSwitch(this.includeChildOrganizationUnitsSetting, this.scope.filterOnChildOrganizationUnits);

        // Recreate the paging.
        this.initActivePage(null, true);
    }

    protected getRelatedResourceTypes() {
        super.getRelatedResourceTypes(() => this.onResourceTypesLoaded(this.scope));
    }

    private onResourceTypesLoaded(myScope: ISkillsScope) {
        // Organization units are fetched asynchronously, so this may not yet work. In that case, it will be done
        // after fetching the organization units.
        if (myScope.relatedOrganizationUnitDict != undefined && myScope.relatedOrganizationUnitDict.count !== 0) {
            this.setResourceTypeSelectability(myScope.relatedResourceTypeDict, myScope);
        }
    }

    protected getRelatedSkillLevels() {
        super.getRelatedSkillLevels(() => this.onSkillLevelsLoaded(this.scope));
    }

    private onSkillLevelsLoaded(myScope: ISkillsScope) {
        //console.log(this.scope.relatedSkillLevelDict);
    }

    /**
        * Function called when the selection of skill levels has changed.
        */
    protected onSkillLevelSelectionChanged(): void {
        this.setSkillLevelSelectability();
        this.onEntityChanged(true, this.scope.selectedItem);
    }

    /**
        * Sets skill level selectability, based on already selected skill levels for this skill. If a skill level has been
        * selected, this means that the only other available skill levels are those that belong to the same organization unit.
        * If no skill levels have been selected, the selectable skills will be determined by the selected organization units.
        * In that case, only skill levels associated with the selected organization units or their children can be selected.
        */
    private setSkillLevelSelectability(): void {
        const selectedSkill = (this.scope.selectedItem as Skill);
        let validSkillLevelIds = selectedSkill.validSkillLevelIds;
        if (validSkillLevelIds == null) validSkillLevelIds = [];
        if (validSkillLevelIds.length > 0) {
            const organizationUnitId = this.scope.relatedSkillLevelDict.value(validSkillLevelIds[0]).organizationUnitId;
            this.scope.relatedSkillLevelDict.forEach((key, value) => {
                // Filter on organization unit belonging to already selected skill level.
                value.selectable = value.organizationUnitId === organizationUnitId;
            });
        } else {
            // Get organization unit and children for the organization units currently associated with the skill.
            let relatedOrgUnitIds = selectedSkill.validOrganizationUnitIds;

            // console.log("0", relatedOrgUnitIds, allOrgUnits);

            for (let i = 0; i < selectedSkill.validOrganizationUnitIds.length; i++) {
                const orgUnit =
                    this.scope.relatedOrganizationUnitDict.value(selectedSkill.validOrganizationUnitIds[i]);
                relatedOrgUnitIds =
                    relatedOrgUnitIds.concat(this.getAllChildOrganizationUnits(orgUnit,
                        this.scope.relatedOrganizationUnitDict.getStore()));
            }

            // console.log("1", relatedOrgUnitIds);

            if (relatedOrgUnitIds.length > 0) {
                // Only make skill levels selectable that are associated with any organization unit associated with the skill.
                this.scope.relatedSkillLevelDict.forEach((key, value) => {
                    value.selectable = relatedOrgUnitIds.indexOf(value.organizationUnitId) > -1;
                });
            } else {
                // No organization unit selected? Then all levels are selectable.
                this.scope.relatedSkillLevelDict.forEach((key, value) => {
                    value.selectable = true;
                });
            }
        }
    }

    /**
        * Called after an entity is selected.
        * Should be overridden in controllers that make use of this functionality.
    */
    protected onEntitySelected() {
        this.setSkillLevelSelectability();
    }

    /**
        * Called when the coupled organization units for the skill have been changed.
        */
    protected onOrganizationUnitSelectionChanged() {
        this.setSkillLevelSelectability();
        this.onEntityChanged(true, null);
    }

    /**
        * Flag to see if skills have been loaded. This necessary to see if the filter can be applied.
        */
    private skillsLoaded = false;

    /**
        * Is a specific entity visible, can be used to filter entities.
        */
    protected isEntityVisible(entity: TreeEntity): boolean {
        const skill = entity as Skill;

        //console.log("Recently added:", this.scope.recentlyModifiedEntityIds.getStore());

        if (this.scope.recentlyModifiedEntityIds.containsKey(entity.id)) return true;

        for (let organizationUnitId of skill.validOrganizationUnitIds) {
            if (this.scope.filterOrganizationUnitDict.containsKey(organizationUnitId)) return true;
        }

        return false;
    }

    /**
    * Callback function called after the entityDict dictionary is filled, this callback function will fill the entities array/tree.
    * @param myScope current scope
    */
    protected onEntitiesLoaded(myScope: ITreeListScope) {
        super.onEntitiesLoaded(myScope);

        this.skillsLoaded = true;
        if (this.organizationUnitsLoaded) this.filterOrganizationUnitChanged(0);
    }

    /**
        * See if the selected item is writable: the user should have at least write permissions for at least one of the related organization units.
        */
    protected isSelectedItemWritable(): boolean {
        if (!this.scope.selectedItem) return false;
        return true;
    }

    /**
        * See if the selected item is deletable.
        */
    protected isSelectedEntityDeletable(): boolean {
        if (!this.scope.selectedItem) return false;

        let item = this.scope.selectedItem as Skill;
        //console.log("isSelectedEntityDeletable()", this.scope, item);
        return !item.changeOfDeletabilityPending &&
            !(item.hasDependingActivityTypes || item.hasDependingResources) &&
            (!item.validResourceTypeIds ||
            item.validResourceTypeIds.length === 0);
    }

    /**
    * Returns the title of the modal delete confirmation window.
    * Should be overridden in controllers that make use of this functionality.
    */
    protected getDeleteConfirmationWindowTitle(): string {
        return this.scope.textLabels.SKILL_DELETION_MODAL_TITLE;
    }

    /**
        * Returns the text of the modal delete confirmation window.
        * Should be overridden in controllers that make use of this functionality.
    */
    protected getDeleteConfirmationWindowText(): string {
        return this.scope.textLabels.SKILL_DELETION_MODAL_TEXT;
    }

    protected newEntityDisplayName() {
        return this.scope.textLabels.NEW_SKILL_DISPLAY_NAME;
    }

    private selectedUnitSetting: string;
    private includeChildOrganizationUnitsSetting: string;

    // Determine whether skills are selectable (i.e.: minimally write permission for one of the related organization units)
    // and set the selectable flags accordingly.
    setResourceTypeSelectability(resourceTypeDict: Dictionary, myScope: ISkillsScope) {
        //console.log("Org unit dict", myScope.relatedOrganizationUnitDict);
        resourceTypeDict.forEach((key, value) => {
            //console.log("Resource type selectability", key, value);

            let validOrganizationUnits: OrganizationUnit[] = [];
            let orgUnitIds = value.validOrganizationUnitIds;

            for (let i = 0; i < orgUnitIds.length; i++) {
                validOrganizationUnits[i] = this.scope.relatedOrganizationUnitDict.value(orgUnitIds[i]);
            }

            value.selectable = this.hasAtLeastPermissionOnOrganizationUnit(validOrganizationUnits, 2);
        });
    }

    /**
    * Make the popup div for creating a new user visible and place it in the center of the browser.
    */
    protected openNewSkillInput() {
        const popupWidth = $("#popupWidth");
        this.scope.popupTop = `${popupWidth.offset().top}px`;
        this.scope.popupLeft = `${popupWidth.offset().left}px`;
        this.scope.popupWidth = `${popupWidth.outerWidth()}px`;
        this.scope.newSkillName = "";
        this.scope.newSkillInputOpen = true;
        this.scope.busyCreatingSkill = false;
    }

    /**
    * Hide the popup div for creating a new user.
    */
    protected closeNewSkillInput() {
        this.scope.newSkillInputOpen = false;
        this.scope.busyCreatingSkill = false;
    }

    /**
    * Hide the popup div and create a new user.
    */
    protected createNewSkill() {
        this.scope.busyCreatingSkill = true;
        this.newEntity(() => { this.closeNewSkillInput(); }, () => { this.scope.busyCreatingSkill = false; });
    }

    /**
    * Returns if a new skill can be created with the information provided.
    */
    protected canCreateNewSkill(): boolean {
        return this.scope.newSkillName !== "" && !this.scope.busyCreatingSkill;
    }

    createNewEntity() {
        const entity = new TreeEntity();
        entity.id = -1;
        entity.displayName = this.scope.newSkillName;
        entity.receivedOrder = 0; // value of exactly 0 avoids sorting in the sortLastEntity function

        const selectedOrganizationUnit = this.scope.relatedOrganizationUnitDict.value(this.scope.filterOrganizationUnitId);
        if (selectedOrganizationUnit && selectedOrganizationUnit.maxPermissionForCurrentUser > 1) { // user has write permission
            (entity as any).validOrganizationUnitIds = [this.scope.filterOrganizationUnitId];
        }

        return entity;
    }
}

