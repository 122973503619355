import { IPageStartService } from './../../../shared/pageStartService';
import { IUserService } from './../../../shared/userService';
import { ITranslationService } from './../../i18n/translationService';
import { ActivityType } from './../../planboard/entities/activitytype';
import { ITreeListScope } from './../../treeListController/ITreeListScope';
import { Dictionary } from './../../utils/dictionary';

export class OrganizationUnitSettingsController {

    userOrganizationUnits = new Dictionary();
    daymarkActivityTypes = new Dictionary();
    filterOrganizationUnitId: number = -1;
    parttimeDaymarkActivityTypeId: number = -1;

    private commonSvc: any;
    private saveDataTimer: angular.IPromise<any> = null;
    private saveSettings: Array<any> = [];

    private readonly apiUrl = "api/OrganizationUnitSettings";
    private readonly organizationUnitApiUrl = "api/OrganizationUnits";
    private readonly urlGetMainActivityTypes: string = "api/ActivityTypes/MainActivityTypes";
    private readonly dialogToken: string = "unitSettingsInfo";
    private readonly permission: string = "OrganizationUnits";
    private readonly automaticSaveDelay: number = 5000;
    

    static $inject = [
        "$scope",
        "$timeout",
        "pageStartService",
        "translationService",
        "userService"
    ];

    constructor(
        public $scope: ITreeListScope,
        private $timeout: ng.ITimeoutService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private userService: IUserService
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.commonSvc = this.pageStartService.initialize(this.$scope, this.permission, this.dialogToken);

        this.$scope.$on("$destroy", () => {
            this.savePendingData();
        });

        this.commonSvc.start(() => { this.loadData(); });
    }

    private loadData(): void {
        // load organization units
        this.commonSvc.loadData(this.organizationUnitApiUrl, this.userOrganizationUnits,
            (success) => {
                // Set selectable flag for each unit.
                this.userOrganizationUnits.forEach((key, value) => {
                    value.selectable = value.maxPermissionForCurrentUser >= 2;
                });
                // Read user setting to see if user previously has selected any organization unit.
                this.filterOrganizationUnitId = this.userService.getDisplaySettingNumber("settings.organizationUnitId", -1);
                if (this.filterOrganizationUnitId >= 0) {
                    this.onOrganizationUnitChanged(this.filterOrganizationUnitId);
                }
            }, null, true, true);

        // load activity types and filter so only daymarks remain
        this.commonSvc.loadData(this.urlGetMainActivityTypes, null,
            (success, loadInto) => {
                this.daymarkActivityTypes.clear();
                for (var i = 0, len = loadInto.length; i < len; i++)
                    if (loadInto[i].categoryId === ActivityType.daymarkCategoryId)
                        this.daymarkActivityTypes.add(loadInto[i].id, loadInto[i]);
            }, null, true, true);
    }

    onOrganizationUnitChanged(itemId: number): void {
        this.saveDataWithTimeOut(this.automaticSaveDelay);
        // load activityType for parttime schedule
        this.commonSvc.loadData(this.apiUrl + "/ActivityTypeForParttimeSchedules/" + itemId.toString(), null,
            (success) => {
                if (success.data)
                    this.parttimeDaymarkActivityTypeId = success.data.activityTypeId;
                if (this.parttimeDaymarkActivityTypeId == undefined || !success.data)
                    this.parttimeDaymarkActivityTypeId = -1;
            }, null, true, true);
    }

    onSelectedParttimeDaymarkChanged(itemId: number): void {
        this.addPendingSave(this.apiUrl + "/ActivityTypeForParttimeSchedules", {
            organizationUnitId: this.filterOrganizationUnitId,
            activityTypeId: itemId
        }, true);
    }

    private savePendingData(): void {
        this.userService.setDisplaySettingNumber("settings.organizationUnitId", this.filterOrganizationUnitId);
        if (this.saveSettings.length === 0) return; // nothing to save
        var saveSettings = this.saveSettings; // make a copy
        this.saveSettings = []; // clear for new changes
        for (var i = 0; i < saveSettings.length; i++) {
            if (saveSettings[i].isPutInsteadOfPost)
                this.commonSvc.putData(saveSettings[i].url, saveSettings[i].postData, null, null, false);
            else
                this.commonSvc.post(saveSettings[i].url, saveSettings[i].postData, null, null, false);
        }
    }

    private addPendingSave(url: string, postData: any, isPutInsteadOfPost: boolean): void {
        var i = this.saveSettings.length;
        while (i > 0) {
            i--;
            if (this.saveSettings[i].url === url) {
                this.saveSettings[i].postData = postData;
                this.saveSettings[i].isPutInsteadOfPost = isPutInsteadOfPost;
                this.saveDataWithTimeOut(this.automaticSaveDelay);
                return;
            }
        }
        this.saveSettings.push({
            url: url,
            postData: postData,
            isPutInsteadOfPost: isPutInsteadOfPost
        });
        this.saveDataWithTimeOut(this.automaticSaveDelay);
    }

    private saveDataWithTimeOut(timeout: number): void {
        if (this.saveDataTimer) { this.$timeout.cancel(this.saveDataTimer); this.saveDataTimer = null; }
        if (timeout == null || timeout <= 0) {
            this.savePendingData();
            return;
        }
        this.saveDataTimer = this.$timeout(() => {
            this.savePendingData();
        }, timeout);
    }
}