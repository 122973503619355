import { TreeEntity } from './../../treeListController/TreeEntity';

export class OrganizationUnit extends TreeEntity {

    /**
        * The maximum permission the user has on this organization unit, based on their user group permissions.
        * Used by other entities that derive their permissions from permissions on related organization units.
        */
    maxPermissionForCurrentUser: number;

    /**
        * Whether this organization unit is selectable in tag lists. Used on pages for other entities that can be coupled to organization units.
        */
    selectable: boolean;

    /**
        * The amount of hours per week that constitutes 1 fte within this organization unit.
        */
    fte: number;

    /**
        * Array with ids of the children (nth generation) of this organization unit.
        */
    childIds: number[];
}