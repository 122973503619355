import { MembershipPeriod } from './membershipPeriod';

/**
    * Resource type membership period for a resource.
    */
export class ResourceTypeMembershipPeriod extends MembershipPeriod {
    /**
        * Id of the resource type for this period.
        */
    resourceTypeId: number;

    /**
        * Displayname of the resourceType with resourceTypeId.
        */
    resourceTypeDisplayName: string;
}