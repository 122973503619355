/**
    * rectangle with left, top, width, height, right, bottom
    */
export class Rect {
    left: number;
    top: number;
    width: number;
    height: number;

    /**
        * create a new rectangle
        * @param left x position
        * @param top y position
        * @param width width of the rectangle
        * @param height height of the rectangle
        */
    constructor(left: number, top: number, width: number, height: number) {
        this.left = left;
        this.top = top;
        this.width = width;
        this.height = height;
    }

    /**
        * create a copy of this rectangle
        */
    copy() {
        return new Rect(this.left, this.top, this.width, this.height);
    }

    /**
        * change this rectangle so that it overlaps with both
        * @param r the rectangle to combine with
        */
    combineWith(r: Rect) {
        const maxright = Math.max(this.right, r.right);
        const maxbottom = Math.max(this.bottom, r.bottom);
        this.left = Math.min(this.left, r.left);
        this.top = Math.min(this.top, r.top);
        this.width = (maxright - this.left) + 1;
        this.height = (maxbottom - this.top) + 1;
    }

    /**
        * change all values of this rectangle object
        * @param left x position
        * @param top y position
        * @param width width of the rectangle
        * @param height height of the rectangle
        */
    change(left: number, top: number, width: number, height: number) {
        this.left = left;
        this.top = top;
        this.width = width;
        this.height = height;
    }

    /**
        * get the right or set the right by changing the width
        */
    get right(): number {
        return this.left + this.width - 1;
    }
    set right(value: number) {
        this.width = (value - this.left) + 1;
    }

    /**
        * get the bottom or set the bottom by changing the height
        */
    get bottom(): number {
        return this.top + this.height - 1;
    }
    set bottom(value: number) {
        this.height = (value - this.top) + 1;
    }
}