import * as Constants from './../utils/constants';

export var MonitoringPageCtrlBase = [
    "$scope", "$http", "translationService", "permissionService", "notificationService", "modalConfirmationWindowService",
    function ($scope, $http, translationService, permissionService, notificationService, modalConfirmationWindowService) {
        var dialogToken = "monitoring";
        var errorDialogToken = "monitoringErr";
        var connectionLostWindowOpened = false;
        var connectionRestoredWindowOpened = false;

        translationService.getTextLabels($scope);

        //console.log($scope.textLabels);

        $scope.verificationStatus = {
            pending: true, // to prevent screen flickering
            failed: false,
            hasPermission: false
        };

        $scope.dataPresent = false;

        // See whether the currently logged in user has the "Monitoring" permission and set flags accordingly.
        permissionService.userHasPermission("Monitoring", $scope.verificationStatus, $scope).then(function () {
            if ($scope.verificationStatus.hasPermission) {

                modalConfirmationWindowService
                    .showModalInfoDialog($scope.textLabels.MONITORING_AWAITING_INITIAL_DATA_TITLE,
                        $scope.textLabels.MONITORING_AWAITING_INITIAL_DATA_MESSAGE,
                        "",
                        null,
                        Constants.modalWaitDelay,
                        dialogToken);

                $scope.getStatuses();

                // Add adding/removing the "Monitoring" string to the client info.
                $scope.$on("$destroy",
                    function () {
                        notificationService.removeStringFromClientInfo("Monitoring");
                    });

                notificationService
                    .subscribeToWebSocketEvent($scope,
                        "monitoringEvent",
                        function (event, message) {
                            //console.log("Received monitoring event: " + message.event);
                            switch (message.event) {
                                case "refresh":
                                    $scope.getStatuses();
                                    break;
                                case "monitoringServiceTimedOut":
                                    if (!connectionLostWindowOpened) {
                                        connectionLostWindowOpened = true; // this is needed to prevent multiple instances of this window from popping up
                                        connectionRestoredWindowOpened = false;
                                        modalConfirmationWindowService
                                            .showModalInfoDialog($scope.textLabels.ERROR_OCCURRED,
                                                $scope.textLabels.MONITORING_CONNECTION_LOST,
                                                $scope.textLabels.OK,
                                                function () { connectionLostWindowOpened = false; },
                                                0,
                                                errorDialogToken);
                                    }
                                    break;
                                case "monitoringServiceReconnected":
                                    modalConfirmationWindowService.closeModalWindow(errorDialogToken);
                                    if (!connectionRestoredWindowOpened) {
                                        connectionRestoredWindowOpened = true;
                                        connectionLostWindowOpened = false;
                                        modalConfirmationWindowService
                                            .showModalInfoDialog($scope.textLabels.ERROR_RESOLVED,
                                                $scope.textLabels.MONITORING_CONNECTION_RESTORED,
                                                $scope.textLabels.OK,
                                                function () { connectionRestoredWindowOpened = false; },
                                                0,
                                                dialogToken);
                                    }
                                default:
                                    console.log("Event type " + message.event + " unknown.");
                                    break;
                            }
                        });

                // Respond to closed websocket connection.
                notificationService.subscribeToWebSocketEvent($scope,
                    "notif:websocketClosed",
                    function () {
                        modalConfirmationWindowService
                            .showModalInfoDialog($scope.textLabels.ERROR_OCCURRED,
                                $scope.textLabels.WEBSOCKET_CONNECTION_LOST,
                                $scope.textLabels.OK,
                                null,
                                0,
                                errorDialogToken);
                    });

                // Respond to (re)opened websocket connection.
                notificationService.subscribeToWebSocketEvent($scope,
                    "notif:websocketOpened",
                    function () {
                        modalConfirmationWindowService.closeModalWindow(errorDialogToken);
                        // Cannot distinguish between open/reopen, so leave the below out for the time being.
                        /*modalConfirmationWindowService
                            .showModalInfoDialog($scope.textLabels.ERROR_RESOLVED,
                                $scope.textLabels.WEBSOCKET_CONNECTION_RESTORED,
                                $scope.textLabels.OK,
                                null,
                                0,
                                dialogToken);*/
                    });

                notificationService.addStringToClientInfo("Monitoring");
            }
        });

        // Get the bootstrap CSS class for displaying a health status code.
        $scope.getStatusCssClass = function (statusCode) {
            //console.log("Status code:", statusCode);
            switch (statusCode) {
                case 0:
                    return "label-success";
                case 1:
                    return "label-warning";
                case 2:
                    return "label-danger";
                default:
                    return null;
            }
        }
    }
];