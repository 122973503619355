import { StateService, StateParams } from '@uirouter/angularjs';
import { INumberService } from './../../shared/numberService';
import { IPageStartService } from './../../shared/pageStartService';
import { IUserService } from './../../shared/userService';
import { Enums } from './../../shared/Enums';

import { ITranslationService } from './../i18n/translationService';

import { IPermissionService } from './../permissions/permissionService';

import { Planboard } from './../planboard/entities/planboard';
import * as Globals from './../planboard/utils/globals';
import { TimeSpan } from './../planboard/utils/timespan';

import { ResourceType } from './../programManagement/resourceTypes/resourceType';
import { OrganizationUnit } from './../programManagement/organizationUnits/organizationUnit';
import { ResourcePropertyName } from './../programManagement/resourceProperties/resourcePropertyName';

import { TreeEntity } from './../treeListController/TreeEntity';
import { TreeListController } from './../treeListController/TreeListController';
import { VerificationStatus } from './../treeListController/TreeListScope';

import * as Constants from './../utils/constants';
import { Dictionary } from './../utils/dictionary';
import { IModalConfirmationWindowService } from './../utils/modalConfirmationWindowService';
import * as Timezone from './../utils/timezone';

import { Cooperation } from './cooperation';
import { IResourcesScope } from './IResourcesScope';
import { MembershipPeriod } from './membershipPeriod';
import { OrganizationUnitMembershipPeriod } from './organizationUnitMembershipPeriod';
import { Resource } from './resource';
import { ResourceForSaving } from './resourceForSaving';
import { ResourcePropertyValue } from './resourcePropertyValue';
import { ResourceTypeMembershipPeriod } from './resourceTypeMembershipPeriod';
import { SkillMembershipPeriod } from './skillMembershipPeriod';

export class ResourcesController extends TreeListController {
    scope: IResourcesScope;

    /**
        * To get access to Angular filters in the controller.
        */
    filter: ng.IFilterService;

    /**
        * indicates if user settings will be saved when changing a selection/filter
        */
    private saveUserSettings = true;

    /**
        * Will be set to true after resourceTypes have been loaded
        */
    private resourceTypesLoaded = false;

    /**
        * Will be set to true after organizationUnits have been loaded
        */
    private organizationUnitsLoaded = false;

    /**
        * The last id of the organization unit for which resources have been loaded
        */
    private lastResourcesForOrganizationUnitId = -1;

    /**
        * The last value of the checkbox for including child organization units in the filter.
        */
    private lastValueForChildOrganizationUnitsSwitch: boolean;

    /**
        * Timer for filtering resources while typing in the filter input
        */
    private resourceFilterTimer: any = null;

    /**
        * The previous selected resourceTypeId that was used to create the filteredSkills list
        */
    private lastSelectedResourceTypeId = -1;

    /**
        * The maximum number of items to show in the taglist dropdown for resource cooperations
        */
    private maxItemsInTaglist = 32768;

    private emptyOrganizationUnitId = 0;

    private organizationUnitsForFiltering: Dictionary;

    static $inject = [
        "$http",
        "$q",
        "$scope",
        "$state",
        "$timeout",
        "$filter",
        "permissionService",
        "modalConfirmationWindowService",
        "translationService",
        "pageStartService",
        "numberService",
        "userService",
        "$stateParams"
    ];

    constructor(
        $http: ng.IHttpService,
        $q: ng.IQService,
        $scope: IResourcesScope,
        $state: StateService,
        $timeout: ng.ITimeoutService,
        $filter: ng.IFilterService,
        permissionService: IPermissionService,
        modalConfirmationWindowService: IModalConfirmationWindowService,
        translationService: ITranslationService,
        protected pageStartService: IPageStartService,
        protected numberService: INumberService,
        protected userService: IUserService,
        $stateParams: StateParams) {

        super($http, $q, $scope, $state, $timeout, $filter, permissionService, modalConfirmationWindowService, translationService, pageStartService, userService);

        this.apiUrl = "api/Resources";
        this.apiUrlWithOUFilter = this.apiUrl + "/OrganizationUnit/{0}/{1}";
		this.apiUrlWithOuFilterIds = this.apiUrl + "/OrganizationUnit/{0}/{1}/Date/{2}/ids";
        this.apiUrlWithOuAndTypeFilter = this.apiUrl + "/OrganizationUnit/{0}/{1}/ResourceType/{2}/Date/{3}/ids";
        this.apiUrlWithOuAndTypeSkillFilter = this.apiUrl + "/OrganizationUnit/{0}/{1}/ResourceType/{2}/Skill/{3}/Date/{4}/ids";
        this.apiUrlWithTypeFilter = this.apiUrl + "/ResourceType/{0}/ids";
        this.apiUrlForResourcePropertyNames = "api/ResourceProperties/{0}";
        this.apiUrlWithoutOrganizationUnits = this.apiUrl + "/WithoutOrganizationUnit";
        
        this.filter = $filter;

        //See whether the currently logged in user has the "Resources" or "ResourcesSuperUser" permission and set flags accordingly.
        $scope.verificationStatus = new VerificationStatus();
        permissionService.userHasPermission("Resources", $scope.verificationStatus, $scope);
        permissionService.userHasPermission("ResourcesSuperUser", $scope.verificationStatus, $scope);

        // Set initial filter values.
        this.scope.filterResourceTypeId = 0;
        this.scope.filterOrganizationUnitId = 0;
        this.scope.filterSkillId = 0;
        this.scope.resourceFilter = "";
        this.scope.filterPeriodBoundDate = new Date();

        // Should one resource be selected?
        this.scope.selectResourceId = $stateParams["selectResourceId"]; // first try to get the variable from $stateParams
        if (this.scope.selectResourceId == null) // if there is no such variable in $stateParams, then get it from the userService variables
            this.scope.selectResourceId = this.userService.getUserVariable("resources.selectResourceId");
        this.userService.deleteUserVariable("resources.selectResourceId"); // delete variable from userService variables
        this.scope.selectResourceId = Number(this.scope.selectResourceId); // convert to number (null will be converted to 0)
        if (this.scope.selectResourceId > 0) {
            this.scope.filterResourceTypeId = -1;
            this.scope.filterOrganizationUnitId = -1;
            this.scope.filterSkillId = -1;
        }

        // Initialise list of resources for cooperations.
        this.scope.resourcesForCooperations = null;
        this.scope.combinedResourcesList = null;
        this.scope.initialResourcesList = Object.create(null);

        // Set useSaml2
        $http.get("api/AuthenticationMode").then(response => {
            $scope.useSaml2 = response.data === Enums.AuthMode.Saml2;
        });

        // Set initially selected period bound property type.
        this.scope.selectedPeriodBoundPropertyType = "orgUnits";

        // Set initial items version for cooperations resource list.
        this.scope.resourcesForCooperationsItemsVersion = 0;

        this.getFilterOrganizationUnitId(this.scope, 0);
        this.getIncludeChildOrganizationUnit(this.scope);
        this.getFilterResourceTypeId(this.scope, 0);
        // Callback function when user settings are loaded, if the user settings are already resolved (as they should be), then this code will not be executed
        this.userService.registerUserSettingsEvent("resourcesController", () => {
            const prevFilterOrganizationUnitId = this.scope.filterOrganizationUnitId;
            const prevFilterResourceTypeId = this.scope.filterResourceTypeId;
            this.getFilterOrganizationUnitId(this.scope, 0);
            this.getIncludeChildOrganizationUnit(this.scope);
            this.getFilterResourceTypeId(this.scope, 0);
            if (prevFilterOrganizationUnitId !== this.scope.filterOrganizationUnitId ||
                prevFilterResourceTypeId !== this.scope.filterResourceTypeId)
                this.filterOrganizationUnitChanged(this.scope.filterOrganizationUnitId);
        });

        if (!this.hasSelectionFilterState()) {
            // get data necessary for this model & controller
            this.getRelatedOrganizationUnits();
            this.getRelatedResourceTypes();
            this.getRelatedSkills();
            this.getRelatedSkillLevels();
            this.getResourcePropertyNames();
        } else {
            // get saved variables
            this.scope.relatedOrganizationUnitDict = this.userService.getUserVariable("resources.relatedOrganizationUnitDict");
            this.scope.relatedResourceTypeDict = this.userService.getUserVariable("resources.relatedResourceTypeDict");
            this.scope.filteredResourceIds = this.userService.getUserVariable("resources.filteredResourceIds");
            this.scope.relatedSkillDict = this.userService.getUserVariable("resources.relatedSkillDict");
            this.scope.relatedSkillLevelDict = this.userService.getUserVariable("resources.relatedSkillLevelDict");
            this.scope.resourcePropertyNames = this.userService.getUserVariable("resources.resourcePropertyNames");
            this.scope.filterResourceTypeId = this.userService.getUserVariable("resources.filterResourceTypeId");
            this.scope.filterOrganizationUnitId = this.userService.getUserVariable("resources.filterOrganizationUnitId");
            this.scope.filterOnChildOrganizationUnits = this.userService.getUserVariable("resources.includeChildOrganizationUnit");
            this.scope.filterPeriodBoundDate = this.userService.getUserVariable("resources.filterPeriodBoundDate");
            this.scope.filterSkillId = this.userService.getUserVariable("resources.filterSkillId");
            this.scope.entityDict = this.userService.getUserVariable("resources.entityDict");
            this.scope.recentlyModifiedEntityIds = this.userService.getUserVariable("resources.recentlyModifiedEntityIds");
            // call functions that would otherwise be called from the onLoaded events for saved variables
            this.resourceTypesLoaded = true;
            this.organizationUnitsLoaded = true;
            this.createOrganizationUnitsTreeObject(this.scope);
            this.createResourceTypesTreeObject(this.scope);
            this.onSkillsLoaded(this.scope);
            this.onSkillLevelsLoaded(this.scope);
            this.onEntitiesLoaded(this.scope);
            // select the previously selected entity
            this.setSelected(this.userService.getUserVariable("resources.selectedItem"));
            // recreate the paging
            this.initActivePage(this.userService.getUserVariable("resources.activePageNr"), true);
            // stop remembering this selected state 
            this.discardSelectionFilterState();
        }
        this.addEmptyOptionToOrganizationUnitFilter();
        this.scope.hideFilters = this.scope.filterOrganizationUnitId === this.emptyOrganizationUnitId;
    }

    private addEmptyOptionToOrganizationUnitFilter() {
        this.organizationUnitsForFiltering = new Dictionary();
        let emptyIndex = 0;

        this.scope.relatedOrganizationUnitDict.forEach((key, value) => {
            this.organizationUnitsForFiltering.add(value.id, value);
            if (value.id > emptyIndex) {
                emptyIndex = value.id;
            }
        });
        this.emptyOrganizationUnitId = emptyIndex + 1;

        this.organizationUnitsForFiltering.add(this.emptyOrganizationUnitId,
            {
                displayName: this.scope.textLabels.RESOURCE_MANAGEMENT_WITHOUT_ORGANIZATION_UNIT,
                id: this.emptyOrganizationUnitId
            });
    }

    /**
        * Save the current filters and selections
        */
    private saveSelectionFilterState() {
        this.userService.setUserVariables({
            "resources.selectedItem": this.scope.selectedItem,
            "resources.entityDict": this.scope.entityDict,
            "resources.filteredResourceIds": this.scope.filteredResourceIds,
            "resources.filterResourceTypeId": this.scope.filterResourceTypeId,
            "resources.filterOrganizationUnitId": this.scope.filterOrganizationUnitId,
            "resources.includeChildOrganizationUnit": this.scope.filterOnChildOrganizationUnits,
            "resources.filterPeriodBoundDate": this.scope.filterPeriodBoundDate,
            "resources.filterSkillId": this.scope.filterSkillId,
            "resources.relatedOrganizationUnitDict": this.scope.relatedOrganizationUnitDict,
            "resources.relatedResourceTypeDict": this.scope.relatedResourceTypeDict,
            "resources.relatedSkillDict": this.scope.relatedSkillDict,
            "resources.relatedSkillLevelDict": this.scope.relatedSkillLevelDict,
            "resources.resourcePropertyNames": this.scope.resourcePropertyNames,
            "resources.activePageNr": this.scope.activePageNr,
            "resources.recentlyModifiedEntityIds": this.scope.recentlyModifiedEntityIds
        });
    }

    /**
        * Do not remember the current filters and selections
        */
    private discardSelectionFilterState() {
        this.userService.deleteUserVariables("resources.");
    }

    /**
        * Returns if there is a saved state for filters and selections
        */
    private hasSelectionFilterState(): boolean {
        return this.userService.getUserVariable("resources.selectedItem") != undefined;
    }

    /**
        * Get the maximum number of visible entities per page.
        */
    protected getNumberOfEntitiesPerPage(): number {
        return parseInt(this.userService.getDisplaySetting("resources.maxEntitiesPerPage", "20"));
    }

    /**
        * Helper function for filterOrganizationUnitChanged, used for refreshing the list of resources for a selected type
        */
    protected initialLoadResourcesForType() {
        if (this.resourceTypesLoaded) {
            this.saveUserSettings = false;
            this.getFilterResourceTypeId(this.scope, this.scope.filterResourceTypeId);
            // test if the selected resource type can be selected for the organization unit
            const selectedResourceType = this.scope.resourceTypes[this.scope.filterResourceTypeId];
            // temporary (because saveUserSettings = false) reset the resourceType filter to all if the resourceType does not match for the organization unit.
            if (!selectedResourceType || !selectedResourceType.visible)
                this.scope.filterResourceTypeId = 0;
            this.filterResourceTypeChanged(this.scope.filterResourceTypeId);
            this.saveUserSettings = true;
            // console.log(this.scope.resourceTypes);
        } else
            this.scope.filteredResourceIds = Object.create(null); // empty filter, because filter for resourceTypes is still empty
    }

    /**
        * Triggers when the user makes a different filter selection
        */
    protected filterOrganizationUnitChangedTimed(itemId: number) {
        this.timeout(() => { this.filterOrganizationUnitChanged(itemId); }, 0);
    }

    /**
        * Triggers when the user selects a different organization unit to filter
        */
    protected filterOrganizationUnitChanged(itemId: number) {

        // Infer the selected item from the dropdown tree in case this function was called from the checkbox.
        if (itemId == null) itemId = this.scope.filterOrganizationUnitId;

        if (itemId === this.emptyOrganizationUnitId) {
            this.scope.filterOnChildOrganizationUnits = false;
        }
        this.scope.hideFilters = itemId === this.emptyOrganizationUnitId;

        // Save the selected unit to use it as an initial value for unit dropdowns later.
        this.userService.setUserVariable(Constants.periodboundInitialOrgUnitUserVar, itemId);

        this.scope.selectedItem = null; // clear the selected item
        this.scope.resourcesForCooperations = null; // clear the list of selectable resources for cooperation

        if (itemId > 0 && !this.organizationUnitsForFiltering.containsKey(itemId)) itemId = 0;

        if (itemId <= 0 || !this.organizationUnitsLoaded) {
            this.scope.filteredResourceIds = Object.create(null); // empty filter, no resources should be visible if the filter for organization units is empty
            if (this.scope.selectResourceId > 0 && this.organizationUnitsLoaded) { // load and select one resource
                this.scope.filteredResourceIds[this.scope.selectResourceId] = this.scope.selectResourceId; // put this one resource in the filter
                this.initActivePage(1, true); // makes sure that all required arrays for paging are initialized
                this.getCompleteResource(this.scope.selectResourceId); // get one resource and select it after loading is complete
            }
            return;
        }

        if (this.saveUserSettings && this.userService.userSettingsLoaded()) {
            this.userService.setDisplaySettingNumber("resources.filterOrganizationUnitId", itemId);
            this.userService.setDisplaySettingSwitch("resources.includeChildOrganizationUnit", this.scope.filterOnChildOrganizationUnits);
        }

        // Clear the array of recently created entities.
        this.scope.recentlyModifiedEntityIds.clear();

        // Get the selected unit's id, plus the ids of all its children. These are used to narrow down the available resource types to choose from.
        const selectedUnit = this.scope.relatedOrganizationUnitDict.value(itemId);
        const unitAndParentIds = [];
        let unit = selectedUnit;
        while (unit != null) {
            unitAndParentIds.push(unit.id);
            unit = this.scope.relatedOrganizationUnitDict.value(unit.parentId);
        }

        // if this code is reached for the same itemId, we skip the webapi call
        if (itemId === this.lastResourcesForOrganizationUnitId && this.scope.filterOnChildOrganizationUnits === this.lastValueForChildOrganizationUnitsSwitch) {
            this.initialLoadResourcesForType();
            return;
        }
        this.lastResourcesForOrganizationUnitId = itemId;
        this.lastValueForChildOrganizationUnitsSwitch = this.scope.filterOnChildOrganizationUnits;

        // Get all resources if "all" has been selected as a filter (itemId == 0) or resources filtered by organization unit if itemId != 0.

        let apiUrl = this.apiUrlWithOUFilter;
        if (itemId === this.emptyOrganizationUnitId) {
            apiUrl = this.apiUrlWithoutOrganizationUnits;
        }
        else if (itemId !== 0) {
            apiUrl = Globals.stringFormat(this.apiUrlWithOUFilter,
                [itemId.toString(), (this.scope.filterOnChildOrganizationUnits ? 1 : 0).toString()]);
        }

        this.getEntityDictionary(apiUrl, this.scope.entityDict,
            () => {
                this.onEntitiesLoaded(this.scope);

                // now hide resource types that are not valid for the selected organization unit
                for (let key in this.scope.resourceTypes) {
                    const resourceType = this.scope.resourceTypes[key] as ResourceType;
                    // Set initial visibility to false if a unit has been selected, to true if "all" has been selected.
                    (resourceType as any).visible = unitAndParentIds.length === 0;
                    for (let i = 0; i < unitAndParentIds.length; i++) {
                        if (resourceType.validOrganizationUnitIds == undefined ||
                            resourceType.validOrganizationUnitIds.indexOf(unitAndParentIds[i]) > -1) {
                            (resourceType as any).visible = true;
                            break;
                        }
                    }
                }

                // must also call the filterResourceTypeChanged, because for a different unit there could be a different list of resource ids
                this.initialLoadResourcesForType();
            }, null);
    }

    /**
        * Triggers when the user selects a different skill to filter
        */
    protected filterSkillChanged(itemId: number) {
        this.scope.filterSkillId = itemId;
        this.filterResourceTypeChanged(this.scope.filterResourceTypeId);
    }

    /**
        * Triggers when the user selects a different date to filter
        */
    protected onFilterDateChange(date: Date) {
        if (!date) return;

        this.scope.filterPeriodBoundDate = date;

        if (this.scope.filterResourceTypeId > 0 || this.scope.filterSkillId > 0)
            this.filterResourceTypeChanged(this.scope.filterResourceTypeId);
        else
            this.applyDateFilterForOuOnly();
    }

    private applyDateFilterForOuOnly() {
        var getUrl = Globals.stringFormat(this.apiUrlWithOuFilterIds,
            [
                this.scope.filterOrganizationUnitId.toString(),
                (this.scope.filterOnChildOrganizationUnits ? 1 : 0).toString(),
                Timezone.dateToStr(this.scope.filterPeriodBoundDate)
            ]);
            
        this.scope.filteredResourceIds = Object.create(null);
        this.getNumberArray(getUrl,
            this.scope.filteredResourceIds,
            () => {
                this.initActivePage(1, true);
            });
    }

    /**
        * Create the filteredSkills object based upon the selected resourceTypeId and selected organizationUnitId
        */
    protected createFilteredSkills(resourceTypeId: number) {
        if (this.scope.skills == null) return; // required data not yet loaded
        if (resourceTypeId == null) resourceTypeId = 0;
        if (this.lastSelectedResourceTypeId === resourceTypeId) return; // already done previously
        this.lastSelectedResourceTypeId = resourceTypeId;
        const allSkills = this.scope.skills;
        let filteredSkills = Object.create(null);
        for (var skillId in allSkills) {
            let skill = allSkills[skillId];
            let validResourceType = skill.id === 0 || resourceTypeId <= 0 ||
                (skill.validResourceTypeIds && skill.validResourceTypeIds.indexOf(resourceTypeId) >= 0);
            if (validResourceType) filteredSkills[skillId] = allSkills[skillId];
        }
        this.scope.filteredSkills = filteredSkills;
    }

    /**
        * Triggers when the user selects a different resource type to filter
        */
    protected filterResourceTypeChanged(itemId: number, pageNr: number = 1, clearRecentlyModified: boolean = true) {
        this.scope.selectedItem = null; // clear the selected item

        if (!this.organizationUnitsLoaded || !this.resourceTypesLoaded) {
            this.scope.filteredResourceIds = Object.create(null); // empty filter, no resources should be visible if the filter for organization units is empty
            return;
        }

        if (this.saveUserSettings && this.userService.userSettingsLoaded() && itemId >= 0)
            this.userService.setDisplaySettingNumber("resources.filterResourceTypeId", itemId);

        // Clear the array of recently created entities.
        if (clearRecentlyModified) this.scope.recentlyModifiedEntityIds.clear();

        // create filtered skill list
        this.createFilteredSkills(itemId);

        if (itemId <= 0 && this.scope.filterSkillId <= 0) {
            // All selection
            this.applyDateFilterForOuOnly();
        } else {
            // Create empty filter
            this.scope.filteredResourceIds = Object.create(null);
            // Fill the filter
            const getUrl = (this.scope.filterSkillId > 0) && (this.scope.filterOrganizationUnitId > 0)
                ? Globals.stringFormat(this.apiUrlWithOuAndTypeSkillFilter,
                    [this.scope.filterOrganizationUnitId.toString(), (this.scope.filterOnChildOrganizationUnits ? 1 : 0).toString(), itemId.toString(),
                        this.scope.filterSkillId.toString(), Timezone.dateToStr(this.scope.filterPeriodBoundDate)])
                : this.scope.filterOrganizationUnitId > 0
                    ? Globals.stringFormat(this.apiUrlWithOuAndTypeFilter,
                        [this.scope.filterOrganizationUnitId.toString(), (this.scope.filterOnChildOrganizationUnits ? 1 : 0).toString(), itemId.toString(), Timezone.dateToStr(this.scope.filterPeriodBoundDate)])
                    : Globals.stringFormat(this.apiUrlWithTypeFilter, [itemId.toString()]);
            this.getNumberArray(getUrl,
                this.scope.filteredResourceIds,
                () => {
                    //console.log("filter loaded:", this.scope.filteredResourceIds);
                    this.initActivePage(pageNr, true);
                });
        }
    }

    /**
        * Is a specific entity visible, can be used to filter entities.
        */
    protected isEntityVisible(entity: TreeEntity): boolean {
        if (this.lastResourcesForOrganizationUnitId === this.emptyOrganizationUnitId) {
            return true;
        }
        const item = entity as Resource;
        let result = false;
        if (this.scope.filteredResourceIds == null) result = true; // there is no filteredResourceIds object, this happens when the All selection is selected.
        else result = this.scope.filteredResourceIds[item.id] != undefined; // item.id must exist in the filteredResourceIds object
        if (result && this.scope.resourceFilter != null && this.scope.resourceFilter !== "") {
            result = false;
            var filter = this.scope.resourceFilter.trim().toLowerCase(); // filter to lower
            if ((item.displayName && item.displayName.trim().toLowerCase().includes(filter)) ||
                (item.emailAddress && item.emailAddress.trim().toLowerCase().includes(filter)) ||
                (item.externalId && item.externalId.trim().toLowerCase().includes(filter))) {
                    result = true;
            }
        }
        return result;
    }

    /**
        * Triggers when the user changes the text input of the resource search filter.
        */
    protected onResourceFilterChanged() {
        if (this.resourceFilterTimer) this.timeout.cancel(this.resourceFilterTimer);
        this.resourceFilterTimer = this.timeout(() => {
            this.scope.selectedItem = null; // clear the selected item
            this.initActivePage(1, true);
        }, 1000);
    }

    /**
        * Override to get related organization units and set the callback function.
        */
    protected getRelatedOrganizationUnits() {
        super.getRelatedOrganizationUnits(() => {
            this.addEmptyOptionToOrganizationUnitFilter();
            this.onOrganizationUnitsLoaded(this.scope);
        });
    }

    /**
        * Gets the resource property names in the user's current display language.
        */
    protected getResourcePropertyNames() {
        this.incrementNumberOfPendingOperations(1);
        const languageCode = this.translationService.getCurrentLanguage();
        this.http.get(Globals.stringFormat(this.apiUrlForResourcePropertyNames, [encodeURIComponent(languageCode)]))
            .then(response => {
                const resourcePropertyNamesUnsorted = response.data as ResourcePropertyName[];
                this.scope.resourcePropertyNames = this.filter("orderBy")(resourcePropertyNamesUnsorted, "text");
                this.decrementNumberOfPendingOperations(1);
            }, response => {
                this.decrementNumberOfPendingOperations(1);
                this.modalConfirmationWindowService
                    .showModalInfoDialog("Error",
                    this.getErrorMessage(response),
                    this.scope.textLabels.OK,
                    () => { },
                    0,
                    this.dialogToken);
            });
    }

    /**
        * Handle a changed resource property value.
        * @param property The resource property value object that has changed.
        * @param resourcePropertyId The id of the resource property. Is needed in case of newly made resource property value objects.
        */
    protected onResourcePropertyChanged(property: ResourcePropertyValue, resourcePropertyId: number): void {

        // Set the resource id/resource property id on the resource property object, as they may not yet have been set if they're new.
        property.resourceId = this.scope.selectedItem.id;
        if (resourcePropertyId) property.resourcePropertyId = resourcePropertyId;
        if (property.id == undefined) property.id = -1; // we need to insert it, so -1

        // Flag this object as changed.
        property.modified = true;

        //console.log(property);
        this.onEntityChanged(false, this.scope.selectedItem);
    }

    /**
        * Puts an updated resource, with the possibility of retrying when the backend detects a possible duplicate.
        * @param resource
        */
    protected putUpdatedResource(resource: ResourceForSaving) {
        this.http.put(this.apiUrl, resource)
            .then(
                () => {
                    resource.skipUniquenessChecks = false; // important: otherwise the uniqueness check will never show again after the first override
                    resource.changeOfDeletabilityPending = false;
                    this.updateCooperationsInOtherResources(resource);
                    this.unflagCooperations(resource.cooperations);
                },
                response => {
                    if (response.data && response.data.canBeRetried) { // sort of haphazard check to see if we should offer the retry window
                        console.log("Duplicate detected", response.data);

                        const resourceInfo = resource.emailAddress
                            ? resource.externalId
                            ? Globals.stringFormat("{0} ({1}, {2})", [resource.displayName, resource.externalId, resource.emailAddress])
                            : Globals.stringFormat("{0} ({1})", [resource.displayName, resource.emailAddress])
                            : resource.externalId
                            ? Globals.stringFormat("{0} ({1})", [resource.displayName, resource.externalId])
                            : resource.displayName;


                        const statusCode = this.translationService.extractStatusCodeFromStatusText(response.data.message);
                        this.modalConfirmationWindowService.showModalDialog(
                            this.scope.textLabels.RESOURCES_POSSIBLE_DUPLICATE_DETECTED,
                            Globals.stringFormat("{0}\n{1} {2}",
                                [
                                    resourceInfo, this.translationService.getTranslationForStatusCode(statusCode),
                                    this.scope.textLabels.RESOURCES_SAVE_ANYWAY
                                ]),
                            () => {
                                // retry, but with skipping uniqueness checks now
                                resource.skipUniquenessChecks = true;
                                this.putUpdatedResource(resource);
                            },
                            () => { this.restoreEntity(resource.id); });
                    }
                    else if (response.data && !response.data.canBeRetried) {
                        console.log("Duplicate resource email address detected", response.data);

                        const resourceInfo = resource.emailAddress
                            ? resource.externalId
                            ? Globals.stringFormat("{0} ({1}, {2})", [resource.displayName, resource.externalId, resource.emailAddress])
                            : Globals.stringFormat("{0} ({1})", [resource.displayName, resource.emailAddress])
                            : resource.externalId
                            ? Globals.stringFormat("{0} ({1})", [resource.displayName, resource.externalId])
                            : resource.displayName;


                        const statusCode = this.translationService.extractStatusCodeFromStatusText(response.data.message);
                        this.modalConfirmationWindowService.showModalInfoDialog(
                            this.scope.textLabels.ERROR_OCCURRED,
                            Globals.stringFormat("{0}\n{1}",
                                [
                                    resourceInfo, this.translationService.getTranslationForStatusCode(statusCode)
                                ]),
                            this.scope.textLabels.OK,
                            () => { this.restoreEntity(resource.id); },
                            0,
                            this.dialogToken);
                    }
                    else {
                        resource.changeOfDeletabilityPending = false;
                        resource.updateFailed = true;
                        //console.log("Update failed", entity);
                        this.modalConfirmationWindowService
                            .showModalInfoDialog(this.scope.textLabels.ERROR_OCCURRED,
                                this.getErrorMessage(response),
                                this.scope.textLabels.OK,
                                () => { this.restoreEntity(resource.id); },
                                0,
                                this.dialogToken);
                    }
                });
    }

    /**
    * called when the scope is destroyed or when a timer calls it
    * @param calledFromTimer indication if this was called by a timer or by the scope destroy
    * @param uponSuccess Function called upon a successful promise resolve for the save.
    */
    protected saveChanges(calledFromTimer: boolean) {

        const changedItemCount = this.changedItemCount; // copy it, as the corresponding scope variable will be set to 0

        if (this.saveChangesTimerRunning && !calledFromTimer) {
            this.timeout.cancel(this.saveChangesTimer);
            //console.log("save changes timer cancelled.");
        }
        this.saveChangesTimerRunning = false;
        if (this.changedItemCount === 0) return;

        //console.log(`save changes: ${this.changedItemCount} entities have changed.`, this.changedItemIds);

        for (let i = this.changedItemCount - 1; i >= 0; i--) {
            const resource: ResourceForSaving = this.scope.entityDict.value(this.changedItemIds[i]);
            if (resource != null && resource.changed) {

                this.preprocessEntity(resource);

                ((resource: ResourceForSaving) => {
                    this.putUpdatedResource(resource);
                })(resource);
                resource.changed = false;
            }
        }

        this.changedItemCount = 0;

        if (changedItemCount === 0) return;

        // make sure the planboard will reload data
        Planboard.clearData();
    }

    /**
        * Method called by the view when the selection preferred cooperations has changed.
        * @param resource
        */
    onPreferredCooperationsChanged(resource: Resource): void {

        this.updateCooperations(resource);
        this.updateCooperationsInOtherResources(resource);
        this.onEntityChanged(false, resource);
    }

    /**
        * Method called by the view when the selection of avoid cooperations has changed.
        * @param resource
        */
    onAvoidCooperationsChanged(resource: Resource): void {

        this.updateCooperations(resource);
        this.updateCooperationsInOtherResources(resource);
        this.onEntityChanged(false, resource);
    }

    /**
        * Updates the other resources wrt the cooperations changed on the input resource. Caution: only affects fully loaded resources.
        * @param resource Resource in which cooperations have been changed.
        */
    updateCooperationsInOtherResources(resource: Resource) {

        const affectedResourceIds: number[] = [];
        const getOtherResourceId = (cooperation: Cooperation) : number => {
            return cooperation.resourceId === resource.id ? cooperation.otherResourceId : cooperation.resourceId;
        };

        // Handle additions.
        for (const cooperation of resource.cooperations.filter(coop => coop.added || coop.deleted)
            .sort(coop => coop.addedOrDeletedTimestamp)) {
            if (cooperation.added) {
                const otherResource =
                    this.scope.entityDict.value(getOtherResourceId(cooperation)) as Resource;
                if (otherResource && otherResource.fullyLoaded) {
                    const newCooperation = new Cooperation();
                    newCooperation.resourceId = resource.id;
                    newCooperation.otherResourceId = otherResource.id;
                    newCooperation.value = cooperation.value;

                    otherResource.cooperations.push(newCooperation);
                    if (affectedResourceIds.indexOf(otherResource.id) === -1)
                        affectedResourceIds.push(otherResource.id);
                }
            }

            // Handle deletions.
            if (cooperation.deleted) {

                const otherResource =
                    this.scope.entityDict.value(getOtherResourceId(cooperation)) as Resource;
                if (otherResource && otherResource.fullyLoaded) {

                    const matchingCooperations =
                        otherResource.cooperations.filter(coop => coop.resourceId === resource.id ||
                            coop.otherResourceId === resource.id);
                    if (matchingCooperations.length === 0) continue;

                    otherResource.cooperations.splice(otherResource.cooperations.indexOf(matchingCooperations[0]));
                    if (affectedResourceIds.indexOf(otherResource.id) === -1)
                        affectedResourceIds.push(otherResource.id);
                }
            }
        }

        // Update selected ids for taglists for affected resources.
        for (const resourceId of affectedResourceIds) {
            const resource = this.scope.entityDict.value(resourceId);
            if (resource) this.extractCooperationLists(resource);
        }
    }

    /**
        * Updates/flags the cooperations of the resource based on changes that may have been done to them.
        * @param resource
        */
    updateCooperations(resource: Resource): void {
        // Pass 1: delete what should no longer be there.
        for (const cooperation of resource.cooperations) {
            const otherResourceId = cooperation.resourceId === resource.id
                ? cooperation.otherResourceId
                : cooperation.resourceId;

            const shouldBeDeleted =
                cooperation.value === 2 && resource.avoidResourceIds.indexOf(otherResourceId) === -1 ||
                    cooperation.value === 1 && resource.preferredResourceIds.indexOf(otherResourceId) === -1;

            cooperation.added = cooperation.added && !shouldBeDeleted;
            cooperation.deleted = shouldBeDeleted;
            cooperation.addedOrDeletedTimestamp = Date.now();
        }

        // Pass 2: add what wasn't already there.
        const addedCoops: Cooperation[] = [];
        this.extractNewCooperations(resource, resource.preferredResourceIds, 1, addedCoops);
        this.extractNewCooperations(resource, resource.avoidResourceIds, 2, addedCoops);

        // Add otherResourceDisplayName property to cooperations
        for (const coop of addedCoops) {
            const otherResource = this.scope.combinedResourcesList[coop.otherResourceId];
            if (otherResource) {
                coop.otherResourceDisplayName = otherResource.displayName;
            }
        }
   
        resource.cooperations = resource.cooperations.concat(addedCoops);
    }

    /**
        * Extract new resource cooperations from a list of ids from preferred/avoid and the existing cooperations.
        * Also undoes any earlier deletions that have been readded.
        * @param resource Resource to do extraction for.
        * @param otherResourceIdList List with ids of other resources for cooperation relations.
        * @param cooperationValue Value of the cooperation type for this call.
        * @param extractedCooperations Array to add any found new cooperations to.
        */
    extractNewCooperations(resource: Resource,
        otherResourceIdList: number[],
        cooperationValue: number,
        extractedCooperations: Cooperation[]): void {
        for (const otherResourceId of otherResourceIdList) {
            const matchingCooperations = resource.cooperations.filter(coop => {
                return coop.value === cooperationValue &&
                    (coop.resourceId === otherResourceId || coop.otherResourceId === otherResourceId);
            });
            if (matchingCooperations.length === 0) {
                const newCoop = new Cooperation();
                newCoop.resourceId = resource.id;
                newCoop.otherResourceId = otherResourceId;
                newCoop.value = cooperationValue;
                newCoop.added = true;
                newCoop.addedOrDeletedTimestamp = Date.now();
                extractedCooperations.push(newCoop);
            } else if (matchingCooperations[0].deleted) {
                matchingCooperations[0].deleted = false;
            }
        }
    }

    /**
        * Unflag addition/deletion
        * @param cooperations
        */
    unflagCooperations(cooperations: Cooperation[]): void {
        for (const cooperation of cooperations) {
            cooperation.deleted = false;
            cooperation.added = false;
            cooperation.addedOrDeletedTimestamp = null;
        }
    }

    /**
        * Convert the loaded organization units dictionary into an object for the tree dropdown.
        */
    private createOrganizationUnitsTreeObject(myScope: IResourcesScope): void {
        myScope.organizationUnits = Object.create(null);
        //myScope.organizationUnits[0] = { displayName: myScope.textLabels.FILTER_ALL, id: 0 }
        myScope.relatedOrganizationUnitDict.forEach((key, value) => {
            const orgUnit = value as OrganizationUnit;
            myScope.organizationUnits[key] = orgUnit;
        });
    }

    /**
        * Get the last used filterOrganizationUnitId from user settings.
        */
    private getFilterOrganizationUnitId(myScope: IResourcesScope, defaultValue: number): void {
        if (myScope.selectResourceId > 0) // show no selection in the filter dropdown if one resource should be selected
            myScope.filterOrganizationUnitId = -1;
        else
            myScope.filterOrganizationUnitId = !this.userService.userSettingsLoaded()
                ? defaultValue
                : this.userService.getDisplaySettingNumber("resources.filterOrganizationUnitId", defaultValue);
    }

    /**
        * Get the last used includeChildOrganizationUnit from user settings.
        */
    private getIncludeChildOrganizationUnit(myScope: IResourcesScope): void {
        if (myScope.selectResourceId > 0) // show no selection in the filter dropdown if one resource should be selected
            myScope.filterOnChildOrganizationUnits = false;
        else
            myScope.filterOnChildOrganizationUnits = !this.userService.userSettingsLoaded()
                ? false
                : this.userService.getDisplaySettingSwitch("resources.includeChildOrganizationUnit");
    }

    /**
        * Convert the loaded organization units dictionary into an object for the tree dropdown.
        */
    private onOrganizationUnitsLoaded(myScope: IResourcesScope): void {
        this.getFilterOrganizationUnitId(myScope, 0);
        this.getIncludeChildOrganizationUnit(myScope);
        this.createOrganizationUnitsTreeObject(myScope);
        this.organizationUnitsLoaded = true;
        if (this.resourceTypesLoaded)
            this.filterOrganizationUnitChanged(myScope.filterOrganizationUnitId);
    }

    /**
        * Override to get related resource types and set the callback function
        */
    protected getRelatedResourceTypes() {
        super.getRelatedResourceTypes(() => this.onResourceTypesLoaded(this.scope));
    }

    /**
        * Convert the loaded resource types dictionary into an object for the tree dropdown.
        */
    private createResourceTypesTreeObject(myScope: IResourcesScope) {
        myScope.resourceTypes = Object.create(null);
        myScope.resourceTypes[0] = { displayName: myScope.textLabels.FILTER_ALL, id: 0 }
        myScope.relatedResourceTypeDict.forEach((key, value) => {
            myScope.resourceTypes[key] = value;
        });
    }

    /**
        * Get the last used filterResourceTypeId from user settings.
        */
    private getFilterResourceTypeId(myScope: IResourcesScope, defaultValue: number): void {
        if (myScope.selectResourceId > 0) // show no selection in the filter dropdown if one resource should be selected
            myScope.filterResourceTypeId = -1;
        else
            myScope.filterResourceTypeId = !this.userService.userSettingsLoaded()
                ? defaultValue
                : this.userService.getDisplaySettingNumber("resources.filterResourceTypeId", defaultValue);
    }

    /**
        * Convert the loaded resource types dictionary into an object for the tree dropdown.
        */
    protected onResourceTypesLoaded(myScope: IResourcesScope) {
        this.getFilterResourceTypeId(myScope, 0);
        this.createResourceTypesTreeObject(myScope);
        //console.log("Loaded resources", myScope.resourceTypes);
        this.resourceTypesLoaded = true;
        if (this.organizationUnitsLoaded)
            this.filterOrganizationUnitChanged(myScope.filterOrganizationUnitId);
    }

    /**
    * Override to get related skills and set the callback function
    */
    protected getRelatedSkills() {
        super.getRelatedSkills(() => this.onSkillsLoaded(this.scope));
    }

    /**
        * Convert the loaded skills dictionary into an object for the tree dropdown.
        */
    protected onSkillsLoaded(myScope: IResourcesScope) {
        myScope.skills = Object.create(null);
        myScope.skills[0] = { displayName: myScope.textLabels.FILTER_ALL, id: 0 }
        myScope.relatedSkillDict.forEach((key, value) => {
            myScope.skills[key] = value;
        });
        this.createFilteredSkills(this.scope.filterResourceTypeId);
    }

    /**
        * Override to get related skill levels and set the callback function
        */
    protected getRelatedSkillLevels() {
        super.getRelatedSkillLevels(() => this.onSkillLevelsLoaded(this.scope));
    }

    /**
        * Convert the loaded skill levels dictionary into a two-layered object for the tree dropdown.
        * Two-layered meaning: first the organization unit id, then the skill level id.
        */
    protected onSkillLevelsLoaded(myScope: IResourcesScope) {
        myScope.skillLevels = new Dictionary();
        myScope.relatedSkillLevelDict.forEach((key, value) => {
            if (!myScope.skillLevels.containsKey(value.organizationUnitId)) {
                myScope.skillLevels.add(value.organizationUnitId, Object.create(null));
            }
            myScope.skillLevels.value(value.organizationUnitId)[key] = value;
        });
    }

    /**
    * Set the selected entity
    * @param entity the entity to select
    * @param setActivePageCallback for resetting the active page when a new resource is added
    */
    protected setSelected(entity: TreeEntity, setActivePageCallback?: () => void) {
        const resourceFromEntityDict = this.scope.entityDict.value(entity.id);
        const fullyLoadedResource = resourceFromEntityDict != null ? resourceFromEntityDict.fullyLoaded : false;

        // If the resource already is fully loaded then take it from the entityDict.
        const resource = fullyLoadedResource ? resourceFromEntityDict as Resource : entity as Resource;

        // Initialize variables for the resource relations taglists.
        this.scope.showResourceRelationTaglistSpinner = true; // wait spinner in dropdown is visible
        this.scope.cooperationsInitializedForEntity = null; // combined list of resources to choose from is not yet initialized
        this.createCooperationResourcesInitialList(resource); // create a new list for resources that are already chosen
        this.scope.combinedResourcesList = this.scope.initialResourcesList; // make sure the taglist uses the newly created initialResourcesList

        if (fullyLoadedResource) {

            // See if an initial organization unit has been chosen before for period bound properties. If not: choose one to which this resource belongs.
            if (this.userService.getUserVariable(Constants.periodboundInitialOrgUnitUserVar) == null && resource.organizationUnits.length > 0) {
                const orderedUnits = this.filter("orderBy")(resource.organizationUnits, "end", true);
                this.userService.setUserVariable(Constants.periodboundInitialOrgUnitUserVar, orderedUnits[0].organizationUnitId);
            }

            // See if an initial resource type has been chosen before for period bound properties. If not: choose one to which this resource belongs.
            if (this.userService.getUserVariable(Constants.periodBoundInitialResourceTypeUserVar) == null && resource.resourceTypes.length > 0) {
                const orderedResourceTypes = this.filter("orderBy")(resource.resourceTypes, "end", true);
                this.userService.setUserVariable(Constants.periodBoundInitialResourceTypeUserVar, orderedResourceTypes[0].resourceTypeId);
            }

            // Resource extends TreeEntity, so we actually can pass it to setSelected.
            super.setSelected(resource);
            if (setActivePageCallback) setActivePageCallback();
        } else {
            setActivePageCallback
                ? this.getCompleteResource(entity.id, () => { setActivePageCallback(); })
                : this.getCompleteResource(entity.id);
        }
    }

    /**
        * return the resource's property that corresponds to the property in: this.scope.resourcePropertyNames
        * @param resource the resource to get the property from e.g. : ctrl.scope.selectedItem
        * @param property one item from: this.scope.resourcePropertyNames
        */
    protected getResourcePropertyValue(resource: Resource, property: ResourcePropertyName): ResourcePropertyValue {
        if (!resource) return null;
        const entity = this.scope.entityDict.value(resource.id);

        for (var i = 0; i < entity.properties.length; i++)
            if (entity.properties[i].resourcePropertyId === property.resourcePropertyId)
                return entity.properties[i];

        // not found, make a new one
        let prop = new ResourcePropertyValue();
        prop.id = -1;
        prop.modified = false;
        prop.readonly = false;
        prop.resourceId = resource.id;
        prop.resourcePropertyId = property.resourcePropertyId;
        prop.value = "";
        resource.properties.push(prop);
        return prop;
    }

    /**
        * Gets a complete resource object from the server, including all property values and relationships.
        * Subsequently replaces the shallow resource object with the complete one and flags the resource object as fully loaded.
        * @param id Id of the resource to get.
        * @param setActivePageCallback for resetting the active page when a new resource is added
        */
    getCompleteResource(id: number, setActivePageCallback?:() => void) {
        this.incrementNumberOfPendingOperations(1);
        this.http.get(`${this.apiUrl}/${id}`).then(response => {
            const entity = response.data as Resource;

            // Correct timezone info for period-bound properties.
            this.correctPeriodTimeZoneInfo(entity.organizationUnits);
            this.correctPeriodTimeZoneInfo(entity.maxOccupations);
            this.correctPeriodTimeZoneInfo(entity.percentages);
            this.correctPeriodTimeZoneInfo(entity.resourceTypes);
            this.correctPeriodTimeZoneInfo(entity.skills);
            this.correctPeriodTimeZoneInfo(entity.daypartsNorm);

            // Remember the receivedOrder from the old existing value, else the sorting based on receivedOrder will no longer work.
            this.scope.entityDict.tryGetValue(entity.id, (value) => { entity.receivedOrder = value.receivedOrder; });

            entity.fullyLoaded = true;
            this.setEntityDefaultValues(entity, this.scope);
            this.placeEntityInTree(entity, false); // don't rebuild the list
            this.createCooperationResourcesInitialList(entity);
            this.extractCooperationLists(entity);
            setActivePageCallback
                ? this.setSelected(entity, () => { setActivePageCallback(); })
                : this.setSelected(entity);
            this.decrementNumberOfPendingOperations(1);
        }, response => {
            this.decrementNumberOfPendingOperations(1);
            this.modalConfirmationWindowService
                .showModalInfoDialog("Error",
                this.getErrorMessage(response),
                this.scope.textLabels.OK,
                () => { },
                0,
                this.dialogToken);
        });
    }

    /**
        * Corrects the timezone info for the starts and ends for period-bound properties of a resource.
        * @param periods Periods to correct the timezone info in.
        */
    correctPeriodTimeZoneInfo(periods: MembershipPeriod[]) {
        for (let i = 0; i < periods.length; i++) {
            if (periods[i].start) periods[i].start = Timezone.correctTimeZoneInfo(periods[i].start);
            if (periods[i].end) periods[i].end = Timezone.correctTimeZoneInfo(periods[i].end);
        }
    }

    /**
        * Returns if a resource is visible in the dropdownlist for preffered cooperations.
        * @param resourceId the id of the resource to test for visibility
        */
    getIsResourceVisibleForPreferredCoop(resourceId: number) {
        if (this.scope.selectedItem) {
            if (this.scope.selectedItem.id === resourceId) return false;
            var selectedResource = this.scope.selectedItem as Resource;
            if (selectedResource && selectedResource.avoidResourceIds)
                return selectedResource.avoidResourceIds.indexOf(resourceId) < 0;
        }
        return true;
    }

    /**
        * Returns if a resource is visible in the dropdownlist for avoiding cooperations.
        * @param resourceId the id of the resource to test for visibility
        */
    getIsResourceVisibleForAvoidCoop(resourceId: number) {
        if (this.scope.selectedItem) {
            if (this.scope.selectedItem.id === resourceId) return false;
            var selectedResource = this.scope.selectedItem as Resource;
            if (selectedResource && selectedResource.preferredResourceIds)
                return selectedResource.preferredResourceIds.indexOf(resourceId) < 0;
        }
        return true;
    }

    /**
        * Creates the combinedResourcesList by combining the initialResourcesList and resourcesForCooperations.
        * @param maxItemsInList optional, the maximum number of items in the combinedResourcesList.
        */
    createCombinedResourceList(maxItemsInList: number): void {
        let tmp = Object.create(null);
        let totalItems = 0;
        // add all items of the initialResourcesList, these are the tags that are already selected for cooperation
        for (let id in this.scope.initialResourcesList) {
            tmp[id] = this.scope.initialResourcesList[id];
            totalItems++;
        }
        // add all item of the resourcesForCooperations, these are all selectable resources that match the current organization unit filter
        for (let id in this.scope.resourcesForCooperations) {
            tmp[id] = this.scope.resourcesForCooperations[id];
            totalItems++;
            if (maxItemsInList != null && totalItems > maxItemsInList) break; 
        }
        this.scope.combinedResourcesList = tmp;
    }

    /**
        * Returns resources available to select for cooperations.
        * @param excludeIds Ids of resources to exclude, e.g. because they are selected in another cooperation tag list.
        */
    getAllResourcesForCooperations(): Object {
        // If the combinedResourcesList has already been initialized for the selected resource then we can return this directly.
        if (this.scope.cooperationsInitializedForEntity == this.scope.selectedItem &&
            this.scope.combinedResourcesList != null && this.scope.resourcesForCooperations != null) {
            this.scope.showResourceRelationTaglistSpinner = false; // no longer needed to show the wait spinner
            return this.scope.combinedResourcesList;
        }

        // The combinedResourcesList has not yet been initialized, but the list of visible resources (resourcesForCooperations) has already been received.
        if (this.scope.resourcesForCooperations != null) {
            this.createCombinedResourceList(this.maxItemsInTaglist);
            this.scope.cooperationsInitializedForEntity = this.scope.selectedItem; // remember that we initialized it for the currently selected resource
            this.scope.showResourceRelationTaglistSpinner = false; // no longer needed to show the wait spinner
            return this.scope.combinedResourcesList;
        }

        const apiUrl = Globals.stringFormat(this.apiUrlWithOUFilter,
            [this.scope.filterOrganizationUnitId.toString(), (this.scope.filterOnChildOrganizationUnits ? 1 : 0).toString()]);

        ((scope: IResourcesScope) => {
            this.http.get(apiUrl).then(response => {
                    var tmp = Object.create(null);
                    var order = 1;

                    for (let resource of response.data as any[]) {
                        tmp[resource.id] = { id: resource.id, displayName: resource.displayName, order: order++ };
                    }

                    scope.resourcesForCooperations = tmp;
                    scope.combinedResourcesList = tmp;
                    scope.showResourceRelationTaglistSpinner = false;
                    scope.cooperationsInitializedForEntity = null;
                    scope.resourcesForCooperationsItemsVersion++;
                },
                response => {
                    scope.showResourceRelationTaglistSpinner = false;
                });
        })(this.scope);

        return this.scope.initialResourcesList;
    }

    /**
        * Adds resources from a resource's cooperations to a new list of resources to choose from for cooperations.
        * This will create a new initialResourcesList object.
        * @param resource
        */
    createCooperationResourcesInitialList(resource: Resource): void {
        let tmp = Object.create(null);
        if (resource && resource.cooperations && resource.cooperations.length > 0)
            for (let coop of resource.cooperations) {
                if (coop.resourceId === resource.id) {
                    tmp[coop.otherResourceId] = { id: coop.otherResourceId, displayName: coop.otherResourceDisplayName };
                } else if (coop.otherResourceId === resource.id) {
                    tmp[coop.resourceId] = { id: coop.resourceId, displayName: coop.resourceDisplayName };
                }
            }
        this.scope.initialResourcesList = tmp;
    }

    /**
        * Extracts preferred/avoided resources from the cooperations from the WebAPI.
        * @param resource
        */
    extractCooperationLists(resource: Resource): void {

        resource.preferredResourceIds = [];
        resource.avoidResourceIds = [];

        for (let coop of resource.cooperations) {
            const resourceId = coop.resourceId === resource.id ? coop.otherResourceId : coop.resourceId;
            (coop.value === 1 ? resource.preferredResourceIds : resource.avoidResourceIds).push(resourceId);
        }
    }

    /**
        * Returns text label for too many items.
        */
    protected getTooManyItemsText(): string {
        return this.scope.textLabels.TOO_MANY_ITEMS;
    }

    /**
        * See if the selected item is writable.
        */
    protected isSelectedItemWritable(): boolean {
        if (!this.scope.selectedItem) return false;

        return true;
    }

    /**
        * See if the selected item is deletable.
        */
    protected isSelectedEntityDeletable(): boolean {
        if (!this.scope.selectedItem) return false;

        return true;
    }

    /**
    * Returns the title of the modal delete confirmation window.
    * Should be overridden in controllers that make use of this functionality.
    */
    protected getDeleteConfirmationWindowTitle(): string {
        return this.scope.textLabels.RESOURCES_DELETION_MODAL_TITLE;
    }

    /**
        * Returns the text of the modal delete confirmation window.
        * Should be overridden in controllers that make use of this functionality.
    */
    protected getDeleteConfirmationWindowText(): string {
        return this.scope.textLabels.RESOURCES_DELETION_MODAL_TEXT;
    }

    /**
        * Determines whether the new resource popup screen is filled correctly.
        * If so, the button to add the resource is active.
    */
    protected canCreateNewEntities(): boolean {
        return this.scope.newResourceDisplayName !== "";
    }

    /**
    * Creates a new entity object that can be inserted.
    */
    protected createNewEntity(): TreeEntity {
        const resourceForSaving = new ResourceForSaving();
        resourceForSaving.id = -1;
        resourceForSaving.displayName = this.scope.newResourceDisplayName;
        resourceForSaving.emailAddress = this.scope.newResourceEmail;
        resourceForSaving.receivedOrder = 0; // value of exactly 0 avoids sorting in the sortLastEntity function

        // Use the organization unit in the selection filter as organization unit for the resource if it is set.
        if (this.scope.filterOrganizationUnitId) {
            const orgUnitMembership = new OrganizationUnitMembershipPeriod();
            orgUnitMembership.organizationUnitId = this.scope.filterOrganizationUnitId;
            orgUnitMembership.start = new Date(Timezone.rollDateForWebApi(TimeSpan.today.toDate()));
            resourceForSaving.organizationUnits = [orgUnitMembership];
        }

        // Use the resource type in the selection filter as resource type for the resource if it is set.
        if (this.scope.filterResourceTypeId) {
            const resourceTypeMembership = new ResourceTypeMembershipPeriod();
            if (this.scope.filterOrganizationUnitId) {
                resourceTypeMembership.organizationUnitId = this.scope.filterOrganizationUnitId;
            }
            resourceTypeMembership.resourceTypeId = this.scope.filterResourceTypeId;
            resourceTypeMembership.start = new Date(Timezone.rollDateForWebApi(TimeSpan.today.toDate()));
            resourceForSaving.resourceTypes = [resourceTypeMembership];
        }

        return resourceForSaving;
    }

    /**
        * Make the popup div for creating a new resource visible and place it in the center of the browser.
        */
    protected openNewResourceInput() {
        const popupWidth = $("#popupWidth");
        this.scope.popupTop = `${popupWidth.offset().top}px`;
        this.scope.popupLeft = `${popupWidth.offset().left}px`;
        this.scope.popupWidth = `${popupWidth.outerWidth()}px`;
        this.scope.newResourceDisplayName = this.newEntityDisplayName();
        this.scope.newResourceEmail = "";
        this.scope.newResourceInputOpen = true;
        this.timeout(() => {
            $("#inputDisplayName").focus();
        }, 0);
    }

    /**
        * Closes the new resource popup form
        */
    protected closeNewResourceInput() {
        this.scope.newResourceInputOpen = false;
    }

    /**
        * Event handler to be used when a base resource property value of an entity has changed (display name, external id, email address).
        */
    protected onResourceBasePropertyChanged() {
        const entity = this.scope.selectedItem;

        this.placeEntityInTree(entity);
        this.onEntityChanged(false, entity);
    }

    /**
    * Creates a new resource at the backend and puts it onto the scope after successful creation.
    */
    protected newEntity() {
        this.closeNewResourceInput();

        this.incrementNumberOfPendingOperations(1); // not strictly pending gets, but we should not get interference with other windows opened in parallel
        this.modalConfirmationWindowService.showModalInfoDialog(this.scope.textLabels.ADDING_DATA_TITLE,
            this.scope.textLabels.ADDING_DATA_TEXT, "", null, Constants.modalWaitDelay, this.dialogToken);
        const resource = this.createNewEntity() as ResourceForSaving;
        ((resource: ResourceForSaving) => {
            this.putNewResource(resource);
        })(resource);
    }

    /**
        * Puts a new resource, with the possibility of retrying when the backend detects a possible duplicate.
        * @param resource
        */
    private putNewResource(resource: ResourceForSaving) {
        this.http.put(this.apiUrl, resource)
            .then(
            response => {
                this.decrementNumberOfPendingOperations(1);
                const savedNewEntity: any = response.data;
                if (savedNewEntity && savedNewEntity.id) {
                    savedNewEntity.skipUniquenessChecks = false; // important: otherwise the uniqueness check will never show again after the first override
                    this.setEntityDefaultValues(savedNewEntity, this.scope);
                    savedNewEntity.receivedOrder = resource.receivedOrder;
                    this.scope.recentlyModifiedEntityIds.add(savedNewEntity.id, savedNewEntity.id);
                    this.placeEntityInTree(savedNewEntity);
                    this.setSelected(savedNewEntity, () => {this.initActivePage(null, true)});
                }

                this.filterResourceTypeChanged(this.scope.filterResourceTypeId, this.scope.activePageNr, false);
            },
            response => {
                this.decrementNumberOfPendingOperations(1);
                if (response.data && response.data.canBeRetried) { // sort of haphazard check to see if we should offer the retry window
                    console.log("Duplicate detected", response.data);
                    const statusCode = this.translationService.extractStatusCodeFromStatusText(response.data.message);
                    this.modalConfirmationWindowService.showModalDialog(
                        this.scope.textLabels.RESOURCES_POSSIBLE_DUPLICATE_DETECTED,
                        this.translationService.getTranslationForStatusCode(statusCode) + " " + this.scope.textLabels.RESOURCES_SAVE_ANYWAY,
                        () => {
                            // retry, but with skipping uniqueness checks now
                            resource.skipUniquenessChecks = true;
                            this.putNewResource(resource);
                        },
                        () => {
                            // no further action needed
                        });
                }
                else if (response.data && !response.data.canBeRetried) {
                    console.log("Duplicate email address detected", response.data);
                    const statusCode = this.translationService.extractStatusCodeFromStatusText(response.data.message);
                    this.modalConfirmationWindowService.showModalInfoDialog(
                        this.scope.textLabels.ERROR_OCCURRED,
                        this.translationService.getTranslationForStatusCode(statusCode),
                        this.scope.textLabels.OK,
                        () => { },
                        0,
                        "tlcNew");  
                }
                else {
                    this.modalConfirmationWindowService
                        .showModalInfoDialog(this.scope.textLabels.ERROR_OCCURRED,
                        this.getErrorMessage(response),
                        this.scope.textLabels.OK,
                        () => { },
                        0,
                        "tlcNew");
                }
            });
    }

    /**
        * Clones the specified entity and inserts it into the collection of entities upon success.
        * @param entity Entity to be cloned.
        */
    protected cloneEntity(entity: TreeEntity) {
        super.cloneEntity(entity, () => { this.filterResourceTypeChanged(this.scope.filterResourceTypeId, this.scope.activePageNr, false) });
    }

    /**
        * Display name for newly made entities.
        */
    protected newEntityDisplayName() {
        return this.scope.textLabels.NEW_RESOURCE_DISPLAY_NAME;
    }

    /**
        * Filter input on text to only accept nummeric values.
        */
    protected filterTextValue($event, oldValue, allowDecimal) {
        this.numberService.filterTextValue($event, oldValue, allowDecimal, null);
    }

    /**
        * Opens resource period bound properties view
        */
    protected openPeriodBoundProperties(entity: TreeEntity) {
        this.saveSelectionFilterState();
        this.state.transitionTo("periodBoundProperties", { resourceId: entity.id });
    }

    /**
        * Opens resource parttime schedule view
        */
    protected openParttimeSchedule(entity: TreeEntity) {
        this.saveSelectionFilterState();
        this.state.transitionTo("parttimeSchedule", { resourceId: entity.id });
    }

    /**
        * Get the displayName for an object if the object is not undefined
        */
    private getItemDisplayName(item: any): string {
        if (item == undefined) return item;
        return item.displayName;
    }

    /**
        * Get the displayName for the currently selected organizationUnit in a membershipPeriod
        */
    protected getOrganizationUnitDisplayName(period: OrganizationUnitMembershipPeriod): string {
        const name = this.getItemDisplayName(this.scope.organizationUnits[period.organizationUnitId]);
        if (name == undefined) return period.organizationUnitDisplayName; // not found in tree: return default
        if (name !== period.organizationUnitDisplayName) period.organizationUnitDisplayName = name; // update default with found name
        return name;
    }

    /**
        * Get the displayName for the currently selected resourceType unit in a membershipPeriod
        */
    protected getResourceTypeDisplayName(period: ResourceTypeMembershipPeriod): string {
        const name = this.getItemDisplayName(this.scope.resourceTypes[period.resourceTypeId]);
        if (name == undefined) return period.resourceTypeDisplayName; // not found in tree: return default
        if (name !== period.resourceTypeDisplayName) period.resourceTypeDisplayName = name; // update default with found name
        return name;
    }

    /**
        * Get the displayName for the currently selected skill in a membershipPeriod
        */
    protected getSkillDisplayName(period: SkillMembershipPeriod): string {
        const name = this.getItemDisplayName(this.scope.skills[period.skillId]);
        if (name == undefined) return period.skillDisplayName; // not found in tree: return default
        if (name !== period.skillDisplayName) period.skillDisplayName = name; // update default with found name
        return name;
    }

    /**
        * Get the displayName for the currently selected skillLevel in a membershipPeriod
        */
    protected getSkillLevelDisplayName(period: SkillMembershipPeriod): string {
        const skillLevelTree = this.scope.skillLevels.value(period.organizationUnitId);
        const name = skillLevelTree == undefined
            ? undefined
            : this.getItemDisplayName(skillLevelTree[period.skillLevelId]);
        if (name == undefined) return period.skillLevelDisplayName; // not found in tree: return default
        if (name !== period.skillLevelDisplayName) period.skillLevelDisplayName = name; // update default with found name
        return name;
    }

    /**
        * Api url for getting resources, filtering on organization units.
        */
    private apiUrlWithOUFilter: string;

    private apiUrlWithOuFilterIds: string;

    /**
        * Api url for getting the ids of resources filtered on organization unit and resource type.
        */
    private apiUrlWithOuAndTypeFilter: string;

    /**
        * Api url for getting the ids of resources filtered on organization unit and resource type and skill.
        */
    private apiUrlWithOuAndTypeSkillFilter: string;

    /**
        * Api url for getting the ids of resources filtered on resource type.
        */
    private apiUrlWithTypeFilter: string;

    /**
        * Api url for getting resource property names.
        */
    private apiUrlForResourcePropertyNames: string;
    private apiUrlWithoutOrganizationUnits: string;

    /**
        * Returns whether a membership period is valid on or after a given date.
        * @param period The period to check for.
        */
    protected isMembershipInDateFilter(period: any): boolean {
        if (!period) return false;
        if (!period.end) return true; // open interval at the end
        let periodEnd = period.end;
        const filterDate = this.scope.filterPeriodBoundDate;
            
        // Make sure the period is an actual date with no timezone kerfuffle.
        if (periodEnd.getFullYear == undefined) {
            periodEnd = new Date(`${periodEnd}${periodEnd.charAt(periodEnd.length - 1) !== "Z" ? "Z" : ""}`);
        }

        return periodEnd.getTime() > filterDate.getTime();
    }

    /**
        * Returns whether at least one of the specified periods is valid on or after a given date.
        * @param periods The periods to check for.
        */
    protected isAnyMembershipInDateFilter(periods: any[]): boolean {
        if (periods) {
            for (let i = 0; i < periods.length; i++) {
                if (this.isMembershipInDateFilter(periods[i])) return true;
            }
        }
        return false;
    }

    /**
        * Called when the scope is destroyed.
        */
    protected onScopeDestroy(): void {
        this.userService.unregisterUserSettingsEvent("resourcesController");
    }
}