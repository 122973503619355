import { StateService } from '@uirouter/angularjs';
import { INumberService } from './../../../shared/numberService';
import { IPageStartService } from './../../../shared/pageStartService';
import { IUserService } from './../../../shared/userService';

import { ITranslationService } from './../../i18n/translationService';
import { IPermissionService } from './../../permissions/permissionService';

import { EntityTreeHelpers } from './../../treeListController/EntityTreeHelpers';
import { ITreeListScope } from './../../treeListController/ITreeListScope';
import { TreeEntity } from './../../treeListController/TreeEntity';
import { TreeListController } from './../../treeListController/TreeListController';
import { VerificationStatus } from './../../treeListController/TreeListScope';

import { Dictionary } from './../../utils/dictionary';
import { IModalConfirmationWindowService } from './../../utils/modalConfirmationWindowService';

import { OrganizationUnit } from './../organizationUnits/organizationUnit';

import { IResourceTypesScope } from './IResourceTypesScope';
import { ResourceType } from './resourceType';

export class ResourceTypesController extends TreeListController {
    scope: IResourceTypesScope;
    commonSvc;

    static $inject = [
        "$http",
        "$q",
        "$scope",
        "$state",
        "$timeout",
        "$filter",
        "$window",
        "permissionService",
        "modalConfirmationWindowService",
        "translationService",
        "pageStartService",
        "numberService",
        "userService"
    ];

    constructor(
        $http: ng.IHttpService,
        $q: ng.IQService,
        $scope: IResourceTypesScope,
        $state: StateService,
        $timeout: ng.ITimeoutService,
        $filter: ng.IFilterService,
        $window: ng.IWindowService,
        permissionService: IPermissionService,
        modalConfirmationWindowService: IModalConfirmationWindowService,
        translationService: ITranslationService,
        protected pageStartService: IPageStartService,
        protected numberService: INumberService,
        userService: IUserService) {

        super($http, $q, $scope, $state, $timeout, $filter, permissionService, modalConfirmationWindowService, translationService, pageStartService, userService);

        $window.location.href = "/angularomrp/program-management/resource-types";

        this.apiUrl = "api/ResourceTypes";
        this.selectedUnitSetting = "resourceTypes.selectedUnit";
        this.includeChildOrganizationUnitsSetting = "resourceTypes.includeChildUnit";

        this.commonSvc = pageStartService.initialize($scope, null, "ResourceTypes");

        this.scope.relatedSkillDict = new Dictionary();
        this.scope.relatedOrganizationUnitDict = new Dictionary();
        this.scope.userGroupDict = new Dictionary();
        this.scope.filterOrganizationUnitDict = new Dictionary();

        this.userService = userService;

        //See whether the currenly logged in user has the "ResourceTypes" permission and set flags accordingly.
        $scope.verificationStatus = new VerificationStatus();
        permissionService.userHasPermission("ResourceTypes", $scope.verificationStatus, $scope);

        // get data necessary for this model & controller
        this.getRelatedOrganizationUnits();
        this.getEntities();
        this.getRelatedSkills();

        this.getUserGroups();
    }

    /**
        * get the checked state of a node in a tree checklist
        * @param node the node, in this case node is always an organization unit
        */
    protected getCheckListNodeState(node: any): boolean {
        if (this.scope.selectedItem && (this.scope.selectedItem as ResourceType).validOrganizationUnitIds)
            return (this.scope.selectedItem as ResourceType).validOrganizationUnitIds.indexOf(node.id) >= 0;
        return false;
    }

    /**
        * set the checked value of an organization unit and all children of that unit
        */
    protected setCheckValue(node: any, value: boolean) {
        if (node == null || node.maxPermissionForCurrentUser == null || node.maxPermissionForCurrentUser < 2) return;
        let unitList = (this.scope.selectedItem as ResourceType).validOrganizationUnitIds;
        const index = unitList.indexOf(node.id);
        if (index < 0 && value)
            unitList.push(node.id);
        else if (index >= 0 && !value)
            unitList.splice(index, 1);
        if (node.nodes && node.nodes.length > 0)
            for (var i = 0; i < node.nodes.length; i++)
                this.setCheckValue(node.nodes[i], value);
    }

    /**
        * called when a resource type is selected
        */
    protected onEntitySelected() {
        // sort selected skills by displayName
        const resourceType = this.scope.selectedItem as ResourceType;
        if (resourceType.validSkillIds) {
            resourceType.validSkillIds = this.filter("orderBy")(resourceType.validSkillIds, (id: number) => {
                const item = this.scope.relatedSkillDict.value(id);
                return item == null ? null : item.displayName;
            });
        }
    }

    /**
        * will trigger when an entity was restored because an error occured while saving the entity
        */
    protected onEntityRestored() {
        // rebuild the organizationUnitTree to clear weird angular repeater states
        this.scope.organizationUnitTree = [];
        this.timeout(() => {
            this.scope.relatedOrganizationUnitDict.forEach((key, value) => {
                value.nodes = null;
                delete value.$$hashKey;
            });
            this.scope.organizationUnitTree = EntityTreeHelpers
                .entityDictionaryToTree(this.scope.relatedOrganizationUnitDict, this.scope,
                this.setEntityDefaultValues, null) as OrganizationUnit[];
        }, 0);
    }

    /**
        * toggle the checked state of a node in a tree checklist
        * @param node the node, in this case node is always an organization unit
        */
    protected toggleCheckListNode(node: any) {
        if (!this.getCheckListNodeEnabled(node)) return; // test if the node is enabled
        if (!this.isSelectedItemWritable()) return; // no permission to edit this resource type
        let unitList = (this.scope.selectedItem as ResourceType).validOrganizationUnitIds;
        if (!unitList) return;
        this.setCheckValue(node, unitList.indexOf(node.id) < 0);
        this.onEntityChanged(null, null);
    }

    protected getRelatedOrganizationUnits() {
        super.getRelatedOrganizationUnits(() => this.onOrganizationUnitsLoaded(this.scope));
    };

    /**
    * Toggles the permission value for a user group on the scope.
    * Toggle means: set to the input value if the current value is smaller, else set it to the input value - 1.
    * @param userGroupId Id of the user group to toggle the permission value for.
    * @param newPermissionValue The new permission value.
    * @param item Item to set the permissions for.
    * @param setInsteadOfToggle Set to true to always set the new permission value, even if is the same as the actual value.
    * @param dontDiminishPermissions Set to true to only allow granting more rights in child organization units.
    */
    protected toggleUserGroupPermissionForItem(userGroupId: number,
        newPermissionValue: number,
        item: TreeEntity,
        shouldPropagate: boolean,
        userTriggered: boolean = false,
        setInsteadOfToggle: boolean = false,
        dontDiminishPermissions: boolean = false) {

        const oldValue = this.getUserGroupPermissionsForItem(userGroupId, item);

        // If diminishing permissions is not allowed only toggle when the new value is larger than the old one.
        if (!dontDiminishPermissions || newPermissionValue > oldValue) {
            super.toggleUserGroupPermissionForItem(userGroupId, newPermissionValue, item, shouldPropagate, userTriggered, setInsteadOfToggle);
        }
        const resultingValue = this.getUserGroupPermissionsForItem(userGroupId, item);

        // Did we just expand permissions? In that case, only allow expanding permissions in child organization units,
        // so no diminishing rights further down the tree.
        const permissionsExpanded = resultingValue >= oldValue;

        // Additionally, perform the same action for all children of this item.
        if (item.nodes && item.nodes.length > 0) {
            if (userTriggered) {
                this.commonSvc.showYesNoDialog(this.scope.textLabels.ORTANIZATION_UNIT_AFFECT_CHILDREN,
                    this.scope.textLabels.ORGANIZATION_UNIT_AFFECT_CHILDREN_QUESTION,
                    () => { // Yes
                        for (let child of item.nodes) {
                            this.toggleUserGroupPermissionForItem(userGroupId,
                                resultingValue,
                                child,
                                shouldPropagate,
                                false,
                                true,
                                permissionsExpanded);
                            this.onEntityChanged(false, child);
                        }
                    },
                    null);
            } else {
                for (let child of item.nodes) {
                    this.toggleUserGroupPermissionForItem(userGroupId,
                        resultingValue,
                        child,
                        shouldPropagate,
                        false,
                        true,
                        permissionsExpanded);
                    this.onEntityChanged(false, child);
                }
            }
        }
    }

    /**
        * Flag to see if resource types have been loaded. This necessary to see if the filter can be applied.
        */
    private resourceTypesLoaded = false;

    /**
        * Flag to see if organization units have been loaded. This necessary to see if the filter can be applied.
        */
    private organizationUnitsLoaded = false;

    private onOrganizationUnitsLoaded(myScope: IResourceTypesScope) {
        // Organization units may have come in after resource types, in that case, we can now set resource type selectability.
        //console.log("onOrganizationUnitsLoaded()", myScope);
        if (myScope.relatedSkillDict != undefined && myScope.relatedSkillDict.count !== 0) {
            this.setSkillSelectability(myScope.relatedSkillDict, myScope);
        }
        // Convert the organization unit dictionary to a tree.
        myScope.organizationUnitTree = EntityTreeHelpers
            .entityDictionaryToTree(myScope.relatedOrganizationUnitDict, myScope,
            this.setEntityDefaultValues, null) as OrganizationUnit[];

        // Register all organization units as children to their parents. This is needed for being able to filter on units including children.
        this.scope.relatedOrganizationUnitDict.forEach((key, value) => {
            this.registerChildToParent(value, null);
        });

        // Restore settings for selected unit and include children checkbox.
        this.scope.filterOrganizationUnitId = this.userService.getDisplaySettingNumber(this.selectedUnitSetting, 0);
        this.scope.filterOnChildOrganizationUnits = this.userService.getDisplaySettingSwitch(this.includeChildOrganizationUnitsSetting);

        this.organizationUnitsLoaded = true;
        if (this.resourceTypesLoaded) this.filterOrganizationUnitChanged(0);
    }

    /**
        * Triggers when the user makes a different filter selection
        */
    protected filterOrganizationUnitChangedTimed(itemId: number) {
        this.timeout(() => { this.filterOrganizationUnitChanged(itemId); }, 0);
    }

    /**
    * Event handler for a changed organization unit selection. Updates the user group ids for which resource types should be shown.
    * @param itemId Item id to filter on. Set to 0 or leave undefined to have this directly read from the control.
    */
    protected filterOrganizationUnitChanged(itemId: number) {

        if (itemId == undefined || itemId < 1) itemId = this.scope.filterOrganizationUnitId;

        // Set the selected filter organization units to the one selected in the control.
        this.scope.filterOrganizationUnitDict.clear();
        this.scope.filterOrganizationUnitDict.add(itemId, itemId);

        const unit = this.scope.relatedOrganizationUnitDict.value(itemId) as OrganizationUnit;
        if (unit == null) return;

        // Clear the array of recently created entities.
        this.scope.recentlyModifiedEntityIds.clear();

        if (this.scope.filterOnChildOrganizationUnits) {
            // Add any children of the selected unit to the selection.
            for (let childId of unit.childIds) {
                this.scope.filterOrganizationUnitDict.add(childId, childId);
            }
        }

        // Save new selection to user settings.
        this.userService.setDisplaySettingNumber(this.selectedUnitSetting, itemId);
        this.userService.setDisplaySettingSwitch(this.includeChildOrganizationUnitsSetting, this.scope.filterOnChildOrganizationUnits);

        // Recreate the paging.
        this.initActivePage(null, true);
    }

    protected getRelatedSkills() {
        super.getRelatedSkills(() => this.onSkillsLoaded(this.scope));
    };

    private onSkillsLoaded(myScope: IResourceTypesScope) {
        // Organization units are fetched asynchronously, so this may not yet work. In that case, it will be done
        // after fetching the organization units.
        if (myScope.relatedOrganizationUnitDict != undefined && myScope.relatedOrganizationUnitDict.count !== 0) {
            this.setSkillSelectability(myScope.relatedSkillDict, myScope);
        }
    }

    /**
        * Is a specific entity visible, can be used to filter entities.
        */
    protected isEntityVisible(entity: TreeEntity): boolean {
        const resourceType = entity as ResourceType;

        if (this.scope.recentlyModifiedEntityIds.containsKey(entity.id)) return true;

        for (let organizationUnitId of resourceType.validOrganizationUnitIds) {
            if (this.scope.filterOrganizationUnitDict.containsKey(organizationUnitId)) return true;
        }

        return false;
    }

    /**
    * Callback function called after the entityDict dictionary is filled, this callback function will fill the entities array/tree.
    * @param myScope current scope
    */
    protected onEntitiesLoaded(myScope: ITreeListScope) {
        super.onEntitiesLoaded(myScope);

        this.resourceTypesLoaded = true;
        if (this.organizationUnitsLoaded) this.filterOrganizationUnitChanged(0);
    }

    protected isSelectedItemWritable(): boolean {
        if (!this.scope.selectedItem) return false;
        return true;
    }

    protected isSelectedEntityDeletable(): boolean {
        if (!this.scope.selectedItem) return false;

        let item = this.scope.selectedItem as ResourceType;
        //console.log("isSelectedEntityDeletable()", this.scope, item);
        return !item.changeOfDeletabilityPending &&
            !(item.hasDependingActivityTypes || item.hasDependingResources) &&
            (!item.validSkillIds ||
            item.validSkillIds.length === 0);
    }

    /**
    * Returns the title of the modal delete confirmation window.
    * Should be overridden in controllers that make use of this functionality.
    */
    protected getDeleteConfirmationWindowTitle(): string {
        return this.scope.textLabels.RESOURCE_TYPE_DELETION_MODAL_TITLE;
    }

    /**
        * Returns the text of the modal delete confirmation window.
        * Should be overridden in controllers that make use of this functionality.
    */
    protected getDeleteConfirmationWindowText(): string {
        return this.scope.textLabels.RESOURCE_TYPE_DELETION_MODAL_TEXT;
    }

    /**
        * Filter input on text to only accept nummeric values.
        */
    protected filterTextValue($event, oldValue, allowDecimal) {
        this.numberService.filterTextValue($event, oldValue, allowDecimal, null);
    }

    protected newEntityDisplayName() {
        return this.scope.textLabels.NEW_RESOURCE_TYPE_DISPLAY_NAME;
    }

    /**
        * Creates a new entity object that can be inserted.
        */
    protected createNewEntity(): TreeEntity {
        const entity = new ResourceType();
        entity.id = -1;
        entity.displayName = this.newEntityDisplayName();
        entity.maximumOccupation = 1;
        entity.receivedOrder = 0; // value of exactly 0 avoids sorting in the sortLastEntity function
        return entity;
    }

    private selectedUnitSetting: string;
    private includeChildOrganizationUnitsSetting: string;

    /**
        * Determine whether skills are selectable (i.e.: minimally write permission for one of the related organization units)
        * and set the selectable flags accordingly.
        * @param skillDict
        * @param myScope
        */
    setSkillSelectability(skillDict: Dictionary, myScope: IResourceTypesScope) {
        //console.log("Org unit dict", myScope.relatedOrganizationUnitDict);
        skillDict.forEach((key, value) => {
            //console.log("Skill selectability", key, value);

            let validOrganizationUnits: OrganizationUnit[] = [];
            let orgUnitIds = value.validOrganizationUnitIds;

            for (let i = 0; i < orgUnitIds.length; i++) {
                validOrganizationUnits[i] = this.scope.relatedOrganizationUnitDict.value(orgUnitIds[i]);
            }

            value.selectable = this.hasAtLeastPermissionOnOrganizationUnit(validOrganizationUnits, 2);
        });
    }
}