/**
    * class with WebApi calls
    */
export class WebApi {
    /**
        * usage: WebApi.post("Login", object, (response) => { console.log(response); });
        * @param url the url to get
        * @param postData the data to post
        * @param action the callback action to call when a response is received
        * @deprecated This should be overridden by an actual implementation when used in an AngularJS controller.
        */
    static post(url: string, postData: any, action: (response: any) => void, errorAction: (response: any) => void) {
        var xhr = new XMLHttpRequest();
        xhr.open("POST", url);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Accept", "text/html,application/json,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8"); // see: http://stackoverflow.com/questions/12904972/mvc-4-web-api-and-json
        xhr.onload = () => {
            if (xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);
                action(response);
            } else if (xhr.status >= 400) {
                console.log(`WebApi post response status = ${xhr.status}`);
                errorAction(xhr);
            }
        };
        xhr.onerror = (ev: Event) => {
            console.log("WebApi post error", ev);
            errorAction(xhr);
        }
        xhr.send(JSON.stringify(postData));
    }

    /**
        * usage: WebApi.get("http://localhost:57266/api/objectToGet", (response) => { console.log(response); });
        * @param url the url to get
        * @param action the callback action to call when a response is received
        * @deprecated This should be overridden by an actual implementation when used in an AngularJS controller.
        */
    static get(url: string, action: (response: any) => void, errorAction: (response: any) => void) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Accept", "text/html,application/json,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8"); // see: http://stackoverflow.com/questions/12904972/mvc-4-web-api-and-json
        xhr.onload = () => {
            if (xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);
                action(response);
            } else if (xhr.status >= 400) {
                console.log(`WebApi get response status = ${xhr.status}`);
                errorAction(xhr);
            }
        };
        xhr.onerror = (ev: Event) => {
            console.log("WebApi get error", ev); 
            errorAction(xhr);
        }
        xhr.send();
    }

    /**
        * usage: WebApi.delete("http://localhost:57266/api/objectToDelete", (response) => { console.log(response); });
        * @param url the url to delete
        * @param action the callback action to call when a response is received
        * @deprecated This should be overridden by an actual implementation when used in an AngularJS controller.
        */
    static delete(url: string, action: (response: any) => void, errorAction: (response: any) => void) {
        var xhr = new XMLHttpRequest();
        xhr.open("DELETE", url);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.setRequestHeader("Accept", "text/html,application/json,application/xhtml+xml,application/xml;q=0.9,*/*;q=0.8"); // see: http://stackoverflow.com/questions/12904972/mvc-4-web-api-and-json
        xhr.onload = () => {
            if (xhr.status === 200 || xhr.status === 204) {
                const response = JSON.parse(xhr.responseText);
                action(response);
            } else if (xhr.status >= 400) {
                console.log(`WebApi delete response status = ${xhr.status}`);
                errorAction(xhr);
            }
        };
        xhr.onerror = (ev: Event) => {
            console.log("WebApi delete error", ev); 
            errorAction(xhr);
        }
        xhr.send();
    }
}