import { ITranslationService } from './../i18n/translationService';
import { ITreeListScope } from './../treeListController/ITreeListScope';
import { IAuthenticationService } from './authenticationService';

export class AuthorizeController {
    message: string;
    showAlert: boolean;
    loginBusy: boolean;

    static $inject = [
        "$scope",
        "$location",
        "authenticationService",
        "translationService"
    ];

    constructor(
        public $scope: ITreeListScope,
        $location: ng.ILocationService,
        authenticationService: IAuthenticationService,
        private translationService: ITranslationService
        ) {

        var self = this;
        
        self.translationService.getTextLabels(this.$scope);

        self.showAlert = false;
        self.loginBusy = true;

        var auth0Params = $location.search();

        authenticationService.loginWithAuth0Token(auth0Params.code, `${window.location.protocol}//${window.location.host}`)
            .then((userId: string) => {
                $location.url(auth0Params.state == null ? "/" : decodeURIComponent(auth0Params.state));
                self.loginBusy = false;
                self.translationService.switchToUserLanguage();
            }, (message: any) => {
                self.message = message;
                self.showAlert = true;
                self.loginBusy = false;
            });
    }
}