import { isDictionary } from './../../app/components/utils/dictionary';
import * as Globals from './../../app/components/planboard/utils/globals';

export var omrpDropdownFilter = [
    "$rootScope", "$timeout",
    function ($rootScope, $timeout) {
        return {
            // can be used as an attribute or element or class
            restrict: "AEC",

            template: require("../template/dropdownFilter.html"),

            scope: {
                allitems: "=", // the list (dictionary, object, array) of all items
                autosizewidth: "@", // true or false, indicates if the dropdown list will be the same width as the filter
                changedisabled: "=", // indicates if the dropdown is in disabled mode
                customheightpx: "=",
                customwidthpx: "=",
                displayproperty: "@", // the property name of an individual item that will be used to display a text
                enabledproperty: "@", // the property name of an individual item that will determine if the item can be changed
                getallitems: "&?", // callback function the get all items trough a function instead of the bound allitems property
                getdefaulttext: "&?", // callback function to call for the currently selected item, if the item does not exist in allitems
                gettexttoomanyitems: "&?", // callback function to return the text to show for too many items
                itemsversion: "=", // when this variable changes the dropdown will rebuild the item tree
                itemvisible: "&?", // the callback function to determine if an individual item is visible
                noselectionid: "=", // the id of the item that indicates no selection
                ondropdown: "&?", // callback function to call after the dropdown is shown or hidden with visible as parameter
                selchanged: "&", // callback function to call when the selected item is changed
                selectedid: "=", // the id of the selected item
                showspinner: "=", // true or false, indicates if the animated wait spinner should be visible
                visibleproperty: "@" // whether the item is visible in the dropdown or not
            },

            link: function (scope, element) {
                scope.additionalHiddenItems = false; // if the hint should be shown that there are more hidden items
                scope.dict = null;
                scope.dropDownClicked = false; // indicates that the tree has been dropped down at least once
                scope.dropdownLeft = "0px";
                scope.dropdownTop = "0px";
                scope.dropdownWidth = "250px";
                scope.dropdownHeight = "200px";
                scope.dropdownVisible = false;
                scope.dropdownvisibility = "visible";
                scope.firstitemid = null;
                scope.highlightedItemIndex = -1;
                scope.itemCount = 0; // the total number of items
                scope.lastDictVersion = -1;
                scope.lastGetItem = null;
                scope.maxVisibleItems = 256; // the maximum number of visible items
                scope.newVisibleidlist = [];
                scope.selectedchanged = false;
                scope.textfilter = "";
                scope.textwidth = "7em";
                scope.tree = Object.create(null);
                scope.triggerElement = element[0];
                scope.visibleidlist = [];

                var itemHeight = 0; // height of an item element

                scope.triggerDropdownChanged = function () {
                    if (scope.ondropdown != undefined)
                        scope.ondropdown({ visible: scope.dropdownVisible });
                }

                // set internal scope.dict variable to either scope.allitems or the dictionary object in scope.allitems, return true if it is not undefined
                scope.initializeDict = function () {
                    var items = scope.allitems;
                    if (scope.getallitems != undefined && scope.dropDownClicked)
                        items = scope.getallitems();
                    if (isDictionary(items)) { // dictionary
                        scope.dict = items.getStore();
                        scope.lastDictVersion = items.version;
                    } else // object
                        scope.dict = items;
                    return (scope.dict != undefined);
                }

                // recreate the items dropdowntree
                scope.refreshItems = function () {
                    scope.lastGetItem = null;
                    scope.firstitemid = null;
                    scope.initializeDict();
                    if (scope.dropdownVisible) {
                        scope.sanitizeAllItems();
                        scope.buildVisibleList(scope.firstitemid);
                        scope.visibleidlist = scope.newVisibleidlist;
                        scope.repositionDropdown();
                    }
                }

                // recreate the items dropdowntree if version changes
                scope.$watch("itemsversion", function (value) { scope.refreshItems(); });

                // recreate the items dropdowntree if allitems changes
                scope.$watch("allitems", function (value) { scope.refreshItems(); });

                scope.$watch("textfilter", function (value) {
                    if (scope.textfilter !== "" && !scope.dropdownVisible) scope.show();
                    scope.buildVisibleList(scope.firstitemid);
                    scope.visibleidlist = scope.newVisibleidlist;
                    scope.repositionDropdown();
                });

                // computes the nextId and childId properties of items in scope.allitems
                scope.sanitizeAllItems = function () {
                    scope.firstitemid = null;
                    scope.lastGetItem = null;
                    if (!scope.initializeDict()) return;
                    var lastRootId = null;
                    var key = -1;
                    var index = -1;
                    var item = null;
                    var treeItem = null;
                    var orderedItems = [];
                    scope.tree = Object.create(null);
                    for (<any>key in scope.dict) {
                        if (scope.dict.hasOwnProperty == undefined || scope.dict.hasOwnProperty(key)) {
                            item = scope.dict[key];
                            treeItem = {
                                nextId: null,
                                childId: null,
                                lastChildId: null,
                                open: item.open == undefined ? false : item.open,
                                parentId: item.parentId,
                                order: item.order || item.receivedOrder || 0
                            };
                            scope.tree[key] = treeItem;
                            // remember and sort based upon the items order property
                            orderedItems.push(key);
                            index = orderedItems.length - 1;
                            while (index > 0 && treeItem.order < scope.tree[orderedItems[index - 1]].order) {
                                orderedItems[index] = orderedItems[index - 1];
                                orderedItems[index - 1] = key;
                                index--;
                            }
                        }
                    }
                    for (var i = 0; i < orderedItems.length; i++) {
                        key = orderedItems[i];
                        item = scope.tree[key];
                        if (!item.parentId || item.parentId < 0 || !scope.tree[item.parentId]) {
                            if (scope.firstitemid == null) scope.firstitemid = key;
                            if (lastRootId != null) scope.tree[lastRootId].nextId = key;
                            lastRootId = key;
                        }
                        if (item.parentId && scope.tree[item.parentId]) {
                            var parent = scope.tree[item.parentId];
                            if (parent.lastChildId)
                                scope.tree[parent.lastChildId].nextId = key;
                            else
                                parent.childId = key;
                            parent.lastChildId = key;
                        }
                    }
                }

                // build an array of all visible items, based upon the open state of each item
                scope.buildVisibleList = function (itemid) {
                    if (scope.firstitemid == null || itemid === scope.firstitemid) scope.newVisibleidlist = [];
                    if (!scope.initializeDict()) return;
                    var item = scope.tree[itemid];
                    while (item != undefined) {
                        if (scope.isItemVisible(itemid)) {
                            scope.newVisibleidlist.push(itemid);
                            if (item.open && item.childId)
                                scope.buildVisibleList(item.childId);
                        }
                        if (item.nextId) {
                            itemid = item.nextId;
                            item = scope.tree[itemid];
                        } else
                            item = undefined;
                    }
                }

                scope.isItemVisible = function (itemId) {
                    itemId = parseInt(itemId);
                    if (scope.itemvisible != undefined)
                        if (!scope.itemvisible({ itemId: itemId })) return false;
                    if (scope.getItemIsLeaf(itemId)) {
                        var filter = scope.textfilter.trim().toLowerCase();
                        if (filter !== "") {
                            if (scope.getItemText(itemId).trim().toLowerCase().indexOf(filter) < 0) return false;
                        }
                    }
                    return true;
                }

                scope.makeItemVisible = function (itemid) {
                    if (!scope.initializeDict()) return;
                    var item = scope.tree[itemid];
                    while (item) {
                        item.open = true;
                        item = scope.tree[item.parentId];
                    }
                }

                scope.toggleItemOpen = function (itemid) {
                    if (scope.noselectionid && parseInt(itemid) == parseInt(scope.noselectionid)) {
                        scope.selectItem(itemid);
                        return;
                    }
                    if (!scope.initializeDict()) return;
                    var item = scope.tree[itemid];
                    if (item && item.childId && scope.tree[item.childId]) {
                        item.open = !item.open;
                        scope.buildVisibleList(scope.firstitemid);
                        scope.visibleidlist = scope.newVisibleidlist;
                        scope.repositionDropdown();
                    }
                }

                scope.selectItem = function (itemid) {
                    if (!scope.initializeDict()) return;
                    // Future TODO: item could have an id higher than maxInt. Consider a different solution
                    if (itemid >= Globals.maxInt) return;// Return if itemId is a section header (e.g. Activity, Daymark)
                    scope.selectedid = parseInt(itemid);
                    if (scope.selchanged != undefined) scope.selchanged({ itemId: scope.selectedid });
                    scope.dropdownVisible = false;
                    // stop listening for keyboard input / re-enable scroll
                    window.removeEventListener("keydown", keyboardInputHandler, false);
                    scope.triggerDropdownChanged();
                }

                scope.getItemOrNull = function (itemid) {
                    if (scope.lastGetItem && scope.lastGetItem.id === itemid) return scope.lastGetItem;
                    scope.lastGetItem = scope.initializeDict() ? scope.dict[itemid] : null;
                    return scope.lastGetItem;
                }

                scope.focusInput = function () {
                    var inputelement = $(scope.triggerElement).find(".omrptextfilter");
                    (inputelement[0] as any).focus();
                }

                scope.getItem = function (itemid) {
                    scope.getItemOrNull(itemid);
                    if (!scope.lastGetItem) scope.lastGetItem = {};
                    return scope.lastGetItem;
                }

                scope.getItemEnabled = function (itemid) {
                    var item = scope.getItemOrNull(itemid);
                    if (!item) return false;
                    if (scope.enabledproperty && scope.enabledproperty !== "")
                        if (item[scope.enabledproperty] != undefined) return item[scope.enabledproperty];
                    if (item.enabled != undefined) return item.enabled;
                    return true;
                }

                // Returns if the item is highlighted (for formatting)
                scope.getItemHighlighted = function (itemIndex) {
                    if (itemIndex === scope.highlightedItemIndex)
                        return true;
                    return false;
                }

                // Sets scope.highlightedItemIndex to the provided itemIndex (used for mouse over highlighting)
                scope.setItemHighlighted = function (itemIndex) {
                    $timeout(function () {
                        scope.highlightedItemIndex = itemIndex;
                    }, 0);
                }

                // Get the id of the html element or give the html element an unique id if it has none
                scope.getTriggerElementId = function () {
                    if (scope.triggerElement.id === "") scope.triggerElement.id = "genId" + Math.floor(Math.random() * Globals.maxInt53);
                    return scope.triggerElement.id;
                }

                scope.getItemVisible = function (itemid) {
                    var item = scope.getItemOrNull(itemid);
                    if (!item) return false;
                    if (scope.visibleproperty && scope.visibleproperty !== "")
                        if (item[scope.visibleproperty] != undefined) return item[scope.visibleproperty];
                    return true;
                }

                scope.getItemOpen = function (itemid) {
                    var item = scope.tree[itemid];
                    return (item && item.open && item.childId && scope.tree[item.childId]);
                }

                scope.getItemIsLeaf = function (itemid) {
                    var item = scope.tree[itemid];
                    return (item && (!item.childId || !scope.tree[item.childId]));
                }

                scope.getItemHasParent = function (itemid) {
                    var item = scope.tree[itemid];
                    return (item && item.parentId && item.parentId >= 0);
                }

                scope.getItemIndent = function (itemid) {
                    var indent = 0;
                    var item = scope.tree[itemid];
                    while (item && indent < 20) {
                        if (!item.childId || !scope.tree[item.childId]) indent--;
                        item = scope.tree[item.parentId];
                        if (item) indent++;
                    }
                    if (indent < 0) indent = 0;
                    return "" + indent + "em";
                }

                scope.getItemText = function (itemid) {
                    var item = scope.getItemOrNull(itemid);
                    if (!item) {
                        if (scope.getdefaulttext != undefined)
                            return scope.getdefaulttext({ id: itemid });
                        return "";
                    }
                    if (scope.displayproperty && scope.displayproperty !== "")
                        return item[scope.displayproperty];
                    return item.text;
                }

                scope.pxToInt = function (pxvalue) {
                    return parseInt(pxvalue.substring(0, pxvalue.length - 2));
                }

                scope.repositionDropdown = function () {
                    $timeout(function () {
                        if (!scope.dropdownVisible) return;
                        if (!scope.dropdownElement) return;
                        if (!scope.displayElement) return;

                        var rectDropdown = scope.dropdownElement.getBoundingClientRect();
                        var rect = scope.displayElement.getBoundingClientRect();
                        var leftPos = rect.left;
                        var topPos = rect.bottom - 1;
                        if (topPos + rectDropdown.height >= window.innerHeight) {
                            topPos = Math.max(rect.top - rectDropdown.height, 0);
                        }
                        if (scope.autosizewidth) {
                            // make the dropdownElement just as wide as the triggerElement
                            scope.dropdownWidth = "" + rect.width + "px";
                        }
                        if (leftPos + scope.pxToInt(scope.dropdownWidth) >= window.innerWidth) {
                            leftPos = Math.max(window.innerWidth - scope.pxToInt(scope.dropdownWidth), 0);
                        }
                        leftPos = Math.round(leftPos + window.pageXOffset);
                        topPos = Math.round(topPos + window.pageYOffset);
                        scope.dropdownLeft = "" + leftPos + "px";
                        scope.dropdownTop = "" + topPos + "px";
                        scope.dropdownvisibility = "visible";
                    }, 1);
                }

                scope.clear = function () {
                    scope.selectItem(-1);
                }

                scope.show = function () {
                    if (scope.changedisabled) return;
                    if (!scope.dropdownElement) {
                        // find the dropdown-list and move it to the end of the document body
                        // this is necessary so it wont be restricted by a parents bounding div element
                        scope.displayElement = scope.triggerElement.firstElementChild.firstElementChild;
                        scope.dropdownElement = scope.triggerElement.firstElementChild.lastElementChild;
                        scope.dropdownElement = document.body.appendChild(scope.dropdownElement);
                    }

                    // Listen for keyboard input
                    window.addEventListener("keydown", keyboardInputHandler, false);

                    // Set the highlightedItemIndex to the currently selected item, or -1
                    $timeout(function () {
                        scope.highlightedItemIndex = scope.visibleidlist.indexOf(String(scope.selectedid));
                        scrollIfNeeded(scope.highlightedItemIndex, "UP");
                    }, 0);

                    scope.dropDownClicked = true;
                    // initialize, if either sanitizeAllItems has not been called before (firstitemid == null) or always if getallitems callback function is specified
                    if (scope.firstitemid == null || scope.getallitems != undefined)
                        scope.sanitizeAllItems();
                    else if (isDictionary(scope.allitems) && scope.lastDictVersion !== scope.allitems.version) {
                        // dictionary has different version
                        scope.sanitizeAllItems();
                    }

                    scope.makeItemVisible(scope.selectedid);
                    scope.buildVisibleList(scope.firstitemid);
                    scope.visibleidlist = scope.newVisibleidlist;
                    if (scope.customwidthpx) scope.dropdownWidth = "" + scope.customwidthpx + "px";
                    if (scope.customheightpx) scope.dropdownHeight = "" + scope.customheightpx + "px";

                    // position the dropdownElement near triggerElement
                    var rect = scope.displayElement.getBoundingClientRect();
                    var leftPos = rect.left;
                    var topPos = rect.bottom - 1;
                    if (topPos + scope.pxToInt(scope.dropdownHeight) >= window.innerHeight) {
                        topPos = Math.max(rect.top - scope.pxToInt(scope.dropdownHeight), 0);
                        scope.dropdownvisibility = "hidden";
                    }
                    if (scope.autosizewidth) {
                        // make the dropdownElement just as wide as the triggerElement
                        scope.dropdownWidth = "" + rect.width + "px";
                    }
                    if (leftPos + scope.pxToInt(scope.dropdownWidth) >= window.innerWidth) {
                        leftPos = Math.max(window.innerWidth - scope.pxToInt(scope.dropdownWidth), 0);
                    }
                    leftPos = Math.round(leftPos + window.pageXOffset);
                    topPos = Math.round(topPos + window.pageYOffset);
                    scope.dropdownLeft = "" + leftPos + "px";
                    scope.dropdownTop = "" + topPos + "px";

                    // toggle visibility of the dropdownElement
                    scope.dropdownVisible = !scope.dropdownVisible;
                    if (!scope.dropdownVisible) {
                        // stop listening for keyboard input / re-enable scroll
                        window.removeEventListener("keydown", keyboardInputHandler, false);
                    }
                    scope.triggerDropdownChanged();
                    scope.repositionDropdown();
                };

                // Event handler for Keyboard input
                // Arrow keys, Tab, Enter, Escape
                function keyboardInputHandler(e) {
                    // event.keyCode is deprecated. If it doesn't exist, use event.key.
                    var keyCode = e.keyCode;
                    if (keyCode === undefined || keyCode === 0)
                        keyCode = e.key;

                    // handle keys
                    switch (keyCode) {
                        case 9:
                        case "Tab":
                            e.preventDefault();
                            onTabKeyPress();
                            break;
                        case 13:
                        case "Enter":
                            e.preventDefault();
                            onEnterKeyPress();
                            break;
                        case 27:
                        case "Escape":
                        case "Esc":
                            e.preventDefault();
                            onEscapeKeyPress();
                            break;
                        case 37:
                        case "ArrowLeft":
                        case "Left":
                            onArrowLeftKeyPress();
                            break;
                        case 38:
                        case "ArrowUp":
                        case "Up":
                            e.preventDefault();
                            onArrowUpKeyPress();
                            break;
                        case 39:
                        case "ArrowRight":
                        case "Right":
                            onArrowRightKeyPress();
                            break;
                        case 40:
                        case "ArrowDown":
                        case "Down":
                            e.preventDefault();
                            onArrowDownKeyPress();
                            break;
                        default:
                            break;
                    }
                }

                // Handle Up Arrow key press
                // Allows user to navigate up through the dropdown items
                function onArrowUpKeyPress() {
                    if (scope.highlightedItemIndex > 0) {
                        scope.$apply(function () {
                            scope.highlightedItemIndex -= 1;
                        });
                    }
                    scrollIfNeeded(scope.highlightedItemIndex, "UP");
                }

                // Handle Down Arrow key press
                // Allows user to navigate down through the dropdown items
                function onArrowDownKeyPress() {
                    if (scope.highlightedItemIndex < scope.visibleidlist.length - 1) {
                        scope.$apply(function () {
                            scope.highlightedItemIndex += 1;
                        });
                    }
                    scrollIfNeeded(scope.highlightedItemIndex, "DOWN");
                }

                // Handle Enter key press
                // Selects the currently highlighted item and closes the dropdown
                function onEnterKeyPress() {
                    if (scope.highlightedItemIndex > -1 && scope.highlightedItemIndex < scope.visibleidlist.length) {
                        var itemId = scope.visibleidlist[scope.highlightedItemIndex];
                        scope.selectItem(itemId);
                    }
                }

                // Handle Tab key press
                function onTabKeyPress() {
                    // Future TODO: handle tab key press?
                    // If they are using keyboard to scroll through dropdown, perhaps they're using tab to go to next form item
                    // Should this select the item and move to the next form element?
                }

                // Handle Escape key press
                // Closes the dropdown without changing the selection
                function onEscapeKeyPress() {
                    $timeout(function () {
                        scope.dropdownVisible = false;
                        // stop listening for keyboard input / re-enable scroll
                        window.removeEventListener("keydown", keyboardInputHandler, false);
                        scope.triggerDropdownChanged();
                    }, 0);
                }

                // Handle Left Arrow key press
                function onArrowLeftKeyPress() {
                    // Future TODO: collapse a tree node
                }

                // Handle Right Arrow key press
                function onArrowRightKeyPress() {
                    // Future TODO: expand a tree node
                }

                // Performs the provided scroll action if needed
                function scrollIfNeeded(itemIndex, scrollDirection) {
                    // Escape early if there is no overflow or if itemIndex is < 0
                    if (scope.dropdownElement.clientHeight >= scope.dropdownElement.scrollHeight || itemIndex < 0) return;
                    // Get an item element to calculate the height if it hasn't been calculated before
                    if (itemHeight <= 0) {
                        var firstItemElement = scope.dropdownElement.firstElementChild.firstElementChild;
                        itemHeight = firstItemElement.offsetHeight;
                    }
                    // Calculate the item top and bottom positions relative to the entire item list container
                    // +1 to account for the 1 pixel space between items (potentially due to a return character)
                    var itemTop = itemIndex * (itemHeight + 1);
                    var itemBottom = itemTop + itemHeight;

                    // Calculate the approximate height of the dropdown.
                    // Removing 2X the vertical padding gives us a height that is a bit smaller than the visible area
                    // When only removing 1X the vertical padding, the height was a bit bigger than the visible area which clipped the bottom highlighted item
                    var computedStyle = getComputedStyle(scope.dropdownElement);
                    var dropdownHeight = scope.dropdownElement.clientHeight -
                        2 * (parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom));

                    // Calculate the top and bottom positions of the visible view
                    var dropdownTop = scope.dropdownElement.scrollTop;
                    var dropdownBottom = dropdownTop + dropdownHeight;

                    if (itemTop < dropdownTop || itemBottom > dropdownBottom) {
                        if (scrollDirection === "UP")
                            scrollUpTo(itemTop);
                        else if (scrollDirection === "DOWN")
                            scrollDownTo(itemBottom, dropdownHeight);
                    }
                }

                // Set the scrollTop to the correct value so that the item will be at the top of the dropdown
                function scrollUpTo(itemTop) {
                    scope.dropdownElement.scrollTop = itemTop;
                }

                // Set the scrollTop to the correct value so that the item will be at the bottom of the dropdown
                function scrollDownTo(itemBottom, dropdownHeight) {
                    scope.dropdownElement.scrollTop = itemBottom - dropdownHeight;
                }

                scope.$on("$destroy", function () {
                    // if the dropdownElement was attached to the body, we also need to remove it when the scope is destroyed
                    if (scope.dropdownElement) document.body.removeChild(scope.dropdownElement);
                });

                $rootScope.$on("documentClicked", function (inner, target) {
                    if (!scope.dropdownVisible) return; // exit early if the dropdown is not visible
                    // check if the clicked on control has a specific class or has any parent with that specific class, then do not close the dropdown
                    var parents = $(target[0]).parents();
                    for (var i = 0; i < parents.length; i++)
                        if (parents[i] === scope.triggerElement) return;
                    if (!$(target[0]).is(".ignore-document-clicked") &&
                        !(<any>($(target[0]).parents(".ignore-document-clicked").length) > 0))
                        scope.$apply(function () {
                            scope.dropdownVisible = false;
                            // stop listening for keyboard input / re-enable scroll
                            window.removeEventListener("keydown", keyboardInputHandler, false);
                            scope.triggerDropdownChanged();
                        });
                });
            }
        };
    }
];