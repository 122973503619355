import { TreeEntity } from './../../treeListController/TreeEntity';

export class ResourceType extends TreeEntity {

    /**
        * Organization units for which this skill is valid.
        */
    validOrganizationUnitIds: number[];

    /**
        * Skills for which this resource type is valid.
        */
    validSkillIds: number[];

    /**
        * Whether this skill has any depending activity types.
        */
    hasDependingActivityTypes: boolean;

    /**
        * Whether this skill has any depending resources.
        */
    hasDependingResources: boolean;

    /**
        * Whether this resource type is selectable in tag lists. Used on pages for other entities that can be coupled to resource types.
        */
    selectable: boolean;

    /**
        * Maximum occupation for this resource type
        */
    maximumOccupation: number;
};