export const userGroupPermissionsTable: angular.IComponentOptions = {
    template: require("./userGroupPermissionsTable.html"),
    controller: userGroupPermissionsTableController,
    bindings: {
        userGroupList: "<",
        canEdit: "<",
        permissionList: "<",
        nameHeading: "@",
        readHeading: "@",
        writeHeading: "@",
        ownerHeading: "@?",
        permissionChangeCallback: "&"
    }
}
userGroupPermissionsTableController.$inject = ["$scope", "translationService", "pageStartService"];
function userGroupPermissionsTableController($scope, translationService, pageStartService) {
    translationService.getTextLabels($scope);
    this.commonSvc = pageStartService.initialize($scope, null, "ug-permissions");
    this.rememberDialog = false;
    this.shouldPropagate = false;

    this.onCallback = (userGroupId: number, permission: number, newPermission: number, shouldShowDialog: boolean) => {
        if (shouldShowDialog && !this.rememberDialog) {
            this.commonSvc.showModalQuestionDialog($scope.textLabels.PROPAGATE_PERMISSION_TITLE,
                $scope.textLabels["PROPAGATE_PERMISSION_" + newPermission.toString()],
                [$scope.textLabels.YES, $scope.textLabels.YES_REMEMBER, $scope.textLabels.NO, $scope.textLabels.NO_REMEMBER],
                [
                    () => { // Yes
                        this.shouldPropagate = true;
                        this.permissionChangeCallback({
                            userGroupId: userGroupId,
                            permission: permission,
                            shouldPropagate: this.shouldPropagate,
                            userTriggered: true
                        });
                    },
                    () => { // Yes & Remember
                        this.shouldPropagate = true;
                        this.rememberDialog = true;
                        this.permissionChangeCallback({
                            userGroupId: userGroupId,
                            permission: permission,
                            shouldPropagate: this.shouldPropagate,
                            userTriggered: true
                        });
                    },
                    () => { // No
                        this.shouldPropagate = false;
                        this.permissionChangeCallback({
                            userGroupId: userGroupId,
                            permission: permission,
                            shouldPropagate: this.shouldPropagate,
                            userTriggered: true
                        });
                    },
                    () => { // No & remember
                        this.shouldPropagate = false;
                        this.rememberDialog = true;
                        this.permissionChangeCallback({
                            userGroupId: userGroupId,
                            permission: permission,
                            shouldPropagate: this.shouldPropagate,
                            userTriggered: true
                        });
                    }
                ]);
        } else {
            this.permissionChangeCallback({
                userGroupId: userGroupId,
                permission: permission,
                shouldPropagate: this.shouldPropagate,
                userTriggered: true
            });
        }
    }

    this.$onChanges = (changes) => {
        this.rememberDialog = false;
        this.shouldPropagate = false;
    }
}