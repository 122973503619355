import { StateService } from '@uirouter/angularjs';
import { IPageStartService } from './../../../shared/pageStartService';
import { IUserService } from './../../../shared/userService';

import { ITranslationService } from './../../i18n/translationService';
import { IPermissionService } from './../../permissions/permissionService';

import { TreeEntity } from './../../treeListController/TreeEntity';
import { TreeListController } from './../../treeListController/TreeListController';
import { VerificationStatus } from './../../treeListController/TreeListScope';

import { Dictionary } from './../../utils/dictionary';
import { IModalConfirmationWindowService } from './../../utils/modalConfirmationWindowService';

import { OrganizationUnit } from './../organizationUnits/organizationUnit';

import { ISkillLevelsScope } from './ISkillLevelsScope';
import { SkillLevel } from './skillLevel';

export class SkillLevelsController extends TreeListController {
    scope: ISkillLevelsScope;

    // variables for adding a new skill level with the appropriate level
    lowestReceivedOrder = -1; // when adding a new skill level it should be placed on top
    highestLevel = 1; // when adding a new skill level it should have the highest level

    static $inject = [
        "$http",
        "$q",
        "$scope",
        "$state",
        "$timeout",
        "$filter",
        "$window",
        "permissionService",
        "modalConfirmationWindowService",
        "translationService",
        "pageStartService",
        "userService"
    ];

    constructor(
        $http: ng.IHttpService,
        $q: ng.IQService,
        $scope: ISkillLevelsScope,
        $state: StateService,
        $timeout: ng.ITimeoutService,
        $filter: ng.IFilterService,
        $window: ng.IWindowService,
        permissionService: IPermissionService,
        modalConfirmationWindowService: IModalConfirmationWindowService,
        translationService: ITranslationService,
        protected pageStartService: IPageStartService,
        protected userService: IUserService) {

        super($http, $q, $scope, $state, $timeout, $filter, permissionService, modalConfirmationWindowService, translationService, pageStartService, userService);

        $window.location.href = "/angularomrp/program-management/skill-levels";

        this.apiUrl = "api/SkillLevels";
        this.scope.relatedOrganizationUnitDict = new Dictionary();

        //See whether the currenly logged in user has the "Skills" permission and set flags accordingly.
        $scope.verificationStatus = new VerificationStatus();
        permissionService.userHasPermission("SkillLevels", $scope.verificationStatus, $scope);

        // get data necessary for this model & controller
        this.getRelatedOrganizationUnits();
        this.getEntities();
    }

    /**
        * Triggers when the user selects a different organization unit to filter
        */
    protected filterOrganizationUnitChanged(itemId: number) {
        this.scope.selectedItem = null; // clear the selected item

        if (this.userService.userSettingsLoaded())
            this.userService.setDisplaySettingNumber("skillLevels.filterOrganizationUnitId", itemId);
    }

    /**
        * Is a specific entity visible, can be used to filter entities.
        */
    protected isEntityVisible(entity: TreeEntity): boolean {
        const item = entity as SkillLevel;
        return this.scope.filterOrganizationUnitId < 0 ||
            this.scope.filterOrganizationUnitId === item.organizationUnitId;
    }

    protected getRelatedOrganizationUnits() {
        super.getRelatedOrganizationUnits(() => this.onOrganizationUnitsLoaded(this.scope));
    }

    /**
        * Convert the loaded organization units dictionary into an object for the tree dropdown.
        */
    private onOrganizationUnitsLoaded(myScope: ISkillLevelsScope) {
        myScope.filterOrganizationUnitId = -1;
        myScope.organizationUnits = Object.create(null);
        myScope.relatedOrganizationUnitDict.forEach((key, value) => {
            const orgUnit = value as OrganizationUnit;
            orgUnit.selectable = (orgUnit.maxPermissionForCurrentUser >= 2);
            myScope.organizationUnits[key] = orgUnit;
            if (orgUnit.selectable && (orgUnit.id < myScope.filterOrganizationUnitId || myScope.filterOrganizationUnitId < 0))
                myScope.filterOrganizationUnitId = orgUnit.id;
        });
        if (this.userService.userSettingsLoaded())
            myScope.filterOrganizationUnitId = this.userService.getDisplaySettingNumber("skillLevels.filterOrganizationUnitId", myScope.filterOrganizationUnitId);
    }

    /**
        * The place of a specific entity in the tree can change.
        */
    protected isEntitySortable(entity: TreeEntity): boolean {
        return true;
    }

    /**
        * Swap the place of two entities.
        */
    protected swapEntityOrder(index1: number, index2: number) {
        const entity1 = this.scope.entities[index1] as SkillLevel;
        const entity2 = this.scope.entities[index2] as SkillLevel;
        const entity1Level = entity1.level;
        entity1.level = entity2.level;
        entity2.level = entity1Level;
        this.scope.entities[index1] = entity2;
        this.scope.entities[index2] = entity1;
        this.addChange(entity1.id);
        this.addChange(entity2.id);
    }

    /**
        * See if the selected item is writable.
        */
    protected isSelectedItemWritable(): boolean {
        if (!this.scope.selectedItem) return false;

        const item = this.scope.selectedItem as SkillLevel;
        const orgUnit = this.scope.relatedOrganizationUnitDict.value(item.organizationUnitId);
        return orgUnit && orgUnit.maxPermissionForCurrentUser >= 2;
    }

    /**
        * See if the selected item is deletable.
        */
    protected isSelectedEntityDeletable(): boolean {
        if (!this.scope.selectedItem) return false;

        const item = this.scope.selectedItem as SkillLevel;
        const orgUnit = this.scope.relatedOrganizationUnitDict.value(item.organizationUnitId);
        return !item.changeOfDeletabilityPending && !item.hasDependingResources &&
            orgUnit && orgUnit.maxPermissionForCurrentUser >= 2;
    }

    /**
    * Returns the title of the modal delete confirmation window.
    * Should be overridden in controllers that make use of this functionality.
    */
    protected getDeleteConfirmationWindowTitle(): string {
        return this.scope.textLabels.SKILL_LEVELS_DELETION_MODAL_TITLE;
    }

    /**
        * Returns the text of the modal delete confirmation window.
        * Should be overridden in controllers that make use of this functionality.
    */
    protected getDeleteConfirmationWindowText(): string {
        return this.scope.textLabels.SKILL_LEVELS_DELETION_MODAL_TEXT;
    }

    protected newEntityDisplayName() {
        return this.scope.textLabels.NEW_SKILL_LEVEL_DISPLAY_NAME;
    }

    /**
        * Creates a new entity object that can be insert
        */
    protected createNewEntity(): TreeEntity {
        const entity = super.createNewEntity() as SkillLevel;
        // set filtered organization unit
        entity.organizationUnitId = this.scope.filterOrganizationUnitId;
        // set receivedOrder to the lowest value, so the new entity will be placed at the top of the tree
        entity.receivedOrder = this.lowestReceivedOrder--;
        // set the level to the highest value, the entity at the top of the tree should have the highest value
        entity.level = this.highestLevel++;
        // test and adjust these values based on other entities already in the tree
        for (let i = 0; i < this.scope.entities.length; i++) {
            const ent = this.scope.entities[i] as SkillLevel;
            entity.receivedOrder = Math.min(ent.receivedOrder - 1, entity.receivedOrder);
            entity.level = Math.max(ent.level + 1, entity.level);
        }
        return entity;
    }

    /**
        * Function that tells the controller how to get the property to compare for sorting its entities.
        * Override in actual controllers if necessary.
        * @param level
        */
    protected getPropertyToCompare(level: any): any {
        return null; // this is not applicable for this controller
    }

}