export var MonitoringPageInstanceCtrl = [
    "$scope", "$http", "$controller", "$stateParams", "$filter", "modalConfirmationWindowService", "componentId", "translationService",
    function ($scope, $http, $controller, $stateParams, $filter, modalConfirmationWindowService, componentId, translationService) {
        var dialogToken = "monitoring";
        var errorDialogToken = "monitoringErr";

        //console.log("Howdy", $stateParams.componentId, $stateParams.instance);

        // Inherit from base.
        $controller("MonitoringPageCtrlBase", { $scope: $scope });

        $scope.componentName = componentId;
        $scope.instanceName = $stateParams.instance;

        $scope.getStatuses = function () {
            $http.get("api/Monitoring/StatusMessages/" + encodeURIComponent($scope.componentName) + "/" + encodeURIComponent($scope.instanceName))
                .then(function (response) {
                    $scope.data = response.data;
                    //console.log($scope.data);
                    if (response.data.length > 0) {
                        $scope.dataPresent = true;
                        modalConfirmationWindowService.closeModalWindow(dialogToken);
                        $scope.extractOnlineSinceTimeStamp();
                        $scope.extractAggregatedHealth();
                    }
                }, function (response) {
                    modalConfirmationWindowService.closeModalWindow(dialogToken);

                    var errorMessage = translationService.translateErrorMessage(response);
                    modalConfirmationWindowService
                        .showModalInfoDialog($scope.textLabels.ERROR_OCCURRED,
                            errorMessage,
                            $scope.textLabels.OK,
                            null,
                            0,
                            errorDialogToken);
                });
        }

        $scope.currentStatus = -1;

        // Extracts since when the component has been online.
        $scope.extractOnlineSinceTimeStamp = function () {
            var onlineSinceLastRestartMessages =
                $filter("filter")($scope.data, { "sentSinceLastRestart": true, "firstMessageSinceOnline": true });

            if (onlineSinceLastRestartMessages.length > 0) {
                $scope.onlineSince = onlineSinceLastRestartMessages[0].timeStamp;
            } else {
                $scope.onlineSince = new Date(); // any valid date will do, really
            }
        }

        // Gets the current aggregated status for this instance.
        $scope.extractAggregatedHealth = function () {
            var messagesRelevantForHealth =
                $filter("filter")($scope.data, { "consideredForComponentHealth": true, "sentSinceLastRestart": true });

            var status = -1; // will be interpreted as "offline"

            for (var i = 0; i < messagesRelevantForHealth.length; i++) {
                status = Math.max(messagesRelevantForHealth[i].health, status);
            }

            $scope.currentStatus = status;
        }
    }
];