import { StateService } from '@uirouter/angularjs';
import { IPageStartService } from './../../shared/pageStartService';
import { IUserService } from './../../shared/userService';

import { ITranslationService } from './../i18n/translationService';

import { Planboard } from './../planboard/entities/planboard';
import * as Globals from './../planboard/utils/globals';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import { Dictionary } from './../utils/dictionary';
import * as Timezone from './../utils/timezone';

export class MergeConflictsController {

    private readonly dialogToken = "mergeConflicts";
    private readonly PLANNING_ID = 1;
    private readonly SOLVER_RESULT_TYPE = 3;
    private readonly NO_SCENARIO = -1;

    conflictsLoaded = false;
    allScenarios = new Dictionary();
    scenariosForDropdown = new Dictionary();
    selectedScenarioId = this.NO_SCENARIO;
    conflictingScenario = new Dictionary();
    conflictingScenarioId = this.NO_SCENARIO;
    scenarioHasConflicts = true;
    conflictsTableData: Array<any> = [];
    selectAllConflicts= false;
    conflictsTable2Data: Array<any> = [];
    selectAllConflicts2 = false;

    private commonSvc: any;
    private planboardActivityTypes = Planboard.activityTypes;
    private planboardScenarioId = Planboard.scenarioId;
    private resourceDisplayNames = new Dictionary();
    private selectedConflictIds = new Dictionary();
    private table1Conflicts: Array<any> = [];
    private table2Conflicts: Array<any> = [];
    private table1ReadyFlag = false;
    private table2ReadyFlag = false;
    private resourceIds: Array<number> = [];
    private accordion1 = $('#accordion1'); // TODO: find alternative for jquery
    private accordion2 = $('#accordion2'); // TODO: find alternative for jquery
    private panel1 = $('#panel1'); // TODO: find alternative for jquery
    private panel2 = $('#panel2'); // TODO: find alternative for jquery

    static $inject = [
        "$scope",
        "$state",
        "pageStartService",
        "translationService",
        "userService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $state: StateService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private userService: IUserService
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.commonSvc = this.pageStartService.initialize(this.$scope, null, this.dialogToken);

        this.commonSvc.start(() => { this.initializeConflictsPage(); });
    }

    private initializeConflictsPage(): void {
        if (this.planboardScenarioId != this.PLANNING_ID)
            this.selectedScenarioId = this.planboardScenarioId;
        if (this.userService.getUserVariable("SCENARIO_SELECTED_SCENARIO_ID")) {
            this.selectedScenarioId = this.userService.getUserVariable("SCENARIO_SELECTED_SCENARIO_ID");
            this.userService.deleteUserVariable("SCENARIO_SELECTED_SCENARIO_ID"); // delete after using: we only want to retrieve this value if navigated to activity details
        }
        this.loadAllScenarios();
    }

    isSolverResult(scenarioId: number): boolean {
        if (scenarioId == this.PLANNING_ID || scenarioId == this.NO_SCENARIO) return false;
        var scenario = this.allScenarios.value(scenarioId);
        if (scenario.scenarioType == this.SOLVER_RESULT_TYPE) return true;
        return false;
    }

    onSelectedScenarioChanged(itemId: number): void {
        if (itemId == this.selectedScenarioId) return;

        this.conflictsTableData = [];
        this.conflictsTable2Data = [];
        this.conflictingScenarioId = this.NO_SCENARIO;
        this.conflictsLoaded = false;
        // Collapse Accordions
        this.accordion1[0].classList.remove("active");
        this.accordion2[0].classList.remove("active");
        this.panel1[0].classList.remove("show");
        this.panel2[0].classList.remove("show");

        this.loadConflictingScenario(itemId);
    }

    onCheckbox(conflict: any, selectValue: boolean): void {
        if (selectValue) this.selectedConflictIds.add(conflict.id, conflict.id);
        else this.selectedConflictIds.remove(conflict.id);
        this.userService.setUserVariable("SCENARIO_CONFLICTS_SELECTED_IDS", this.selectedConflictIds);
    }

    onSelectAllConflicts(tableData: Array<any>, selectValue: boolean): void {
        for (var i = 0; i < tableData.length; i++) {
            var conflict = tableData[i];
            conflict.selected = selectValue;
            this.onCheckbox(conflict, selectValue);
        }
    }

    onResolveConflicts(tableData: Array<any>, scenarioId: number): void {
        var conflictsToRemove = []
        for (var i = 0; i < tableData.length; i++) {
            var conflict = tableData[i];
            if (conflict.selected)
                conflictsToRemove.push(conflict);
        }
        for (var i = 0; i < conflictsToRemove.length; i++) {
            var conflict = conflictsToRemove[i];
            var index = tableData.indexOf(conflict);
            if (index > -1) {
                // make delete request
                var scenarioIdForRequest = scenarioId;
                var conflictIdForRequest = tableData[index].id;
                this.deleteConflict(scenarioIdForRequest, conflictIdForRequest, tableData, conflict);
            }
        }
    }

    onActivityClick(activityId: number, isDeleted: boolean): void {
        if (isDeleted) return;
        if (activityId > 0) {
            this.userService.setUserVariable("SCENARIO_SELECTED_SCENARIO_ID", this.selectedScenarioId); // To remember scenario id when navigate back from activity details
            this.$state.transitionTo("planboard.activity", { activityId: activityId });
        }
    }

    updateTable1SelectAll(tableData: Array<any>, conflict: any): void {
        this.selectAllConflicts = this.updateSelectAll(this.selectAllConflicts, tableData, conflict);
    }

    updateTable2SelectAll(tableData: Array<any>, conflict: any): void {
        this.selectAllConflicts2 = this.updateSelectAll(this.selectAllConflicts2, tableData, conflict);
    }

    setRowClass(isDeleted: boolean): string {
        if (isDeleted) return "disabled-row";
        else return "";
    }

    private loadAllScenarios(): void {
        this.commonSvc.loadData("api/Scenarios", null,
            (success, loadInto) => {
                this.allScenarios.clear();
                for (var i = 0; i < loadInto.length; i++)
                    this.allScenarios.add(loadInto[i].id, loadInto[i]);
                this.setScenariosForDropdown();
                if (this.isSolverResult(this.selectedScenarioId))
                    this.selectedScenarioId = this.NO_SCENARIO;
                this.loadConflictingScenario(this.selectedScenarioId);
            },
            null, true, false);
    }

    private setScenariosForDropdown(): void {
        this.scenariosForDropdown.clear();
        this.allScenarios.forEach((id, scenario) => {
            if (!this.isSolverResult(id))
                this.scenariosForDropdown.add(id, scenario);
        });
    }

    private loadConflictingScenario(selectedScenarioId: number): void {
        if (selectedScenarioId == this.NO_SCENARIO)
            return;
        var url = "api/Scenarios/" + selectedScenarioId + "/ConflictingScenarios/";
        this.commonSvc.loadData(url, null,
            (success, loadInto) => {
                this.conflictingScenario.clear();
                if (loadInto.length > 0) {
                    for (var i = 0; i < loadInto.length; i++) {
                        if (loadInto[i].id == this.allScenarios.value(selectedScenarioId).originalScenarioId) {
                            if (loadInto[i].id == this.PLANNING_ID) this.conflictingScenario.add(this.PLANNING_ID, { id: this.PLANNING_ID, displayName: this.$scope.textLabels.SCENARIO_TYPE_PLANNING });
                            else this.conflictingScenario.add(loadInto[i].id, loadInto[i]);
                            this.conflictingScenarioId = loadInto[i].id;
                            this.scenarioHasConflicts = true;
                            this.loadConflicts(this.selectedScenarioId, this.conflictingScenarioId);
                            return;
                        }
                    }
                    this.scenarioHasConflicts = false;
                }
                else this.scenarioHasConflicts = false;
            },
            null, true, false);
    }

    private loadConflicts(selectedScenarioId: number, conflictingScenarioId: number): void {
        // Table 1 - Selected scenario = target
        var url = "api/Scenarios/" + selectedScenarioId + "/Conflicts/With/" + conflictingScenarioId;
        this.commonSvc.loadData(url, null,
            (success, loadInto) => {
                this.table1Conflicts = [];
                for (var i = 0; i < loadInto.length; i++) {
                    this.table1Conflicts.push(loadInto[i]);
                    if (this.table1Conflicts[i].activity.resourceId) {
                        if (!this.resourceDisplayNames.value(this.table1Conflicts[i].activity.resourceId))
                            this.resourceIds.push(this.table1Conflicts[i].activity.resourceId);
                    }
                    if (this.table1Conflicts[i].otherActivity.resourceId) {
                        if (!this.resourceDisplayNames.value(this.table1Conflicts[i].otherActivity.resourceId))
                            this.resourceIds.push(this.table1Conflicts[i].otherActivity.resourceId);
                    }
                }
                this.table1ReadyFlag = true;
                if (this.table2ReadyFlag) {
                    this.loadResourceDisplayNames(this.resourceIds);
                    this.table1ReadyFlag = this.table2ReadyFlag = false;
                    this.conflictsLoaded = true;
                    this.resourceIds = [];
                }
            },
            null, true, false);

        // Table 2 - Selected scenario = source
        var url = "api/Scenarios/" + conflictingScenarioId + "/Conflicts/With/" + selectedScenarioId;
        this.commonSvc.loadData(url, null,
            (success, loadInto) => {
                this.table2Conflicts = [];
                for (var i = 0; i < loadInto.length; i++) {
                    this.table2Conflicts.push(loadInto[i]);
                    if (this.table2Conflicts[i].activity.resourceId) {
                        if (!this.resourceDisplayNames.value(this.table2Conflicts[i].activity.resourceId))
                            this.resourceIds.push(this.table2Conflicts[i].activity.resourceId);
                    }
                    if (this.table2Conflicts[i].otherActivity.resourceId) {
                        if (!this.resourceDisplayNames.value(this.table2Conflicts[i].otherActivity.resourceId))
                            this.resourceIds.push(this.table2Conflicts[i].otherActivity.resourceId);
                    }
                }
                this.table2ReadyFlag = true;
                if (this.table1ReadyFlag) {
                    this.loadResourceDisplayNames(this.resourceIds);
                    this.table2ReadyFlag = this.table1ReadyFlag = false;
                    this.conflictsLoaded = true;
                    this.resourceIds = [];
                }
            },
            null, true, false);
    }

    private loadResourceDisplayNames(resourceIds: Array<number>): void {
        if (resourceIds.length > 0) {
            var resources = { idList: resourceIds }
            this.commonSvc.post("api/Resources/WithId", resources,
                (success) => {
                    for (var i = 0; i < success.data.length; i++)
                        this.resourceDisplayNames.add(success.data[i].id, success.data[i].displayName);

                    this.conflictsTableData = this.setTableData(this.table1Conflicts);
                    this.selectAllConflicts = this.initSelectAll(this.conflictsTableData);
                    this.conflictsTable2Data = this.setTableData(this.table2Conflicts);
                    this.selectAllConflicts2 = this.initSelectAll(this.conflictsTable2Data);
                }, null);

        } else {
            this.conflictsTableData = this.setTableData(this.table1Conflicts);
            this.selectAllConflicts = this.initSelectAll(this.conflictsTableData);
            this.conflictsTable2Data = this.setTableData(this.table2Conflicts);
            this.selectAllConflicts2 = this.initSelectAll(this.conflictsTable2Data);
        }
    }

    private deleteConflict(scenarioIdForRequest: number, conflictIdForRequest: number, tableData: Array<any>, conflict: any): void {
        var url = "api/Scenarios/" + scenarioIdForRequest + "/Conflicts/" + conflictIdForRequest;
        this.commonSvc.deleteData(url,
            (success) => {
                var index = tableData.indexOf(conflict);
                if (index > -1)
                    tableData.splice(index, 1); // remove conflicts from table
            }, null, true);
    }

    private setTableData(conflicts: Array<any>): Array<any> {
        var tableData = [];
        if (this.userService.getUserVariable("SCENARIO_CONFLICTS_SELECTED_IDS"))
            this.selectedConflictIds = this.userService.getUserVariable("SCENARIO_CONFLICTS_SELECTED_IDS");

        for (var i = 0; i < conflicts.length; i++) {
            var activity = conflicts[i].activity;
            var otherActivity = conflicts[i].otherActivity;
            var activityType = this.planboardActivityTypes.getObject(activity.activityTypeId);
            var isActivityDeleted = false;
            var isOtherActivityDeleted = false;
            if (conflicts[i].type == 1) isOtherActivityDeleted = true;
            if (conflicts[i].type == 2) isActivityDeleted = true;

            var tableRowData =
            {
                id: conflicts[i].id,

                activityId: activity.id,
                activityBackColor: this.getActivityBackColor(activityType),
                activityTextColor: this.getActivityTextColor(activityType),
                activityShortText: this.getActivityShortText(activityType),
                activityDateRange: this.getActivityDateRange(activity),
                activityTimeRange: this.getActivityTimeRange(activity),
                activityResource: this.getResourceDisplayName(activity.resourceId),
                activityDeleted: isActivityDeleted,

                otherActivityDateRange: this.getActivityDateRange(otherActivity),
                otherActivityTimeRange: this.getActivityTimeRange(otherActivity),
                otherActivityResource: this.getResourceDisplayName(otherActivity.resourceId),
                otherActivityDeleted: isOtherActivityDeleted,

                description: this.getConflictDescription(conflicts[i]),
                selected: this.selectedConflictIds.tryGetValue(conflicts[i].id, (value) => { })
            };
            tableData.push(tableRowData);
        }
        return tableData;
    }

    private getActivityBackColor(activityType: any): string {
        if (activityType)
            return activityType.backColor;

        return "#eeeeee";
    }

    private getActivityTextColor(activityType: any): string {
        if (activityType)
            return activityType.textColor;

        return "#222222";
    }

    private getActivityShortText(activityType: any): string {
        if (activityType)
            return activityType.shortText;

        return "-";
    }

    private getActivityDateRange(activity: any): string {
        var startDate = Timezone.correctTimeZoneInfo(activity.startDate);
        var endDate = Timezone.correctTimeZoneInfo(activity.endDate);
        var startDateStr = Globals.dateFormat(startDate, 'shortDate');
        var endDateStr = Globals.dateFormat(endDate, 'shortDate');

        if (startDateStr == endDateStr)
            return startDateStr;
        else
            return startDateStr + " - " + endDateStr;
    }

    private getActivityTimeRange(activity: any): string {
        var startDate = Timezone.correctTimeZoneInfo(activity.startDate);
        var endDate = Timezone.correctTimeZoneInfo(activity.endDate);
        var startTimeStr = Globals.dateFormat(startDate, 'shortTime');
        var endTimeStr = Globals.dateFormat(endDate, 'shortTime');

        return startTimeStr + " - " + endTimeStr;
    }

    private getResourceDisplayName(resourceId: number): string {
        if (resourceId)
            return this.resourceDisplayNames.value(resourceId);

        return "-";
    }

    private getConflictDescription(conflict: any): string {
        var descriptionToParse = this.$scope.textLabels["SCENARIO_CONFLICT_TYPE_" + conflict.type.toString()];
        var targetScenarioId = conflict.activity.scenarioId;
        var sourceScenarioId = conflict.otherActivity.scenarioId;
        var conflictDescriptionStr = this.parseConflictDescription(descriptionToParse, targetScenarioId, sourceScenarioId);

        return conflictDescriptionStr;
    }

    private parseConflictDescription(descriptionToParse: string, targetScenarioId: number, sourceScenarioId: number): string {
        var targetScenarioFind = "[TargetScenario]";
        var sourceScenarioFind = "[SourceScenario]";
        var targetScenarioReplace = "";
        var sourceScenarioReplace = "";

        // Find displayName of provided scenarioIds
        // We don't know if target is selectedScenario or conflictingScenario so check
        if (this.allScenarios.value(targetScenarioId)) {
            targetScenarioReplace = this.allScenarios.value(targetScenarioId).displayName;
            sourceScenarioReplace = this.conflictingScenario.value(sourceScenarioId).displayName;
        } else {
            targetScenarioReplace = this.conflictingScenario.value(targetScenarioId).displayName;
            sourceScenarioReplace = this.allScenarios.value(sourceScenarioId).displayName;
        }

        // Parse String
        // Quick fix - each string shows up twice at most, so run .replace twice for target and twice for scenario
        var parsedString = descriptionToParse.replace(targetScenarioFind, targetScenarioReplace);
        parsedString = parsedString.replace(targetScenarioFind, targetScenarioReplace);
        parsedString = parsedString.replace(sourceScenarioFind, sourceScenarioReplace);
        parsedString = parsedString.replace(sourceScenarioFind, sourceScenarioReplace);

        return parsedString;
    }

    private updateSelectAll(selectAllValue: boolean, tableData: Array<any>, conflict: any): boolean {
        if (selectAllValue) {
            if (!conflict.selected)
                return false;
        } else {
            if (conflict.selected) {
                var allConflictsAreSelected = true;
                for (var i = 0; i < tableData.length; i++) {
                    if (!tableData[i].selected) {
                        allConflictsAreSelected = false;
                        break;
                    }
                }
                return allConflictsAreSelected;
            }
        }
        return selectAllValue;
    }

    private initSelectAll(tableData: Array<any>): boolean {
        var allConflictsAreSelected = true;
        for (var i = 0; i < tableData.length; i++)
            if (!tableData[i].selected) {
                allConflictsAreSelected = false;
                break;
            }
        return allConflictsAreSelected;
    }

}