import * as Globals from './../../app/components/planboard/utils/globals';

export var omrpColorpicker = [
    "$rootScope",
    function ($rootScope) {
        return {
            // can be used as an attribute or element or class
            restrict: "AEC",

            template: require("../template/colorpicker.html"),

            require: "ngModel",

            scope: {
                autosizewidth: "@",
                changedisabled: "=",
                color: "=ngModel",
                colorchanged: "&"
            },

            link: function (scope, element, attrs, ngModelCtrl) {
                scope.triggerElement = element[0];
                scope.dropdownVisible = false;
                scope.dropdownLeft = "0px";
                scope.dropdownTop = "0px";
                scope.dropdownWidth = "250px";
                scope.dropdownHeight = "200px";
                scope.blocksize = "18px";
                scope.showsliders = true;
                scope.revision = 1;

                scope.palette = [
                    ["#000000", "#444444", "#666666", "#999999", "#cccccc", "#eeeeee", "#f3f3f3", "#ffffff"],
                    ["#ff0000", "#ff9900", "#ffff00", "#00ff00", "#00ffff", "#0000ff", "#9900ff", "#ff00ff"],
                    ["#f4cccc", "#fce5cd", "#fff2cc", "#d9ead3", "#d0e0e3", "#cfe2f3", "#d9d2e9", "#ead1dc"],
                    ["#ea9999", "#f9cb9c", "#ffe599", "#b6d7a8", "#a2c4c9", "#9fc5e8", "#b4a7d6", "#d5a6bd"],
                    ["#e06666", "#f6b26b", "#ffd966", "#93c47d", "#76a5af", "#6fa8dc", "#8e7cc3", "#c27ba0"],
                    ["#cc0000", "#e69138", "#f1c232", "#6aa84f", "#45818e", "#3d85c6", "#674ea7", "#a64d79"],
                    ["#990000", "#b45f06", "#bf9000", "#38761d", "#134f5c", "#0b5394", "#351c75", "#741b47"],
                    ["#660000", "#783f04", "#7f6000", "#274e13", "#0c343d", "#073763", "#20124d", "#4c1130"]
                ];

                scope.custompalette = null;

                scope.getTriggerElementId = function () {
                    if (scope.triggerElement.id === "") scope.triggerElement.id = "genId" + Math.floor(Math.random() * Globals.maxInt53);
                    return scope.triggerElement.id;
                }

                scope.pxToInt = function (pxvalue) {
                    return parseInt(pxvalue.substring(0, pxvalue.length - 2));
                }

                scope.colorchanged = function () {
                    scope.prefixedColor = false;
                    if (!scope.color)
                        scope.htmlcolor = "#000000";
                    else {
                        if (scope.color.substring(0, 1) === "#" || scope.color.substring(0, 4) === "rgb(")
                            scope.htmlcolor = scope.color;
                        else {
                            scope.htmlcolor = "#" + scope.color;
                            scope.prefixedColor = true; // remember that we prefixed with a #, so that it can be removed when returned
                        }
                    }
                    scope.manualcolor = scope.htmlcolor;
                    scope.initsliders();
                }

                scope.initsliders = function () {
                    if (!scope.manualcolor) return;
                    var newcolorred = Math.floor(parseInt(scope.manualcolor.substring(1, 3), 16));
                    var newcolorgreen = Math.floor(parseInt(scope.manualcolor.substring(3, 5), 16));
                    var newcolorblue = Math.floor(parseInt(scope.manualcolor.substring(5, 7), 16));
                    if (!isNaN(newcolorred) && scope.colorred !== newcolorred) scope.colorred = newcolorred;
                    if (!isNaN(newcolorgreen) && scope.colorgreen !== newcolorgreen) scope.colorgreen = newcolorgreen;
                    if (!isNaN(newcolorblue) && scope.colorblue !== newcolorblue) scope.colorblue = newcolorblue;
                }

                scope.colorComponentsChanged = function () {
                    if (isNaN(scope.colorred) || isNaN(scope.colorgreen) || isNaN(scope.colorblue)) return;
                    var newmanual = scope.colorblue.toString(16);
                    if (newmanual.length < 2) newmanual = "0" + newmanual;
                    newmanual = scope.colorgreen.toString(16) + newmanual;
                    if (newmanual.length < 4) newmanual = "0" + newmanual;
                    newmanual = scope.colorred.toString(16) + newmanual;
                    if (newmanual.length < 6) newmanual = "0" + newmanual;
                    newmanual = "#" + newmanual;
                    if (newmanual !== scope.manualcolor) scope.manualcolor = newmanual;
                }

                scope.$watch("colorred", function (value) {
                    if (scope.manualred) scope.colorComponentsChanged();
                });

                scope.$watch("colorgreen", function (value) {
                    if (scope.manualgreen) scope.colorComponentsChanged();
                });

                scope.$watch("colorblue", function (value) {
                    if (scope.manualblue) scope.colorComponentsChanged();
                });

                scope.$watch("manualcolor", function (value) {
                    scope.manualred = false;
                    scope.manualgreen = false;
                    scope.manualblue = false;
                    scope.initsliders();
                });

                scope.$watch("color", function (value) {
                    scope.colorchanged();
                });

                scope.addcustom = function (htmlcolorvalue) {
                    var row, col;
                    if (scope.custompalette)
                        for (col = 0; col < scope.custompalette.length; col++)
                            if (scope.custompalette[col] === htmlcolorvalue)
                                return; // already in custom list
                    for (row = 0; row < scope.palette.length; row++)
                        for (col = 0; col < scope.palette[row].length; col++)
                            if (scope.palette[row][col] === htmlcolorvalue)
                                return; // already in default list
                    if (!scope.custompalette)
                        scope.custompalette = [];
                    if (scope.custompalette.length < scope.palette[0].length)
                        scope.custompalette.push(htmlcolorvalue);
                    else {
                        for (col = 0; col < scope.custompalette.length - 1; col++)
                            scope.custompalette[col] = scope.custompalette[col + 1];
                        scope.custompalette[scope.palette[0].length - 1] = htmlcolorvalue;
                    }
                }

                scope.show = function () {
                    if (scope.changedisabled) return;
                    if (!scope.dropdownElement) {
                        // find the dropdown-list and move it to the end of the document body
                        // this is neccesary so it wont be restricted by a parents bounding div element
                        // scope.triggerElement = the element triggering this directive
                        // scope.triggerElement.firstElementChild = "dropdown-container" element
                        // scope.triggerElement.firstElementChild.lastElementChild = "dropdown-list" element
                        // scope.triggerElement.firstElementChild.firstElementChild = "dropdown-display" element
                        scope.displayElement = scope.triggerElement.firstElementChild.firstElementChild;
                        scope.dropdownElement = scope.triggerElement.firstElementChild.lastElementChild;
                        scope.dropdownElement = document.body.appendChild(scope.dropdownElement);
                    }

                    // add current color to custom list if neccesary
                    scope.addcustom(scope.htmlcolor);

                    // determine width and height of the dropdown
                    var extraheight = 12;
                    var extrawidth = 12;
                    var paletteheight = (scope.pxToInt(scope.blocksize) + 2) * scope.palette.length;
                    if (scope.custompalette || scope.showsliders) extraheight += 6 + (scope.pxToInt(scope.blocksize) + 2);
                    if (scope.showsliders) {
                        extrawidth += 120;
                        var htmlcolorText = $("#htmlColorTextBox-" + scope.getTriggerElementId());
                        var htmlcolorTextHeight = htmlcolorText.height();
                        scope.sliderheight = "" + (extraheight + paletteheight - scope.pxToInt(scope.blocksize) - htmlcolorTextHeight - 18) + "px";
                    }
                    scope.dropdownHeight = "" + (extraheight + paletteheight) + "px";
                    scope.dropdownWidth = "" + (extrawidth + (scope.pxToInt(scope.blocksize) + 2) * scope.palette[0].length) + "px";

                    // position the dropdownElement near triggerElement
                    var rect = scope.displayElement.getBoundingClientRect();
                    var leftPos = rect.left;
                    var topPos = rect.bottom - 1;
                    if (topPos + scope.pxToInt(scope.dropdownHeight) >= window.innerHeight) {
                        topPos = Math.max(rect.top - scope.pxToInt(scope.dropdownHeight), 0);
                    }
                    /*
                    if (scope.autosizewidth) {
                        // make the dropdownElement just as wide as the triggerElement
                        scope.dropdownWidth = "" + rect.width + "px";
                    }
                    */
                    if (leftPos + scope.pxToInt(scope.dropdownWidth) >= window.innerWidth) {
                        leftPos = Math.max(window.innerWidth - scope.pxToInt(scope.dropdownWidth), 0);
                    }
                    leftPos = Math.round(leftPos + window.pageXOffset);
                    topPos = Math.round(topPos + window.pageYOffset);
                    scope.dropdownLeft = "" + leftPos + "px";
                    scope.dropdownTop = "" + topPos + "px";

                    scope.colorchanged();

                    // toggle visibility of the dropdownElement
                    scope.dropdownVisible = !scope.dropdownVisible;
                    scope.revision++;
                };

                scope.$on("$destroy", function () {
                    // if the dropdownElement was attached to the body, we also need to remove it when the scope is destroyed
                    if (scope.dropdownElement) document.body.removeChild(scope.dropdownElement);
                });

                $rootScope.$on("documentClicked", function (inner, target) {
                    if (!scope.dropdownVisible) return; // exit early if the dropdown is not visible
                    // clicked in color block?
                    if ($(target[0]).is(".color-block")) {
                        var idsplit = target[0].id.split("-");
                        var colorvalue = idsplit[idsplit.length - 1]; // extract colorvalue out of the id
                        if (scope.prefixedColor)
                            colorvalue = colorvalue.substring(1); // remove the # at the start
                        scope.$apply(function () { scope.dropdownVisible = false; });
                        ngModelCtrl.$setViewValue(colorvalue); // this will trigger ng-change if the value passed is different than the one in ng-scope (=color.value)
                        scope.color = colorvalue;
                        if (scope.colorchanged != undefined) scope.colorchanged();
                        return;
                    }
                    // check if the clicked on control has a specific class or has any parent with that specific class, then do not close the dropdown
                    var parents = $(target[0]).parents();
                    for (var i = 0; i < parents.length; i++)
                        if (parents[i] === scope.triggerElement) return;
                    if (!$(target[0]).is(".ignore-document-clicked") &&
                        !(<any>($(target[0]).parents(".ignore-document-clicked").length) > 0)
                        //&& !$(target[0]).is(".dropdown-display.clicked") &&
                        //!$(target[0]).parents(".dropdown-display.clicked").length > 0
                    )
                        scope.$apply(function () { scope.dropdownVisible = false; });
                });

            }
        };
    }
];