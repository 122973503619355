/**
    * Delay in ms used for modal wait windows. Modal wait windows will show if an operation takes longer than this time.
    */
export var modalWaitDelay = 200;

/**
    * Constant for activity detail: description.
    */
export var activityDetailDescription = 1;

/**
    * Constant for activity detail: hyperlink.
    */
export var activityDetailHyperlink = 2;

/**
    * Constant for activity detail: imposes risk.
    */
export var activityDetailRisk = 3;

/**
    * constant for activity detail: number of subjects.
    */
export var activityNumberOfSubjects = 4;

/**
    * constant for activity detail: cancellation reason.
    */
export var activityCancellationReason = 5;

/**
    * activity flag indicating if the resource exists in a resourceGroup.
    */
export var flagResourceIsGrouped = 1;

/**
    * activity flag indicating that an activity should be reloaded.
    */
export var flagReloadActivity = 2;

/**
    * status for an activity that indicates that the activity is visible in the planning
    */
export var StatusActivityPlanned = 1;

/**
    * status for an activity that indicates that the activity is deleted
    */
export var StatusActivityDeleted = 2;

/**
    * status for an activity that indicates that the activity is cancelled
    */
export var StatusActivityCancelled = 3;

/**
    * status for an activity that indicates that the activity is visible in the planning, but may not be changed
    */
export var StatusActivityLocked = 4;

/**
    * status for an activity that indicates that the activity does not require a resource
    */
export var StatusActivityNotRequired = 5;

/**
    * User variable in which the initial organization unit to choose for period bound properties is stored.
    */
export var periodboundInitialOrgUnitUserVar = "periodbound.initialOrgUnitId";

/**
    * User variable in which the initial resource type to choose for period bound properties is stored.
    */
export var periodBoundInitialResourceTypeUserVar = "periodbound.resourceTypeId";

/**
    * Constant for activity change type: New Activity
    */
export var activityChangeTypeNew = 1;

/**
    * Constant for activity change type: Status Change
    */
export var activityChangeTypeStatus = 2;

/**
    * Constant for activity change type: Resource Change
    */
export var activityChangeTypeResource = 3;

/**
    * Constant for activity change type: DateTime Change
    */
export var activityChangeTypeDateTime = 4;

/**
    * Constant for activity change type: Details Change
    */
export var activityChangeTypeDetails = 5;

/**
    * Constant for activity change type: Memo Change
    */
export var activityChangeTypeMemo = 6;

/**
    * Constant for Activity save failure reason: Resource does not have required skills
    * ActivitySaveFailureReasons.cs
    */
export var activitySaveFailureResourceNotSkilled = 11;

/**
    * Constant for Activity action failure: Resource does not have required skills
    * (used by activity paste)
    * ActivityActionFailure.cs
    */
export var activityActionFailureResourceNotSkilled = 7;

/**
    * Enum value for read permissions.
    */
export var permRead = 1;

/**
    * Enum value for write permissions.
    */
export var permWrite = 2;

/*
    * Enum value for owner permissions.
    */
export var permOwner = 3;

export var auth0ClientId = "3tKAxw5sJShsdCMTv1qWjl0xWcaxxZMl";
export var auth0Domain = "logiqcare.eu.auth0.com";
export var auth0Audience = "https://logiqcare.eu.auth0.com/userinfo";
export var auth0RedirectUri = window.location.protocol + "//" + window.location.host + '/auth0Callback.html?singleSpaAppPrefix=omrp&includeHashBang=true';
export var auth0Scope = 'openid profile email offline_access';

export const currentUserStorageKey = 'ngStorage-currentUser';
export const refreshTokenStorageKey = 'ngStorage-refreshToken';


export const originSystemOWS = 'OWS';