export var omrpSlider = [
    "$rootScope", "$timeout",
    function ($rootScope, $timeout) {
        return {
            // can be used as an attribute or element or class
            restrict: "AEC",

            template: '<canvas class="slider-canvas"></canvas>',

            scope: {
                color: "@",
                minvalue: "@",
                maxvalue: "@",
                wheelstep: "@",
                padding: "@",
                manualchange: "=",
                value: "=",
                revision: "=" // if the slider does not draw correctly, use this binding to increase the revision. With each increase a $timeout redraw will be forced.
            },

            link: function (scope, element) {
                scope.triggerelement = element[0];
                scope.buttondown = false;

                var canvas = scope.triggerelement.firstElementChild;
                var ctx = canvas.getContext("2d");

                /*  var pxToInt = function (pxvalue) {
                    return parseInt(pxvalue.substring(0, pxvalue.length - 2));
                } */

                scope.redraw = function () {
                    var rect = scope.triggerelement.getBoundingClientRect();
                    if (rect.width === 0 || rect.height === 0) {
                        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                        return;
                        /* canvas.width = pxToInt(scope.triggerelement.style.width);
                        canvas.height = pxToInt(scope.triggerelement.style.height); */
                    } else {
                        canvas.width = rect.width;
                        canvas.height = rect.height;
                    }

                    var padding = parseInt(scope.padding);
                    if (isNaN(padding)) padding = 0;
                    var ctxwidth = ctx.canvas.width - padding * 2;
                    var ctxheight = ctx.canvas.height - padding * 2;
                    var minvalue = parseInt(scope.minvalue);
                    var maxvalue = parseInt(scope.maxvalue);
                    var radius = 0;
                    var value = parseInt(scope.value);
                    if (isNaN(value)) value = minvalue;
                    value = Math.min(Math.max(value, minvalue), maxvalue);

                    scope.horizontal = (ctxwidth > ctxheight);
                    ctx.clearRect(0, 0, ctxwidth, ctxheight);
                    ctx.fillStyle = scope.color;
                    if (scope.horizontal) {
                        scope.middlepos = padding + Math.floor(ctxheight / 2);
                        scope.radius = Math.max(Math.min(ctxheight * 0.3, ctxheight * 0.5 - 6), 3);
                        scope.position = padding + scope.radius +
                            ((ctxwidth - scope.radius * 2) * (value - minvalue)) /
                            Math.max(maxvalue - minvalue, 1);
                        var lineheight = Math.floor(Math.max(scope.radius / 3, 2));
                        ctx.fillRect(padding + scope.radius, scope.middlepos, ctxwidth - scope.radius * 2, lineheight);
                        ctx.beginPath();
                        radius = scope.radius + (scope.buttondown ? Math.max(scope.radius * 0.2, 2) : 0);
                        ctx.arc(scope.position, scope.middlepos + lineheight * 0.5, radius, 0, Math.PI * 2);
                        ctx.closePath();
                        ctx.fill();
                    } else {
                        scope.middlepos = padding + Math.floor(ctxwidth / 2);
                        scope.radius = Math.max(Math.min(ctxwidth * 0.3, ctxwidth * 0.5 - 6), 3);
                        scope.position = padding + scope.radius +
                            ((ctxheight - scope.radius * 2) * ((maxvalue - value) - minvalue)) /
                            Math.max(maxvalue - minvalue, 1);
                        var linewidth = Math.floor(Math.max(scope.radius / 3, 2));
                        ctx.fillRect(scope.middlepos, padding + scope.radius, linewidth, ctxheight - scope.radius * 2);
                        ctx.beginPath();
                        radius = scope.radius + (scope.buttondown ? Math.max(scope.radius * 0.2, 2) : 0);
                        ctx.arc(scope.middlepos + linewidth * 0.5, scope.position, radius, 0, Math.PI * 2);
                        ctx.closePath();
                        ctx.fill();
                    }
                }

                scope.$watch("value", function (value) {
                    scope.redraw();
                });

                scope.$watch("revision", function (value) {
                    scope.buttondown = false;
                    $timeout(function () { scope.redraw(); }, 1);
                });

                var mouseX = function (clientX) {
                    return Math.floor(clientX - ctx.canvas.getBoundingClientRect().left);
                }

                var mouseY = function (clientY) {
                    return Math.floor(clientY - ctx.canvas.getBoundingClientRect().top);
                }

                scope.mouseDownCanvas = function (evt) {
                    scope.buttondown = true;
                    scope.dragpos = scope.radius * 2;
                    var x = mouseX(evt.clientX);
                    var y = mouseY(evt.clientY);
                    if (scope.horizontal) {
                        if (x >= scope.position - scope.radius && x <= scope.position + scope.radius)
                            scope.dragpos = x - scope.position;
                    } else {
                        if (y >= scope.position - scope.radius && y <= scope.position + scope.radius)
                            scope.dragpos = y - scope.position;
                    }
                    scope.mouseMoveCanvas(evt);
                    scope.redraw();
                    evt.preventDefault();
                    evt.stopPropagation();
                }

                scope.mouseMoveCanvas = function (evt) {
                    if (!scope.buttondown) return;
                    scope.manualchange = true;
                    var x = mouseX(evt.clientX);
                    var y = mouseY(evt.clientY);
                    var pos = scope.horizontal ? x : y;
                    if (scope.dragpos >= -scope.radius && scope.dragpos <= scope.radius) pos -= scope.dragpos;
                    var canvassize = scope.horizontal ? ctx.canvas.width : ctx.canvas.height;
                    var padding = parseInt(scope.padding);
                    if (isNaN(padding)) padding = 0;
                    pos -= padding;
                    canvassize -= padding * 2;
                    if (!scope.horizontal) pos = canvassize - pos;
                    var minvalue = parseInt(scope.minvalue);
                    var maxvalue = parseInt(scope.maxvalue);
                    var value = ((pos - scope.radius) * (maxvalue - minvalue)) /
                        Math.max(canvassize - scope.radius * 2, 1);
                    value = Math.floor(value + minvalue);
                    scope.applyValueChanged(value);
                    evt.preventDefault();
                    evt.stopPropagation();
                }

                scope.applyValueChanged = function (value) {
                    value = Math.floor(Math.min(Math.max(value, parseInt(scope.minvalue)), parseInt(scope.maxvalue)));
                    if (isNaN(value)) return;
                    if (scope.value === value) return;
                    scope.value = value;
                    scope.redraw();
                    if (!scope.valueApplyTimeout) {
                        scope.valueApplyTimeout = true;
                        $timeout(function () { scope.valueApplyTimeout = false; scope.$apply(); }, 100);
                    }
                }

                scope.mouseUpCanvas = function (evt) {
                    scope.buttondown = false;
                    scope.redraw();
                    evt.preventDefault();
                    evt.stopPropagation();
                }

                scope.mouseWheelCanvas = function (evt) {
                    scope.manualchange = true;
                    var wheelvalue = Math.max(-1, Math.min(1, (evt.wheelDelta || -evt.detail)));
                    var wheelstep = parseInt(scope.wheelstep);
                    if (isNaN(wheelstep) || wheelstep <= 0) wheelstep = 8;
                    var value = Math.floor(Math.round(scope.value / wheelstep) * wheelstep) + wheelvalue * wheelstep;
                    if (wheelvalue < 0 && value >= scope.value) value -= wheelstep;
                    if (wheelvalue > 0 && value <= scope.value) value += wheelstep;
                    scope.applyValueChanged(value);
                    evt.stopPropagation();
                    evt.preventDefault();
                }

                canvas.addEventListener("mousedown", scope.mouseDownCanvas, false);
                canvas.addEventListener("mousemove", scope.mouseMoveCanvas, false);
                canvas.addEventListener("mouseup", scope.mouseUpCanvas, false);
                canvas.addEventListener("mousewheel", scope.mouseWheelCanvas, false);
                canvas.addEventListener("DOMMouseScroll", scope.mouseWheelCanvas, false);
                canvas.addEventListener("mouseout", scope.mouseUpCanvas, false);
            }
        };
    }
];