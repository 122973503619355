import { IPageStartService } from './../../shared/pageStartService';
import { IUserService } from './../../shared/userService';
import { IConfigurationService } from './../../shared/configurationService';

import { ITranslationService } from './../i18n/translationService';

import { Planboard } from './../planboard/entities/planboard';
import { TimeSpan } from './../planboard/utils/timespan';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import * as Timezone from './../utils/timezone';

export class ScenarioManagementController {
    private date: Date = new Date();
    private year: number = this.date.getFullYear();
    private month: number = this.date.getMonth();
    private startOfCurrentWeek = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - this.date.getDay() + 1);

    newScenarioRequestPending: boolean = false;
    newScenarioInputOpen: boolean = false;
    changeScenarioNameInputOpen: boolean = false;
    newScenarioDisplayName: string;
    newScenarioStartDate: Date = this.startOfCurrentWeek;
    newScenarioEndDate: Date = new Date(this.year, this.month + 1, 0);
    popupTop: string = "0px";
    popupLeft: string = "0px";
    popupWidth: string = "50%";
    allScenarios: Array<any> = []; // for current user
    selectedScenario: any = null;
    selectedScenarioId: number = -1;
    startDateBeforeCurrentWeek = false;
    dateExceedsLimitSetting = false;
    scenarioMaximumLengthInvalid = false;

    private commonSvc: any;
    private deletePending: Array<boolean> = []; // deletePending[x] is true right after click on scenario delete button and during deletion
    private renamePending: Array<boolean> = []; // renamePending[x] is true right after click on scenario delete button and during rename
    private updatePending: Array<boolean> = []; // updatePending[x] is true right after click on scenario update button and during update
    private mergePending: Array<boolean> = [];  // mergePending[x] is true right after click on scenario merge button and during merge
    private requestCountDict: any = {};
    private scenarioManagementMaxYearsDifference = null;

    private readonly apiUrl: string = "api/Scenarios";
    private readonly dialogToken: string = "scenManInfo";
    private readonly permission: string = "ScenarioManagement";
    private readonly maxPendingGetRequests: number = 2;

    static $inject = [
        "$scope",
        "$filter",
        "$http",
        "pageStartService",
        "translationService",
        "userService",
        "configurationService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $filter: ng.IFilterService,
        private $http: ng.IHttpService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private userService: IUserService,
        private configurationService: IConfigurationService
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.configurationService.getLimitSettings(() => {
            this.scenarioManagementMaxYearsDifference = this.configurationService.limitSettings.scenarioManagementMaxYearsDifference;
        });
        this.pageStartService.subscribeToWebSocketEvent(this.$scope, "scenarioEvent", (message) => { this.scenarioWebsocketEventCallback(message) });
        this.commonSvc = this.pageStartService.initialize(this.$scope, this.permission, this.dialogToken);

        this.newScenarioDisplayName = this.$scope.textLabels.NEW_SCENARIO_TEXT;

        this.commonSvc.start(() => { this.loadData(); });
    }

    private loadData() {
        this.commonSvc.loadData(this.apiUrl, this.allScenarios,
            (success, loadInto) => {
                this.setScenarioStatusDescriptions(this.allScenarios);
                this.setScenarioTypeDescriptions(this.allScenarios);
                this.setScenarioDeletionPending(this.allScenarios);
                this.setScenarioMergePending(this.allScenarios);
            }, null, true, true);
        // Select the previously selected scenario request after all data has been loaded
        this.commonSvc.onAllDataLoaded(() => {
            var id = this.userService.getUserVariable("selectedScenarioRequestId");
            if (id != undefined && this.selectedScenarioId !== id) this.selectScenario(id);
        });
    }

    selectScenario(id) {
        var idx = this.indexOfScenarioId(id);
        if (idx < 0) return;
        this.userService.setUserVariable("selectedScenarioRequestId", id);
        this.selectedScenarioId = id;
        this.selectedScenario = this.allScenarios[idx];
    }

    getCorrectedScenarioDate(date: Date): Date {
        return Timezone.correctTimeZoneInfo(date);
    }

    getSelectedScenarioStatus(): any {
        if (this.selectedScenario.status != null) return this.selectedScenario.status.status;
        return null;
    }

    actionPending(): boolean {
        return (this.selectedScenarioId === -1 ||
            this.deletePending[this.selectedScenarioId] ||
            this.renamePending[this.selectedScenarioId] ||
            this.updatePending[this.selectedScenarioId] ||
            this.mergePending[this.selectedScenarioId] ||
            this.newScenarioInputOpen ||
            this.newScenarioRequestPending);
    }

    deletePendingForSelectedScenario(): boolean {
        var id = this.selectedScenarioId;
        return (this.deletePending[id]);
    }

    canCreateNewScenario(): boolean {
        return !this.newScenarioRequestPending &&
            this.newScenarioDisplayName !== "" &&
            this.isEndDateValid() &&
            this.isStartDateValid();
    }

    openScenarioNameInput() {
        var popupWidth = $("#popupWidth");
        this.popupTop = "" + popupWidth.offset().top + "px";
        this.popupLeft = "" + popupWidth.offset().left + "px";
        this.popupWidth = "" + popupWidth.outerWidth() + "px";
        this.newScenarioDisplayName = this.selectedScenario.displayName;
        this.changeScenarioNameInputOpen = true;
    }

    closeScenarioNameInput() {
        this.changeScenarioNameInputOpen = false;
    }

    openNewScenarioInput() {
        var popupWidth = $("#popupWidth");
        this.popupTop = "" + popupWidth.offset().top + "px";
        this.popupLeft = "" + popupWidth.offset().left + "px";
        this.popupWidth = "" + popupWidth.outerWidth() + "px";
        this.newScenarioStartDate = this.startOfCurrentWeek;
        this.newScenarioEndDate = new Date(this.year, this.month + 1, 0);
        this.newScenarioInputOpen = true;
    }

    closeNewScenarioInput() {
        this.newScenarioInputOpen = false;
    }

    requestScenarioNameChange() {
        var id = this.selectedScenarioId;
        this.renamePending[id] = true;

        var requestBody = {
            id: this.selectedScenario.id,
            displayName: this.newScenarioDisplayName
        };

        this.setSelectedScenarioStatus(2);

        this.$http.put(this.apiUrl + "/UpdateProperties", requestBody).then(
            (success) => {
                this.setSelectedScenarioStatus(1);
                this.selectedScenario.displayName = this.newScenarioDisplayName;
                this.renamePending[id] = false;
                this.changeScenarioNameInputOpen = false;
            },
            (error) => {
                this.renamePending[id] = false;
                this.commonSvc.httpErrorResponse(error, () => { this.changeScenarioNameInputOpen = false; });
            });
    }

    updateScenario() {
        var id = this.selectedScenarioId;
        this.updatePending[id] = true; // assume the update (requested) is pending
        Planboard.clearData(); // to force scenario to redraw when we return to planboard

        var requestBody = {
            fromScenarioId: this.selectedScenario.originalScenarioId,
            toScenarioId: this.selectedScenario.id
        };

        this.setSelectedScenarioStatus(2);

        // do the WebAPI update (PUT) request
        this.$http.put(this.apiUrl + "/Merge", requestBody).then(
            (success) => {
                // setSelectedScenarioStatus(1); // TODO: dit werkt niet, de scenario status is pas 1 nadat de merge actie is afgerond door de service, beter om hier een timed get request te doen voor het scenario (maar lijkt momenteel geheel overbodig)
            },
            (error) => {
                this.updatePending[id] = false;
                this.commonSvc.httpErrorResponse(error, () => { });
            });
    }

    mergeScenario() {
        var id = this.selectedScenarioId;
        this.resetPlanboardScenario(id);
        this.mergePending[id] = true; // assume the update (requested) is pending
        Planboard.clearData(); // to force scenario to redraw when we return to planboard

        var requestBody = {
            fromScenarioId: this.selectedScenario.id,
            toScenarioId: this.selectedScenario.originalScenarioId
        };

        this.setSelectedScenarioStatus(2);

        // do the WebAPI update (PUT) request
        this.$http.put(this.apiUrl + "/Merge", requestBody).then(
            (success) => {
                // setSelectedScenarioStatus(1); // TODO: dit werkt niet, de scenario status is pas 1 nadat de merge actie is afgerond door de service, beter om hier een timed get request te doen voor het scenario (maar lijkt momenteel geheel overbodig)
            },
            (error) => {
                this.mergePending[id] = false;
                this.commonSvc.httpErrorResponse(error, () => { });
            });
    }

    deleteScenario() {
        var id = this.selectedScenarioId;
        var idx = this.indexOfScenarioId(id);
        this.resetPlanboardScenario(id);
        this.deletePending[id] = true;
        this.$http.delete(this.toUrl(id)).then(
            (success) => {
                // remove the scenario from the table
                if (idx !== -1) {
                    this.selectedScenarioId = -1;
                    this.allScenarios.splice(idx, 1);
                    this.deletePending[id] = false;
                }
            },
            (error) => {
                this.commonSvc.httpErrorResponse(error, () => { this.deletePending[id] = false; });
            }
        );
    }

    requestNewScenario() {
        this.month = this.newScenarioStartDate.getMonth();
        this.closeNewScenarioInput();
        this.newScenarioRequestPending = true;
        this.commonSvc.post(this.apiUrl + "/CreateFromPlanning",
            {
                newScenarioName: this.newScenarioDisplayName,
                startDate: Timezone.rollDateForWebApi(this.newScenarioStartDate),
                endDate: Timezone.rollDateForWebApi(this.newScenarioEndDate)
            },
            (success) => {
                this.newScenarioRequestPending = false;
                var scenario = success.data;
                this.addScenario(scenario);
            },
            (error) => {
                this.newScenarioRequestPending = false;
                this.commonSvc.httpErrorResponse(error, () => { });
            }, true);
    }

    /**
     * returns if start date is valid
     */
    isStartDateValid() {
        if (this.newScenarioStartDate < this.startOfCurrentWeek) {
            this.startDateBeforeCurrentWeek = true;
            return false;
        }
        if (!this.newScenarioStartDate) return false; // No start date
        if (this.newScenarioStartDate.getFullYear() - new Date().getFullYear() > this.scenarioManagementMaxYearsDifference) {
            this.dateExceedsLimitSetting = true;
            return false;
        }

        this.dateExceedsLimitSetting = false;
        this.startDateBeforeCurrentWeek = false;

        return true;
    }

    /**
    * returns if end date is valid
    */
    isEndDateValid() {
        if (!this.newScenarioEndDate) return false; // No end date
        if (!this.newScenarioEndDate) return true; // No start date
        if (TimeSpan.getDayNr(this.newScenarioEndDate) - TimeSpan.getDayNr(this.newScenarioStartDate) < 0) {
            this.dateExceedsLimitSetting = false;
            this.scenarioMaximumLengthInvalid = false;
            return false;
        }; // end date earlier than start

        this.dateExceedsLimitSetting = this.newScenarioEndDate.getFullYear() - new Date().getFullYear() > this.scenarioManagementMaxYearsDifference;

        var startDate = new Date(this.newScenarioStartDate.getTime()); // clone scenario start date
        startDate.setFullYear(startDate.getFullYear() + 1);
        startDate.setDate(startDate.getDate() - 1);
        this.scenarioMaximumLengthInvalid = startDate < this.newScenarioEndDate;

        if (this.dateExceedsLimitSetting || this.scenarioMaximumLengthInvalid) return false;

        return true;
    }

    /**
    * add scenario to $scope.allScenarios if not already in it
    * @param scenario The scenario to add
    */
    private addScenario(scenario: any) {
        if (this.indexOfScenarioId(scenario.id) === -1) {
            this.setStatusDescr(scenario);
            this.allScenarios.push(scenario);
        };
    }


    /**
    * retrieve data for a scenario and execute an action effecting the $scope.allScenarios and thus the scenarios table on the page
    * @param url The WebAPI url
    * @param action An action function to be executed after retrieval of the response.data
    * @param idx The index of the scenario in $scope.allScenarios that the action function has to be performed on
    */
    private updateScenariosTable(url: string, action: (idx: number, scenario: any ) => any, idx: number) {
        var reqCnt = this.getRequestCount(idx);
        if (reqCnt < this.maxPendingGetRequests) {
            this.setRequestCount(idx, reqCnt + 1);
            this.$http.get(url).then(
                (success) => {
                    var scenario: any = success.data;
                    this.setStatusDescr(scenario);
                    if (scenario.status.status === 1) {
                        this.updatePending[scenario.id] = false;
                        this.mergePending[scenario.id] = false;
                    };
                    this.setScenarioTypeDescr(scenario);
                    if (action) action(idx, scenario);
                    this.setRequestCount(idx, this.getRequestCount(idx) - 1);
                },
                (error) => {
                    // ignore the error, the scenario could just be deleted because of a solver result merge
                    //this.commonSvc.httpErrorResponse(error, () => { });
                    this.setRequestCount(idx, this.getRequestCount(idx) - 1);
                });
        }
    }

    /**
    * return the count of GET requests for a scenario with index idx
    * @param idx Index of the scenario in question
    * @returns the number of GET requests pending for this scenario
    */
    private getRequestCount(idx: number): number {
        var result = 0;
        if (idx >= 0) {
            result = this.requestCountDict[idx];
            if (result == undefined) result = 0;
        }
        return result;
    }

    private setRequestCount(idx: number, n: number): void {
        if (idx >= 0) this.requestCountDict[idx] = n;
    }

    private setStatusDescr(scenario: any): void {
        if (!scenario.status) return;
        switch (scenario.status.status) {
            case 1:
                scenario.status.descr = this.$scope.textLabels.SCENARIO_STATUS_AVAILABLE;
                break;
            case 2:
                scenario.status.descr = this.$scope.textLabels.SCENARIO_STATUS_PENDING;
                break;
            case 3:
                scenario.status.descr = this.$filter("number")(scenario.status.progressPercentage, 2) + "%"; // latestRequestPercentage ?
                break;
            case 4:
                scenario.status.descr = this.$scope.textLabels.SCENARIO_STATUS_FAILED;
                break;
            case 5:
                scenario.status.descr = this.$scope.textLabels.SCENARIO_STATUS_ABORTED;
                break;
            default:
                scenario.status.descr = "?";
        };
    }

    private setScenarioTypeDescr(scenario: any): void {
        switch (scenario.scenarioType) {
            case 1:
                scenario.scenarioTypeDescr = this.$scope.textLabels.SCENARIO_TYPE_PLANNING;
                break;
            case 2:
                scenario.scenarioTypeDescr = this.$scope.textLabels.SCENARIO_TYPE_DRAFT;
                break;
            case 3:
                scenario.scenarioTypeDescr = this.$scope.textLabels.SCENARIO_TYPE_SOLVERRESULT;
                break;
            default:
                scenario.scenarioTypeDescr = this.$scope.textLabels.SCENARIO_TYPE_UNKNOWN;
        }
    }

    private setScenarioStatusDescriptions(scenarios: Array<any>): void {
        for (var i = 0; i < scenarios.length; i++) this.setStatusDescr(scenarios[i]);
    }

    private setScenarioTypeDescriptions (scenarios: Array<any>): void {
        for (var i = 0; i < scenarios.length; i++) this.setScenarioTypeDescr(scenarios[i]);
    }

    private setScenarioDeletionPending(scenarios: Array<any>): void {
        for (var i = 0; i < scenarios.length; i++) this.deletePending[scenarios[i].id] = false;
    }

    private setScenarioMergePending(scenarios: Array<any>): void {
        for (var i = 0; i < scenarios.length; i++) {
            var id = scenarios[i].id;
            this.updatePending[id] = false;
            this.mergePending[id] = false;
        }
    }

    private setSelectedScenarioStatus(status: any): void {
        if (this.selectedScenario != null) {
            this.selectedScenario.status.status = status;
            this.setStatusDescr(this.selectedScenario);
        }
    }

    private toUrl(id: number): string {
        return (this.apiUrl + "/" + id);
    }

    /**
    * reset the saved planboard.scenarioId user displaysetting if it is affected by scenarioId parameter
    */
    private resetPlanboardScenario(scenarioId: number) {
        var savedId = this.userService.getDisplaySettingNumber("planboard.scenarioId", 1);
        if (savedId === scenarioId)
            this.userService.setDisplaySettingNumber("planboard.scenarioId", 1);
        // make sure the planboard will reload data for the planning scenario if it was affected by scenarioId parameter
        if (Planboard.scenarioId === scenarioId) {
            Planboard.scenarioId = 1;
            Planboard.clearData();
        }
    }

    private indexOfScenarioId(scenarioId: number): number {
        for (var i = 0; i < this.allScenarios.length; i++)
            if (this.allScenarios[i].id === scenarioId) return i;
        return -1;
    };

    private scenarioWebsocketEventCallback(message) {
        var scenarioId = Number(message.scenarioId);
        var idx = this.indexOfScenarioId(scenarioId);
        if (message.event === "Added") {
            // Insert the scenario when not already in the table
            this.updateScenariosTable(this.toUrl(scenarioId), (index, scenario) => {
                this.addScenario(scenario);
            }, -1);
        } else if (message.event === "Modified") {
            // Update the scenario entry with newly fetched data
            var updatingScenario = this.updatePending[scenarioId];
            var mergingScenario = this.mergePending[scenarioId];
            this.updateScenariosTable(this.toUrl(scenarioId), (index, scenario) => {
                this.allScenarios[index].status = scenario.status; // only update the status object in scenario
                if (scenario.status.status !== 1) this.updatePending[scenario.id] = true;
                if ((scenario.status.status === 1) && updatingScenario) this.updatePending[scenario.id] = false;
                if ((scenario.status.status === 1) && mergingScenario) this.updatePending[scenario.id] = false;
            }, idx);
        } else if (message.event === "Deleted") {
            // Delete the scenario from the table
            if (idx !== -1) {
                this.$scope.$apply(() => { this.allScenarios.splice(idx, 1) });
                this.selectedScenarioId = -1;
                this.selectedScenario = null;
            }
        };
    }
}