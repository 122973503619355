import * as Constants from './../utils/constants';

export interface IPermissionService {
    /**
        * Update the provided verification status object by checking for the specified permission.
        * @param permissionName The name of the permission to check for.
        * @param verificationStatus Variable in which to put the result of the check (true|false).
        * @param callerScope Caller scope, used for accessing text labels and triggering on the scope destroy.
        */
    userHasPermission(permissionName: string, verificationStatus: any, callerScope: any);

    /**
        * Gets all permissions for the current user, e.g. for use to filter the availability of buttons.
        */
    getAllPermissions(userPermissions, verificationStatus, callerScope);
}

// Service used to make inquiries to the backend about permissions of the currently logged in user.
export var permissionService = [
    "$http", "modalConfirmationWindowService",
    function ($http, modalConfirmationWindowService) {
        var svc = this;

        var dialogToken = "permission";

        /**
         * Add the names of the permissions for which the user has permission through at least 1 user group.
         * @param {} data Response data from WebAPI call.
         * @param {} userPermissions Object to add the granted permissions to.
         */
        var processPermissions = function (data, userPermissions) {
            userPermissions.granted = [];
            for (var i = 0; i < data.length; i++) {
                if (data[i].numberUserUserGroupsWithThisPermission > 0) {
                    userPermissions.granted.push(data[i].permissionName);
                }
            }
        }

        /**
         * Update the provided verification status object by checking for the specified permission.
         * @param {} permissionName The name of the permission to check for.
         * @param {} verificationStatus Variable in which to put the result of the check (true|false).
         * @param {} callerScope Caller scope, used for accessing text labels and triggering on the scope destroy.
         */
        svc.userHasPermission = function (permissionName, verificationStatus, callerScope) {

            // Put text labels from the translation service onto a local object and start a delayed modal window
            // that will show the user that we're busy checking their permissions.
            modalConfirmationWindowService.showModalInfoDialog(callerScope.textLabels.CHECKING_PERMISSIONS_TITLE,
                callerScope.textLabels.CHECKING_PERMISSIONS_MESSAGE, "", null, Constants.modalWaitDelay, dialogToken);

            // Make sure the window is closed again when the calling controller is destroyed.
            callerScope.$on("$destroy", function () { modalConfirmationWindowService.closeModalWindow(dialogToken); });

            return $http.get("api/Permission/HasPermission/" + encodeURIComponent(permissionName))
                .then(function (response) {
                    verificationStatus.hasPermission |= response.data;
                    verificationStatus.pending = false;
                    modalConfirmationWindowService.closeModalWindow(dialogToken);
                })
                .catch(function (data, status) {
                    console.error("Error", status, data);
                    verificationStatus.failed = true;
                    modalConfirmationWindowService.closeModalWindow(dialogToken);
                });
        }

        /**
         * Gets all permissions for the current user, e.g. for use to filter the availability of buttons.
         */
        svc.getAllPermissions = function (userPermissions, verificationStatus, callerScope) {

            // Put text labels from the translation service onto a local object and start a delayed modal window
            // that will show the user that we're busy checking their permissions.
            modalConfirmationWindowService.showModalInfoDialog(callerScope.textLabels.CHECKING_PERMISSIONS_TITLE,
                callerScope.textLabels.CHECKING_PERMISSIONS_MESSAGE, "", null, Constants.modalWaitDelay, dialogToken);

            // Make sure the window is closed again when the calling controller is destroyed.
            callerScope.$on("$destroy", function () { modalConfirmationWindowService.closeModalWindow(dialogToken); });

            userPermissions.pending = true;
            return $http.get("api/Permissions")
                .then(function (response) {
                    processPermissions(response.data, userPermissions);
                    verificationStatus.pending = false;
                    userPermissions.pending = false;
                    modalConfirmationWindowService.closeModalWindow(dialogToken);
                })
                .catch(function (data, status) {
                    console.error("Error", status, data);
                    verificationStatus.failed = true;
                    userPermissions.pending = false;
                    modalConfirmationWindowService.closeModalWindow(dialogToken);
                });
        }
    }
];