import * as Globals from './../utils/globals';
import { DrawHelper } from './drawing';

/**
    * this creates a closed polygon with rounded corners
    * copied from: https://stackoverflow.com/questions/44855794/html5-canvas-triangle-with-rounded-corners
    * @param ctx the canvas context to draw on
    * @param points an array of point objects with an x, y, and optional a maximum radius variable. e.g. [{x:10,y:10,radius:5},{x:20,y:20},{x:-10,y:25,radius:3}]
    * @param radiusAll the overall radius for all points
    */
export function drawRoundedPoly(ctx: CanvasRenderingContext2D, points: any[], radiusAll: number) {
    var i: number, x: number, y: number, len: number;
    var p1: any, p2: any, p3: any, v1: any, v2: any;
    var sinA: number, sinA90: number, radDirection: number;
    var drawDirection: boolean; // true for anti-clockwise, false for clockwise
    var angle: number, halfAngle: number, cRadius: number, lenOut: number, radius: number;

    // convert 2 points into vector form, polar form, and normalised 
    var asVec = function (p: any, pp: any, v: any) {
        v.x = pp.x - p.x;
        v.y = pp.y - p.y;
        v.len = Math.sqrt(v.x * v.x + v.y * v.y);
        v.nx = v.x / v.len;
        v.ny = v.y / v.len;
        v.ang = Math.atan2(v.ny, v.nx);
    }

    ctx.beginPath();
    radius = radiusAll;
    v1 = {};
    v2 = {};
    len = points.length;
    p1 = points[len - 1];
    // for each point
    for (i = 0; i < len; i++) {
        p2 = points[(i) % len];
        p3 = points[(i + 1) % len];
        // Part 1
        asVec(p2, p1, v1);
        asVec(p2, p3, v2);
        sinA = v1.nx * v2.ny - v1.ny * v2.nx;
        sinA90 = v1.nx * v2.nx - v1.ny * -v2.ny;
        angle = Math.asin(sinA);
        radDirection = 1;
        drawDirection = false;
        if (sinA90 < 0) {
            if (angle < 0) {
                angle = Math.PI + angle;
            } else {
                angle = Math.PI - angle;
                radDirection = -1;
                drawDirection = true;
            }
        } else {
            if (angle > 0) {
                radDirection = -1;
                drawDirection = true;
            }
        }
        if (p2.radius !== undefined) {
            radius = p2.radius;
        } else {
            radius = radiusAll;
        }
        // Part 2
        halfAngle = angle / 2;
        // Part 3
        lenOut = Math.abs(Math.cos(halfAngle) * radius / Math.sin(halfAngle));
        // Special part A
        if (lenOut > Math.min(v1.len / 2, v2.len / 2)) {
            lenOut = Math.min(v1.len / 2, v2.len / 2);
            cRadius = Math.abs(lenOut * Math.sin(halfAngle) / Math.cos(halfAngle));
        } else {
            cRadius = radius;
        }
        // Part 4
        x = p2.x + v2.nx * lenOut;
        y = p2.y + v2.ny * lenOut;
        // Part 5
        x += -v2.ny * cRadius * radDirection;
        y += v2.nx * cRadius * radDirection;
        // Part 6
        ctx.arc(x, y, cRadius, v1.ang + Math.PI / 2 * radDirection, v2.ang - Math.PI / 2 * radDirection, drawDirection);
        p1 = p2;
        p2 = p3;
    }
    ctx.closePath();
}

/**
    * draw a rectangle with a specific border thickness on a context
    */
export function drawBorder(left: number, top: number, width: number, height: number,
    borderSize: number, borderColor: string, ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = borderColor;
    ctx.fillRect(left, top, width, borderSize);
    ctx.fillRect(left, top + height - borderSize, width, borderSize);
    ctx.fillRect(left, top + borderSize, borderSize, height - borderSize * 2);
    ctx.fillRect(left + width - borderSize, top + borderSize, borderSize, height - borderSize * 2);
}

/**
    * draw the triangle part of an arrow, default pointing right or down when rotated is true
    */
export function drawArrowTriangle(id: number, destCtx: CanvasRenderingContext2D,
    destX: number, destY: number, size: number, rotated: boolean, mirrored: boolean, color: string) {
    const ctx = DrawHelper.sharedBuffer.ctx;
    if (size <= 0 || size >= ctx.canvas.width) return;
    const stored = DrawHelper.getStoredObject(id, size, size);
    const r = stored[1];
    if (!stored[2]) {
        const halfSize = Math.floor(size / 2);
        let x = rotated ? halfSize : (mirrored ? 0 : size - 1);
        let y = rotated ? (mirrored ? 0 : size - 1) : halfSize;
        ctx.fillStyle = color;
        for (let i = 0; i < halfSize; i++) {
            x += rotated ? -1 : (mirrored ? 1 : -1);
            y += rotated ? (mirrored ? 1 : -1) : -1;
            ctx.fillRect(r.left + x, r.top + y, rotated ? i * 2 + 1 : 1, rotated ? 1 : i * 2 + 1);
        }
    }
    destCtx.drawImage(ctx.canvas, r.left, r.top, size, size, destX, destY, size, size);
}

/**
    * draw a triangular corner symbol on a context
    */
export function drawCorner(id: number, destCtx: CanvasRenderingContext2D,
    destX: number, destY: number, size: number,
    flipped: boolean, mirrored: boolean, color: string, borderColor?: string,
    symbol?: string, symbolColor?: string) {
    const ctx = DrawHelper.sharedBuffer.ctx;
    if (size <= 0 || size >= ctx.canvas.width) return;
    const stored = DrawHelper.getStoredObject(id, size, size);
    const r = stored[1];
    if (!stored[2]) { // it was not stored so we need to draw it
        let x = mirrored ? size - 1 : 0;
        let y = flipped ? 0 : size - 1;
        ctx.fillStyle = color;
        for (let i = 1; i <= size; i++) {
            ctx.fillRect(r.left + x, r.top + y, i, 1);
            x += mirrored ? -1 : 0;
            y += flipped ? 1 : -1;
        }
        if (borderColor != undefined && borderColor !== "") {
            ctx.fillStyle = borderColor;
            ctx.fillRect(r.left, flipped ? r.bottom : r.top, size, 1);
            ctx.fillRect(mirrored ? r.right : r.left, r.top, 1, size);
            x = mirrored ? 0 : size - 1;
            y = flipped ? size - 1 : 0;
            for (let i = 1; i <= size; i++) {
                ctx.fillRect(r.left + x, r.top + y, 1, 1);
                x += mirrored ? 1 : -1;
                y += flipped ? -1 : 1;
            }
        }
        if (symbol != undefined && symbol !== "") {
            x = Math.floor(size * 0.5 + size * 0.2 * (mirrored ? 1 : -1));
            y = Math.floor(size * 0.5 + size * 0.2 * (flipped ? 1 : -1));
            let symbolSize = Math.max(4, Math.floor(size * 0.4));
            let oddSize = (symbolSize % 2 === 0) ? symbolSize + 1 : symbolSize;
            ctx.fillStyle = (symbolColor != undefined && symbolColor !== "") ? symbolColor : borderColor;
            if (symbol === "+" || symbol === "-")
                ctx.fillRect(r.left + x - (oddSize >> 1), r.top + y, oddSize, 1);
            if (symbol === "+")
                ctx.fillRect(r.left + x, r.top + y - (oddSize >> 1), 1, oddSize);
        }
    }
    destCtx.drawImage(ctx.canvas, r.left, r.top, size, size, destX, destY, size, size);
}

/**
    * draw a yellow warning triangle with exclamation mark symbol
    */
export function drawWarning(id: number, destCtx: CanvasRenderingContext2D,
    destX: number, destY: number, size: number) {
    const ctx = DrawHelper.sharedBuffer.ctx;
    if (size <= 0 || size >= ctx.canvas.width) return;
    const stored = DrawHelper.getStoredObject(id, size, size);
    const r = stored[1];
    if (!stored[2]) {
        const halfSize = Math.floor(size / 2);
        const arcSize = Math.floor(halfSize / 4);
        const exclSize = Math.max(1, Math.floor(halfSize / 6));
        // yellow triangle with black border
        ctx.fillStyle = "yellow";
        ctx.strokeStyle = Globals.darker3DColor;
        drawRoundedPoly(ctx, [{ x: r.left + halfSize, y: r.top + 1 - arcSize }, { x: r.left + 1, y: r.bottom - 1 }, { x: r.right - 1, y: r.bottom - 1 }], arcSize);
        ctx.fill();
        ctx.stroke();
        // exclamation mark
        ctx.fillStyle = Globals.darker3DColor;
        ctx.beginPath();
        ctx.arc(r.left + halfSize, r.bottom - 3 - exclSize * 2, Math.max(2, exclSize), 0, 2 * Math.PI, false);
        ctx.closePath();
        ctx.fill();
        ctx.beginPath();
        ctx.moveTo(r.left + halfSize, r.bottom - 4 - exclSize * 3 - Math.max(1.25, exclSize));
        ctx.lineTo(r.left + halfSize - 0.5, r.bottom - 4 - exclSize * 3 - Math.max(1.25, exclSize));
        ctx.lineTo(r.left + halfSize - 0.5 - exclSize, r.top + arcSize + exclSize + 4);
        ctx.lineTo(r.left + halfSize, r.top + arcSize + exclSize + 3);
        ctx.lineTo(r.left + halfSize + 0.5 + exclSize, r.top + arcSize + exclSize + 4);
        ctx.lineTo(r.left + halfSize + 0.5, r.bottom - 4 - exclSize * 3 - Math.max(1.25, exclSize));
        ctx.closePath();
        ctx.fill();
    }
    destCtx.drawImage(ctx.canvas, r.left, r.top, size, size, destX, destY, size, size);
}