export var defaultFont = "12pt Roboto";
export var smallFont = "9pt Roboto";
export var largeFont = "14pt Roboto";
export var largeFontBold = "bold 14pt Roboto";
export var fontHeight = 24; // TODO: should be calculated somehow based on how high (in pixels) text is displayed elsewhere in the webapp.
export var maxInt = 2147483647;
export var maxInt53 = 9007199254740991;

export var scrollBarColor1 = "rgb(232,232,236)"; // scrollbar background color
export var scrollBarColor2 = "rgb(136,136,136)"; // scrollbar bar color select
export var scrollBarColor3 = "rgb(208,209,215)"; // scrollbar bar color unselected
export var scrollBarColor4 = "rgb(28,151,234)"; // scrollbar arrow color selected
export var scrollBarColor5 = "rgb(134,137,153)"; // scrollbar arrow color unselected
export var scrollBarSize = 17; // width or height of scrollbars

export var windowColor = "rgb(255,255,255)"; // window background (white)
export var windowTextColor = "rgb(0,0,0)"; // window text (black)
export var gridLinesColor = "rgb(192,192,196)"; // grid lines (light grey)
export var face3DColor = "rgb(232,232,236)";
export var light3DColor = "rgb(232,232,236)"; // "rgb(248,248,252)";
export var dark3DColor = "rgb(192,192,196)";
export var darker3DColor = "rgb(112,112,116)";
export var highlightColor = "rgb(224,232,255)"; // highlight background (very light blue)
export var darkerHighlightColor = "rgb(208,216,255)"; // darker highlight background (light blue)
export var imposesRiskColor = "rgb(191,0,0)"; // color to use to display the imposes risk in activity hint text
export var holidayTextColor = "rgb(224,128,96)"; // light orange
export var holidayTextSymbol = "&#x1F38A;"; // alternatives: 🎉 🏝️ 🏖️ 🎊
export var orangeWarningColor = "rgb(255, 165, 0)"; // orange outline
export var resourceUnavailable = "rgb(255,31,31)"; // red

export var planningStatusColors = [null, null, "rgb(156,219,255)"]; // colors for visible planning status

export var alignCenter: CanvasTextAlign = "center";
export var alignLeft: CanvasTextAlign = "left";
export var alignRight: CanvasTextAlign = "right";
export var alignMiddle: CanvasTextBaseline = "middle";
export var alignTop: CanvasTextBaseline = "top";
export var alignBottom: CanvasTextBaseline = "bottom";

export var resourceCountersRefreshDelay = 3000; // when 1 activity changed and counters need to be refreshed for the resource involved
export var resourceCountersInitialDelay = 1000; // when changing the counters period or when first loading counter data for resources

/**
    * initialize a canvas context by setting the smoothing options and default font
    * @param ctx the canvas context to initialize
    */
export function initContext(ctx: any) {
    ctx.globalCompositeOperation = "source-over";
    // set Chrome image smoothing to false
    if (typeof ctx.imageSmoothingEnabled !== "undefined") ctx.imageSmoothingEnabled = false;
    else if (typeof ctx.webkitImageSmoothingEnabled !== "undefined") ctx.webkitImageSmoothingEnabled = false;
    // set IE image smooting to false
    if (typeof ctx.msImageSmoothingEnabled !== "undefined") ctx.msImageSmoothingEnabled = false;
    // set Firefox image smoothing to false
    if (typeof ctx.mozImageSmoothingEnabled !== "undefined") ctx.mozImageSmoothingEnabled = false;
    // set Opera image smoothing to false
    if (typeof ctx.oImageSmoothingEnabled !== "undefined") ctx.oImageSmoothingEnabled = false;
    ctx.font = defaultFont;
}

/**
    * convert a date and time to readable string
    * @param dt the date object to convert
    * @param includeDate if the date part should be in the result
    * @param includeTime if the time part should be in the result
    */
export function dateToDisplayStr(dt: Date, includeDate: boolean, includeTime: boolean): string {
    let text = "";
    if (includeDate) {
        const dateOptions = { weekday: "short", month: "short", day: "numeric" };
        text = dt.toLocaleString(undefined, dateOptions);
    }
    if (includeTime) {
        const timeOptions = { hour: "2-digit", minute: "2-digit" };
        text += (text === "" ? "": " ") + dt.toLocaleTimeString(undefined, timeOptions);
    }
    return text;
}

/**
    * format a date, override this function in the controller
    */
export function dateFormat(dt: Date, format: string): string {
    return "";
}

/**
    * format a string, e.g. OMRP.Globals.stringFormat("Test {0}. Dit is een {1} en nog een {2}{0}.", ["123", "Testerdetest", "test:"])
    * @param str the string to format
    * @param params values to insert
    */
export function stringFormat(str: string, params: string[]): string {
    for (let i = 0; i < params.length; i++) {
        const searchStr = `{${i}}`;
        let index = 1;
        while (index >= 0) {
            index = str.indexOf(searchStr);
            if (index >= 0) str = str.slice(0, index) + params[i] + str.slice(index + searchStr.length);
        }
    }
    return str;
}