import { StateService } from '@uirouter/angularjs';
import { IPageStartService } from './../../../shared/pageStartService';
import { IUserService } from './../../../shared/userService';

import { ITranslationService } from './../../i18n/translationService';
import { IAuthenticationService } from './../../login/authenticationService';
import { IPermissionService } from './../../permissions/permissionService';

import * as Globals from './../../planboard/utils/globals';

import { ITreeListScope } from './../../treeListController/ITreeListScope';
import { TreeEntity } from './../../treeListController/TreeEntity';
import { TreeListController } from './../../treeListController/TreeListController';
import { VerificationStatus } from './../../treeListController/TreeListScope';

import * as Constants from './../../utils/constants';
import { Dictionary } from './../../utils/dictionary';
import { IModalConfirmationWindowService } from './../../utils/modalConfirmationWindowService';

import { OrganizationUnit } from './../organizationUnits/organizationUnit';

import { IUserScope } from './IUserScope';
import { User } from './user';

export class UsersController extends TreeListController {

    scope: IUserScope;

    dialogService: IModalConfirmationWindowService;

    // webApi urls
    urlPasswordReset = "api/Users/{0}/ResetPassword";

    dialogTokenPasswordReset = "passwordreset";

    private selectedUnitSetting: string;
    private includeChildOrganizationUnitsSetting: string;

    static $inject = [
        "$http",
        "$q",
        "$scope",
        "$state",
        "$timeout",
        "$filter",
        "$window",
        "permissionService",
        "modalConfirmationWindowService",
        "translationService",
        "pageStartService",
        "authenticationService",
        "userService"
    ];

    constructor(
        $http: ng.IHttpService,
        $q: ng.IQService,
        $scope: IUserScope,
        $state: StateService,
        $timeout: ng.ITimeoutService,
        $filter: ng.IFilterService,
        $window: ng.IWindowService,
        permissionService: IPermissionService,
        modalConfirmationWindowService: IModalConfirmationWindowService,
        translationService: ITranslationService,
        protected pageStartService: IPageStartService,
        protected authenticationService: IAuthenticationService,
        userService: IUserService
    ) {
        super($http, $q, $scope, $state, $timeout, $filter, permissionService, modalConfirmationWindowService, translationService, pageStartService, userService);

        $window.location.href = "/angularomrp/program-management/users";

        this.apiUrl = "api/Users";
        this.selectedUnitSetting = "users.selectedOrganizationUnit";
        this.includeChildOrganizationUnitsSetting = "users.includeChildOrganizationUnit";

        this.dialogService = modalConfirmationWindowService;
        this.userService = userService;

        //See whether the currently logged in user has the "Users" permission and set flags accordingly.
        $scope.verificationStatus = new VerificationStatus();
        permissionService.userHasPermission("Users", $scope.verificationStatus, $scope);

        // get data necessary for this model & controller
        this.getRelatedOrganizationUnits();
        this.getEntities(true);
        this.getAvailableLanguages();

        this.scope.userGroupDict = new Dictionary();
        this.scope.filteredUserGroupsDict = new Dictionary();
        this.getUserGroups();

        $scope.useSaml2 = authenticationService.useSaml2;
        $scope.useLocalLogin = authenticationService.useLocalLogin;
    }

    protected isSelectedItemWritable(): boolean {
        if (!this.scope.selectedItem) return false;
        return true;
    }

    protected isSelectedEntityDeletable(): boolean {
        if (!this.scope.selectedItem) return false;
        return true;
    }

    protected newEntityDisplayName() {
        return this.scope.textLabels.NEW_USER;
    }

    /**
    * Returns the title of the modal delete confirmation window.
    * Should be overridden in controllers that make use of this functionality.
    */
    protected getDeleteConfirmationWindowTitle(): string {
        return this.scope.textLabels.USERS_DELETION_MODAL_TITLE;
    }

    /**
        * Returns the text of the modal delete confirmation window.
        * Should be overridden in controllers that make use of this functionality.
    */
    protected getDeleteConfirmationWindowText(): string {
        return this.scope.textLabels.USERS_DELETION_MODAL_TEXT;
    }

    /**
        * Asks for confirmation and resets the selected user's password upon confirmation.
        */
    protected confirmPasswordReset() {
        this.dialogService.showModalDialog(this.scope.textLabels.USERS_PASSWORD_RESET,
            this.scope.textLabels.PASSWORD_CONFIRM_RESET,
            () => { this.passwordReset(); },
            () => {});
    }

    /**
        * Resets the selected user's password.
        */
    protected passwordReset() {
        if (!this.scope.selectedItem) return;

        this.dialogService.showModalInfoDialog(
            this.scope.textLabels.USERS_PASSWORD_RESET,
            this.scope.textLabels.PASSWORD_RESETTING,
            "",
            null, Constants.modalWaitDelay, this.dialogTokenPasswordReset);

        ((ctrl: UsersController) => {
            ctrl.http.post(Globals.stringFormat(ctrl.urlPasswordReset, [`${ctrl.scope.selectedItem.id}`]),
                    ctrl.scope.selectedItem)
                .then(
                    response => {
                        ctrl.dialogService.closeModalWindow(ctrl.dialogTokenPasswordReset);
                        ctrl.dialogService.showModalInfoDialog(
                            ctrl.scope.textLabels.USERS_PASSWORD_RESET,
                            ctrl.scope.textLabels.PASSWORD_RESET_OK,
                            ctrl.scope.textLabels.OK,
                            null, 0, ctrl.dialogTokenPasswordReset);
                    },
                    response => {
                        //console.log(ctrl.scope.textLabels.PASSWORD_RESET_ERROR);
                        ctrl.dialogService.closeModalWindow(ctrl.dialogTokenPasswordReset);
                        ctrl.dialogService.showModalInfoDialog(
                            ctrl.scope.textLabels.USERS_PASSWORD_RESET,
                            ctrl.getErrorMessage(response),
                            ctrl.scope.textLabels.OK,
                            null, 0, ctrl.dialogTokenPasswordReset);
                    });
        })(this);
    }

    /**
        * Gets the languages available to users from the WebAPI.
        */
    private getAvailableLanguages() {
        this.http.get("api/i18n/AvailableLanguages")
            .then(response => {
                this.scope.availableLanguages = <any[]>response.data;
            },
            response => {
                this.modalConfirmationWindowService
                    .showModalInfoDialog(this.scope.textLabels.ERROR_OCCURRED,
                    this.getErrorMessage(response),
                    this.scope.textLabels.OK,
                    () => { },
                    0,
                    "usersCtrlGetAvailableLanguages");
            });
    }

    /**
        * Creates a new entity object that can be inserted. This overrides the default createNewEntity in TreeListScope
        */
    protected createNewEntity(): TreeEntity {
        const entity = new User();
        entity.id = -1;
        entity.receivedOrder = 0; // value of exactly 0 avoids sorting in the sortLastEntity function
        entity.displayName = this.scope.newUserDisplayName;
        entity.userName = this.scope.newUserName;
        entity.emailAddress = this.scope.newUserEmail;
        return entity;
    }

    /**
        * Make the popup div for creating a new user visible and place it in the center of the browser.
        */
    protected openNewUserInput() {
        const popupWidth = $("#popupWidth");
        this.scope.popupTop = `${popupWidth.offset().top}px`;
        this.scope.popupLeft = `${popupWidth.offset().left}px`;
        this.scope.popupWidth = `${popupWidth.outerWidth()}px`;
        this.scope.newUserDisplayName = this.newEntityDisplayName();
        this.scope.newUserName = "";
        this.scope.newUserEmail = "";
        this.scope.newUserInputOpen = true;
        this.scope.busyCreatingUser = false;
    }

    /**
        * Hide the popup div for creating a new user.
        */
    protected closeNewUserInput() {
        this.scope.newUserInputOpen = false;
        this.scope.busyCreatingUser = false;
    }

    /**
        * Hide the popup div and create a new user.
        */
    protected createNewUser() {
        this.scope.busyCreatingUser = true;
        this.newEntity(() => { this.closeNewUserInput(); }, () => { this.scope.busyCreatingUser = false; });
    }

    protected removeUser(user: User) {
        this.confirmDeleteEntity(user).result.then(confirmed => {
            if (confirmed && user.userName == this.userService.getUserName()) {
                this.authenticationService.logoff(() => {}, () => {});
            }
        });
    }

    /**
        * Returns if a new user can be created with the information provided.
        */
    protected canCreateNewUser(): boolean {
        return this.scope.newUserDisplayName !== "" && this.scope.newUserName !== "" && !this.scope.busyCreatingUser &&
            (this.scope.newUserEmail !== "" || this.scope.useSaml2);
    }

    protected getRelatedOrganizationUnits() {
        super.getRelatedOrganizationUnits(() => this.onOrganizationUnitsLoaded(this.scope));
    }

    /**
        * Flag to see if organization units have been loaded. This necessary to see if the filter can be applied.
        */
    private organizationUnitsLoaded = false;
    private onOrganizationUnitsLoaded(myScope: IUserScope) {
        // Register all organization units as children to their parents. This is needed for being able to filter on units including children.
        this.scope.relatedOrganizationUnitDict.forEach((key, value) => {
            this.registerChildToParent(value, null);
        });
            
        // Restore settings for selected unit and include children checkbox.
        this.scope.filterOrganizationUnitId = this.userService.getDisplaySettingNumber(this.selectedUnitSetting, 0);
        this.scope.filterOnChildOrganizationUnits = this.userService.getDisplaySettingSwitch(this.includeChildOrganizationUnitsSetting);

        this.organizationUnitsLoaded = true;
        if (this.usersLoaded) this.filterOrganizationUnitChanged(0);
    }

    /**
        * Triggers when the user makes a different filter selection
        */
    protected filterOrganizationUnitChangedTimed(itemId: number) {
        this.timeout(() => { this.filterOrganizationUnitChanged(itemId); }, 0);
    }

    /**
        * Event handler for a changed organization unit selection. Updates the user group ids for which users should be shown.
        * @param itemId Item id to filter on. Set to 0 or leave undefined to have this directly read from the control.
        */
    protected filterOrganizationUnitChanged(itemId: number) {
        if (itemId == undefined || itemId < 1) itemId = this.scope.filterOrganizationUnitId;

        //console.log("Selected unit:", itemId);

        this.scope.filteredUserGroupsDict.clear();

        const unit = this.scope.relatedOrganizationUnitDict.value(itemId) as OrganizationUnit;

        if (unit == null) return;

        // Clear the array of recently created entities.
        this.scope.recentlyModifiedEntityIds.clear();

        // Add groups that have permissions on the selected unit.
        for (let permission of unit.userGroupPermissionList) {
            if (permission.permission > 0) {
                //console.log(permission.userGroupId);
                this.scope.filteredUserGroupsDict.add(permission.userGroupId, permission.userGroupId);
            }
        }

        if (this.scope.filterOnChildOrganizationUnits) {

            // Add any groups that have permissions on children of the selected unit.
            for (let childId of unit.childIds) {
                //console.log("Child:", childId);

                const child = this.scope.relatedOrganizationUnitDict.value(childId) as OrganizationUnit;

                for (let permission of child.userGroupPermissionList) {
                    if (permission.permission > 0) {
                        //console.log(permission.userGroupId);
                        this.scope.filteredUserGroupsDict.add(permission.userGroupId, permission.userGroupId);
                    }
                }
            }
        }

        // Save new selection to user settings.
        this.userService.setDisplaySettingNumber(this.selectedUnitSetting, itemId);
        this.userService.setDisplaySettingSwitch(this.includeChildOrganizationUnitsSetting, this.scope.filterOnChildOrganizationUnits);

        // Recreate the paging.
        this.initActivePage(null, true);
    }

    /**
        * Flag to see if users have been loaded. This necessary to see if the filter can be applied.
        */
    private usersLoaded = false;

    /**
        * Is a specific entity visible, can be used to filter entities.
        * Should be overridden in controllers that make use of this functionality.
        */
    protected isEntityVisible(entity: TreeEntity): boolean {

        const user = entity as User;

        //console.log("Recently added:", this.scope.recentlyModifiedEntityIds.getStore());

        // If filtering for no user groups only return users without group
        if (this.scope.filterOnNoUserGroup) {
            if (user.userGroupIds.length === 0) return true;
            else return false;

        }

        if (this.scope.recentlyModifiedEntityIds.containsKey(entity.id)) return true;
        if (user.userGroupIds) {
            for (let userGroupId of user.userGroupIds) {
                if (this.scope.filteredUserGroupsDict.containsKey(userGroupId)) return true;
            }
        }
            

        return false;
    }

    /**
         * callback when the selected user groups list changes
         */
        protected onUserGroupsChanged() {
            if (!this.scope.selectedItem) return;

            const user = this.scope.selectedItem as User;
            user.selectedUserGroupsChanged = true;

            this.onEntityChanged(true, this.scope.selectedItem);
        }

        /**
         * called when a user is selected
         */
        protected onEntitySelected() {
            this.scope.canAddUserGroups = this.isSelectedItemWritable();
        }

        /**
    * Callback function called after the entityDict dictionary is filled, this callback function will fill the entities array/tree.
    * @param myScope current scope
    */
    protected onEntitiesLoaded(myScope: ITreeListScope) {
        super.onEntitiesLoaded(myScope);

        this.usersLoaded = true;
        if (this.organizationUnitsLoaded) this.filterOrganizationUnitChanged(0);
    }

}

