import { Timer } from './../utils/timer';
import * as Globals from './../utils/globals';

import { BasicControl } from './basicControl';

/**
    * scrollbar control
    */
export class ScrollBarControl extends BasicControl {
    maxValue: number; // the maximum value
    value: number; // the current value
    largeChange: number; // largechange value
    smallChange = 1; // smallchange (button+-) value
    barStep = 1; // when dragging the scrollbar it goes in steps
    private mouseOverNr = 0; // part of the scrollbar the mouse is over
    private buttonSize: number; // size of the +- buttons
    private barTotalSize: number; // size of the scrollbar without the buttons
    private barSize: number; // size of the bar
    private barPos: number; // position of the bar
    private mouseDownPos: number; // position the mouse was pressed
    private mouseIsDown = false; // indicates if the mouse was pressed
    private scrollTimer: Timer = null; // timer for scrolling when mouse is pressed on a button
    private lastMouseX: number; // last mouse x position
    private lastMouseY: number; // last mouse y position
    private targetValue = -1; // targetvalue when scrolling with timer

    /**
        * the change callback, override to do something interesting
    */
    change = (t: ScrollBarControl, action: number) => { }

    /**
        * create a new scrollbar
        * @param owner the owner control
        * @param left x position from owner
        * @param top y position from owner
        * @param width width of the control
        * @param height height of the control
        */
    constructor(owner: BasicControl, left: number, top: number, width: number, height: number) {
        super(owner, left, top, width, height);
        this.useMouseWheel = true;
        this.userDraw = true;
    }

    /**
        * return the height or width of the control, wichever is greater
        */
    get size(): number {
        return Math.max(this.position.width, this.position.height);
    }

    /**
        * return the last mouse position in the control, y for vertical scrollbar, x for horizontal scrollbar
        */
    get lastMousePos(): number {
        return this.position.width > this.position.height ? this.lastMouseX : this.lastMouseY;
    }

    /**
        * disable the scroll timer
        */
    disableScrollTimer() {
        if (this.scrollTimer != null) this.scrollTimer.enabled = false;
        this.targetValue = -1;
    }

    /**
        * stop the scrolling because of mousewheel
        */
    stopWheelScroll() {
        if (this.targetValue !== -1) this.disableScrollTimer();
    }

    /**
        * enable the scroll timer
        * @param interval interval in miliseconds
        */
    private enableScrollTimer(interval: number) {
        if (this.scrollTimer == null) {
            this.scrollTimer = new Timer(this, interval);
            this.scrollTimer.tick = (t: Timer) => {
                var owner = t.owner as ScrollBarControl;
                var newValue = owner.value;
                if (owner.targetValue < 0) {
                    if (owner.mouseIsDown && owner.lastMouseX >= 0 && owner.lastMouseY >= 0 && owner.lastMouseX < owner.position.width && owner.lastMouseY < owner.position.height) {
                        if (owner.mouseOverNr === 1 && owner.lastMousePos < owner.buttonSize) newValue -= owner.smallChange;
                        if (owner.mouseOverNr === 2 && owner.lastMousePos >= owner.size - owner.buttonSize) newValue += owner.smallChange;
                        if (owner.mouseOverNr === 4 && owner.lastMousePos >= owner.buttonSize && owner.lastMousePos < owner.buttonSize + owner.barPos) newValue -= owner.largeChange;
                        if (owner.mouseOverNr === 5 && owner.lastMousePos >= owner.buttonSize + owner.barPos + owner.barSize && owner.lastMousePos < owner.size - owner.buttonSize) newValue += owner.largeChange;
                    }
                } else {
                    if (newValue === owner.targetValue) {
                        owner.disableScrollTimer();
                        return;
                    }
                    if (this.controller.mouseOverControl === this)
                        this.mouseOverNr = this.determineMouseOverNr(this.lastMouseX, this.lastMouseY);
                    var scrollspeed = Math.min(32, 1 + (Math.abs(newValue - owner.targetValue) * 0.1));
                    if (owner.targetValue < newValue)
                        newValue = Math.floor(Math.max(owner.targetValue, newValue - scrollspeed));
                    else if (owner.targetValue > newValue)
                        newValue = Math.floor(Math.min(owner.targetValue, newValue + scrollspeed));
                }
                newValue = Math.floor(Math.max(0, Math.min(newValue, owner.maxValue)));
                if (newValue !== owner.value) {
                    owner.value = newValue;
                    owner.controller.onScrollStart();
                    owner.change(owner, owner.targetValue < 0 ? owner.mouseOverNr : 3);
                    this.controller.redrawControl(owner);
                    if (t.interval > 20)
                        t.restart(Math.max(Math.floor(t.interval * 0.5), 20));
                }
                else if (t.interval < 500)
                    t.restart(500);
            }
        }
        this.scrollTimer.restart(interval);
    }

    /**
        * scroll to a specific target value using the scroll timer
        * @param target value to scroll to
        */
    scrollToTarget(target: number) {
        target = Math.floor(Math.max(Math.min(target, this.maxValue), 0));
        if (target !== this.value) {
            this.disableScrollTimer();
            this.targetValue = target;
            this.enableScrollTimer(17);
        }
    }

    /**
        * calculate bar position and size
        */
    calculatePositions() {
        this.value = Math.floor(Math.max(0, Math.min(this.value, this.maxValue)));
        this.buttonSize = Math.floor(Math.min(this.position.width, this.position.height));
        this.barTotalSize = Math.floor(Math.max(this.position.width, this.position.height) - this.buttonSize * 2);
        this.barSize = this.maxValue <= 0 ? this.barTotalSize : this.buttonSize + (((this.barTotalSize - this.buttonSize) * this.largeChange) / (this.maxValue + this.largeChange));
        this.barSize = Math.floor(Math.min(this.barSize, this.barTotalSize));
        this.barPos = this.maxValue <= 0 ? 0 : ((this.barTotalSize - this.barSize) * this.value) / this.maxValue;
        this.barPos = Math.floor(this.barPos);
    }

    /**
        * draw callback
        * @param left x position on screen
        * @param top y position on screen
        */
    draw(left: number, top: number) {
        super.draw(left, top);
        const context = this.controller.ctx;
        this.calculatePositions();
        context.fillStyle = Globals.scrollBarColor1;
        context.fillRect(left, top, this.position.width, this.position.height);

        if (!this.enabled || this.buttonSize <= 0 || this.barTotalSize <= 0 || this.barSize <= 0)
            return;

        var i: number;
        if (this.position.width > this.position.height) {
            context.fillStyle = this.mouseOverNr === 3 ? Globals.scrollBarColor2 : context.fillStyle = Globals.scrollBarColor3;
            context.fillRect(left + this.buttonSize + this.barPos, top + 4, this.barSize, this.buttonSize - 8);
            context.fillStyle = this.value <= 0 ? Globals.scrollBarColor3 :
                this.mouseOverNr === 1 ? Globals.scrollBarColor4 : context.fillStyle = Globals.scrollBarColor5;
            for (i = 0; i < 5; i++)
                context.fillRect(left + Math.floor(this.buttonSize * 0.5) - 2 + i, top + Math.floor(this.buttonSize * 0.5) - i, 1, 1 + i * 2);
            context.fillStyle = this.value >= this.maxValue ? Globals.scrollBarColor3 :
                this.mouseOverNr === 2 ? Globals.scrollBarColor4 : context.fillStyle = Globals.scrollBarColor5;
            for (i = 0; i < 5; i++)
                context.fillRect(left + this.position.width - Math.floor(this.buttonSize * 0.5) + 2 - i, top + Math.floor(this.buttonSize * 0.5) - i, 1, 1 + i * 2);
        } else {
            context.fillStyle = this.mouseOverNr === 3 ? Globals.scrollBarColor2 : context.fillStyle = Globals.scrollBarColor3;
            context.fillRect(left + 4, top + this.buttonSize + this.barPos, this.buttonSize - 8, this.barSize);
            context.fillStyle = this.value <= 0 ? Globals.scrollBarColor3 :
                this.mouseOverNr === 1 ? Globals.scrollBarColor4 : context.fillStyle = Globals.scrollBarColor5;
            for (i = 0; i < 5; i++)
                context.fillRect(left + Math.floor(this.buttonSize * 0.5) - i, top + Math.floor(this.buttonSize * 0.5) - 2 + i, 1 + i * 2, 1);
            context.fillStyle = this.value >= this.maxValue ? Globals.scrollBarColor3 :
                this.mouseOverNr === 2 ? Globals.scrollBarColor4 : context.fillStyle = Globals.scrollBarColor5;
            for (i = 0; i < 5; i++)
                context.fillRect(left + Math.floor(this.buttonSize * 0.5) - i, top + this.position.height - Math.floor(this.buttonSize * 0.5) + 2 - i, 1 + i * 2, 1);
        }
    }

    /**
        * mouseWheel callback to replace the default from basicControl
        * @param x
        * @param y
        * @param delta
        */
    mouseWheel(x: number, y: number, delta: number) {
        if (!this.enabled) return;
        this.scrollToTarget((this.targetValue < 0 ? this.value : (this.targetValue + this.value) * 0.5) + delta * this.largeChange);
    }

    /**
        * mouseDown callback to replace the default from basicControl
        * @param x left screen position
        * @param y top screen position
        * @param button mousebutton number: 0 = left
        */
    mouseDown(x: number, y: number, button: number) {
        if (!this.enabled) return;
        x -= this.screenPos.left;
        y -= this.screenPos.top;
        this.mouseIsDown = true;
        if (this.mouseOverNr === 3) {
            this.disableScrollTimer();
            if (this.position.width > this.position.height)
                this.mouseDownPos = x - (this.buttonSize + this.barPos);
            else
                this.mouseDownPos = y - (this.buttonSize + this.barPos);
        }
        let newValue = this.value;
        if (this.mouseOverNr === 1) newValue -= this.smallChange;
        if (this.mouseOverNr === 2) newValue += this.smallChange;
        if (this.mouseOverNr === 4) newValue -= this.largeChange;
        if (this.mouseOverNr === 5) newValue += this.largeChange;
        newValue = Math.floor(Math.max(0, Math.min(newValue, this.maxValue)));
        if (newValue !== this.value) {
            this.value = newValue;
            this.controller.onScrollStart();
            this.change(this, this.mouseOverNr);
            this.redraw();
            this.enableScrollTimer(500);
        }
    }

    /**
        * change the value of the scrollbar
        */
    setValue(newValue: number) {
        newValue = Math.floor(Math.max(0, Math.min(newValue, this.maxValue)));
        if (newValue !== this.value) {
            const action = newValue > this.value ? 2 : 1;
            this.disableScrollTimer();
            this.value = newValue;
            this.controller.onScrollStart();
            this.change(this, action);
            this.redraw();
        }
    }

    /**
        * recalculate the mouseDownPos variable, in case the scrollbar changed position while scrolling
        */
    recalculateMouseDownPos() {
        if (this.position.width > this.position.height)
            this.mouseDownPos = this.lastMouseX - (this.buttonSize + this.barPos);
        else
            this.mouseDownPos = this.lastMouseY - (this.buttonSize + this.barPos);
    }

    /**
        * mouseUp callback to replace the default from basicControl
        * @param x left screen position
        * @param y top screen position
        * @param button mousebutton number: 0 = left
        */
    mouseUp(x: number, y: number, button: number) {
        this.disableScrollTimer();
        this.mouseIsDown = false;
        if (this.mouseOverNr !== 0) {
            if (x < this.screenPos.left || y < this.screenPos.top || x > this.screenPos.right || y > this.screenPos.bottom)
                this.mouseOverNr = 0;
            else
                this.mouseOverNr = this.determineMouseOverNr(x - this.screenPos.left, y - this.screenPos.top);
            this.redraw();
        }
    }

    /**
        * determine the mouse over number based on mouse position
        * @param x left position on control
        * @param y top position on control
        */
    private determineMouseOverNr(x: number, y: number): number {
        let newMouseOverNr = 0;
        if (this.position.width > this.position.height) {
            if (x >= this.buttonSize + this.barPos && x < this.buttonSize + this.barPos + this.barSize)
                newMouseOverNr = 3;
            if (x < this.buttonSize)
                newMouseOverNr = 1;
            if (x >= this.position.width - this.buttonSize)
                newMouseOverNr = 2;
            if (x >= this.buttonSize && x < this.buttonSize + this.barPos)
                newMouseOverNr = 4;
            if (x >= this.buttonSize + this.barPos + this.barSize && x < this.position.width - this.buttonSize)
                newMouseOverNr = 5;
        } else {
            if (y >= this.buttonSize + this.barPos && y < this.buttonSize + this.barPos + this.barSize)
                newMouseOverNr = 3;
            if (y < this.buttonSize)
                newMouseOverNr = 1;
            if (y >= this.position.height - this.buttonSize)
                newMouseOverNr = 2;
            if (y >= this.buttonSize && y < this.buttonSize + this.barPos)
                newMouseOverNr = 4;
            if (y >= this.buttonSize + this.barPos + this.barSize && y < this.position.height - this.buttonSize)
                newMouseOverNr = 5;
        }
        return newMouseOverNr;
    }

    /**
        * mouseMove callback to replace the default from basicControl
        * @param x left screen position
        * @param y top screen position
        * @param button mousebutton number: 0 = left
        */
    mouseMove(x: number, y: number, button: number) {
        if (!this.enabled || this.maxValue <= 0) return;
        x -= this.screenPos.left;
        y -= this.screenPos.top;
        this.lastMouseX = x;
        this.lastMouseY = y;
        if (!this.mouseIsDown) {
            const newMouseOverNr = this.determineMouseOverNr(x, y);
            if (newMouseOverNr !== this.mouseOverNr) {
                this.mouseOverNr = newMouseOverNr;
                this.redraw();
            }
        } else {
            if (this.mouseOverNr === 3 && this.maxValue !== 0) {
                let newBarPos: number;
                let newValue: number;
                if (this.position.width > this.position.height)
                    newBarPos = (x - this.buttonSize) - this.mouseDownPos;
                else
                    newBarPos = (y - this.buttonSize) - this.mouseDownPos;
                newValue = (newBarPos * this.maxValue) / (this.barTotalSize - this.barSize);
                if (this.barStep > 1) newValue = Math.floor(newValue / this.barStep) * this.barStep;
                newValue = Math.floor(Math.max(0, Math.min(newValue, this.maxValue)));
                if (newValue !== this.value) {
                    this.value = newValue;
                    this.controller.onScrollStart();
                    this.change(this, this.mouseOverNr);
                    this.redraw();
                }
            }
        }
    }

    /**
        * mouseLeave callback to replace the default from basicControl
        */
    mouseLeave() {
        if (this.useMouseWheel) this.disableScrollTimer();
        this.mouseIsDown = false;
        if (this.mouseOverNr !== 0) {
            this.mouseOverNr = 0;
            this.redraw();
        }
    }
}