import { Dictionary, OrderedDictionary } from './../../utils/dictionary';
import * as Timezone from './../../utils/timezone';
import * as Constants from './../../utils/constants';

import { PlanboardSplitters } from './../components/planboardSplitters';
import { PlannedActivities } from './../components/plannedActivities';
import { PlanboardResources } from './../components/planboardResources';
import { OpenActivities } from './../components/openActivities';

import { MainController } from './../controls/mainController';
import { SplitterControl } from './../controls/splitterControl';
import { AreaControl } from './../controls/areaControl';
import { PictureControl } from './../controls/pictureControl';

import { DrawHelper } from './../drawing/drawing';
import { ActivityInfo } from './../drawing/activityInfo';
import * as Timeline from './../drawing/timeline';

import { ResourcePlanningStatus } from './../entities/resourcePlanningStatus';

import { Timer } from './../utils/timer';
import * as Globals from './../utils/globals';
import { ObjectList } from './../utils/objectlist';
import { TimeSpan } from './../utils/timespan';
import { WebApi } from './../utils/webapi';

import { Activity, ActivityList } from './activity';
import { ActivityType } from './activitytype';
import { Resource } from './resource';
import { ResourceActivities } from './resourceActivities';
import { ResourceType } from './../../programManagement/resourceTypes/resourceType';

export class Planboard {
    // controls
    static controller: MainController = null; // controller for planboard
    static split1: SplitterControl; // main splitter for all areas
    static split2: SplitterControl; // splitter for lower part so it can have a header
    static areaMain: AreaControl; // the main area where the activities are shown
    static areaResourceHeader: AreaControl; // the area where resource header is shown
    static areaResources: AreaControl; // the area where resource data is shown
    static areaCounterHeader: AreaControl; // the area where counter header is shown
    static areaCounters: AreaControl; // the area where counters for resources are shown
    static areaDate: AreaControl; // the top area with the dates
    static areaToPlan: AreaControl;
    static dragDropControl: PictureControl;
    static toolTipControl: PictureControl;
    static horizontalIndicator: PictureControl;
    static verticalIndicator: PictureControl;
    static verticalIndicatorCounters: PictureControl;

    // refresh timer
    static refreshTimer: Timer; // used by Planboard.timedRefresh() function 
    static activityTypesLoading = false; // indicates if activity types are currently still loading
    static resourcesLoading = false; // indicates if resources are currently still loading
    static cancelInitialResources = false; // set to true to cancel loading resources

    // public variables
    static dataRequested = false; // if any data has already been requested (such as resources and activitytypes)
    static textLabels: Object;
    static leftDate = new Date();
    static firstDayOfWeek = 1; // 0 = sunday, 1 = monday
    static dayStartHour = 6;
    static dayEndHour = 23;
    static scenarioId = 1;
    static zoomLevel = 4;
    static planningStatusHeight = 0;
    static viewRevision = 0;
    static resizeActivity: Activity = null;
    static multiSelectedActivities: Dictionary = null; // list of multiple selected activities Ids, only contains Ids of the activities
    static selectedActivity: Activity = null; // the last selected activity
    static selectedActivityPos: [number, number]; // mouseX, mouseY
    static selectedActivityCol: number;
    static selectedActivityRow: number;
    static selectedActivityTypeId = -1;
    static mouseDownDate: Date = null;
    static lastMouseDownPos: [number, number];
    static readOnly = false;
    static swapActivitiesOnlySameDay = false; // true to only allow swapping activities on the same day
    static daymarkStartTime: Date = null; // date containing start time to use for daymark if planned with click
    static daymarkDurationMinutes: number = null; // the duration of the daymark in minutes
    static daymarkActivityTypeId: number = null; // the activity type id of the last saved daymark start time and duration

    static activityTypes = new ObjectList(); // all activitytypes
    static resourceTypes: Dictionary = null; // all resourcetypes 
    static userFilterActivityTypeIds: number[] = null; // array of user-selected activity type ids that should be visible (including root and leaf types)
    static filterActivityTypeIds: number[] = null; // array of user-selected activity type ids that should be visible depending on user preference filterByLeafActivityTypes
    static activities = new ResourceActivities(); // activities per date per resource
    static resourceProperties: [any]; // all available resource properties
    static resourceCooperations = new Dictionary(); // all resource cooperations for resources the user can read
    static holidaysPerYear = new ObjectList(); // all loaded holidays grouped by year

    static sortByResourcePropertyIds = "-1"; // the resource properties to sort the resource list on
    static sortDescending = false; // true to sort the resource list in descending order
    static applyUnitFilterToResources = false; // true to apply the user defined organization unit filter to the list of resources
    static applyCustomResourceFilter = false; // true to apply the user defined custom resource filter and order to the list of resources
    static applyResourcesToHide = false; // true to apply the user defined custom resources to hide
    static applySkillsFilter = false; //true to apply the user defined custem skill filter on resources

    static requestRefreshResourceList = false; // set to true to reload the resourcelist when opening the planboard

    static activityChangesDeleted = false; // used to remember if activity changes have already been deleted
    static savedResourceLineheights: Array<{ resourceId: number, lineheight: number }> = [];
    static globalLineHeight: number;
    static autoRowHeight = false; // true to automatically enlarge rows the moment the row is drawn

    /**
        * events that will be fired when activity types have finished loading
        */
    static onActivityTypesLoaded = new Dictionary();

    /**
        * events that will be fired when the leftDate variable changes
        */
    static onLeftDateChanged = new Dictionary();

    /**
         * events that will be fired when activities have finished loading
         */
    static onActivitiesLoaded = new Dictionary();

    /**
     * events that will be fired when new activities are requested
     */
    static onRequestingActivities = new Dictionary();

    /**
        * the onMouseDown event, override in angular controller
        */
    static onMouseDownCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => { }

    /**
        * the onMouseUp event, override in angular controller
        */
    static onMouseUpCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => { }

    /**
        * event for clicking on a violation icon, override in angular controller
        */
    static onViolationIconClicked = (r: Resource) => { }

    /**
        * show a message with a title and a text, override in angular controller
        */
    static showMessage(title: string, text: string, buttonText: string, buttonAction: any) { }

    /**
        * show a message with a title and a text and two buttons, override in angular controller
        */
    static showYesNoMessage(title: string, text: string, buttonYesAction: any, buttonNoAction: any) { }

    /**
        * show a message with a title and a text and custom buttons, override in angular controller
        */
    static showQuestionMessage(title: string, text: string, buttonTextArray: string[], buttonActionArray: any[]) { }

    /**
        * the onMouseMove event from Planboard
        */
    static onMouseMove(): void { }

    /**
        * get the main activity type for a specific activity type, will return the input activity type if no main activity type can be found
        * @param activityType the activity type to get the main activity type for
        */
    static getMainActivityType(activityType: ActivityType): ActivityType {
        while (activityType != null && activityType.parentId >= 0) {
            let parentType = this.activityTypes.getObject(activityType.parentId) as ActivityType;
            if (parentType == null) return activityType; // parent type does not exist (probably not readable for user)
            activityType = parentType;
        }
        return activityType;
    }

    /**
        * Show only activity types that are in rootActivityTypeIds or have a parent in rootActivityTypeIds.
        * @param activityTypeIds the array of activity types that should be visible, use null to apply the last known filter
        * @param applyToPlanned true to apply the filter to planned activities
        * @param applyToOpen true to apply the filter to open activities
        * @param filterByLeafTypes true if we should filter by leaf type ids in list
        */
    static applyActivityTypeFilter(activityTypeIds: number[], applyToPlanned: boolean, applyToOpen: boolean, filterByLeafTypes: boolean) {
        // remember root ids by creating a copy of the rootActivityTypeIds parameter
        if (activityTypeIds != null) {
            Planboard.userFilterActivityTypeIds = [];
            for (let i = 0; i < activityTypeIds.length; i++)
                Planboard.userFilterActivityTypeIds.push(activityTypeIds[i]);
        }

        if (Planboard.activityTypes.count === 0) return; // there are no activity types to filter (Planboard may not have been initialized yet)

        if (Planboard.userFilterActivityTypeIds == null) return; // there is no filter

        // rebuild filterActivityTypeIds and PlannedActivities.activityTypeFilterIds if necessary
        if (activityTypeIds != null || Planboard.filterActivityTypeIds == null) {
            // clear array filterActivityTypeIds
            Planboard.filterActivityTypeIds = [];

            // clear the activityTypeFilterIds dictionary in PlannedActivities
            PlannedActivities.activityTypeFilterIds.clear();

            Planboard.userFilterActivityTypeIds.forEach((id) => {
                var item = Planboard.activityTypes.getObject(id);

                if (!item) {
                    return;
                }

                if (item.isLeaf) {
                    for (let i = 0; i < Planboard.userFilterActivityTypeIds.length; i++) {
                        let id = Planboard.userFilterActivityTypeIds[i];
                        PlannedActivities.activityTypeFilterIds.add(id, id);
                        Planboard.filterActivityTypeIds.push(id);
                    }
                } else {
                    // fill activityTypeFilterIds dictionary with activity type ids that are in rootActivityTypeIds or have a parent in rootActivityTypeIds
                    Planboard.activityTypes.forEach((id, item) => {
                        const rootActivityType = Planboard.getMainActivityType(item);
                        if (rootActivityType && Planboard.userFilterActivityTypeIds.indexOf(rootActivityType.id) >= 0) {
                            PlannedActivities.activityTypeFilterIds.add(id, id);
                            Planboard.filterActivityTypeIds.push(id);
                        }
                    });
                }
            });
        }

        // actually enable or disable the filter for planned activities
        PlannedActivities.activityTypeFilterActive = applyToPlanned;

        // apply or remove the filter for open activities
        if (applyToOpen)
            OpenActivities.applyActivityTypeFilter("userPrefActivityTypeFilterOpen", Planboard.filterActivityTypeIds);
        else
            OpenActivities.removeActivityTypeFilter("userPrefActivityTypeFilterOpen");
    }

    /**
        * return a text label with a specific name
        * @param name name of the text label
        */
    static getTextLabel(name: string): string {
        let text = "";
        if (Planboard.textLabels) {
            text = Planboard.textLabels[name];
            if (text == undefined) text = "";
        }
        return text;
    }

    /**
        * register an event
        * @param eventList the existing eventlist to add this event to, e.g. OMRP.Entities.Planboard.onActivityTypesLoaded
        * @param uniqueName the unique name of the event to add
        * @param callbackFunction callback function to call for this event
        */
    static registerEvent(eventList: Dictionary, uniqueName: string, callbackFunction: any) {
        eventList.add(uniqueName, callbackFunction);
    }

    /**
        * unregister an event
        * @param eventList the existing eventlist to remove this event from, e.g. OMRP.Entities.Planboard.onActivityTypesLoaded
        * @param uniqueName the name of the event to remove
        */
    static unregisterEvent(eventList: Dictionary, uniqueName: string) {
        eventList.remove(uniqueName);
    }

    /**
        * trigger all events in an event dictionary
        * @param eventList the events dictionary
        */
    static triggerEvents(eventList: Dictionary) {
        eventList.forEach((key, value) => { if (value) value(); });
    }

    /**
        * Apply user display preferences
        */
    static applyUserDisplayPreferences() {
        if (Planboard.split1 != null) {
            Planboard.split1.setHorizontalSize(0, PlanboardSplitters.splitResourceWidth, 0);
            Planboard.split1.setVerticalSize(2, PlanboardSplitters.splitUnplannedHeight);
        }
    }

    /**
        * Assign user display preferences to variables so they can be saved per user
        */
    static assignUserDisplayPreferences() {
        if (Planboard.split1 != null) {
            var size = Planboard.split1.getHorizontalSize(2);
            if (size > 0) PlanboardSplitters.splitCountersWidth = size;
            PlanboardSplitters.splitResourceWidth = Planboard.split1.getHorizontalSize(0);
            PlanboardSplitters.splitUnplannedHeight = Planboard.split1.getVerticalSize(2);
        }
    }

    /**
        * load holidays for a year and do a timed refresh
        * @param yearNr the year to load holidays for
        */
    static loadHolidaysForYear(yearNr: number) {
        WebApi.get(`api/Holidays/${yearNr}`, (response) => {
            if (response != null && response.length > 0) {
                // create the dictionary for this year if it did not yet exist
                let holidaysInYear = this.holidaysPerYear.getObject(yearNr) as Dictionary;
                if (holidaysInYear == undefined) {
                    holidaysInYear = new Dictionary();
                    this.holidaysPerYear.addObject(yearNr, holidaysInYear);
                }
                // add to dictionary for this year
                for (let i = 0; i < response.length; i++) {
                    let d = Timezone.correctTimeZoneInfo(response[i].holidayDate);
                    let dayNr = TimeSpan.getDayNr(d);
                    holidaysInYear.add(dayNr, response[i].text);
                }
                Planboard.timedRefresh();
            }
        }, (response) => {
            this.showMessage(this.getTextLabel("F1060"), "", this.getTextLabel("OK"), () => { });
        });
    }

    /**
        * Returns if a date is a holiday
        * @param d the date to test
        */
    static isHoliday(d: Date): boolean {
        const yearNr = d.getFullYear();
        // get the dictionary for this year
        let holidaysInYear = this.holidaysPerYear.getObject(yearNr) as Dictionary;
        if (holidaysInYear == undefined) {
            // add an empty dictionary for this year
            holidaysInYear = new Dictionary();
            this.holidaysPerYear.addObject(yearNr, holidaysInYear);
            // load holidays from web api
            this.loadHolidaysForYear(yearNr);
            return false;
        }
        const dayNr = TimeSpan.getDayNr(d);
        const holiday = holidaysInYear.value(dayNr);
        return holiday != undefined;
    }

    /**
        * hide the counters area
        */
    static hideCounters() {
        if (!Planboard.areaCounters.visible) return;
        var size = Planboard.split1.getHorizontalSize(2);
        if (size > 0) PlanboardSplitters.splitCountersWidth = size;
        Planboard.verticalIndicatorCounters.visible = Planboard.areaCounterHeader.visible = Planboard.areaCounters.visible = false;
        Planboard.split1.setHorizontalSize(2, 0, 0, 0, 0).setHorizontalSize(1, -1, Globals.scrollBarSize, undefined, 0);
        Planboard.redrawAll();
    }

    /**
        * show the counters area
        */
    static showCounters() {
        if (Planboard.areaCounters.visible) return;
        var size = PlanboardSplitters.splitCountersWidth;
        if (size <= 0) size = 100;
        Planboard.verticalIndicatorCounters.visible = Planboard.areaCounterHeader.visible = Planboard.areaCounters.visible = true;
        Planboard.split1.setHorizontalSize(2, size, 0, Globals.maxInt, 0).setHorizontalSize(1, -1, Globals.scrollBarSize, undefined, 6);
        Planboard.redrawAll();
    }

    /**
        * set the zoom level for the days
        * @param level zoom level
        * @param forceReset true to apply changes even if the new zoom level is the same as the previous zoom level
        * @param desiredNrColumns optional, how many columns should be visible
        */
    static setZoom(level: number, forceReset: boolean = false, desiredNrColumns: number = 0): number {
        let newWidth = 0;
        if (desiredNrColumns > 0) {
            newWidth = Math.floor(Planboard.areaMain.clientArea.width / desiredNrColumns);
            level = Math.floor((newWidth - 1) / ((Planboard.dayEndHour - Planboard.dayStartHour) * 4));
            // newWidth = 0; // optionally, we could reset newWidth to 0 after calculation the level to have the width of the columns calculated by the calculateTimeLineWidth function below.
        }
        level = Math.min(64, Math.max(1, level));
        if (Planboard.zoomLevel === level && !forceReset && desiredNrColumns === 0) return level;
        while (newWidth === 0 || (level > 1 && newWidth >= DrawHelper.sharedBuffer.maxWidth)) {
            if (newWidth > 0) level--;
            newWidth = Timeline.calculateTimeLineWidth(Planboard.dayStartHour, Planboard.dayEndHour, level);
        }
        // increase the number of columns if the total screen area would be larger (counting in 14 days extra scrolling space)
        const widthToFill = Planboard.areaMain.position.width + 14 * newWidth;
        if (Planboard.areaMain.cols.count * newWidth < widthToFill)
            Planboard.areaMain.cols.count = Math.ceil(widthToFill / newWidth);
        const leftCol = Planboard.areaMain.cols.nrAtPos(Planboard.areaMain.innerLeft);
        Planboard.zoomLevel = level;
        for (let i = 0; i < Planboard.areaMain.cols.count; i++) {
            Planboard.areaMain.cols.setSize(i, newWidth, 0);
            Planboard.areaDate.cols.setSize(i, newWidth, 0);
            Planboard.areaToPlan.cols.setSize(i, newWidth, 0);
        }
        Planboard.areaMain.showArea(Planboard.areaMain.cols.getPos(leftCol), Planboard.areaMain.innerTop);
        Planboard.redrawAll();
        return level;
    }

    /**
        * increase the number of columns if the total screen area becomes larger, allowing for 2 weeks of extra scrolling space
        */
    static dynamicResize() {
        var colWidth = Planboard.areaMain.cols.getSize(0);
        if (Planboard.areaMain.cols.count * colWidth < Planboard.areaMain.position.width + 14 * colWidth)
            this.setZoom(Planboard.zoomLevel, true);
    }

    /**
        * call this when something about the planboard view changed, for example the visible activitytypes
        */
    static viewChanged() {
        Planboard.viewRevision++;
        if (Planboard.viewRevision >= Globals.maxInt)
            Planboard.viewRevision = 0;
    }

    /**
        * clear old data
        */
    static clearData() {
        if (!Planboard.dataRequested) return;
        Planboard.dataRequested = false;
        Planboard.activities.cancelLazyLoadTimer();
        Planboard.activities.clearActivities();
        Planboard.activities.clearResources();
        Planboard.activityTypes.clear();
        Planboard.resourceCooperations.clear();
        Planboard.holidaysPerYear.clear();
        Planboard.pendingRequestsPerGroupIndex.clear();
        Planboard.areaMain.rows.count = Planboard.areaResources.rows.count = Planboard.areaCounters.rows.count = 0;
    }

    /**
        * redraw all contents of AreaControls on the planboard
        */
    static redrawAll() {
        if (!Planboard.controller) return;
        ActivityInfo.refresh();
        Planboard.areaMain.redrawAll();
        Planboard.areaResources.redrawAll();
        Planboard.areaResourceHeader.redrawAll();
        Planboard.areaCounters.redrawAll();
        Planboard.areaCounterHeader.redrawAll();
        Planboard.areaDate.redrawAll();
        Planboard.areaToPlan.redrawAll();
        Planboard.controller.redraw();
    }

    /**
        * refresh the planboard after a few miliseconds, staggering multiple refresh calls.
        */
    static timedRefresh() {
        if (Planboard.refreshTimer == null) {
            Planboard.refreshTimer = new Timer(null, 200);
            Planboard.refreshTimer.tick = (t: Timer) => {
                if (!Planboard.activityTypesLoading &&
                    !Planboard.resourcesLoading) {
                    ActivityInfo.refresh();
                    Planboard.refreshTimer.enabled = false;

                    let prevAutoRowHeight = Planboard.autoRowHeight;
                    if (Planboard.autoRowHeight) {
                        Planboard.autoRowHeight = false; // for safety set it to false to avoid the drawCell adding more rows to this.autoEnlargeRowNrs
                        PlannedActivities.expandRows(Planboard.areaMain);
                    }

                    Planboard.redrawAll();

                    Planboard.autoRowHeight = prevAutoRowHeight;
                }
            }
        }
        Planboard.refreshTimer.enabled = true;
    }

    /**
        * if the resource counters are visible, then start a timed refresh
        */
    static timedRefreshResourceCounters() {
        if (Planboard.areaCounters.visible && Planboard.split1.getHorizontalSize(2) > 0) Planboard.timedRefresh();
    }

    static refreshOpenActivities() {
        Planboard.activities.clearOpenActivities();
    }

    /**
        * get the date in the column that is currently visible
        */
    static getCurrentDate(): Date {
        let d = new Date(Planboard.leftDate.getTime());
        d.setHours(0, 0, 0, 0);
        d.setDate(d.getDate() + Planboard.areaMain.cols.nrAtPos(Planboard.areaMain.innerLeft));
        return d;
    }

    /**
        * scroll the planboard to a date
        * @param date the date to scroll to
        */
    static showDate(date: Date, noScroll: boolean = false) {
        const dayNr = TimeSpan.getDayNr(date);
        let leftDayNr = TimeSpan.getDayNr(Planboard.leftDate);
        if (dayNr >= leftDayNr && dayNr < leftDayNr + Planboard.areaMain.cols.count) {
            const pos = Planboard.areaMain.cols.getPos(dayNr - leftDayNr);
            if (pos >= 0 && pos <= Planboard.areaMain.hBar.maxValue) {
                if (noScroll || Math.abs(pos - Planboard.areaMain.innerLeft) > Planboard.areaMain.position.width)
                    Planboard.areaMain.showArea(pos, Planboard.areaMain.innerTop);
                else
                    Planboard.areaMain.scrollToTarget(pos, Planboard.areaMain.innerTop);
                return;
            }
        }
        Planboard.leftDate = TimeSpan.fromDateNoTime(date).addDays(-7).toDate();
        while (Planboard.leftDate.getDay() !== Planboard.firstDayOfWeek)
            Planboard.leftDate = TimeSpan.fromDate(Planboard.leftDate).addDays(-1).toDate();
        leftDayNr = TimeSpan.getDayNr(Planboard.leftDate);
        Planboard.areaMain.showArea(Planboard.areaMain.cols.getPos(dayNr - leftDayNr), Planboard.areaMain.innerTop);
        Planboard.redrawAll();
        Planboard.triggerEvents(Planboard.onLeftDateChanged);
    }

    static setCurrentDate(date: Date) {
        const dayNr = TimeSpan.getDayNr(date);
        let leftDayNr = TimeSpan.getDayNr(Planboard.leftDate);
  
        Planboard.leftDate = TimeSpan.fromDateNoTime(date).addDays(-7).toDate();
        while (Planboard.leftDate.getDay() !== Planboard.firstDayOfWeek)
            Planboard.leftDate = TimeSpan.fromDate(Planboard.leftDate).addDays(-1).toDate();
        leftDayNr = TimeSpan.getDayNr(Planboard.leftDate);
        Planboard.areaMain.showArea(Planboard.areaMain.cols.getPos(dayNr - leftDayNr), Planboard.areaMain.innerTop);
        Planboard.redrawAll()
    }

    /**
        * convert a date to a string with format: yyyy-mm-dd
        * @param d the date to convert to string
        */
    static dateToStr(d: Date): string {
        let s = `${d.getDate()}`;
        while (s.length < 2) s = `0${s}`;
        s = `${d.getMonth() + 1}-${s}`;
        while (s.length < 5) s = `0${s}`;
        s = `${d.getFullYear()}-${s}`;
        while (s.length < 10) s = `0${s}`;
        return s;
    }

    /**
        * adjust a date to utc time, needed before sending a date object trough WebApi
        * this should not be neccesary, unfortunately the timezoneoffset in the date object gets lost when transmitting to the WebApi
        * @param d the date to adjust
        */
    static adjustToUtc(d: Date): Date {
        return new Date(Date.UTC(d.getFullYear(),
            d.getMonth(),
            d.getDate(),
            d.getHours(),
            d.getMinutes(),
            d.getSeconds()));
    }

    /**
        * helper method to add an array of activities that have just been received from the webApi
        * @param activityArray the array of activities to add
        * @param replaceExisting true to replace any existing activities with the same id
        */
    static addActivitiesToMemory(activityArray: any[], replaceExisting: boolean) {
        const dateSuffix = activityArray.length > 0 &&
            activityArray[0].startDate.charAt != undefined &&
            activityArray[0].startDate.charAt(activityArray[0].startDate.length - 1) !== "Z"
            ? "Z" : "";
        const convertDate = activityArray.length > 0 && activityArray[0].startDate.getFullYear == undefined;
        for (let i = 0; i < activityArray.length; i++) {
            const a = new Activity(activityArray[i].id,
                convertDate ? new Date(activityArray[i].startDate + dateSuffix) : activityArray[i].startDate,
                convertDate ? new Date(activityArray[i].endDate + dateSuffix) : activityArray[i].endDate,
                activityArray[i].activityTypeId,
                activityArray[i].resourceId,
                activityArray[i].memoId,
                activityArray[i].status,
                activityArray[i].origin);
            if (a.resourceId < 0)
                a.resourceId = null;
            if (activityArray[i].parentId != null)
                a.parentId = activityArray[i].parentId;
            a.resourceTypeId = activityArray[i].resourceTypeId;
            Planboard.activities.addActivity(a, replaceExisting);
        }
    }

    /**
        * remove one activity from memory
        * @param activityId id of the activity
        * @param startRefreshPlanboard true to start timed planboard refresh
        */
    static removeActivityFromMemory(activityId: number, startRefreshPlanboard: boolean) {
        const a = Planboard.activities.getActivity(activityId);
        if (a != null) {
            Planboard.activities.removeActivity(a);
            if (a.parentId != null && a.parentId > 0) Planboard.activities.checkFilled(a.parentId);
            if (startRefreshPlanboard) Planboard.timedRefresh();
        }
    }

    /**
        * remove one activity group from memory
        * @param activityId id of one of the activities in the group
        * @param startRefreshPlanboard true to start timed planboard refresh
        */
    static removeActivityGroupFromMemory(activityId: number, startRefreshPlanboard: boolean) {
        const group = Planboard.getAllActivitiesInGroup(activityId);
        if (group && group.length > 0) {
            for (let i = 0; i < group.length; i++) Planboard.activities.removeActivity(group[i]);
            if (startRefreshPlanboard) Planboard.timedRefresh();
        }
    }

    /**
        * request multiple activities in a group from the WebApi
        * @param id the id of one of the activities in the group
        * @param hideError true to not show any error
        */
    static readActivityGroup(id: number, hideError: boolean = false) {
        const mainActivity = Planboard.activities.getMainActivity(id);
        if (mainActivity != undefined) id = mainActivity.id; // try to find the root, so the webApi does not have to
        WebApi.get(`api/Activities/GetActivityGroup/${id}`, (response) => {
            // first remove the group from memory (in case one of the leaves was deleted)
            this.removeActivityGroupFromMemory(id, false);
            if (response != null && response.length > 0) {
                // now add the group as we received it
                this.addActivitiesToMemory(response, true);
                Planboard.activities.checkFilled(response[0].id);
            }
            Planboard.timedRefresh();
        }, (response) => {
            if (!hideError)
                this.showMessage(this.getTextLabel("ACTIVITY_READ_FAILED"),
                    this.getActivityFailureReasons(response), this.getTextLabel("OK"), () => { });
            // on error: remove the activity group from memory
            this.removeActivityGroupFromMemory(id, true);
        });
    }

    /**
        * request one activity from the WebApi
        * @param id the id of the activity
        * @param hideError true to not show any error
        */
    static readActivity(id: number, hideError: boolean = false) {
        WebApi.get(`api/Activities/GetActivity/${id}`, (response) => {
            if (response != null) {
                if (response.status === 2) { // the activity has status deleted
                    this.removeActivityFromMemory(id, true);
                } else {
                    this.addActivitiesToMemory([response], true);
                    Planboard.activities.checkFilled(response.id);
                    Planboard.timedRefresh();
                }
            } else {
                // no result, if the activity exist in planboard memory, then remove it from memory
                this.removeActivityFromMemory(id, true);
            }
        }, (response) => {
            if (!hideError)
                this.showMessage(this.getTextLabel("ACTIVITY_READ_FAILED"),
                    this.getActivityFailureReasons(response), this.getTextLabel("OK"), () => {});
            // on error: remove the activity from memory
            this.removeActivityFromMemory(id, true);
        });
    }

    /**
        * create a new activity group based upon the main activity
        * @param mainActivity the main activity
        * @param ignoreResourceRestrictions if resource restrictions should be ignored
        */
    static newActivityGroup(mainActivity: Activity, ignoreResourceRestrictions: boolean = false, ignoreOWSUnavailability: boolean = false) {
        const act = {
            scenarioId: Planboard.scenarioId,
            id: -1,
            parentId: null,
            memoId: mainActivity.memoId,
            activityTypeId: mainActivity.activityTypeId,
            startDate: this.adjustToUtc(mainActivity.startDate),
            endDate: this.adjustToUtc(mainActivity.endDate),
            resourceId: mainActivity.resourceId,
            ignoreResourceRestrictions: ignoreResourceRestrictions,
            ignoreOWSUnavailability: ignoreOWSUnavailability
        }
        WebApi.post("api/Activities/NewActivityGroup", act, (response) => {
            if (response &&
                response.insertedActivities &&
                response.insertedActivities.length &&
                response.insertedActivities.length > 0) {
                const insertedActivities = response.insertedActivities;
                this.addActivitiesToMemory(insertedActivities, false);
                // current selection is still on the temporary activity?
                if (Planboard.selectedActivity === mainActivity) {
                    if (mainActivity.resourceId != null && mainActivity.resourceId > 0) {
                        // reselect the activity with the same resourceId as the temporary mainActivity
                        for (let i = 0; i < insertedActivities.length; i++)
                            if (insertedActivities[i].resourceId === mainActivity.resourceId) {
                                const actType = Planboard.activityTypes.getObject(insertedActivities[i].activityTypeId) as ActivityType;
                                if (OpenActivities.activityTypeVisible(actType, PlannedActivities.activityTypeFilterActive))
                                    Planboard.selectedActivity = Planboard.activities.getActivity(insertedActivities[i].id);
                            }
                    } else {
                        // new activity was created in open activities: select the activity that is visible in the current view
                        for (let i = 0; i < insertedActivities.length; i++) {
                            const actType = Planboard.activityTypes.getObject(insertedActivities[i].activityTypeId) as ActivityType;
                            if (OpenActivities.activityTypeVisible(actType, OpenActivities.activityTypeFilterActive))
                                Planboard.selectedActivity = Planboard.activities.getActivity(insertedActivities[i].id);
                        }
                    }
                }
            }
            if (Planboard.selectedActivity === mainActivity) Planboard.selectedActivity = null;
            Planboard.activities.removeActivity(mainActivity); // remove the temporary activity from memory
            Planboard.redrawAll();
        }, (response) => {
            const title = this.getTextLabel("ACTIVITY_SAVE_FAILED");
            const reason = this.getActivityFailureReasons(response);
            if (!ignoreResourceRestrictions && response.canIgnoreResourceRestrictions) {
                const retryText = this.getTextLabel("ACTIVITY_SAVE_LESS_CHECKS");
                this.showYesNoMessage(title, reason + "\n\n" + retryText,
                    () => { // yes action (retry)
                        this.newActivityGroup(mainActivity, true, ignoreOWSUnavailability);
                    },
                    () => { // no action
                        if (Planboard.selectedActivity === mainActivity) Planboard.selectedActivity = null;
                        Planboard.activities.removeActivity(mainActivity); // remove the temporary activity from memory
                        Planboard.redrawAll();
                    });
            } else if (!ignoreOWSUnavailability && response.canIgnoreOWSUnavailability) {
                const retryText = this.getTextLabel("ACTIVITY_SAVE_OVERRULE_OWS");
                this.showYesNoMessage(title, reason + "\n\n" + retryText,
                    () => { // yes action (retry)
                        this.newActivityGroup(mainActivity, ignoreResourceRestrictions, true);
                    },
                    () => { // no action
                        if (Planboard.selectedActivity === mainActivity) Planboard.selectedActivity = null;
                        Planboard.activities.removeActivity(mainActivity); // remove the temporary activity from memory
                        Planboard.redrawAll();
                    });
            } else {
                if (Planboard.selectedActivity === mainActivity) Planboard.selectedActivity = null;
                Planboard.activities.removeActivity(mainActivity); // remove the temporary activity from memory
                Planboard.redrawAll();
                this.showMessage(title, reason, this.getTextLabel("OK"), () => { });
            }
        });
    }

    /**
        * function to extract activity failure reasons from the webapi response
        * @param response full webapi response object
        */
    static getActivityFailureReasons(response: any): string {
        let text = "";
        if (response && response.failureReasons) {
            response.failureReasons.forEach(failureReason => {
                text += (text === "" ? "" : "\n") + "\u2022 ";
                text += this.getTextLabel(`ACTIVITY_SAVE_FAILED_${failureReason.reason}`);

                let idx = 0;
                if (failureReason && failureReason.data) {
                    failureReason.data.forEach((data) => {
                        if (data) {
                            text += idx === 0 ? "\n" : ", ";
                            idx++;
                            if (data.substring(0, 6) === "@date:") {
                                text += Globals.dateFormat(Timezone.correctTimeZoneInfo(new Date(data.substring(6))),
                                    "short");
                            } else {
                                text += data;
                            }
                        }
                    });
                }
            });
        }
        else if (response && response.statusText && response.statusText !== "") {
            const key = response.statusText.substring(0, response.statusText.indexOf(" "));
            text = this.getTextLabel(key);
        }
        if (text.trim() === "") {
            text = this.getTextLabel("TRY_AGAIN_LATER");
        }
        return text;
    }

    /**
        * function that will be called when an activity failed to change
        * @param act the activity that was send to the webapi, or an array of multiple activities that were send to the webapi
        * @param response full webapi response object
        * @param activityGroup true if this was a change for a group, false if it was a change for one activity
        * @param retryText text to add below failure reasons, asking the user if a retry should be done, can be null if there is no retry possibility
        * @param retryCallback action to call if user click the retry button, can be null if there is no retry possibility
        */
    static changeActivityFailed(act: any, response: any, activityGroup: boolean, retryText: string, retryCallback: any) {
        const title = this.getTextLabel("ACTIVITY_SAVE_FAILED");
        const reason = this.getActivityFailureReasons(response);

        // create an array of all activity ids to reload, use both the response and the act parameters to construct one array of ids
        let reloadIds: number[] = [];
        if (act != null && act.length != null && act.length > 0) {
            for (let i = 0; i < act.length; i++)
                reloadIds.push(act[i].id);
        }
        else if (act.id != null)
            reloadIds.push(act.id);
        if (response.id != undefined && reloadIds.indexOf(response.id) < 0)
            reloadIds.push(response.id);

        if (retryCallback == null) {
            for (let i = 0; i < reloadIds.length; i++)
                if (activityGroup)
                    Planboard.readActivityGroup(reloadIds[i], true);
                else
                    Planboard.readActivity(reloadIds[i], true);

            this.showMessage(title, reason, this.getTextLabel("OK"), () => { });
        }
        else
            this.showYesNoMessage(title, reason + "\n\n" + retryText,
                () => { retryCallback(); }, // yes action (retry)
                () => {
                    // no action, reload the activity or group of activities
                    for (let i = 0; i < reloadIds.length; i++)
                        if (activityGroup)
                            Planboard.readActivityGroup(reloadIds[i], true);
                        else
                            Planboard.readActivity(reloadIds[i], true);
                });
    }

    /**
        * returns the order of activities based on the sort order in the activity type
        * returns < 0 if activityId1 should be before activityId2, > 0 if activityId1 should be after activityId2, or 0 if they are equal.
        */
    static compareActivityOrderByType(activityId1: number, activityId2: number): number {
        let act1 = Planboard.activities.getActivity(activityId1);
        let act2 = Planboard.activities.getActivity(activityId2);
        if (act1 && act2) {
            let actType1 = Planboard.activityTypes.getObject(act1.activityTypeId) as ActivityType;
            let actType2 = Planboard.activityTypes.getObject(act2.activityTypeId) as ActivityType;
            if (actType1 && actType2) {
                if (actType1.sortOrder == actType2.sortOrder) return 0; // both are equal number or both are null
                if (actType1.sortOrder != null && actType2.sortOrder != null) // both are not null and different in number
                    return actType1.sortOrder - actType2.sortOrder;
                if (actType1.sortOrder == null) return 1; // actType1 is null so it should be after actType2
                if (actType2.sortOrder == null) return -1; // actType2 is null so actType1 should be before actType2
            }
        }
        return 0;
    }

    /**
        * helper function for getAllActivityIdsInGroup
        * recursively adds the activity Id and its children Ids to the array actIdList
        * @param id the id of the activity to start with, this should be the rootActivityId for a complete activity group
        * @param actIdList array to add the activityIds to
        * @param sortByType true to return the array sorted by activity type sortOrder
        */
    private static buildActivityList(id: number, actIdList: Array<number>, sortByType: boolean = false) {
        actIdList.push(id);
        const lst = Planboard.activities.getActivityChilds(id);
        if (lst && lst.length > 0) {
            if (sortByType) lst.sort(this.compareActivityOrderByType);
            for (let i = 0; i < lst.length; i++)
                this.buildActivityList(lst[i], actIdList, sortByType);
        }
    }

    /**
        * return an array with all activity ids in a group
        * @param id the id of one of the activities in the group
        * @param sortByType true to return the array sorted by activity type sortOrder
        */
    static getAllActivityIdsInGroup(id: number, sortByType: boolean = false): Array<number> {
        const actIdList: Array<number> = [];
        const mainActivity = Planboard.activities.getMainActivity(id);
        if (mainActivity != undefined)
            this.buildActivityList(mainActivity.id, actIdList, sortByType);
        else if (Planboard.activities.getActivity(id) != undefined)
            actIdList.push(id);
        return actIdList;
    }

    /**
        * return an array with all activities in a group, only activities that exist in memory are returned
        * @param id the id of one of the activities in the group
        */
    static getAllActivitiesInGroup(id: number): Array<Activity> {
        const actList: Array<Activity> = [];
        const actIdList = this.getAllActivityIdsInGroup(id);
        for (let i = 0; i < actIdList.length; i++) {
            const a = Planboard.activities.getActivity(actIdList[i]); // get activity from memory
            if (a != undefined) actList.push(a);
        }
        return actList;
    }

    /**
        * return if there is any activity locked in a group, only activities that exist in memory are checked
        * @param id the id of one of the activities in the group.
        * Activity is also locked if it was created during and OWS import, that is the origin is OWS.
        */
    static isAnyActivityFromGroupLocked(id: number): boolean {
        const actList: Array<Activity> = this.getAllActivitiesInGroup(id);
        for (let i = 0; i < actList.length; i++) {
            if (actList[i].status === Constants.StatusActivityLocked || actList[i].origin == Constants.originSystemOWS) {
                return true;
            }
        }

        return false;
    }

    /**
        * return an array of activity types without write permissions in a group, only activities that exist in memory are checked
        * @param id the id of one of the activities in the group
        */
    static getActivityTypesFromGroupWithoutWritePermissions(resourceTypes: Array<ResourceType>): Array<ActivityType> {
        let actList = Planboard.getAllActivityIdsInGroup(this.selectedActivity.id, true); // retrieve in sorted order based on activity types sortOrder

        // convert activity id list to activity entity list
        let activities = new ActivityList();
        let actTypesWithoutWritePermissions = new Array<ActivityType>();

        for (let i = 0; i < actList.length; i++) activities.items.push(Planboard.activities.getActivity(actList[i]));

        for (let i = 0; i < activities.items.length; i++) {
            let act = activities.items[i];
            let currentActType = Planboard.activityTypes.getObject(act.activityTypeId);
            let ignoreResourceTypeWritePermissionsCheckForRoot = Planboard.selectedActivity.parentId === act.id;

            // activity type: no write permissions
            const hasNoValidActivityTypePermission = !currentActType || currentActType.maxPermissionForCurrentUser < 2;

            // resource type: no write permissions
            let hasNoValidResourceTypePermission = ignoreResourceTypeWritePermissionsCheckForRoot ? currentActType.isLeaf && act.parentId !== null : true;
            for (let resourceTypeId = 0; resourceTypeId < currentActType.resourceTypeIdList.length; resourceTypeId++) {
                if (resourceTypes[currentActType.resourceTypeIdList[resourceTypeId]] && resourceTypes[currentActType.resourceTypeIdList[resourceTypeId]].maxPermissionForCurrentUser >= 2) {
                    hasNoValidResourceTypePermission = false;
                    break;
                }
            }

            if (hasNoValidActivityTypePermission || hasNoValidResourceTypePermission) {
                actTypesWithoutWritePermissions.push(currentActType);
            }
        };

        return actTypesWithoutWritePermissions;
    }

    /**
        * move an activity group to a different date or change the start/end times of all activities in the group
        * @param id the id of one of the activities in the group
        * @param moveDays number of days to move forward or backward (if negative)
        * @param newStart new start date/time, or null to not change
        * @param newEnd new end date/time, or null to not change
        * @param originalStart original start date/time, or null if unknown
        * @param originalEnd original end date/time, or null if unknown
        * @param smartDateChange true to change date/time in a smart way (only if the previous start/end matches that of the root and original start/end)
        * @param callbackBeforeReinsert function to call before reinserting the group (can be null)
        */
    static moveActivityGroup(id: number, moveDays: number, newStart: Date, newEnd: Date, originalStart: Date, originalEnd: Date, smartDateChange: boolean, callbackBeforeReinsert: (group: Activity[]) => void) {
        const group = Planboard.getAllActivitiesInGroup(id);
        if (group.length === 0) return; // nothing found to move
        const rootStart = group[0].startDate.getTime(); // start time of root activity
        const rootEnd = group[0].endDate.getTime(); // end time of root activity
        const originalStartTime = originalStart == null ? rootStart : originalStart.getTime();
        const originalEndTime = originalEnd == null ? rootEnd : originalEnd.getTime();
        // remove group from memory
        for (let i = 0; i < group.length; i++) Planboard.activities.removeActivity(group[i]);
        // change date of all activities in the group
        for (let i = 0; i < group.length; i++) {
            let changeStart = group[i].startDate;
            let changeEnd = group[i].endDate;
            if (smartDateChange) {
                if (newStart != null && group[i].startDate.getTime() === rootStart &&
                    (originalStart == null || originalStartTime !== newStart.getTime()) &&
                    originalStartTime === rootStart) changeStart = newStart;
                if (newEnd != null && group[i].endDate.getTime() === rootEnd &&
                    (originalEnd == null || originalEndTime !== newEnd.getTime()) &&
                    originalEndTime === rootEnd) changeEnd = newEnd;
            } else {
                if (newStart != null) changeStart = newStart;
                if (newEnd != null) changeEnd = newEnd;
            }
            // sanity check: start must be before end
            if (changeStart != null && changeEnd != null && changeStart.getTime() < changeEnd.getTime()) {
                group[i].startDate = changeStart;
                group[i].endDate = changeEnd;
            }
            if (moveDays !== 0) {
                group[i].startDate = TimeSpan.fromDate(group[i].startDate).addDays(moveDays).toDate();
                group[i].endDate = TimeSpan.fromDate(group[i].endDate).addDays(moveDays).toDate();
            }
        }
        // callback before re-insert
        if (callbackBeforeReinsert != null) callbackBeforeReinsert(group);
        // re-insert group in memory
        for (let i = 0; i < group.length; i++) Planboard.activities.addActivity(group[i], false);
        Planboard.activities.checkFilled(id);
    }

    /**
        * create a copy of an activity in memory to be sent to the webApi (helper function for changeActivity)
        * @param a the activity to copy
        */
    static prepareActivityForWebApi(a: Activity): any {
        return {
            scenarioId: Planboard.scenarioId,
            id: a.id,
            parentId: (a.parentId < 0 ? null : a.parentId),
            memoId: a.memoId,
            status: a.status,
            activityTypeId: a.activityTypeId,
            startDate: new Date(a.startDate.getTime() - a.startDate.getTimezoneOffset() * 60000).toISOString(), // toISOString format = YYYY-MM-DDTHH:mm:ss.sssZ
            endDate: new Date(a.endDate.getTime() - a.endDate.getTimezoneOffset() * 60000).toISOString(),
            resourceId: a.resourceId == null || a.resourceId < 0 ? null : a.resourceId,
            resourceTypeId: a.resourceTypeId == null || a.resourceTypeId < 0 ? null : a.resourceTypeId
        }
    }

    /**
        * do the webApi post action to change one activity or an array of activities
        * @param act the prepared activity or array of activities to post
        * @param activityGroup true if this is a group of activities, false if it is a single activity
        */
    private static postChangedActivities(act: any, activityGroup: boolean) {
        let firstAct = activityGroup ? act[0] : act;

        // temporary assign the resourceTypeId field of changed activities, until the response from the post has arrived
        const assignResourceTypeId = Planboard.activities.activeResourceSelectionId;
        let actList = activityGroup ? act : [firstAct];
        for (let i = 0; i < actList.length; i++) {
            // only assign a resourceTypeId if there is a resourceId and no resourceTypeId
            if (actList[i].resourceId != null && actList[i].resourceTypeId == null) {
                let a = Planboard.activities.getActivity(actList[i].id);
                if (a) a.resourceTypeId = assignResourceTypeId;
            }
        }

        WebApi.post(activityGroup ? "api/Activities/ChangeActivityGroup" : "api/Activities/ChangeActivity", act, (response) => {
            if (!response.success) {
                // this should never occur, if there is no success then the post action should fail instead
                this.changeActivityFailed(firstAct, response, activityGroup, "", null);
            } else {
                // if the response contains a list of changed activities then update the resourceTypeId in memory for each of those activities
                if (response.changedActivities && response.changedActivities.length > 0) {
                    for (let i = 0; i < response.changedActivities.length; i++) {
                        let a = Planboard.activities.getActivity(response.changedActivities[i].id);
                        if (a) a.resourceTypeId = response.changedActivities[i].resourceTypeId;
                    }
                }
            }
            Planboard.timedRefreshResourceCounters();
        }, (response) => {
            // the post action failed, see if it is possible to resend without resource restrictions (unless that was already tried before)
            if (response.canIgnoreResourceRestrictions && !firstAct.ignoreResourceRestrictions) {
                this.changeActivityFailed(firstAct, response, activityGroup, this.getTextLabel("ACTIVITY_SAVE_LESS_CHECKS"), () => {
                    // set ignoreResourceRestrictions and call postChangedActivity again
                    if (activityGroup) {
                        for (let i = 0; i < act.length; i++) act[i].ignoreResourceRestrictions = true;
                    } else
                        act.ignoreResourceRestrictions = true;
                    this.postChangedActivities(act, activityGroup);
                });
            } else if (!firstAct.ignoreOWSUnavailability && response.canIgnoreOWSUnavailability) {
                this.changeActivityFailed(firstAct, response, activityGroup, this.getTextLabel("ACTIVITY_SAVE_OVERRULE_OWS"), () => {
                    // set ignoreOWSUnavailability and call postChangedActivity again
                    if (activityGroup) {
                        for (let i = 0; i < act.length; i++) act[i].ignoreOWSUnavailability = true;
                    } else
                        act.ignoreOWSUnavailability = true;
                    this.postChangedActivities(act, activityGroup);
                });
            } else {               
                this.changeActivityFailed(firstAct, response, activityGroup, "", null);
            }
        });
    }

    /**
        * do the webApi post action to swap the resources of two activities
        * @param firstAct the first activity
        * @param secondAct the second activity
        */
    private static postSwapActivities(firstAct: any, secondAct: any) {
        WebApi.post("api/Activities/SwapActivityResources", [firstAct, secondAct], (response) => {
            // this should never occur, if there is no success then the post action should fail instead
            if (!response.success) this.changeActivityFailed([firstAct, secondAct], response, true, "", null);
            Planboard.timedRefreshResourceCounters();
        }, (response) => {
            // the post action failed, see if it is possible to resent without resource restrictions (unless that was already tried before)
            if (response.canIgnoreResourceRestrictions && !firstAct.ignoreResourceRestrictions)
                this.changeActivityFailed([firstAct, secondAct], response, true, this.getTextLabel("ACTIVITY_SAVE_LESS_CHECKS"), () => {
                    // set ignoreResourceRestrictions and call postChangedActivity again
                    firstAct.ignoreResourceRestrictions = true;
                    secondAct.ignoreResourceRestrictions = true;
                    this.postSwapActivities(firstAct, secondAct);
                });
            else
                this.changeActivityFailed([firstAct, secondAct], response, true, "", null);
        });
    }

    /**
        * send a request to change all activities in a group to the WebApi
        * @param id the id of one of the activities in the group
        */
    static changeActivityGroup(id: number) {
        if (id < 0) return; // temporary activity has a negative Id. It will be replaced by the result of newActivityGroup or newActivityGroup will show an error.
        const actIdList = this.getAllActivityIdsInGroup(id);
        let actList = [];
        for (let i = 0; i < actIdList.length; i++) {
            const a = Planboard.activities.getActivity(actIdList[i]);
            if (a != undefined) actList.push(this.prepareActivityForWebApi(a));
        }
        if (actList.length === 0) return;
        this.postChangedActivities(actList, true);
    }

    /**
        * send a request to change one activity to the WebApi
        * @param id the id of the activity
        */
    static changeActivity(id: number) {
        if (id < 0) return; // temporary activity has a negative Id. It will be replaced by the result of newActivityGroup or newActivityGroup will show an error.
        const a = Planboard.activities.getActivity(id);
        if (a == undefined) return;
        this.postChangedActivities(this.prepareActivityForWebApi(a), false);
    }

    /**
        * send a request to swap the resources on two activities
        * @param firstId the id of the first activity
        * @param secondId the id of the second activity
        */
    static swapActivities(firstId: number, secondId: number) {
        if (firstId < 0 || secondId < 0) return; // temporary activity has a negative Id. It will be replaced by the result of newActivityGroup or newActivityGroup will show an error.
        const a1 = Planboard.activities.getActivity(firstId);
        const a2 = Planboard.activities.getActivity(secondId);
        if (a1 == undefined || a2 == undefined) return;
        this.postSwapActivities(this.prepareActivityForWebApi(a1), this.prepareActivityForWebApi(a2));
    }

    /**
        * send a request to delete one activity to the WebApi
        * @param a the activity object to delete
        */
    static deleteActivity(a: Activity) {
        if (a == undefined) return;
        const act = this.prepareActivityForWebApi(a);
        WebApi.delete("api/Activities/" + act.id, (response) => {
            if (!response.success) this.changeActivityFailed(act, response, false, "", null);
            Planboard.timedRefreshResourceCounters();
        }, (response) => {
            this.changeActivityFailed(act, response, false, "", null);
        });
    }

    /**
        * remember the current pending requests by readActivities
        */
    private static pendingRequestsPerGroupIndex = new Dictionary();

    /**
        * add a pending requests by readActivities
        */
    private static addPendingRequest(groupIndex: number, fromDate: Date, toDate: Date) {
        let pendingPerGroup: any[] = this.pendingRequestsPerGroupIndex.value(groupIndex);
        if (pendingPerGroup == undefined) {
            pendingPerGroup = [];
            this.pendingRequestsPerGroupIndex.add(groupIndex, pendingPerGroup);
        }
        const pendingRequest = { fromDateTime: fromDate.getTime(), toDateTime: toDate.getTime() }
        pendingPerGroup.push(pendingRequest);
    }

    /**
        * return if an identical requests by readActivities is still pending
        * @param removeIfPending when true, the request will be removed from the pending list
        */
    private static isRequestPending(groupIndex: number, fromDate: Date, toDate: Date, removeIfPending: boolean): boolean {
        let pendingPerGroup: any[] = this.pendingRequestsPerGroupIndex.value(groupIndex);
        if (pendingPerGroup == undefined) return false;
        let index = pendingPerGroup.length;
        let fromDateTime = fromDate.getTime();
        let toDateTime = toDate.getTime();
        while (index > 0) {
            index--;
            if (pendingPerGroup[index].fromDateTime === fromDateTime &&
                pendingPerGroup[index].toDateTime === toDateTime) {
                if (removeIfPending) pendingPerGroup.splice(index, 1);
                return true;
            }
        }
        return false;
    }

    /**
        * request activities from the WebApi where date >= fromDate and date < toDate
        * @param fromDate start date (inclusive)
        * @param toDate end date (exclusive)
        */
    static readActivities(fromDate: Date, toDate: Date, groupIndex: number) {
        ((fromDate: Date, toDate: Date, groupIndex: number) => { // this function wrap will make a copy of the input variables, so that they are usable in the WebApi callback
                
            if (this.isRequestPending(groupIndex, fromDate, toDate, false)) return; // quit if an identical request is still pending
            this.addPendingRequest(groupIndex, fromDate, toDate); // remember that this request is pending

            const resIdList: number[] = [];
            const groupResources = Planboard.activities.getResourcesInGroup(groupIndex);
            for (let i = 0; i < groupResources.length; i++)
                resIdList.push(groupResources[i].id);
            const activitySelection = {
                scenarioId: Planboard.scenarioId,
                fromDateInclusive: Planboard.dateToStr(fromDate),
                toDateExclusive: Planboard.dateToStr(toDate),
                resourceIdList: resIdList,
                activitiesWithoutResource: false
            }

            Planboard.triggerEvents(Planboard.onRequestingActivities);
            const urlGetActivities = groupIndex < 0 ? "api/Activities/Unplanned" : "api/Activities/GetActivities";
            WebApi.post(urlGetActivities, activitySelection, (response) => {
                Planboard.triggerEvents(Planboard.onActivitiesLoaded);

                this.isRequestPending(groupIndex, fromDate, toDate, true); // remove from pending requests
                Planboard.activities.setDaysReceivedTime(fromDate, toDate, groupIndex, new Date().getTime());
                if (response.length && response.length > 0)
                    this.addActivitiesToMemory(response, false);
                Planboard.timedRefresh();
            }, (response) => {
                Planboard.triggerEvents(Planboard.onActivitiesLoaded);

                this.isRequestPending(groupIndex, fromDate, toDate, true); // remove from pending requests
                this.showMessage(this.getTextLabel("ACTIVITY_READ_FAILED"),
                    this.getActivityFailureReasons(response), this.getTextLabel("OK"), () => { Planboard.timedRefresh(); });
            });
        })(fromDate, toDate, groupIndex);
    }

    /**
        * Read multiple activities, specified by their root activity ids.
        * If the ids are not of the root, they will be replaced by the root id before the post is made to the webapi.
        * @param rootIds Ids of the roots of the activities to get.
        */
    static readActivitiesWithRootIds(rootIds: number[]) {
        if (rootIds == null || rootIds.length == null || rootIds.length === 0) return; // there is nothing to request

        // make sure the rootIds actually are root ids by replacing the id in the array with the id of the root
        for (let i = rootIds.length - 1; i >= 0; i--) {
            let mainActivity = Planboard.activities.getMainActivity(rootIds[i]);
            if (mainActivity) rootIds[i] = mainActivity.id;
        }

        ((rootIds: number[]) => {
            const activitySelection = {
                scenarioId: Planboard.scenarioId,
                fromDateInclusive: null,
                toDateExclusive: null,
                resourceIdList: [],
                rootActivityIds: rootIds,
                activitiesWithoutResource: true
            }

            WebApi.post("api/Activities/GetActivities", activitySelection, (response) => {
                if (response.length && response.length > 0) {
                    // first remove the groups from memory (in case one of the leaves was deleted)
                    for (let i = rootIds.length - 1; i >= 0; i--)
                        this.removeActivityGroupFromMemory(rootIds[i], false);
                    // next add the new groups to memory
                    this.addActivitiesToMemory(response, true);
                }
                Planboard.timedRefresh();
            }, (response) => {
                this.showMessage(this.getTextLabel("ACTIVITY_READ_FAILED"),
                    this.getActivityFailureReasons(response), this.getTextLabel("OK"), () => { });
                Planboard.timedRefresh();
            });

        })(rootIds);
    }

    /**
        * request all activitytypes from the WebApi
        */
    static readActivityTypes() {
        Planboard.activityTypesLoading = true;
        WebApi.get("api/ActivityTypes", (response) => {
            if (response.length && response.length > 0) {
                for (let i = 0; i < response.length; i++) {
                    const a = new ActivityType(response[i].id, response[i].parentId, response[i].shortName,
                        `#${response[i].backColor}`, `#${response[i].textColor}`, response[i].categoryId);
                    a.displayName = response[i].displayName;
                    a.sortOrder = response[i].sortOrder;
                    a.maxPermissionForCurrentUser = response[i].maxPermissionForCurrentUser;
                    a.resourceTypeIdList = response[i].resourceTypeIdList;
                    a.defaultTimeSlotList = response[i].defaultTimeSlotList;
                    a.notRequired = response[i].notRequired;
                    Planboard.activityTypes.addObject(a.id, a);
                }
            }
            Planboard.activityTypes.forEach((id, item) => {
                // add to default filter for open activities
                OpenActivities.activityTypeOrder.add(id, item.sortOrder || id);
                // set isLeaf of all parent activityTypes to false
                if (item.parentId) {
                    const parentItem = Planboard.activityTypes.getObject(item.parentId);
                    if (parentItem)
                        parentItem.isLeaf = false;

                    // Find rootItem
                    var rootItem = parentItem
                    while (rootItem && rootItem.parentId) {
                        rootItem = Planboard.activityTypes.getObject(rootItem.parentId);
                    }

                    if (rootItem) {
                        // Put leaf item into the rootitem
                        if (!rootItem.leafActivityTypeIdList) {
                            rootItem.leafActivityTypeIdList = [];
                        }
                        rootItem.leafActivityTypeIdList.push(item.id);
                    }
                }
            });
            // call events (if any)
            Planboard.triggerEvents(Planboard.onActivityTypesLoaded);
            Planboard.activityTypesLoading = false; 
            Planboard.timedRefresh();
        }, () => {
            Planboard.activityTypesLoading = false;
            Planboard.timedRefresh();
            this.showMessage(this.getTextLabel("ACTIVITY_TYPE_READ_FAILED"), "", this.getTextLabel("OK"), () => { });
        });
    }

    /**
        * add not grouped resources to the planboard memory
        * @param resourceList the resources as received from the webApi
        */
    static addNotGroupedResources(resourceList: any) {
        if (resourceList && resourceList.length && resourceList.length > 0)
            for (let i = 0; i < resourceList.length; i++) {
                const r = new Resource(resourceList[i].id, resourceList[i].externalId, resourceList[i].displayName);
                Planboard.activities.notGroupedResources.addObject(r.id, r);
            }
    }

    /**
        * request activity memos from the WebApi for a specific list of memo ids
        */
    static readActivityMemos(memoIdList: number[]) {
        if (memoIdList.length <= 0) return;
        const memoSelection = { idList: memoIdList }
        WebApi.post("api/Activities/GetMemosWithId",
            memoSelection,
            (response) => {
                if (response.length && response.length > 0) {
                    for (let i = 0; i < response.length; i++)
                        Planboard.activities.setMemoText(response[i].id, response[i].text);
                    ActivityInfo.refresh();
                }
            },
            () => {
                this.showMessage(this.getTextLabel("MEMO_READ_FAILED"), "", this.getTextLabel("OK"), () => { });
            });
    }

    /**
        * request all details for an activity from the WebApi
        */
    static readActivityDetails(id: number) {
        WebApi.get(`api/Activities/Details/${id}`,
            (response) => {
                Planboard.activities.setActivityDetails(id, response);
                ActivityInfo.refresh();
            },
            () => {
                this.showMessage(this.getTextLabel("ACTIVITY_DETAILS_READ_FAILED"), "", this.getTextLabel("OK"), () => { });
            });
    }

    /**
        * request counters for a set of resources from the WebApi
        * @param resourceIdList array of resource ids to get the counters for
        * @param weekNr optional week number to get the counters for that week
        * @param monthNr optional month number to get the counters for that month
        * @param yearNr required year number to get either the counters for that whole year or for a week/month if those parameters are specified
        * @param startDate optional startdate of the custom counter period
        * @param endDate optional up to including enddate of the custom counter period (inclusive)
        */
    static readResourceCounters(resourceIdList: number[], weekNr: number, monthNr: number, yearNr: number, startDate: Date, endDate: Date) {
        if (resourceIdList.length <= 0 || yearNr < 0) return;
        let exclusiveEndDate = endDate;
        if (exclusiveEndDate != null) { 
            exclusiveEndDate = new Date(endDate.getTime()); // exclusiveEndDate is a copy of endDate
            exclusiveEndDate.setDate(exclusiveEndDate.getDate() + 1); // add 1 day
        }
        const selection = {
            scenarioId: Planboard.scenarioId,
            resourceIds: resourceIdList,
            resourceTypeIds: [this.activities.activeResourceSelectionId],
            weekNumber: weekNr == null || weekNr < 0 ? null : weekNr,
            monthNumber: monthNr == null || monthNr < 0 ? null : monthNr,
            yearNumber: yearNr,
            startDate: startDate == null ? null : Timezone.rollDateForWebApi(startDate),
            endDate: exclusiveEndDate == null ? null : Timezone.rollDateForWebApi(exclusiveEndDate)
        }
        WebApi.post("api/Counters/ForPlanningBoard",
            selection,
            (response) => {
                for (var resourceId in response) {
                    var counters = response[resourceId];
                    Planboard.activities.setCountersForResource(parseInt(resourceId),
                        counters.balance, counters.planned, counters.required, counters.absence);
                }
                Planboard.timedRefresh();
            },
            () => {
                this.showMessage(this.getTextLabel("F1801"), "", this.getTextLabel("OK"), () => { });
            });
    }

    /**
        * request resource displaynames from the WebApi for a specific list of resource ids
        */
    static readResourceDisplayNames(resourceIdList: number[]) {
        if (resourceIdList.length <= 0) return;
        const resourceSelection = { idList: resourceIdList }
        WebApi.post("api/Resources/WithId",
            resourceSelection,
            (response) => {
                if (response.length && response.length > 0) {
                    this.addNotGroupedResources(response);
                    ActivityInfo.refresh();
                }
            },
            () => {
                this.showMessage(this.getTextLabel("F1001"), "", this.getTextLabel("OK"), () => { });
            });
    }

    /**
        * request resource planning status from the WebApi for a specific list of resource ids
        */
    static readResourcePlanningStatus(resourceIdList: number[]) {
        if (resourceIdList.length <= 0) return;
        const resourceSelection = {
            idList: resourceIdList,
            periodStart: null,
            periodEnd: null
        }
        WebApi.post("api/Resources/WithId/PlanningStatus",
            resourceSelection,
            (response) => {
                let resource: Resource = null;
                if (response.length && response.length > 0) {
                    for (let i = 0; i < response.length; i++) {
                        if (resource == null || response[i].resourceId !== resource.id) {
                            resource = Planboard.activities.getResource(response[i].resourceId, false);
                            if (resource == null) continue;
                            if (resource.planningStatus == null || resource.planningStatus.length > 0) {
                                resource.planningStatus = []; // reset possible previous array of planningStatusses
                            }
                        }
                        resource.planningStatus.push(new ResourcePlanningStatus(Timezone.correctTimeZoneInfo(response[i].untilDate), response[i].status));
                    }
                    Planboard.timedRefresh();
                }
            },
            () => {
                this.showMessage(this.getTextLabel("F1001"), "", this.getTextLabel("OK"), () => { });
            });
    }

    /**
        * request resource organization units from the WebApi for a specific list of resource ids
        */
    static readResourceUnits(resourceIdList: number[]) {
        if (resourceIdList.length <= 0) return;
        const resourceSelection = {
            idList: resourceIdList,
            periodStart: null,
            periodEnd: null
        }
        WebApi.post("api/Resources/WithId/OrganizationUnitMemberships",
            resourceSelection,
            (response) => {
                let resource: Resource = null;
                if (response.length && response.length > 0) {
                    for (let i = 0; i < response.length; i++) {
                        if (resource == null || response[i].resourceId !== resource.id) {
                            // The variable response is an array of organization unit memberships for each resource, sorted by resource.
                            // For each resource we create a dictionary for the organization unit memberships.
                            // The key in this dictionary is the organization unit id. The value is a list of organization unit memberships.
                            resource = Planboard.activities.getResource(response[i].resourceId, false);
                            if (resource == null) continue;
                            if (resource.units == null)
                                resource.units = new OrderedDictionary();
                            else
                                resource.units.clear();
                        }
                        if (response[i].start) response[i].start = Timezone.correctTimeZoneInfo(response[i].start);
                        if (response[i].end) response[i].end = Timezone.correctTimeZoneInfo(response[i].end);
                        var unitList: any[] = resource.units.value(response[i].organizationUnitId);
                        if (!unitList) {
                            unitList = [];
                            resource.units.add(response[i].organizationUnitId, unitList);
                        }
                        unitList.push(response[i]);
                    }
                    Planboard.timedRefresh();
                }
            },
            () => {
                this.showMessage(this.getTextLabel("F1001"), "", this.getTextLabel("OK"), () => { });
            });
    }

    /**
        * request resource properties from the WebApi for a specific list of resource ids
        */
    static readResourceProperties(resourceIdList: number[]) {
        if (resourceIdList.length <= 0) return;

        let lastDate = new Date(Planboard.leftDate.getTime());
        lastDate.setDate(lastDate.getDate() + Planboard.areaMain.cols.count - 1);

        const resourceSelection = {
            resourceIdList: resourceIdList,
            startDate: Timezone.rollDateForWebApi(Planboard.leftDate),
            endDate: Timezone.rollDateForWebApi(lastDate),
        }
        WebApi.post("api/Resources/WithId/Properties",
            resourceSelection,
            (response) => {
                let resource: Resource = null;
                if (response.length && response.length > 0) {
                    for (let i = 0; i < response.length; i++) {
                        if (resource == null || response[i].resourceId !== resource.id) {
                            resource = Planboard.activities.getResource(response[i].resourceId, false);
                            if (resource == null) continue;
                            if (resource.properties == null)
                                resource.properties = new Dictionary();
                        }
                        resource.properties.add(response[i].resourcePropertyId, response[i].value);
                    }
                    Planboard.timedRefresh();
                }
            },
            () => {
                this.showMessage(this.getTextLabel("F1001"), "", this.getTextLabel("OK"), () => { });
            });
    }

    /**
        * set the number of resource property columns and the saved width per column
        */
    static updateResourcePropertyColumns() {
        if (Planboard.resourceProperties == null) return;
        Planboard.areaResourceHeader.cols.count = Planboard.areaResources.cols.count = PlanboardResources.fixedColumns + Planboard.resourceProperties.length;
        let colWidth = PlanboardResources.initializedColumnSizes.value(-1);
        if (colWidth != null) { // displayname column
            Planboard.areaResources.cols.setSize(0, colWidth, 16);
            Planboard.areaResourceHeader.cols.setSize(0, colWidth, 16);
        }
        colWidth = PlanboardResources.initializedColumnSizes.value(-2);
        if (colWidth != null) { // organization unit column
            Planboard.areaResources.cols.setSize(1, colWidth, 16);
            Planboard.areaResourceHeader.cols.setSize(1, colWidth, 16);
        }
        for (let i = 0; i < Planboard.resourceProperties.length; i++) {
            colWidth = PlanboardResources.initializedColumnSizes.value(Planboard.resourceProperties[i].resourcePropertyId);
            if (colWidth == null) colWidth = Planboard.areaResources.cols.getSize(0);
            Planboard.areaResources.cols.setSize(i + PlanboardResources.fixedColumns, colWidth, 16);
            Planboard.areaResourceHeader.cols.setSize(i + PlanboardResources.fixedColumns, colWidth, 16);
        }
        Planboard.timedRefresh();
    }

    /**
        * add resources from a webapi response, updates displayname and extid if the resource already exists
        * @param response array of resources to add
        */
    static addResources(resourceArray: any[]) {
        let r: Resource;
        for (let i = 0; i < resourceArray.length; i++) {
            r = Planboard.activities.getResource(resourceArray[i].id);
            if (r == null) {
                r = new Resource(resourceArray[i].id, resourceArray[i].externalId, resourceArray[i].displayName);
                Planboard.activities.addResource(r);
            } else {
                r.extid = resourceArray[i].externalId;
                r.displayName = resourceArray[i].displayName;
            }
        }
    }

    /**
        * returns the height of the planning status that will be shown under each row.
        */
    static getPlanningStatusRowHeight(): number {
        return this.planningStatusHeight;
    }

    /**
        * set the height of rows in the planboard
        * @param rowNr the row index to change the height for, or null to change the height of all rows
        * @param lines number of lines height
        */
    static setRowLineHeight(rowNr: number, lines: number) {
        const newHeight = (Globals.fontHeight * lines) + 1 + this.getPlanningStatusRowHeight();
        if (rowNr != null && rowNr >= 0 && rowNr < Planboard.areaMain.rows.count) {
            // set the height for only one row
            const resourceId = Planboard.activities.getResourceId(rowNr);
            Planboard.saveResourceLineheight(resourceId, newHeight);
            Planboard.areaMain.rows.setSize(rowNr, newHeight);
            Planboard.areaResources.rows.setSize(rowNr, newHeight);
            Planboard.areaCounters.rows.setSize(rowNr, newHeight);
            return;
        }
        // set the height for all rows
        this.globalLineHeight = newHeight;
        this.savedResourceLineheights = [];
        for (let i = 0; i < Planboard.areaMain.rows.count; i++) {
            Planboard.areaMain.rows.setSize(i, newHeight);
            Planboard.areaResources.rows.setSize(i, newHeight);
            Planboard.areaCounters.rows.setSize(i, newHeight);
        }
    }

    /**
        * get the height of a row (or average of all rows if rowNr = null or < 0) in number of lines
        */
    static getRowLineHeight(rowNr: number): number {
        const t = Planboard.areaMain;
        if (t.rows.count <= 0) return 1;
        const extraRowHeight = 1 + this.getPlanningStatusRowHeight(); // 1 for the raster line + the height of the planning status indicator
        if (rowNr != null && rowNr >= 0)
            return Math.max(Math.round((t.rows.getSize(rowNr) - extraRowHeight) / Globals.fontHeight), 1);
        let totalHeight = 0;
        for (let i = 0; i < t.rows.count; i++)
            totalHeight += Math.max(Math.round((t.rows.getSize(i) - extraRowHeight) / Globals.fontHeight), 1);
        return Math.max(Math.round(totalHeight / t.rows.count), 1);
    }

    /**
        * update the number of resource rows in the planboard
        */
    static updateResourceRows() {
        const newHeight = this.globalLineHeight ? this.globalLineHeight : Globals.fontHeight + 1 + this.getPlanningStatusRowHeight();
        Planboard.areaMain.rows.count = Planboard.areaResources.rows.count = Planboard.areaCounters.rows.count = 0;

        for (let i = 0; i < Planboard.activities.getResourceCount(); i++) {
            let heightToSet = newHeight;
            const resourceId = Planboard.activities.getResourceId(i);
            if (resourceId && this.savedResourceLineheights.length > 0) {
                const savedResourceLineHeight = this.savedResourceLineheights.find(rl => rl.resourceId === resourceId);
                if (savedResourceLineHeight) {
                    heightToSet = savedResourceLineHeight.lineheight;
                }
            }
            Planboard.areaMain.rows.setSize(i, heightToSet, newHeight);
            Planboard.areaResources.rows.setSize(i, heightToSet, newHeight);
            Planboard.areaCounters.rows.setSize(i, heightToSet, newHeight);
        }
    }

    /**
        * request violations from the WebApi
        * intentionally left empty, this function should be replaced by the planboard controller
        */
    static readViolations = function (resourceIdList: number[]) { }

    /**
        * request al resources from the WebApi
        */
    static readResources() {
        ((activeResourceSelectionId: number) => {
            Planboard.resourcesLoading = true;
            let url = "api/Resources/ForPlanningBoard/Filtered";
            let lastDate = new Date(Planboard.leftDate.getTime());
            lastDate.setDate(lastDate.getDate() + Planboard.areaMain.cols.count - 1);
            let filter =
                {
                    resourceTypeId: activeResourceSelectionId,
                    referenceDate: Planboard.dateToStr(Planboard.leftDate),
                    beforeReferenceDate: Planboard.dateToStr(lastDate),
                    sortByResourcePropertyIds: Planboard.sortByResourcePropertyIds.split(','),
                    sortDescending: Planboard.sortDescending,
                    applyUnitFilter: Planboard.applyUnitFilterToResources,
                    applyResourceFilter: Planboard.applyCustomResourceFilter,
                    applyResourcesToHide: Planboard.applyResourcesToHide,
                    applySkillsFilter: Planboard.applySkillsFilter
                };

            WebApi.post(url, filter,
                (response) => {
                    if (!Planboard.cancelInitialResources && response.length && response.length > 0) {
                        Planboard.addResources(response);
                        Planboard.updateResourceRows();
                    }

                    if (!Planboard.cancelInitialResources && activeResourceSelectionId !== 0) {
                        // create a list of resource ids and set or update this selection
                        let resourceIdList = [];
                        if (response.length && response.length > 0)
                            for (let i = 0; i < response.length; i++) resourceIdList.push(response[i].id);
                        Planboard.activities.setResourceIdsInSelection(activeResourceSelectionId, resourceIdList);
                        Planboard.activities.activateResourceSelection(activeResourceSelectionId, true);

                        // ask webapi if there are any violations for the received resources
                        Planboard.readViolations(resourceIdList);
                    }

                    Planboard.resourcesLoading = false;
                    Planboard.timedRefresh();
                },
                () => {
                    Planboard.resourcesLoading = false;
                    Planboard.timedRefresh();
                    this.showMessage(this.getTextLabel("F1001"), "", this.getTextLabel("OK"), () => { });
                });

        })(Planboard.activities.activeResourceSelectionId);
    }

    static deleteMultipleActivities(activityIds: number[],
        commonSvc: any, // TODO: should have an interface because of Shannon's work by now.
        ngDateFilter: ng.IFilterDate,
        onSuccess: () => void,
        onFailure: () => void) {
        if (activityIds.length === 0) return;

        const multiSelectActionRemoveActivities = 3;

        const actionDto = {
            scenarioId: this.scenarioId,
            activityIds: activityIds,
            action: multiSelectActionRemoveActivities
        };

        // post the action
        commonSvc.post("api/Activities/MultiSelectAction",
            actionDto,
            response => { // success
                onSuccess();
                this.showDeleteActivitiesResult(response, activityIds, commonSvc, ngDateFilter, "REMOVE_ACTIVITIES");
            },
            response => { // failure
                onFailure();
                this.showDeleteActivitiesResult(response, activityIds, commonSvc, ngDateFilter, "REMOVE_ACTIVITIES");
            },
            true);
    }

    static unplanMultipleActivities(activityIds: number[],
        commonSvc: any, // TODO: should have an interface because of Shannon's work by now.
        ngDateFilter: ng.IFilterDate,
        onSuccess: () => void,
        onFailure: () => void) {
        if (activityIds.length === 0) return;

        const multiSelectActionUnplanActivities = 1;

        const actionDto = {
            scenarioId: this.scenarioId,
            activityIds: activityIds,
            action: multiSelectActionUnplanActivities
        };

        // post the action
        commonSvc.post("api/Activities/MultiSelectAction",
            actionDto,
            response => { // success
                onSuccess();
                this.showDeleteActivitiesResult(response, activityIds, commonSvc, ngDateFilter, "VACATE_ACTIVITIES");
            },
            response => { // failure
                onFailure();
                this.showDeleteActivitiesResult(response, activityIds, commonSvc, ngDateFilter, "VACATE_ACTIVITIES");
            },
            true);
    }

    static showDeleteActivitiesResult(response, selectedActivityIds, commonSvc: any, ngDateFilter: ng.IFilterDate, dialogTitle: string) {
        // first remove all the activitygroups that were selected from planboard memory
        var i: number;
        for (i = 0; i < selectedActivityIds.length; i++) {
            this.removeActivityGroupFromMemory(selectedActivityIds[i], false);
        }
        this.redrawAll();
            
        // loop over all failure reasons
        var text = "";
        var failureReasons = [];

        if (response.data && response.data.failureReasonsPerItem) {
            for (var itemId in response.data.failureReasonsPerItem) {
                response.data.failureReasonsPerItem[itemId]?.forEach(reason => !failureReasons.includes(reason) ? failureReasons.push(reason) : undefined);
            }

            if (failureReasons && failureReasons.length > 0) {
                for (i = 0; i < failureReasons.length; i++) {
                    var reasonText = this.getTextLabel("MULTISELECT_ACTION_FAILED_" + failureReasons[i].toString());
                    if (reasonText) text += "\n" + reasonText;
                }
            }
        }

        let successCount = 0;
        let failedCount = 0;
        for (var itemId in response.data.successPerItem)
            successCount += Number(response.data.successPerItem[itemId]);
        for (var itemId in response.data.failedPerItem)
            failedCount += Number(response.data.failedPerItem[itemId]);
        if (successCount > 0) text += "\n\n" + this.getTextLabel("MULTISELECT_SUCCESS_COUNT") + ": " + successCount.toString();
        if (failedCount > 0) {
            text += "\n\n" + this.getTextLabel("MULTISELECT_FAILED_COUNT") + ": " + failedCount.toString();
            // loop over all individual failure reasons
            if (response.data && response.data.itemCopiesPerFailureReason) {
                for (var itemId in response.data.itemCopiesPerFailureReason) {
                    var reasons = response.data.itemCopiesPerFailureReason[itemId];
                    for (let reasonId in reasons) {
                        var reasonText = this.getTextLabel("MULTISELECT_ACTION_FAILED_" + reasonId.toString());
                        if (reasonText) text += "\n• " + reasonText;
                        else continue;
                        const dates = reasons[reasonId];
                        for (i = 0; i < dates.length; i++)
                            text += " " + ngDateFilter(dates[i], "mediumDate") + ".";
                    }
                }
            }
        }

        commonSvc.showDialog(this.getTextLabel(dialogTitle),
            text,
            this.getTextLabel("OK"),
            () => {});
    }

    static saveResourceLineheight(resourceId: number, lineheight: number): void {
        const savedResource = this.savedResourceLineheights.find(rl => rl.resourceId === resourceId);
        if (!savedResource) {
            this.savedResourceLineheights.push({ resourceId, lineheight });
        } else {
            savedResource.lineheight = lineheight;
        }
    }

}
