import { BasicControl } from './basicControl';
import * as Globals from './../utils/globals';
import { Rect } from './../utils/rect';

/**
    * internal splitter size structure
    */
class SplitterSize {
    size: number;
    actualSize: number;
    minSize: number;
    maxSize: number;
    splitSize: number;
    splitPos = 0;

    constructor(size: number, minSize: number, maxSize: number, splitSize: number) {
        this.size = size;
        this.actualSize = size;
        this.minSize = minSize;
        this.maxSize = maxSize;
        this.splitSize = splitSize;
    }
}

/**
    * splitter control
    */
export class SplitterControl extends BasicControl {
    borderSize = 4; // the outer border size around the splitter control
    color = Globals.face3DColor; // splitter face color
    lightColor = Globals.light3DColor; // splitter 3d light color
    darkColor = Globals.dark3DColor; // splitter 3d dark color
    private horizontalCount: number; // number of horizontal splitters
    private verticalCount: number; // number of vertical splitters
    private hSplitSize: SplitterSize[]; // size information about horizontal splitters
    private vSplitSize: SplitterSize[]; // size information about vertical splitters
    splitAreas: BasicControl[]; // the individual areas divided amongst horizontal and vertical splitters
    private lastSize: Rect; // last position and size of the splitter control
    private mouseHnr = -1; // horizontal splitter number the mouse was pressed on
    private mouseVnr = -1; // vertical splitter number the mouse was pressed on
    private mouseXoffset: number; // offset between splitter left and mouse x
    private mouseYoffset: number; // offset between splitter top and mouse y

    /**
        * sizeChanged callback event: override to do something interesting
        */
    sizeChanged = (t: SplitterControl) => { }

    /**
        * custom mouseMove callback event: override to do something interesting
        */
    mouseMoveCustom = (t: SplitterControl, x: number, y: number, button: number) => { }

    /**
        * custom mouseDown callback event: override to do something interesting
        */
    mouseDownCustom = (t: SplitterControl, x: number, y: number, button: number) => { }

    /**
        * create a new splitter control
        * @param owner the owner control
        * @param left x position from owner
        * @param top y position from owner
        * @param width width of the control
        * @param height height of the control
        * @param horizontalCount number of horizontal areas to split
        * @param verticalCount number of vertical areas to split
        */
    constructor(owner: BasicControl, left: number, top: number, width: number, height: number, horizontalCount: number, verticalCount: number) {
        super(owner, left, top, width, height);
        this.horizontalCount = horizontalCount;
        this.verticalCount = verticalCount;
        this.hSplitSize = new Array(horizontalCount);
        this.vSplitSize = new Array(verticalCount);
        let i: number;
        for (i = 0; i < horizontalCount; i++) this.hSplitSize[i] = new SplitterSize(-1, 0, Globals.maxInt, 4);
        for (i = 0; i < verticalCount; i++) this.vSplitSize[i] = new SplitterSize(-1, 0, Globals.maxInt, 4);
        this.hSplitSize[horizontalCount - 1].splitSize = 0;
        this.vSplitSize[verticalCount - 1].splitSize = 0;
        this.splitAreas = new Array(horizontalCount * verticalCount);
        for (i = 0; i < horizontalCount * verticalCount; i++)
            this.splitAreas[i] = new BasicControl(this, 0, 0, 0, 0);
        this.lastSize = new Rect(0, 0, 0, 0);
        this.userDraw = true;
    }

    setSplitSize(splitSize: number) {
        let i: number;
        for (i = 0; i < this.hSplitSize.length; i++)
            this.hSplitSize[i].splitSize = splitSize;
        for (i = 0; i < this.vSplitSize.length; i++)
            this.vSplitSize[i].splitSize = splitSize;
        return this;
    }

    /**
        * set the sizes of a horizontal split area
        * @param horizontalNr the horizontal area number
        * @param size the desired size (-1 = automatic)
        * @param minSize the minimum size
        * @param maxSize the maximum size
        * @param splitSize size of the splitter
        */
    setHorizontalSize(horizontalNr: number, size?: number, minSize?: number, maxSize?: number, splitSize?: number) {
        if (size != undefined) this.hSplitSize[horizontalNr].size = size;
        if (minSize != undefined) this.hSplitSize[horizontalNr].minSize = minSize;
        if (maxSize != undefined) this.hSplitSize[horizontalNr].maxSize = maxSize;
        if (splitSize != undefined) this.hSplitSize[horizontalNr].splitSize = splitSize;
        this.lastSize = new Rect(0, 0, 0, 0);
        return this;
    }

    /**
        * get the desired size of a split area
        * @param horizontalNr the horizontal area number
        */
    getHorizontalSize(horizontalNr: number): number { return this.hSplitSize[horizontalNr].size; }

    /**
        * set the sizes of a vertical split area
        * @param verticalNr the vertical area number
        * @param size the disired size (-1 = automatic)
        * @param minSize the minimum size
        * @param maxSize the maximum size
        * @param splitSize size of the splitter
        */
    setVerticalSize(verticalNr: number, size?: number, minSize?: number, maxSize?: number, splitSize?: number) {
        if (size != undefined) this.vSplitSize[verticalNr].size = size;
        if (minSize != undefined) this.vSplitSize[verticalNr].minSize = minSize;
        if (maxSize != undefined) this.vSplitSize[verticalNr].maxSize = maxSize;
        if (splitSize != undefined) this.vSplitSize[verticalNr].splitSize = splitSize;
        this.lastSize = new Rect(0, 0, 0, 0);
        return this;
    }

    /**
        * get the desired size of a split area
        * @param verticalNr the vertical area number
        */
    getVerticalSize(verticalNr: number): number { return this.vSplitSize[verticalNr].size; }

    /**
        * calculate actual screen sizes of all areas
        * @param splitSize the horizontal or vertical splittersize array
        * @param splitCount total number of elements in the array
        * @param totalSize total size (width or height) on the control
        */
    private determineSizes(splitSize: SplitterSize[], splitCount: number, totalSize: number) {
        var fixedSize = 0; // total space used by fixed sized elements
        var nrVariable = 0; // total number of variable sized elements
        var i: number;
        for (i = 0; i < splitCount; i++) {
            totalSize -= splitSize[i].splitSize;
            if (splitSize[i].size >= 0) {
                fixedSize += splitSize[i].size;
                splitSize[i].actualSize = splitSize[i].size;
            } else {
                splitSize[i].actualSize = 0;
                nrVariable++;
            }
        }
        if (totalSize <= 0) { // no space
            for (i = 0; i < splitCount; i++) splitSize[i].actualSize = 0;
            return;
        }
        if (fixedSize > totalSize) { // not enough space: temporary reduce fixed size of each element
            for (i = 0; i < splitCount; i++)
                if (splitSize[i].size > 0) {
                    splitSize[i].actualSize = Math.floor(splitSize[i].size * totalSize / fixedSize);
                    fixedSize -= splitSize[i].size - splitSize[i].actualSize;
                    if (fixedSize < totalSize) { // got too small?
                        splitSize[i].actualSize += totalSize - fixedSize;
                        fixedSize = totalSize;
                    }
                }
            var temp = 0;
            if (fixedSize > totalSize) // still too large?
                for (i = 0; i < splitCount; i++)
                    if (splitSize[i].actualSize > 0 && fixedSize > totalSize) {
                        temp = Math.min(fixedSize - totalSize, splitSize[i].actualSize);
                        splitSize[i].actualSize -= temp;
                        fixedSize -= temp;
                    }
        }
        if (nrVariable > 0 && fixedSize < totalSize) { // set actual size of variable sized elements
            var variableSize = totalSize - fixedSize;
            var lastNr = 0;
            for (i = 0; i < splitCount; i++)
                if (splitSize[i].size < 0) {
                    splitSize[i].actualSize = Math.floor(variableSize / nrVariable);
                    fixedSize += splitSize[i].actualSize;
                    lastNr = i;
                }
            splitSize[lastNr].actualSize += totalSize - fixedSize; // fix rounding
        }
    }

    /**
        * propagate a size change to all previous and next areas
        * @param splitSize the horizontal or vertical splittersize array
        * @param splitCount total number of elements in the array
        * @param changeNr the splitter number that was initially changed
        * @param change by how much the area could not be changed further
        */
    private propagateSizes(splitSize: SplitterSize[], splitCount: number, changeNr: number, change: number) {
        var left: number;
        var right: number;
        var newSize: number;
        var maxChange: number;
        var realChange: number;
        if (change < 0) {
            change = -change;
            left = changeNr - 1;
            while (change > 0 && left >= 0) {
                newSize = splitSize[left].actualSize - change;
                if (newSize < splitSize[left].minSize) newSize = splitSize[left].minSize;
                maxChange = splitSize[left].actualSize - newSize;
                right = changeNr + 1; // intentional difference with below part
                while (maxChange > 0 && right < splitCount) {
                    newSize = splitSize[right].actualSize + maxChange;
                    if (newSize > splitSize[right].maxSize) newSize = splitSize[right].maxSize;
                    realChange = newSize - splitSize[right].actualSize;
                    splitSize[left].actualSize -= realChange;
                    splitSize[right].actualSize += realChange;
                    maxChange -= realChange;
                    change -= realChange;
                    right++;
                }
                left--;
            }
        }
        else if (change > 0) {
            right = changeNr + 1;
            while (change > 0 && right < splitCount) {
                newSize = splitSize[right].actualSize - change;
                if (newSize < splitSize[right].minSize) newSize = splitSize[right].minSize;
                maxChange = splitSize[right].actualSize - newSize;
                left = changeNr; // intentional difference with above part
                while (maxChange > 0 && left >= 0) {
                    newSize = splitSize[left].actualSize + maxChange;
                    if (newSize > splitSize[left].maxSize) newSize = splitSize[left].maxSize;
                    realChange = newSize - splitSize[left].actualSize;
                    splitSize[left].actualSize += realChange;
                    splitSize[right].actualSize -= realChange;
                    maxChange -= realChange;
                    change -= realChange;
                    left--;
                }
                right++;
            }
        }
    }

    /**
        * change the size of an area and propagate the changes to previous and next areas as needed
        * @param splitSize the horizontal or vertical splittersize array
        * @param splitCount total number of elements in the array
        * @param changeNr the splitter number that is changed
        * @param desiredPos the position on the control where the splitter should be
        */
    private changeSizes(splitSize: SplitterSize[], splitCount: number, changeNr: number, desiredPos: number): boolean {
        const diff = desiredPos - splitSize[changeNr].splitPos;
        if (diff === 0) return false;

        let newSize: number;
        let realDiff: number;
        newSize = splitSize[changeNr].actualSize + diff;
        if (newSize < splitSize[changeNr].minSize) newSize = splitSize[changeNr].minSize;
        if (newSize > splitSize[changeNr].maxSize) newSize = splitSize[changeNr].maxSize;
        realDiff = newSize - splitSize[changeNr].actualSize;

        if (changeNr + 1 < splitCount) {
            newSize = splitSize[changeNr + 1].actualSize - realDiff;
            if (newSize < splitSize[changeNr + 1].minSize) newSize = splitSize[changeNr + 1].minSize;
            if (newSize > splitSize[changeNr + 1].maxSize) newSize = splitSize[changeNr + 1].maxSize;
            realDiff = -(newSize - splitSize[changeNr + 1].actualSize);
            splitSize[changeNr + 1].actualSize -= realDiff;
        }
        splitSize[changeNr].actualSize += realDiff;

        if (realDiff !== diff)
            this.propagateSizes(splitSize, splitCount, changeNr, diff - realDiff);

        let result = false;
        for (let i = 0; i < splitCount; i++)
            if (splitSize[i].size >= 0 && splitSize[i].size !== splitSize[i].actualSize) {
                splitSize[i].size = Math.max(0, splitSize[i].actualSize);
                result = true;
            }
        return result;
    }

    /**
        * mouseLeave callback to replace the default from basicControl
        */
    mouseLeave() {
    }

    /**
        * mouseUp callback to replace the default from basicControl
        * @param x left screen position
        * @param y top screen position
        * @param button mousebutton number: 0 = left
        */
    mouseUp(x: number, y: number, button: number) {
        this.mouseHnr = -1;
        this.mouseVnr = -1;
    }

    /**
        * get the horizontal splitter number for a specific mouse position
        * @param x left position on the control
        */
    private getMouseOverX(x: number): number {
        for (let i = 0; i < this.horizontalCount - 1; i++)
            if (x >= this.hSplitSize[i].splitPos && x < this.hSplitSize[i].splitPos + this.hSplitSize[i].splitSize)
                return i;
        return -1;
    }

    /**
        * get the vertical splitter number for a specific mouse position
        * @param y top position on the control
        */
    private getMouseOverY(y: number): number {
        for (let i = 0; i < this.verticalCount - 1; i++)
            if (y >= this.vSplitSize[i].splitPos && y < this.vSplitSize[i].splitPos + this.vSplitSize[i].splitSize)
                return i;
        return -1;
    }

    /**
        * mouseDown callback to replace the default from basicControl
        * @param x left screen position
        * @param y top screen position
        * @param button mousebutton number: 0 = left
        */
    mouseDown(x: number, y: number, button: number) {
        x -= this.screenPos.left;
        y -= this.screenPos.top;
        this.mouseHnr = this.getMouseOverX(x);
        this.mouseVnr = this.getMouseOverY(y);
        if (this.mouseHnr >= 0)
            this.mouseXoffset = x - this.hSplitSize[this.mouseHnr].splitPos;
        if (this.mouseVnr >= 0)
            this.mouseYoffset = y - this.vSplitSize[this.mouseVnr].splitPos;
        this.mouseDownCustom(this, x, y, button);
    }

    /**
        * mouseMove callback to replace the default from basicControl
        * @param x left screen position
        * @param y top screen position
        * @param button mousebutton number: 0 = left
        */
    mouseMove(x: number, y: number, button: number) {
        x -= this.screenPos.left;
        y -= this.screenPos.top;
        let changeX = false;
        let changeY = false;
        let mouseOverX = this.mouseHnr;
        let mouseOverY = this.mouseVnr;
        if (this.mouseHnr < 0 && this.mouseVnr < 0) {
            mouseOverX = this.getMouseOverX(x);
            mouseOverY = this.getMouseOverY(y);
        }
        if (mouseOverX >= 0 && mouseOverY >= 0) this.controller.mouseCursor = "move";
        else if (mouseOverX >= 0) this.controller.mouseCursor = "w-resize";
        else if (mouseOverY >= 0) this.controller.mouseCursor = "n-resize";
        if (this.mouseHnr >= 0)
            changeX = this.changeSizes(this.hSplitSize, this.horizontalCount, this.mouseHnr, x - this.mouseXoffset);
        if (this.mouseVnr >= 0)
            changeY = this.changeSizes(this.vSplitSize, this.verticalCount, this.mouseVnr, y - this.mouseYoffset);
        if (changeX || changeY) {
            this.lastSize = new Rect(0, 0, 0, 0);
            this.redraw();
        }
        this.mouseMoveCustom(this, x, y, button);
    }

    /**
        * get a specific split area
        * @param horizontalNr the horizontal area number starting at 0
        * @param verticalNr the vertical area number starting at 0
        */
    getSplitArea(horizontalNr: number, verticalNr: number): BasicControl {
        return this.splitAreas[(verticalNr * this.horizontalCount) + horizontalNr];
    }

    /**
        * draw callback
        * @param left x position on screen
        * @param top y position on screen
        */
    draw(left: number, top: number) {
        super.draw(left, top);
        const context = this.controller.ctx;
        var a = new Rect(left, top, this.position.width, this.position.height);
        var bs = this.borderSize;
        var y: number;
        var x: number;
        var splitSize: number;
        // draw border
        if (this.borderSize > 0) {
            if (this.borderSize > 1) {
                bs--;
                context.fillStyle = this.lightColor;
                context.fillRect(a.left, a.top, a.width, 1);
                context.fillRect(a.left, a.top, 1, a.height);
                if (a.width - bs - bs > 0) context.fillRect(a.left + bs, a.bottom - bs, a.width - bs - bs, 1);
                if (a.height - bs - bs > 0) context.fillRect(a.right - bs, a.top + bs, 1, a.height - bs - bs);
                context.fillStyle = this.darkColor;
                context.fillRect(a.left, a.bottom, a.width, 1);
                context.fillRect(a.right, a.top, 1, a.height);
                if (a.width - bs - bs > 0) context.fillRect(a.left + bs, a.top + bs, a.width - bs - bs, 1);
                if (a.height - bs - bs > 0) context.fillRect(a.left + bs, a.top + bs, 1, a.height - bs - bs);
                if (this.borderSize > 2)
                    a.change(a.left + 1, a.top + 1, a.width - 2, a.height - 2);
                bs = Math.max(1, bs - 1);
            }
            context.fillStyle = this.color;
            context.fillRect(a.left, a.top, a.width, bs);
            context.fillRect(a.left, a.top + a.height - bs, a.width, bs);
            if (a.height - bs - bs > 0) context.fillRect(a.left, a.top + bs, bs, a.height - bs - bs);
            if (a.height - bs - bs > 0) context.fillRect(a.left + a.width - bs, a.top + bs, bs, a.height - bs - bs);
            bs = this.borderSize;
            a.change(left + bs, top + bs, this.position.width - bs - bs, this.position.height - bs - bs);
        }
        var i: number;
        if (a.height <= 0 || a.width <= 0) {
            for (i = 0; i < this.splitAreas.length; i++)
                if (this.splitAreas[i] != undefined) this.splitAreas[i].visible = false;
            return;
        }
        var j: number;
        if (this.position.width !== this.lastSize.width || this.position.height !== this.lastSize.height) {
            this.lastSize.change(0, 0, this.position.width, this.position.height);
            this.determineSizes(this.hSplitSize, this.horizontalCount, a.width);
            this.determineSizes(this.vSplitSize, this.verticalCount, a.height);
            // set position of clients
            var gridNr = 0;
            y = a.top;
            for (i = 0; i < this.verticalCount; i++) {
                x = a.left;
                for (j = 0; j < this.horizontalCount; j++) {
                    this.splitAreas[gridNr].position.change(x - left, y - top, this.hSplitSize[j].actualSize, this.vSplitSize[i].actualSize);
                    this.splitAreas[gridNr].visible = this.splitAreas[gridNr].position.width > 0 && this.splitAreas[gridNr].position.height > 0;
                    x += this.hSplitSize[j].actualSize + this.hSplitSize[j].splitSize;
                    gridNr++;
                }
                y += this.vSplitSize[i].actualSize + this.vSplitSize[i].splitSize;
            }
            this.sizeChanged(this);
        }
        // draw sizebars
        bs = Math.min(1, bs);
        for (j = 0; j < 3; j++) { // first step = light, second = dark, third = color
            if (j === 0) context.fillStyle = this.lightColor;
            else if (j === 1) context.fillStyle = this.darkColor;
            else context.fillStyle = this.color;
            x = a.left;
            for (i = 0; i < this.horizontalCount; i++) {
                x += this.hSplitSize[i].actualSize;
                this.hSplitSize[i].splitPos = x - left;
                splitSize = this.hSplitSize[i].splitSize;
                if (splitSize > 2 && j === 0) context.fillRect(x, a.top, 1, a.height);
                else if (splitSize > 2 && j === 1) context.fillRect(x + splitSize - 1, a.top, 1, a.height);
                else if (splitSize > 0 && j === 2) context.fillRect(splitSize > 2 ? x + 1 : x, a.top - bs, splitSize > 2 ? splitSize - 2 : splitSize, a.height + bs + bs);
                x += splitSize;
            }
            y = a.top;
            for (i = 0; i < this.verticalCount; i++) {
                y += this.vSplitSize[i].actualSize;
                this.vSplitSize[i].splitPos = y - top;
                splitSize = this.vSplitSize[i].splitSize;
                if (splitSize > 2 && j === 0) context.fillRect(a.left, y, a.width, 1);
                else if (splitSize > 2 && j === 1) context.fillRect(a.left, y + splitSize - 1, a.width, 1);
                else if (splitSize > 0 && j === 2) context.fillRect(a.left - bs, splitSize > 2 ? y + 1 : y, a.width + bs + bs, splitSize > 2 ? splitSize - 2 : splitSize);
                y += splitSize;
            }
        }
    }
}