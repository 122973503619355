import * as Globals from './../utils/globals';
import { TimeSpan } from './../utils/timespan';
import { Planboard } from './../entities/planboard';
import { DrawHelper } from './drawing';

/**
    * draw a timeline
    * @param id unique id to cach the image
    * @param destCtx destenation canvas context
    * @param destX destenation left position
    * @param destY destenation top position
    * @param width width of the timeline
    * @param height height of the timeline
    */
export function drawTimeLine(id: number, destCtx: CanvasRenderingContext2D,
    destX: number, destY: number, width: number, height: number,
    startHour: number, endHour: number) {
    const ctx = DrawHelper.sharedBuffer.ctx;
    if (width <= 0 || height <= 0 || width >= ctx.canvas.width) return;
    const stored = DrawHelper.getStoredObject(id, width, height);
    const r = stored[1];
    if (!stored[2]) { // it was not stored so we need to draw it
        DrawHelper.draw3DRect(ctx, r.left, r.top, width, height);
        const totalHours = endHour - startHour;
        let hourMod = 1;
        let halfHourMod = 0;
        let maxLines = width / 4;
        if (maxLines >= totalHours * 12) { maxLines = totalHours * 12; hourMod = 12; halfHourMod = 6; } // every 5 minutes
        else if (maxLines >= totalHours * 4) { maxLines = totalHours * 4; hourMod = 4; halfHourMod = 2; } // every 15 minutes
        else if (maxLines >= totalHours * 2) {maxLines = totalHours * 2; hourMod = 2; } // every 30 minutes
        else if (maxLines >= totalHours) maxLines = totalHours; // every hour
        if (width / 4 <= 20) hourMod = 1; // do not draw hour text
        if (maxLines >= totalHours) {
            const hourHeight = halfHourMod > 0 ? 9 : 8;
            let x: number;
            let currHour = startHour;
            ctx.fillStyle = Globals.darker3DColor;
            ctx.textAlign = Globals.alignCenter;
            ctx.textBaseline = Globals.alignBottom;
            ctx.font = Globals.smallFont;
            for (let i = 1; i <= maxLines; i++) {
                x = Math.floor((i * (width - 1)) / maxLines);
                if (i % hourMod === 0 && hourMod >= 2) {
                    ctx.fillRect(r.left + x, r.bottom - hourHeight, 1, hourHeight);
                    currHour++;
                    if (currHour > startHour && currHour < endHour && (currHour % 2 === 0 || halfHourMod >= 2))
                        ctx.fillText(`${currHour}`, r.left + x, r.bottom - hourHeight);
                } else if (halfHourMod > 0 && i % halfHourMod === 0) {
                    ctx.fillRect(r.left + x, r.bottom - 6, 1, 6);
                } else
                    ctx.fillRect(r.left + x, r.bottom - 4, 1, 4);
            }
            ctx.font = Globals.defaultFont; // restore to default
        }
    }
    destCtx.drawImage(ctx.canvas, r.left, r.top, width, height, destX, destY, width, height);
}

/**
    * calculate the width of a column
    * @param startHour starting hour
    * @param endHour ending hour
    * @param zoomLevel zoom level 1 and higher
    */
export function calculateTimeLineWidth(startHour: number, endHour: number, zoomLevel: number): number {
    return Math.floor((endHour - startHour) * 4 * Math.max(zoomLevel, 1)) + 1;
}

/**
    * convert a screen position to a number of minutes
    * @param startHour starting hour of the day
    * @param endHour ending hour of the day
    * @param width total width of the screen space
    * @param screenX the number of pixels on screen
    */
export function screenToMinutes(startHour: number, endHour: number, width: number, screenX: number): number {
    const visibleMinutes = (endHour - startHour) * 60;
    const minutes = Math.floor((visibleMinutes * screenX) / width);
    return (startHour * 60) + minutes;
}

/**
    * convert a time to a screen position
    * @param startHour starting hour of the day
    * @param endHour ending hour of the day
    * @param width total width of the screen space
    * @param hours the hours component of the time (or 0 if minutes is the total minutes)
    * @param minutes the minutes component of the time (or the total minutes if hours is 0)
    */
export function timeToX(startHour: number, endHour: number, width: number, hours: number, minutes: number): number {
    const visibleMinutes = (endHour - startHour) * 60;
    const timeMinutes = (hours * 60) + minutes - (startHour * 60);
    return Math.floor((timeMinutes * width) / visibleMinutes);
}

/**
    * convert a date with time to a cell position
    * @param d the date and time
    * @param colDayNr the day number of the column
    * @param colWidth the width of the cell
    * @param isEndTime true if this is the endtime, false if it is the starttime
    */
export function dateTimeToX(d: Date, colDayNr: number, colWidth: number, isEndTime: boolean): number {
    const dateDayNr = TimeSpan.getDayNr(d);
    let x = isEndTime ? colWidth + 7 : -8;
    if (colDayNr === dateDayNr)
        x = timeToX(Planboard.dayStartHour, Planboard.dayEndHour, colWidth - 1, d.getHours(), d.getMinutes());
    return x;
}