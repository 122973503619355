import { AreaControl } from './../controls/areaControl';
import { DrawHelper } from './../drawing/drawing';
import * as Globals from './../utils/globals';
import { Planboard } from './../entities/planboard';

import { PlanboardResources } from './planboardResources';
import { PlanboardSplitters } from './planboardSplitters';
import { PlannedActivities } from './plannedActivities';

export class PlanboardCounters {
    static countersWeekNr = -1;
    static countersMonthNr = -1;
    static countersYearNr = -1;
    static countersStartDate = null;
    static countersEndDate = null;

    static initialize() {
        const areaCounters = Planboard.areaCounters = new AreaControl(Planboard.split1.getSplitArea(2, 1), 0, 0, 100, 100, 3000, 2000);
        areaCounters.name = "areaCounters";
        areaCounters.setUseBackbuffer(true);
        areaCounters.setAlign(0, 0, 0, 0);
        areaCounters.backcolor = Globals.windowColor;
        areaCounters.gridColor = Globals.gridLinesColor;
        areaCounters.clearColor = areaCounters.backcolor;
        areaCounters.cols.setSize(3, 80, 16);
        areaCounters.rows.setSize(Planboard.activities.getResourceCount() - 1, Globals.fontHeight + 1 + Planboard.getPlanningStatusRowHeight(), 8);
        areaCounters.linkVertical(Planboard.areaMain).linkVertical(Planboard.areaResources);
        areaCounters.vBarVisible = false;
        areaCounters.drawCell = (t: AreaControl, ctx: CanvasRenderingContext2D, col: number, row: number, colWidth: number, rowHeight: number, contextX: number, contextY: number) => {
            const resourceId = Planboard.activities.getResourceId(row);
            if (resourceId === PlanboardResources.selectedResourceId) {
                ctx.fillStyle = Globals.highlightColor;
                ctx.fillRect(contextX, contextY, colWidth - 1, rowHeight - 1);
            }
            let counters = Planboard.activities.getResourceCounters(resourceId, this.countersWeekNr, this.countersMonthNr, this.countersYearNr, this.countersStartDate, this.countersEndDate);
            if (!counters) return;
            let value = col === 0 ? counters.balance : col === 1 ? counters.planned : col === 2 ? counters.required : col === 3 ? counters.absence : null;
            if (value != null) {
                ctx.fillStyle = counters.dirtyFlag !== 2 ? Globals.dark3DColor : value < 0 ? "rgb(191,0,0)" : Globals.windowTextColor;
                ctx.textAlign = Globals.alignRight;
                ctx.textBaseline = Globals.alignMiddle;
                ctx.fillText(`${value.toString()}`, Math.floor(contextX + colWidth - 4), Math.floor(contextY + rowHeight * 0.5));
            }
        }
        areaCounters.mouseDownCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            const resourceId = Planboard.activities.getResourceId(row);
            PlanboardResources.selectedResourceId = resourceId === PlanboardResources.selectedResourceId ? -1 : resourceId;
            Planboard.areaMain.redrawAll();
            Planboard.areaResources.redrawAll();
            Planboard.areaCounters.redrawAll();
            Planboard.controller.redraw();
            Planboard.onMouseDownCell(t, col, row, celX, celY, button, mouseX, mouseY);
        }
        areaCounters.mouseUpCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            Planboard.onMouseUpCell(t, col, row, celX, celY, button, mouseX, mouseY);
        }
        areaCounters.dragDropCell = Planboard.areaMain.dragDropCell;
        this.initMouseMoveCell();

        const areaCounterHeader = Planboard.areaCounterHeader = new AreaControl(Planboard.split1.getSplitArea(2, 0), 0, 0, 100, 100, 3000, 2000);
        areaCounterHeader.name = "areaCounterHeader";
        areaCounterHeader.setUseBackbuffer(true);
        areaCounterHeader.setAlign(0, 0, 0, 0);
        areaCounterHeader.backcolor = Globals.windowColor;
        areaCounterHeader.gridColor = "";
        areaCounterHeader.clearColor = areaCounterHeader.backcolor;
        areaCounterHeader.cols.setSize(areaCounters.cols.count - 1, areaCounters.cols.getSize(0), areaCounters.cols.getMinimum(0));
        areaCounterHeader.rows.setSize(0, PlanboardSplitters.timelineHeight(), 0);
        areaCounterHeader.linkHorizontal(areaCounters);
        areaCounterHeader.hBarVisible = false;
        areaCounterHeader.vBarVisible = false;
        areaCounterHeader.enableColumnResize = true;
        areaCounterHeader.drawCell = (t: AreaControl, ctx: CanvasRenderingContext2D, col: number, row: number, colWidth: number, rowHeight: number, contextX: number, contextY: number) => {
            DrawHelper.draw3DRect(ctx, contextX, contextY, colWidth, rowHeight);
            ctx.fillStyle = Globals.darker3DColor;
            ctx.textAlign = Globals.alignLeft;
            ctx.textBaseline = Globals.alignMiddle;
            if (col === 0) {
                let text = Planboard.getTextLabel("COUNTERS_BALANCE");
                ctx.fillText(text, Math.floor(contextX + 4), Math.floor(contextY + rowHeight * 0.3));
                text = PlanboardCounters.countersWeekNr >= 0 ? Planboard.getTextLabel("WEEK") + " " + PlanboardCounters.countersWeekNr.toString()
                    : PlanboardCounters.countersMonthNr >= 0 ? Globals.dateFormat(new Date(PlanboardCounters.countersYearNr, PlanboardCounters.countersMonthNr - 1, 15), "MMMM")
                        : PlanboardCounters.countersStartDate != null ? Globals.dateFormat(PlanboardCounters.countersStartDate, 'shortDate') + ' - ' + Globals.dateFormat(PlanboardCounters.countersEndDate, 'shortDate')
                            : PlanboardCounters.countersYearNr >= 0 ? PlanboardCounters.countersYearNr.toString() : "";
                ctx.fillText(text, Math.floor(contextX + 4), Math.floor(contextY + rowHeight * 0.7));
            } else {
                const text = Planboard.getTextLabel(col === 1 ? "COUNTERS_PLANNED" : col === 2 ? "COUNTERS_REQUIRED" : "ABSENCE");
                ctx.fillText(text, Math.floor(contextX + 4), Math.floor(contextY + rowHeight * 0.5));
            }
        }
        areaCounterHeader.dragDropCell = Planboard.areaMain.dragDropCell;
        areaCounterHeader.mouseDownCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            Planboard.onMouseDownCell(t, col, row, celX, celY, button, mouseX, mouseY);
        }
        areaCounterHeader.mouseUpCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            Planboard.onMouseUpCell(t, col, row, celX, celY, button, mouseX, mouseY);
        }

        areaCounterHeader.visible = areaCounters.visible = false;
    }

    private static initMouseMoveCell() {
        Planboard.areaCounters.mouseMoveCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            PlannedActivities.moveIndicators(t, mouseX, mouseY);
        }
    }

}