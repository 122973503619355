import { StateService } from '@uirouter/angularjs';
import { IUserService } from './../../shared/userService';
import { ITranslationService } from './../i18n/translationService';
import { ITreeListScope } from './../treeListController/ITreeListScope';

import * as Constants from './../utils/constants';
import { IModalConfirmationWindowService } from './../utils/modalConfirmationWindowService';

import { IAuthenticationService } from './authenticationService';
import { IConfigurationService } from '../../shared/configurationService';

export class LoginLogoutLinkController {

    private readonly dialogToken: string = "loginLogout";
    public featurePlanboardAPlanningBoard: boolean = false;

    static $inject = [
        "$scope",
        "$location",
        "$timeout",
        "$state",
        "$window",
        "authenticationService",
        "modalConfirmationWindowService",
        "translationService",
        "configurationService",
        "userService"
    ];

    constructor(
        public $scope: ITreeListScope,
        private $location: ng.ILocationService,
        private $timeout: ng.ITimeoutService,
        private $state: StateService,
        private $window: ng.IWindowService,
        private authenticationService: IAuthenticationService,
        private modalConfirmationWindowService: IModalConfirmationWindowService,
        private translationService: ITranslationService,
        private configurationService: IConfigurationService,
        public userService: IUserService
    ) {
        this.translationService.getTextLabels(this.$scope);
        this.configurationService.getAppConfiguration(() => {
            this.featurePlanboardAPlanningBoard = this.configurationService.appConfiguration.featureManagement.featurePlanboardAPlanningBoard;
        });
        this.translationService.setHelpUrlPath();
    }

    logoff(): void {
        this.modalConfirmationWindowService.showModalInfoDialog(
            this.$scope.textLabels.LOGGING_OFF_TITLE,
            this.$scope.textLabels.LOGGING_OFF_TEXT, "", null, Constants.modalWaitDelay, this.dialogToken);
        this.timedLogoff(10);
    }

    openPreferences(): void {
        this.$state.transitionTo("userPreferences", {});
    }

    isHelpUrlDefined(): boolean {
        if (this.translationService.getHelpUrlPath())
            return true;
        return false;
    }

    onHelpButtonClick(): void {
        this.$window.open(this.translationService.getHelpUrlPath(), '_blank');
    }

    private timedLogoff(retryCount): void {
        var waitTime = this.userService.getLogoffWaitTime();
        if (waitTime > 0 && retryCount > 0)
            this.$timeout(() => { this.timedLogoff(retryCount - 1); }, 1000);
        else
            this.authenticationService.logoff(this.onLogoutSuccess, this.onLogoutFail);
    }
    private onLogoutSuccess(): void {
        this.modalConfirmationWindowService.closeModalWindow(this.dialogToken);
        this.$location.path("/login");
    };

    private onLogoutFail(): void {
        this.modalConfirmationWindowService.closeModalWindow(this.dialogToken);
        // TODO: what to do when logging out has failed?    
    };
}