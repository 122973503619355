﻿//The interceptor intercepts all messages sent with $http, and adds the token to the Authorization header

export var authInterceptorService = ["$q", "$location", "$window", "$injector",
    function ($q, $location, $window, $injector) {
        var authInterceptorServiceFactory = {};

        function isStaticResource(url) {
            return url.endsWith(".json");
        }

        var _request = function (config) {
            config.headers = config.headers || {};

            const currentUserJson = $window.localStorage.getItem('ngStorage-currentUser');
            const currentUser = currentUserJson === undefined ? undefined : JSON.parse(currentUserJson);
            if (currentUser && !isStaticResource(config.url)) {
                config.headers.Authorization = 'Bearer ' + currentUser.token;
            }
            return config;
        };
        // Token was not accepted, reroute to the login page
        var _responseError = function (rejection) {
            if (rejection.status === 401 && $window.location.hash !== "#!/portal") {
                const userService = $injector.get('userService');

                if (userService.isAuthenticated) {
                    const authenticationService = $injector.get('authenticationService');

                    authenticationService.logoff();
                }

            }

            return $q.reject(rejection);
        };

        authInterceptorServiceFactory.request = _request;
        authInterceptorServiceFactory.responseError = _responseError;

        return authInterceptorServiceFactory;
    }];