import { Dictionary, OrderedDictionary } from './../../utils/dictionary';
import { ResourcePlanningStatus } from './resourcePlanningStatus';

export class Resource {
    id: number;
    extid: string;
    displayName: string;
    lastRequestTime: number;
    properties: Dictionary;
    units: OrderedDictionary;
    lastMembershipUnit: any; // for displaying resource availability in planning status line in the planboard
    planningStatus: ResourcePlanningStatus[]; // array of statusses ordered by untilDate

    constructor(id: number, extid: string, displayName: string) {
        this.id = id;
        this.extid = extid;
        this.displayName = displayName;
        this.lastRequestTime = 0;
    }
}