export class TimeSpan {
    totalMiliseconds: number;

    /**
        * create a new TimeSpawn with a total number of miliseconds
        * @param miliseconds the number of miliseconds
        */
    constructor(miliseconds: number) {
        this.totalMiliseconds = miliseconds;
    }

    /**
        * convert this TimeSpan to a date
        */
    toDate(): Date { return new Date(this.totalMiliseconds); }

    /**
        * increase/decrease with number of minutes and return self for chaining
        */
    addMinutes(nr: number): TimeSpan {
        //this.totalMiliseconds += nr * 60000; // fails if the timezoneOffset changes due to daylight savings
        let d = this.toDate();
        d.setMinutes(d.getMinutes() + nr);
        this.totalMiliseconds = d.getTime();
        return this; 
    }

    /**
        * increase/decrease with number of days and return self for chaining
        */
    addDays(nr: number): TimeSpan {
        //this.totalMiliseconds += nr * 86400000; // fails if the timezoneOffset changes due to daylight savings
        let d = this.toDate();
        d.setDate(d.getDate() + nr);
        this.totalMiliseconds = d.getTime();
        return this;
    }

    /**
        * get total number of days since 1 January 1970, compensating for the users timezone
        */
    static getDayNr(d: Date): number { return Math.floor((d.getTime() - d.getTimezoneOffset() * 60000) / 86400000); }
    static getDayNrWithOffset(d: Date, offset: number): number { return Math.floor((d.getTime() + offset - d.getTimezoneOffset() * 60000) / 86400000); }

    /**
        * returns if two dates are on the same day
        * @param d1 first date
        * @param d2 second date
        */
    static sameDayNr(d1: Date, d2: Date): boolean { return (this.getDayNr(d1) === this.getDayNr(d2)); }

    /**
        * convert a dayNr back to a date
        */
    static dayNrToDate(dayNr: number): Date {
        let miliseconds = dayNr * 86400000 + 12 * 3600000;
        let d = new Date(miliseconds);
        while (this.getDayNr(d) !== dayNr) {
            miliseconds += (this.getDayNr(d) < dayNr) ? 86400000 : -86400000;
            d = new Date(miliseconds);
        }
        d.setHours(0, 0, 0, 0);
        return d;
    }

    /**
        * create a new TimeSpan from a date
        */
    static fromDate(d: Date): TimeSpan { return new TimeSpan(d.getTime()); }

    /**
        * create a new TimeSpan from a date without the time component
        */
    static fromDateNoTime(d: Date): TimeSpan {
        d = new Date(d.getTime());
        d.setHours(0, 0, 0, 0);
        return new TimeSpan(d.getTime());
    }

    /**
        * get the current date without the time component
        */
    static get today(): TimeSpan {
        const d = new Date();
        d.setHours(0, 0, 0, 0);
        return new TimeSpan(d.getTime());
    }

    /**
        * return if periodB falls within periodA, inclusively
        * @param periodAStart the start date of periodA
        * @param periodAEnd the end date of periodA
        * @param periodBStart the start date of periodB
        * @param periodBEnd the end date of periodB
        */
    static periodContainsPeriod(periodAStart: Date, periodAEnd: Date, periodBStart: Date, periodBEnd: Date): boolean {
        // If A has no bounds, then it contains B
        if (!periodAStart && !periodAEnd)
            return true;

        // B is contained if it starts on or after A
        if (!periodAEnd && periodBStart)
            if (this.getDayNr(periodBStart) - this.getDayNr(periodAStart) >= 0)
                return true;

        // B is contained if it ends on or before A
        if (!periodAStart && periodBEnd)
            if (this.getDayNr(periodAEnd) - this.getDayNr(periodBEnd) >= 0)
                return true;

        // B is not contained if it has no start or end while A has start and end
        if (!periodBStart || !periodBEnd)
            return false;

        // B is contained if it starts on or after A and ends on or before A
        if (this.getDayNr(periodBStart) - this.getDayNr(periodAStart) >= 0 &&
            this.getDayNr(periodAEnd) - this.getDayNr(periodBEnd) >= 0)
            return true;

        return false;
    }

}