export var omrpDynamicInput = [
    "$rootScope", "$timeout",
    function ($rootScope, $timeout) {
        return {
            // can be used as an attribute or element or class
            restrict: "AEC",

            // declare the directives private scope
            scope: {
                focuslost: "&",
                valuechanged: "&",
                mask: "@",
                useclass: "@"
            },

            link: function (scope, element) {
                var inputVisible = false;
                var triggerElement = element[0];
                var inputField;
                var originalValue;
                var nummericChars = "0123456789";
                var alphaCharsL = "abcdefghijklmnopqrstuvwxyz";
                var alphaCharsU = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                var maskedParts = [];

                var setCaretPosition = function (el, caretPos) {
                    el.focus();
                    if (el.selectionStart || el.selectionStart === 0) {
                        el.setSelectionRange(caretPos, caretPos);
                    } else if (el.createTextRange) {
                        var range = el.createTextRange();
                        range.move("character", caretPos);
                        range.select();
                    };
                }

                var getCaretPosition = function (el) {
                    var result = 0;
                    el.focus();
                    if (el.selectionStart || el.selectionStart === 0) {
                        result = el.selectionStart;
                    } else if ((<any>document).selection) {
                        var oSel = (<any>document).selection.createRange();
                        oSel.moveStart("character", -el.value.length);
                        result = oSel.text.length;
                    }
                    return result;
                }

                var createMaskedParts = function () {
                    var startPos = 0;
                    var isNummeric = true;
                    var negAllowed = false;
                    var decSymbol = "";
                    var validChars = "*" + nummericChars + alphaCharsL + alphaCharsU;
                    var inputMask = scope.mask + ":";
                    for (var i = 0; i < inputMask.length; i++) {
                        var charValue = inputMask.substring(i, i + 1);
                        if (!negAllowed && isNummeric && charValue === "-" &&
                            nummericChars.indexOf(inputMask.substring(i + 1, i + 2)) >= 0) {
                            negAllowed = true; // negative values allowed if - followed by number, e.g. "-999"
                        } else if (i > 0 && isNummeric && decSymbol === "" &&
                            (charValue === "." || charValue === ",") &&
                            nummericChars.indexOf(inputMask.substring(i - 1, i)) >= 0 &&
                            nummericChars.indexOf(inputMask.substring(i + 1, i + 2)) >= 0) {
                            decSymbol = charValue; // decimal values allowed if , or . surrounded by numbers, e.g. "999.9999"
                        } else if (validChars.indexOf(charValue) < 0) {
                            if (startPos < i) {
                                maskedParts.push({
                                    mask: inputMask.substring(startPos, i),
                                    nummeric: isNummeric,
                                    negative: negAllowed,
                                    decimal: decSymbol,
                                    separator: i < inputMask.length - 1 ? charValue : ""
                                });
                            }
                            startPos = i + 1;
                            isNummeric = true;
                            negAllowed = false;
                            decSymbol = "";
                        } else if (nummericChars.indexOf(charValue) < 0) isNummeric = false;
                    }
                }

                var convertToMask = function (inputText, inputElement) {
                    var caretPos = inputElement != undefined ? getCaretPosition(inputElement) : 0;
                    var result = "";
                    var pos = 0;
                    for (var i = 0; i < maskedParts.length; i++)
                        if (pos < inputText.length) {
                            var checkLength = !(maskedParts[i].mask === "*" ||
                                maskedParts[i].mask === "0" ||
                                maskedParts[i].mask === "a" ||
                                maskedParts[i].mask === "A");
                            var endPos = maskedParts[i].separator === ""
                                ? inputText.length
                                : inputText.indexOf(maskedParts[i].separator, pos);
                            if (endPos < 0) endPos = inputText.length;
                            var checkText = inputText.substring(pos, endPos);
                            var addText = "";
                            var j = 0;
                            while (j < checkText.length) {
                                var charValue = checkText.substring(j, j + 1);
                                var charAllowed = true;
                                if (j === 0 && i > 0 && charValue === maskedParts[i - 1].separator) {
                                } else {
                                    if (maskedParts[i].nummeric && nummericChars.indexOf(charValue) < 0) {
                                        if (charValue !== maskedParts[i].decimal &&
                                            (charValue !== "-" || !maskedParts[i].negative)) charAllowed = false;
                                    } else if (!maskedParts[i].nummeric) {
                                        if (nummericChars.indexOf(charValue) >= 0 ||
                                            alphaCharsL.indexOf(charValue) >= 0 ||
                                            alphaCharsU.indexOf(charValue) >= 0) {
                                        } else charAllowed = false;
                                    }
                                    if (charAllowed)
                                        addText += charValue;
                                    else caretPos--;
                                }
                                j++;
                            }
                            if (checkLength && addText.length > maskedParts[i].mask.length) {
                                var oldCaretPos = caretPos;
                                caretPos -= addText.length - maskedParts[i].mask.length;
                                if (caretPos < oldCaretPos) caretPos = oldCaretPos;
                                addText = addText.substring(0, maskedParts[i].mask.length);
                            }
                            result += addText;
                            pos = endPos;
                            if (maskedParts[i].separator !== "") {
                                result += maskedParts[i].separator;
                                pos += maskedParts[i].separator.length;
                            }
                        }
                    if (inputElement != undefined) {
                        inputElement.value = result;
                        setCaretPosition(inputElement, Math.max(Math.min(caretPos, result.length), 0));
                    }
                    return result;
                }

                var inputCallback = function () {
                    if (scope.mask && scope.mask !== "") {
                        if (maskedParts.length === 0) createMaskedParts();
                        convertToMask(inputField.value, inputField);
                    }
                    scope.valuechanged({ id: triggerElement.id, value: inputField.value });
                }

                var clickingCallback = function () {
                    if (inputVisible) return;
                    originalValue = triggerElement.innerText;
                    var rect = triggerElement.getBoundingClientRect();
                    inputVisible = true;
                    inputField = document.createElement("input");
                    inputField.type = "text";
                    if (scope.useclass)
                        inputField.className = scope.useclass;
                    else {
                        inputField.style.border = "none";
                        inputField.style.padding = "0";
                    }
                    inputField.style.width = "" + rect.width + "px";
                    inputField.style.height = "" + rect.height + "px";
                    inputField.style.left = "0px";
                    inputField.style.top = "0px";
                    inputField.value = originalValue;
                    triggerElement.innerText = "";
                    triggerElement.appendChild(inputField);
                    inputField.focus();
                    if (inputField.value) {
                        setCaretPosition(inputField, inputField.value.length);
                    }
                    inputField.addEventListener("input", inputCallback);
                };

                var focusLoss = function () {
                    inputVisible = false;
                    if (inputField.value !== originalValue) {
                        //scope.valuechanged({ id: triggerElement.id, value: inputField.value });
                        scope.focuslost({ id: triggerElement.id, value: inputField.value });
                    }
                    triggerElement.innerText = inputField.value;
                    try { triggerElement.removeChild(inputField); } catch (err) { }
                }

                scope.$on("$destroy",
                    function () {
                        if (!inputVisible) return;
                        focusLoss();
                    });

                $rootScope.$on("documentClicked",
                    function (inner, target) {
                        if (target[0] === triggerElement) {
                            clickingCallback();
                            return;
                        }
                        if (!inputVisible) return;
                        var parents = $(target[0]).parents();
                        for (var i = 0; i < parents.length; i++)
                            if (parents[i] === triggerElement) return;
                        focusLoss();
                    });
            }

        };
    }
];