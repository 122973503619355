export class Cooperation {
    resourceId: number;
    otherResourceId: number;
    value: number;

    constructor(resourceId: number, otherResourceId: number, value: number) {
        this.resourceId = resourceId;
        this.otherResourceId = otherResourceId;
        this.value = value;
    }
}