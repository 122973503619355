import { StateParams } from '@uirouter/angularjs';
import * as moment from 'moment-timezone';
import { INumberService } from './../../shared/numberService';
import { IPageStartService } from './../../shared/pageStartService';
import { IUserService } from './../../shared/userService';

import { ITranslationService } from './../i18n/translationService';

import { ActivityInfo } from './../planboard/drawing/activityInfo';
import { Activity } from './../planboard/entities/activity';
import { ActivityType } from './../planboard/entities/activitytype';
import { Cooperation } from './../planboard/entities/cooperation';
import { Planboard } from './../planboard/entities/planboard';
import * as Globals from './../planboard/utils/globals';
import { TimeSpan } from './../planboard/utils/timespan';

import { ITreeListScope } from './../treeListController/ITreeListScope';

import * as Constants from './../utils/constants';
import { Dictionary } from './../utils/dictionary';
import { IModalConfirmationWindowService } from './../utils/modalConfirmationWindowService';
import * as Timezone from './../utils/timezone';

export class ActivityController {

    rootActivity: any = null;
    selectedActivity: any = null;
    activityTree: Array<any> = []; // array with only one activity, this activity has a property nodes with child activities
    emptyTree: object = Object.create(null);
    memoText: string = null;
    memoHistory: object = Object.create(null);
    selectedHistoryMemoId: number = 0;
    selectedHistoryMemoCount: number = 0;
    memoChangedByUser: boolean = false;
    activityDescription: string = "";
    activityNrSubjects: string = "";
    activityLinkText: string = "";
    activityLinkUrl: string = "";
    activityRisk: boolean = false;
    activityLinkEdit: boolean = false;
    activityIsLocked: boolean = false;
    detailsVisible: boolean = false;
    detailsText: string = "";

    private commonSvc: any;
    private planboard = Planboard;
    private viewLoaded: boolean = false;
    private elActivityTree = $("#elActivityTree"); // TODO: find alternative to jquery
    private detailsPane = $("#detailsPane"); // TODO: find alternative to jquery
    private activityDict: object = null;
    private resourceDisplayNames: object = Object.create(null);
    private resourceDisplayNamesLoading: boolean = false;
    private resourceTypes: object = Object.create(null);
    private uniqueNewId: number = -1000;
    private deletedActivities: Array<any> = [];
    private pendingWebApiActions: Array<any> = [];
    private currentWebApiActionIndex: number = 0;
    private originalActivityDescription: string = "";
    private originalActivityNrSubjects: string = "";
    private originalActivityLinkText: string = "";
    private originalActivityLinkUrl: string = "";
    private originalActivityRisk: boolean = false;
    private originalMemoText: string = null;
    private memoLoading: boolean = false;
    private activityDetails: Array<any> = [];
    private timezone: string;

    private readonly dialogToken: string = "activity";
    private readonly apiUrl: string = "api/Activities";
    private readonly urlGetResourceTypes: string = "api/ResourceTypes/ForPlanningBoard";
    private readonly resourceNotRequiredId: number = -2;
    

    static $inject = [
        "$scope",
        "$filter",
        "$stateParams",
        "$timeout",
        "modalConfirmationWindowService",
        "numberService",
        "pageStartService",
        "translationService",
        "userService"
    ];
    constructor(
        public $scope: ITreeListScope,
        private $filter: ng.IFilterService,
        private $stateParams: StateParams,
        private $timeout: ng.ITimeoutService,
        private modalConfirmationWindowService: IModalConfirmationWindowService,
        private numberService: INumberService,
        private pageStartService: IPageStartService,
        private translationService: ITranslationService,
        private userService: IUserService
    ) {

        this.translationService.getTextLabels(this.$scope);
        this.detailsVisible = userService.getDisplaySettingSwitch("activityView.detailsVisible");
        this.detailsText = this.detailsVisible ? $scope.textLabels.HIDE_DETAILS : $scope.textLabels.SHOW_DETAILS;
        if (this.detailsVisible) this.detailsPane.css("width", "30%"); // TODO: find alternative to jquery
        this.planboard.scenarioId = userService.getDisplaySettingNumber("planboard.scenarioId", this.planboard.scenarioId);

        this.$scope.$on("$viewContentLoaded", () => {
            this.$timeout(() => { this.viewLoaded = true; }, 100);
        });

        this.commonSvc = this.pageStartService.initialize(this.$scope, null, this.dialogToken);
        this.commonSvc.start(() => { this.loadData(); });
    }

    private loadData() {
        // make sure the planboard has loaded all activityTypes
        if (this.planboard.activityTypes == null || this.planboard.activityTypes.count <= 0)
            this.planboard.readActivityTypes();

        // boolean for planboard active
        var planboardActive = this.planboard.activities != null && this.planboard.controller != null;

        // try to get the activity structure from the planboard
        var activityTree = planboardActive
            ? this.activityArrayToTree(this.planboard.getAllActivitiesInGroup(this.$stateParams.activityId))
            : [];

        this.activityTree = activityTree;
        this.rootActivity = this.activityTree.length > 0 ? this.activityTree[0] : null;
        this.selectedActivity = this.rootActivity;

        // try to get the memo from the planboard
        if (this.rootActivity && this.rootActivity.memoId && this.rootActivity.memoId > 0) {
            if (planboardActive)
                this.selectedActivity.memoText = this.selectedActivity.originalMemoText = this.planboard.activities.getMemoText(this.rootActivity.memoId, false, false);
            if (!this.selectedActivity.memoText || this.selectedActivity.memoText === "")
                this.loadMemoHistory();
        }

        // try to get the details from the planboard
        var details = null;
        if (this.rootActivity && planboardActive) {
            details = this.planboard.activities.getActivityDetails(this.rootActivity.id, false, false);
            if (details) this.setActivityDetails(details);
        }

        // if unable to get data from the planboard, then query the webapi
        if (this.rootActivity == null)
            this.loadGroupFromWebApi();

        // load details if unable to get them from the planboard
        if (this.rootActivity != null && details == null)
            this.loadActivityDetails();

        // load all resourcetypes from the webapi
        this.resourceTypes[-1] = this.newTreeItem(-1, this.$scope.textLabels.FILTER_NONE, -1, true);
        this.commonSvc.loadData(this.urlGetResourceTypes, this.resourceTypes, null, null, true, false);
        this.commonSvc.loadData('api/v1/GlobalSettings', null, (response) => {
            this.timezone = response.data.timeZone;

            // load memo history
            this.loadMemoHistory();
        }, null, true, true);
            
        // load all resource cooperations if not already loaded
        if (this.planboard.resourceCooperations.count === 0)
            this.loadResourceCooperationsFromWebApi();

        // sort the activity tree by the activity types' display names
        this.activityTree = this.sortActivityTree(this.activityTree);
        this.commonSvc.onAllDataLoaded(() => { this.activityTree = this.sortActivityTree(this.activityTree) });

        // check if activity is locked
        this.activityIsLocked = this.isActivityLocked();
    }

    onMemoHistorySelected(itemId: number): void {
        var item = this.selectedActivity.memoHistory[itemId.toString()];
        if (item) this.selectedActivity.memoText = this.selectedActivity.originalMemoText = item.text;
    }

    onMemoTextChanged(): void {
        this.selectedHistoryMemoId = 0;
        this.memoChangedByUser = true;
    }

    userHasPermission(permissionName: string): boolean {
        return this.pageStartService.userHasPermission(permissionName, this.$scope, null);
    }

    getIsDisabled(activity: any, testPermission: string): boolean {
        // root is locked: this means all leaves are also locked
        if (this.rootActivity?.origin == Constants.originSystemOWS)
            return true;
        if (this.rootActivity && this.rootActivity.status == Constants.StatusActivityLocked)
            return true;
        // if no activity is specified, use the $scope.selectedActivity instead
        if (activity == null) {
            activity = this.selectedActivity;
            if (activity == null) return false;
        }
        // this activity is locked, no change can be made to this leaf
        if (activity.status == Constants.StatusActivityLocked) return true;
        if (testPermission != null) {
            // test specific permission
            if (!this.userHasPermission(testPermission)) return true;
        }
        // user can not edit activities
        if (!this.userHasPermission("EditActivity")) return true;
        return false;
    }

    // If the root activity originates from OWS then origin is from OWS.
    isOriginOws() {
        return this.rootActivity.origin === Constants.originSystemOWS;
    }

    getIsResourceDropdownDisabled(activity: Activity, ignoreResourceTypeWritePermissionsCheckForRoot?: boolean): boolean {
        const activityType = this.planboard.activityTypes.getObject(activity.activityTypeId);

        // activity type: no write permissions
        if (!activityType || activityType.maxPermissionForCurrentUser < 2) return true;

        // resource type: no write permissions
        let hasNoValidResourceTypePermission = ignoreResourceTypeWritePermissionsCheckForRoot ? activityType.isLeaf && activity.parentId !== null : true;
        for (var i = 0; i < activityType.resourceTypeIdList.length; i++) {
            if (this.resourceTypes[activityType.resourceTypeIdList[i]] && this.resourceTypes[activityType.resourceTypeIdList[i]].maxPermissionForCurrentUser >= 2) {
                hasNoValidResourceTypePermission = false;
                break;
            }
        }
        if (hasNoValidResourceTypePermission) return true;

        return false;
    }

    toggleActivityLocked(): void {
        // toggle to locked
        if (this.selectedActivity.status != Constants.StatusActivityLocked) {
            if (!this.userHasPermission("LockActivity")) return;
            this.selectedActivity.status = Constants.StatusActivityLocked;
            this.propagateLockedStatusToChildren(this.selectedActivity);
        } else { // toggle to planned
            if (!this.userHasPermission("UnlockActivity")) return;
            this.selectedActivity.status = Constants.StatusActivityPlanned;
            this.propagateLockedStatusToChildren(this.selectedActivity);
        }
        this.activityIsLocked = this.isActivityLocked();
    }

    getIsLockDisabled(): boolean {
        // If activity originated from OWS then the Lock checkbox must be disabled
        if (this.rootActivity.origin === Constants.originSystemOWS) {
            return true;
        } 
        return this.noPermissionOnLeaf();        
    }

    noPermissionOnLeaf(memoCheck: boolean = false): boolean {
        var anyActivityWithoutWritePermission = false;
        var selectedActivityWithoutWritePermission = this.getIsResourceDropdownDisabled(this.selectedActivity, true);

        const activityType = this.planboard.activityTypes.getObject(this.selectedActivity.activityTypeId);

        var disableMemoForRoot = this.rootActivity.status == Constants.StatusActivityLocked || !memoCheck;
        var disableMemoForLeaf = !activityType || activityType.maxPermissionForCurrentUser < 2 || !memoCheck;
        var disableForNode = !activityType.isLeaf && activityType.parentId && activityType.parentId !== -1;
   
        for (var key in this.activityDict) {
            var activity = this.activityDict[key];
    
            if (this.getIsResourceDropdownDisabled(activity, true)) {
                anyActivityWithoutWritePermission = true;
            }
        }

        if ((this.rootActivity &&
            this.rootActivity.id === this.selectedActivity.id &&
            anyActivityWithoutWritePermission && disableMemoForRoot) ||
            (selectedActivityWithoutWritePermission && disableMemoForLeaf) ||
            disableForNode ||
            this.isLeafWithoutVisibleAssignedResource()) {
            return true; // the selected activity is the root and one of the leaves doesn't have write permissions.
        }

        if (this.rootActivity &&
            this.rootActivity.status == Constants.StatusActivityLocked &&
            this.rootActivity.id !== this.selectedActivity.id) {
            return true; // root activity is locked
        }

        return false;
    }

    isLeafWithoutVisibleAssignedResource(): boolean {
        var resourceAssignedToLeaf = this.planboard.activities ? this.planboard.activities.getResource(this.selectedActivity.resourceId, true) : undefined;

        return resourceAssignedToLeaf && resourceAssignedToLeaf.displayName === "";
    }

    isWebApiActionPending(): boolean {
        return this.currentWebApiActionIndex < this.pendingWebApiActions.length;
    }

    saveChanges(): void {
        var changedActivities = []; // array of changed activities to be sent to the webApi
        var newActivities = []; // array with new activities to be sent to the webApi

        // for tracking time change of non-tree daymark
        if (Object.keys(this.activityDict).length === 1) {
            // we could add check on activity type being daymark, but not necessary yet 
            // because plannedActivities.ts does this check before using values
            const daymark = this.activityDict[Object.keys(this.activityDict)[0]];
            this.planboard.daymarkStartTime = daymark.startDate;
            this.planboard.daymarkDurationMinutes =
            (TimeSpan.fromDate(daymark.endDate).totalMiliseconds -
                TimeSpan.fromDate(daymark.startDate).totalMiliseconds) / 1000 / 60;
            this.planboard.daymarkActivityTypeId = daymark.activityTypeId;

        }

        for (var key in this.activityDict) {
            var activity = this.activityDict[key];
            var originalActivity = activity != null ? activity.originalActivity : null;
            if (activity == null) continue;
            // ignore leaves of daymarks without resources
            // If the parentId is -1, it means it doesn't have a parent and it is the root activity 
            if (this.activityIsDayMark(activity) && activity.resourceId === null && activity.parentId != -1) {
                continue;
            }

            // check if endtime is after begintime, show error and stop
            if (activity.endDate.getTime() <= activity.startDate.getTime()) {
                this.commonSvc.showDialog(this.$scope.textLabels.ACTIVITY_SAVE_FAILED,
                    this.$scope.textLabels.BEGIN_AFTER_END_TIME,
                    this.$scope.textLabels.OK,
                    null);
                return;
            }
            // set status to "not required" if the resource in the dropdown list was the "not required" resource
            if (activity.resourceId === this.resourceNotRequiredId)
                activity.status = Constants.StatusActivityNotRequired;
            else if (activity.status == Constants.StatusActivityNotRequired)
                activity.status = Constants.StatusActivityPlanned;
            // make sure the resource on the activity is known by the planboard memory, if not then add it to the lazy load list
            if (activity.resourceId != null &&
                activity.resourceId > 0 &&
                this.planboard.activities != null &&
                this.planboard.controller != null &&
                this.planboard.activities.getResource(activity.resourceId, true) == null)
                this.planboard.activities.addNotGroupedResource(activity.resourceId);
            // new activities have to be saved in a different call to the webApi
            if (activity.id == null || activity.id <= 0) {
                var addNewActivity = this.planboard.prepareActivityForWebApi(activity);
                if (activity.memoText !== "") {
                    addNewActivity.updateMemoText = true;
                    addNewActivity.memoText = activity.memoText;
                }
                newActivities.push(addNewActivity);
                continue;
            }
            // reset resourceTypeId client side when no resource is planned on the activity
            if ((activity.resourceId == null || activity.resourceId <= 0) && originalActivity != null) {
                activity.resourceTypeId = null;
                originalActivity.resourceTypeId = null;
            }
            // add to array if any properties in originalActivity are different in activity
            var memoDifferent = activity.originalMemoText !== activity.memoText;
            if (memoDifferent || this.objectsDifferent(originalActivity, activity)) {
                var sendActivity = this.planboard.prepareActivityForWebApi(activity);
                sendActivity.updateMemoText = memoDifferent;
                sendActivity.memoText = activity.memoText;
                changedActivities.push(sendActivity);
            }
        }

        if (newActivities.length > 0) {
            // add childs to children property
            for (var index = 0; index < newActivities.length; index++) {
                newActivities[index].children = [];
                var actWithNodes = this.activityDict[newActivities[index].id];
                if (actWithNodes && actWithNodes.nodes)
                    for (var nodeNr = 0; nodeNr < actWithNodes.nodes.length; nodeNr++)
                        for (var childNr = 0; childNr < newActivities.length; childNr++)
                            if (newActivities[childNr].id === actWithNodes.nodes[nodeNr].id)
                                newActivities[index].children.push(newActivities[childNr]);
            }
            // remove activities without parent from newActivities, because those were just added to the children property
            for (var i = newActivities.length - 1; i >= 0; i--)
                if (newActivities[i].parentId == null)
                    newActivities.splice(i, 1);
        }

        // execute pending actions in order
        this.executePendingActions(changedActivities, newActivities);
    }

    onRestoreMissingActivitiesClick(node: any): void {
        if (!node || !node.nodes) return;
        var childTypeIds = this.getChildActivityTypeIds(node.activityTypeId);
        for (var i = 0; i < childTypeIds.length; i++) {
            var index = node.nodes.length - 1;
            while (index >= 0 && node.nodes[index].activityTypeId !== childTypeIds[i]) index--;
            if (index < 0)
                this.addNewActivityWithType(node, childTypeIds[i], node.id, node.startDate, node.endDate);
        }
        // recursively add missing types of child nodes
        for (var j = 0; j < node.nodes.length; j++)
            this.onRestoreMissingActivitiesClick(node.nodes[j]);
    }

    addActivityCopy(node: any): void {
        if (this.getIsDisabled(node, null)) {
            return;
        }

        var parentNode = node.parentNode ? node.parentNode : this.activityTree[0];
        var nodeCopy = this.createNewActivity(node.activityTypeId, node.parentId, node.startDate, node.endDate);
        nodeCopy.parentNode = parentNode;

        // add to parents .nodes array at the position just after the copied node
        if (parentNode.nodes == null) parentNode.nodes = [];
        var index = parentNode.nodes.length - 1;
        for (var i = 0; i < parentNode.nodes.length; i++)
            if (parentNode.nodes[i] === node || parentNode.nodes[i].id === node.id)
                index = i;
        parentNode.nodes.splice(index + 1, 0, nodeCopy);

        // now also copy all childs for this activityType
        var childTypeIds = this.getChildActivityTypeIds(nodeCopy.activityTypeId);
        for (var j = 0; j < childTypeIds.length; j++)
            this.addNewActivityWithType(nodeCopy, childTypeIds[j], nodeCopy.id, nodeCopy.startDate, nodeCopy.endDate);

        // select the new node
        this.$timeout(() => { this.onSelectActivity(nodeCopy); }, 0);
    }

    onRemoveActivityClick(node: any): void {
        if (node)
            this.modalConfirmationWindowService.showModalDialog(this.$scope.textLabels.DELETE_ACTIVITY,
                this.$scope.textLabels.DELETE_ACTIVITY_CONFIRM,
                () => {
                    // remove from parents node list
                    if (node && node.parentNode && node.parentNode.nodes)
                        for (var i = node.parentNode.nodes.length - 1; i >= 0; i--)
                            if (node === node.parentNode.nodes[i] || node.id === node.parentNode.nodes[i].id)
                                node.parentNode.nodes.splice(i, 1);

                    // remove a node and all children from $scope.activityDict
                    this.removeFromActivityDict(node, true);

                    // root node is removed?
                    if (node === this.rootActivity || node.id === this.rootActivity.id) {
                        this.deletedActivities.length = 0;
                        this.deletedActivities.push(this.planboard.prepareActivityForWebApi(node));
                        this.addPendingDelete();
                        this.executePendingActions(null, null);
                    } else // select the parent node
                        this.$timeout(() => { this.onSelectActivity(node.parentNode); }, 0);
                },
                null);
    }

    getResourceTypesForSelectedActivity(): object {
        // get activity type object
        var actType = this.selectedActivity == null ? null : this.getActivityType(this.selectedActivity.activityTypeId);
        // set selectable property of each item in the tree
        for (var key in this.resourceTypes)
            this.resourceTypes[key].selectable = actType != null && actType.resourceTypeIdList.indexOf(Number(key)) >= 0;
        // the filter_none option is not usable if there is only one resourceType to select
        this.resourceTypes[-1].selectable = actType != null && actType.resourceTypeIdList.length !== 1;
        // select default if nothing is selected and there is only one to select
        this.getSelectedActivityResourceTypeId();
        return this.resourceTypes;
    }

    getDefaultResourceTypeText(): string {
        var resourceTypeId = this.getSelectedActivityResourceTypeId();
        var resourceType = this.resourceTypes[resourceTypeId];
        return resourceType == null ? "" : resourceType.displayName;
    }

    activityIsDayMark(node: any): boolean {
        if (!node) return false;
        var activity = this.activityDict[node.id];
        if (!activity) return false;
        var actType = this.getActivityType(activity.activityTypeId);
        if (!actType) return false;
        return actType.categoryId === ActivityType.daymarkCategoryId;
    }

    getResourceTreeForActivity(node: any): object {
        if (!this.isLeafActivity(node)) return this.emptyTree;
        var activity = this.activityDict[node.id];
        if (activity == undefined) return this.emptyTree;
        var loadForResourceTypeId = this.getActivityResourceTypeId(activity, false);
        if (activity.resourceTree == undefined || activity.availableResourcesForTypeId !== loadForResourceTypeId) {
            activity.availableResourcesForTypeId = loadForResourceTypeId;
            activity.availableResourcesLoaded = false;
            activity.resourceTree = Object.create(null);
            var actType = this.getActivityType(activity.activityTypeId);
            // add special choices if the activity is not a daymark
            if (actType && actType.categoryId !== ActivityType.daymarkCategoryId) {
                // add empty resource
                activity.resourceTree[-1] = this.newTreeItem(-1, this.$scope.textLabels.FILTER_NONE, 0, undefined);
                // add not required
                activity.resourceTree[this.resourceNotRequiredId] = this.newTreeItem(this.resourceNotRequiredId, this.$scope.textLabels.NOT_REQUIRED, 1, undefined);
            }
            this.commonSvc.post("api/Resources/ForActivity",
                this.planboard.prepareActivityForWebApi(activity),
                (success) => {
                    activity.availableResourcesLoaded = true;
                    var resourceOrder = 2;
                    // add current resource (might be replaced if it is also in the received array, that is okay)
                    if (activity.resourceId != null && activity.resourceId > 0)
                        activity.resourceTree[activity.resourceId] =
                            this.newTreeItem(activity.resourceId, this.getDefaultResourceText(activity), resourceOrder++);
                    // add preferred(+skilled) and skilled and unskilled parent nodes, using OMRP.Globals.maxInt + x for an unique node Id.
                    activity.resourceTree[Globals.maxInt] =
                        this.newTreeItem(Globals.maxInt, this.$scope.textLabels.RESOURCES_PREFERRED, resourceOrder++, false);
                    activity.resourceTree[Globals.maxInt + 1] =
                        this.newTreeItem(Globals.maxInt, this.$scope.textLabels.RESOURCES_SKILLED, resourceOrder++, false);
                    activity.resourceTree[Globals.maxInt + 2] =
                        this.newTreeItem(Globals.maxInt + 1, this.$scope.textLabels.RESOURCES_UNSKILLED, resourceOrder++, false);
                    activity.resourceTree[Globals.maxInt].open = true;
                    activity.resourceTree[Globals.maxInt + 1].open = true;
                    // receiving two arrays in response.data, first is skilled resources, second is unskilled resources
                    if (success && success.data && success.data.length > 0)
                        for (var i = 0; i < success.data.length; i++) {
                            var resourceList = success.data[i];
                            if (resourceList && resourceList.length > 0)
                                for (var j = 0; j < resourceList.length; j++) {
                                    var resource = resourceList[j];
                                    resource.order = resourceOrder++;
                                    resource.parentId = Globals.maxInt + 1 + i;
                                    resource.visible = true;
                                    activity.resourceTree[resource.id] = resource;
                                }
                        }
                    this.applyResourceCooperations(activity);
                    activity.itemVersion++; // to refresh the dropdownTree
                },
                null,
                false);
        } else {
            this.applyResourceCooperations(activity);
        }
        return activity.resourceTree;
    }

    getDefaultResourceText(node: any): string {
        if (node.resourceId == null || node.resourceId < 0) {
            if (node.status == Constants.StatusActivityNotRequired)
                return this.$scope.textLabels.NOT_REQUIRED;
            return "";
        }

        // Check if the current user is allowed to read the resource type
        if (node.resourceTypeId !== null && node.resourceTypeId !== -1) {
            // resource types are not loaded; we should not load 'assigned' label until then
            if (Object.keys(this.resourceTypes).length === 1 && ("-1" in this.resourceTypes)) {
                return "";
            }

            var readableResourceType = false;
            for (var key in this.resourceTypes) {
                if (this.resourceTypes[key].id === node.resourceTypeId && this.resourceTypes[key].maxPermissionForCurrentUser >= 1) {
                    readableResourceType = true;

                    break;
                }
            }

            if (!readableResourceType) {
                return this.$scope.textLabels.RESOURCE_ASSIGNED_PLACEHOLDER;
            }
        }
        
        // try to get name from $scope.resourceDisplayNames
        var name = this.resourceDisplayNames[node.resourceId];
        if (name != undefined) return name;

        // try to get name from planboard memory
        if (this.planboard.activities) {
            var resource = this.planboard.activities.getResource(node.resourceId, true);
            if (resource)
                if (resource.displayName !== "") return resource.displayName;
                else return this.$scope.textLabels.RESOURCE_ASSIGNED_PLACEHOLDER;
        }

        // request specific resources from webApi if not already doing so
        if (!this.resourceDisplayNamesLoading) this.loadResourceDisplayNames();

        return "";
    }

    onSelectActivity(node: any): void {
        if (this.selectedActivity !== node) {
            this.selectedActivity = node;
            this.activityIsLocked = this.isActivityLocked();
            this.loadMemoHistory();
        }
    }

    isSelectedActivity(node: any): boolean {
        if (node == null || this.selectedActivity == null) return false;
        return node.id === this.selectedActivity.id;
    }

    isRootActivity(node: any): boolean {
        return node === this.rootActivity;
    }

    isLeafActivity(node: any): boolean {
        var actType = node == null ? null : this.getActivityType(node.activityTypeId);
        return actType == null ? false : actType.resourceTypeIdList.length > 0;
    }

    showResourceCell(node: any): boolean {
        if (this.activityIsDayMark(node) && node.nodes.length !== 0) {
            return false
        }
        else {
            return this.isLeafActivity(node);
        }
    }

    getActivityBackColor(node: any): string {
        var actType = this.getActivityType(node.activityTypeId);
        return this.toHtmlColor(actType == null ? "" : actType.backColor);
    }

    getActivityTextColor(node: any): string {
        var actType = this.getActivityType(node.activityTypeId);
        return this.toHtmlColor(actType == null ? "" : actType.textColor);
    }

    getActivityShortName(node: any): string {
        var actType = this.getActivityType(node.activityTypeId);
        return actType == null ? "" : actType.shortText;
    }

    getActivityDisplayName(node: any): string {
        var actType = this.getActivityType(node.activityTypeId);
        return actType == null ? "" : actType.displayName;
    }

    filterTextTime($event: any, oldValue: string): void {
        this.numberService.filterTextTime($event, oldValue);
    }

    beginTimeChanged(node: any): void {
        this.$timeout(() => { this.recursiveChangeStartDateTime(node, node.startTimeStr, null, false); }, 0);
    }

    endTimeChanged(node: any): void {
        this.$timeout(() => { this.recursiveChangeEndDateTime(node, node.endTimeStr, null, false); }, 0);
    }

    onBeginDateChanged(date: Date, node: any): void {
        var newStartDate = new Date(date.getTime());
        newStartDate.setHours(node.startDate.getHours(), node.startDate.getMinutes(), 0, 0);

        // remember difference in days between start and end
        var days = TimeSpan.getDayNr(node.endDate) - TimeSpan.getDayNr(node.startDate);
        var newEndDate = new Date(date.getTime());
        newEndDate.setHours(node.endDate.getHours(), node.endDate.getMinutes(), 0, 0);
        if (days > 0) newEndDate.setDate(newEndDate.getDate() + days);

        this.$timeout(() => {
            this.recursiveChangeStartDateTime(node, null, newStartDate, false);
            this.recursiveChangeEndDateTime(node, null, newEndDate, true);
        }, 0);
    }

    onEndDateChanged(date: Date, node: any): void {
        var newEndDate = new Date(date.getTime());
        newEndDate.setHours(node.endDate.getHours(), node.endDate.getMinutes(), 0, 0);
        this.$timeout(() => {
            this.recursiveChangeEndDateTime(node, null, newEndDate, false);
        }, 0);
    }

    showActivityDisplayname(): boolean {
        if (!this.viewLoaded || !this.elActivityTree || !this.elActivityTree[0]) return true;
        return this.elActivityTree[0].offsetWidth > 500;
    }

    isDetailsPaneVisbile(): boolean {
        if (this.detailsVisible || !this.viewLoaded || !this.detailsPane || !this.detailsPane[0]) return true;
        return this.detailsVisible || this.detailsPane[0].offsetWidth > 1;
    }
                   
    toPreviousState(): void {
        this.$timeout(() => { window.history.back(); }, 100);
    }

    getTitle():string {
        if (this.rootActivity != null) {
            var actType = this.getActivityType(this.rootActivity.activityTypeId);
            var includeEndDate = TimeSpan.getDayNr(this.rootActivity.startDate) !==
                TimeSpan.getDayNr(this.rootActivity.endDate);
            return (actType != null ? actType.displayName + " : " : "") +
                this.dateToDisplayStr(this.rootActivity.startDate, true, true) +
                " - " +
                this.dateToDisplayStr(this.rootActivity.endDate, includeEndDate, true);
        }
        return "";
    }

    toggleDetails(): void {
        this.detailsVisible = !this.detailsVisible;
        this.userService.setDisplaySettingSwitch("activityView.detailsVisible", this.detailsVisible);
        this.detailsText = this.detailsVisible ? this.$scope.textLabels.HIDE_DETAILS : this.$scope.textLabels.SHOW_DETAILS;
        var widthPercentage = this.detailsVisible ? 30 : 0;
        this.detailsPane.stop().animate({ width: "" + widthPercentage + "%" }, 300);
    }

    toggleHyperlinkEdit(): void {
        this.activityLinkEdit = !this.activityLinkEdit;
        this.userService.setUserVariable("activityView.toggleHyperlinkEditValue", this.activityLinkEdit);
    }

    loadMemoHistory(): void {
        if (this.selectedActivity && this.selectedActivity.memoId && !this.selectedActivity.memoHistory) {
            this.memoLoading = true;
            this.commonSvc.loadData(this.apiUrl + "/Memos/" + this.selectedActivity.id,
                null,
                (success) => {
                    this.memoLoading = false;
                    var memoHistory = Object.create(null);
                    var memoCount = 0;
                    if (success && success.data && success.data.length > 0) {
                        var latestMemoId = success.data[0].id;

                        for (var i = 0; i < success.data.length; i++) {
                            if (success.data[i].id === this.selectedActivity.memoId) {
                                this.selectedActivity.memoText = this.selectedActivity.originalMemoText = success.data[i].text;
                            }

                            var d = Timezone.correctTimeZoneInfoWithMoment(Timezone.parseDate(success.data[i].lastEditedTimestamp));
                            var displayName = "";
                            if (success.data[i].userDisplayName === "" || success.data[i].userDisplayName == null) {
                                if (this.rootActivity.origin === Constants.originSystemOWS) {
                                    displayName = "OWS, ";
                                }
                                else {
                                    displayName = "";
                                }
                            }
                            else {
                                displayName = success.data[i].userDisplayName + ", ";
                            }

                            var momDateWithTimezone = moment.utc(d, 'ddd MMM DD YYYY HH:mm:ss').tz(this.timezone);
                            var textUserAndDate = `${displayName} ${momDateWithTimezone.format('LL')} ${momDateWithTimezone.format('HH:mm')}`;

                            if (success.data[i].id > latestMemoId) {
                                latestMemoId = success.data[i].id;
                            }

                            memoHistory[success.data[i].id.toString()] = {
                                id: success.data[i].id,
                                text: success.data[i].text,
                                displayName: textUserAndDate,
                                order: i + 1
                            };
                            memoCount++;
                        }

                        this.selectedActivity.memoId = latestMemoId;
                        this.selectedActivity.selectedHistoryMemoCount = memoCount;
                        this.selectedActivity.selectedHistoryMemoId = this.selectedActivity.memoId;
                        this.selectedActivity.memoHistory = memoHistory;
                    }
                },
                null,
                true);
        }
    }

    private loadGroupFromWebApi(): void {
        var id = this.rootActivity ? this.rootActivity.id : this.$stateParams.activityId;
        this.commonSvc.loadData(this.apiUrl + "/GetActivityGroup/" + id,
            null,
            (success) => {
                if (success && success.data && success.data.length > 0) {
                    if (this.planboard.activities != null && this.planboard.controller != null) {
                        // also refresh this group in planboard memory
                        this.planboard.removeActivityGroupFromMemory(success.data[0].id, false);
                        this.planboard.addActivitiesToMemory(success.data, true);
                        this.planboard.activities.checkFilled(success.data[0].id);
                        this.planboard.timedRefresh();
                    }

                    this.activityTree = this.activityArrayToTree(success.data);
                    this.rootActivity = this.activityTree.length > 0 ? this.activityTree[0] : null;
                    this.selectedActivity = this.rootActivity;
                    this.loadMemoHistory();
                    this.loadActivityDetails();
                }
            }, null, true, false);
    }

    private loadActivityDetails(): void {
        var id = this.rootActivity ? this.rootActivity.id : this.$stateParams.activityId;
        this.commonSvc.loadData(this.apiUrl + "/Details/" + id,
            null,
            (response) => {
                this.setActivityDetails(response && response.data ? response.data : response);
                if (this.planboard.activities != null && this.planboard.controller != null && response && response.data)
                    this.planboard.activities.setActivityDetails(id, response.data);
            }, null, true, true);
    }

    private loadResourceDisplayNames(): void {
        this.resourceDisplayNamesLoading = true;
        if (!this.userService.isAuthenticated) return; // do not post request if it is known that we are not authenticated
        var resourceSelection = { idList: [] }
        if (this.activityDict != null)
            for (var key in this.activityDict) {
                var activity = this.activityDict[key];
                if (activity != null && activity.resourceId != null && activity.resourceId > 0)
                    resourceSelection.idList.push(this.activityDict[key].resourceId);
            }
        if (resourceSelection.idList.length > 0)
            this.commonSvc.post("api/Resources/WithId",
                resourceSelection,
                (success) => {
                    // add empty strings for all the requested resources so that this will not be asked again
                    for (var i = resourceSelection.idList.length - 1; i >= 0; i--)
                        if (this.resourceDisplayNames[resourceSelection.idList[i]] == undefined)
                            this.resourceDisplayNames[resourceSelection.idList[i]] = "";
                    // now add the names of resources that we actually received
                    if (success && success.data && success.data.length && success.data.length > 0)
                        for (var i = 0; i < success.data.length; i++)
                            this.resourceDisplayNames[success.data[i].id] = success.data[i].displayName;
                    this.resourceDisplayNamesLoading = false;
                },
                null,
                false);
    }

    private loadResourceCooperationsFromWebApi(): void {
        // clear and add dummy, so we can test if this dictionary is filled
        this.planboard.resourceCooperations.clear();
        this.planboard.resourceCooperations.add(0, null); // empty object for resource with id 0

        this.commonSvc.loadData("api/Resources/Cooperations",
            null,
            (success) => {
                var item = null, list = null, resourceId = 0, otherResourceId = 0;
                if (success && success.data && success.data.length > 0)
                    for (var step = 1; step <= 2; step++) // once for resourceId => otherResourceId and once the other way around
                        for (var i = 0; i < success.data.length; i++) {
                            item = success.data[i];
                            resourceId = step === 1 ? item.resourceId : item.otherResourceId;
                            otherResourceId = step === 1 ? item.otherResourceId : item.resourceId;
                            var dict = this.planboard.resourceCooperations.value(resourceId);
                            if (dict == undefined) {
                                dict = new Dictionary();
                                this.planboard.resourceCooperations.add(resourceId, dict);
                            }
                            dict.add(otherResourceId, new Cooperation(resourceId, otherResourceId, item.value));
                        }
            }, null, true, false);
    }

    private setActivityDetails(data: Array<any>): void {
        this.activityDescription = this.originalActivityDescription = "";
        this.activityNrSubjects = this.originalActivityNrSubjects = "";
        this.activityRisk = this.originalActivityRisk = false;
        var details = [];
        var activityDescriptionFound = false;
        var activityNrSubjectsFound = false;
        var activityRiskFound = false;
        var activityLinkFound = false;
        if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                var detail = data[i];
                detail.originalValue = detail.value;
                details.push(detail);
                if (detail.detailType === Constants.activityDetailDescription) {
                    activityDescriptionFound = true;
                    this.activityDescription = this.originalActivityDescription = detail.value;
                }
                if (detail.detailType === Constants.activityNumberOfSubjects) {
                    activityNrSubjectsFound = true;
                    this.activityNrSubjects = this.originalActivityNrSubjects = detail.value;
                }
                if (detail.detailType === Constants.activityDetailHyperlink) {
                    activityLinkFound = true;
                    var obj = JSON.parse(detail.value);
                    this.activityLinkText = this.originalActivityLinkText = obj.text;
                    this.activityLinkUrl = this.originalActivityLinkUrl = obj.url;
                }
                if (detail.detailType === Constants.activityDetailRisk) {
                    activityRiskFound = true;
                    this.activityRisk = this.originalActivityRisk = detail.value !== "0";
                }
            }
        }
        if (!activityDescriptionFound) details.push({ id: 0, value: "", originalValue: "", detailType: Constants.activityDetailDescription });
        if (!activityNrSubjectsFound) details.push({ id: 0, value: "", originalValue: "", detailType: Constants.activityNumberOfSubjects });
        if (!activityLinkFound) details.push({ id: 0, value: "", originalValue: "", detailType: Constants.activityDetailHyperlink });
        if (!activityRiskFound) details.push({ id: 0, value: 0, originalValue: 0, detailType: Constants.activityDetailRisk });
        this.activityDetails = details;
        // if the url is empty and the user has not hidden the edit fields this session, then automatically make the fields visible
        if (this.activityLinkText == undefined || this.activityLinkText === "") {
            var toggleValue = this.userService.getUserVariable("activityView.toggleHyperlinkEditValue");
            if (toggleValue == undefined || toggleValue == true) this.activityLinkEdit = true;
        }
    }

    private activityArrayToTree(activities: Array<any>): Array<any> {
        var activityTree = [];
        var activityDict = Object.create(null);
        var i = 0;

        // first make copies of all activities and add them to activityDict
        for (i = 0; i < activities.length; i++) {
            var originalActivity = activities[i];
            // date might be in string format if just received from webApi
            if (originalActivity.startDate.getFullYear == undefined || originalActivity.endDate.getFullYear == undefined) {
                originalActivity.startDate = this.stringToDate(originalActivity.startDate);
                originalActivity.endDate = this.stringToDate(originalActivity.endDate);
            }
            var copyAct = this.createActivityNodeCopy(originalActivity);
            activityDict[copyAct.id] = copyAct;
        }
        // next add to each others nodes list
        for (i = 0; i < activities.length; i++) {
            var act = activityDict[activities[i].id];
            if (act.parentId != null && act.parentId > 0) {
                var parent = activityDict[act.parentId];
                if (parent != undefined) { // add to parents nodes array
                    act.parentNode = parent;
                    parent.nodes.push(act);
                } else
                    activityTree.push(act); // parent could not be found, treat this activity as a root
            } else
                activityTree.push(act); // this is a root activity
        }
        this.activityDict = activityDict;
        return activityTree;
    }

    private createActivityNodeCopy(originalActivity: any): any {
        var copyAct = Object.create(null);
        // copy all properties
        this.copyObjectProperties(originalActivity, copyAct);
        copyAct.startDate =
            new Date(copyAct.startDate.getTime()); // make a new object for startDate instead of referencing the one in originalActivity
        copyAct.startDateCopy = new Date(copyAct.startDate.getTime()); // copy startDate for datepicker
        copyAct.endDate = new Date(copyAct.endDate.getTime()); // make a new object for endDate instead of referencing the one in originalActivity
        copyAct.endDateCopy = new Date(copyAct.endDate.getTime()); // copy endDate for datepicker
        copyAct.startTimeStr = this.dateToDisplayStr(copyAct.startDate, false, true); // duplicate start time component for text input
        copyAct.endTimeStr = this.dateToDisplayStr(copyAct.endDate, false, true); // duplicate end time component for text input
        copyAct.nodes = []; // array with child nodes
        copyAct.originalActivity = originalActivity; // also store the original activity
        copyAct.availableResourcesLoaded = false; // indicates if the list of available resources have finished loading (for spinner)
        copyAct.availableResourcesForTypeId = undefined; // indicates for what resourceTypeId the list of available resources have been loaded
        // if the status of the activity is "not required" then set the resourceId so it matches the "not required" item in the dropdown list
        if (copyAct.status == Constants.StatusActivityNotRequired) copyAct.resourceId = this.resourceNotRequiredId;
        else if (copyAct.resourceId === this.resourceNotRequiredId) copyAct.resourceId = null;
        copyAct.itemVersion = 0; // used to refresh the dropdownTree after a list of resources have been received
        return copyAct;
    }

    private copyObjectProperties(leadingObject: object, copyToObject: object): void {
        for (var key in leadingObject)
            if (Object.prototype.hasOwnProperty.call(leadingObject, key))
                copyToObject[key] = leadingObject[key];
    }

    private stringToDate (dateStr: any): Date {
        if (dateStr.getFullYear == undefined) {
            dateStr = new Date("" + dateStr + (dateStr.charAt(dateStr.length - 1) !== "Z" ? "Z" : ""));
            dateStr = new Date(dateStr.getTime() + dateStr.getTimezoneOffset() * 60000);
        }
        return dateStr;
    }

    private dateToDisplayStr(dt: Date, includeDate: boolean, includeTime: boolean): string {
        return includeDate
            ? this.$filter("date")(dt, "EEE") +
            " " +
            this.$filter("date")(dt, "mediumDate").replace(dt.getFullYear().toString(), "").replace(",", "").replace(".", "").trim() +
            (includeTime ? " " + this.$filter("date")(dt, "HH:mm") : "")
            : this.$filter("date")(dt, "HH:mm");
    }

    private newTreeItem (id: number, name: string, order: number, selectable: boolean = null): object {
        return { id: id, displayName: name, order: order, selectable: selectable, visible: true }
    }

    /**
     * Recursively sorts an activity tree, currently based on the display name of the activity type.
     * @param tree Array with root level nodes of the tree to sort.
     * @returns The recursively sorted tree.
    */
    private sortActivityTree(tree: Array<any>): Array<any> {
        // only sort if there is anything to sort
        if (this.planboard.activityTypes == null || this.planboard.activityTypes.count <= 0) return tree;

        tree = this.$filter("orderBy")(tree,
            (item) => {
                return this.planboard.activityTypes.getObject(item.activityTypeId).sortOrder;
            });

        for (var i = 0; i < tree.length; i++) {
            if (tree[i].nodes) tree[i].nodes = this.sortActivityTree(tree[i].nodes);
        }

        return tree;
    }

    private isActivityLocked(): boolean {
        if (this.rootActivity?.origin == Constants.originSystemOWS)
            return true;
        if (this.rootActivity && this.rootActivity.status == Constants.StatusActivityLocked)
            return true;
        if (this.selectedActivity && this.selectedActivity.status == Constants.StatusActivityLocked)
            return true;

        return false;
    }

    private toHtmlColor(colorString: string): string {
        if (colorString === "" || colorString == undefined) return "transparent";
        return colorString.charAt(0) === "#" ? colorString : "#" + colorString;
    }

    private getActivityType(id: number): any {
        if (this.planboard.activityTypes == null) return null;
        return this.planboard.activityTypes.getObject(id);
    }

    private getChildActivityTypeIds(activityTypeId: number): Array<number> {
        var result = [];
        this.planboard.activityTypes.forEach((id, item) => {
            if (item.parentId === activityTypeId) result.push(id);
        });
        return result;
    }

    private getDateWithTime(dateWithDatePart: Date, dateWithTimePart: Date): Date {
        var hours = dateWithTimePart.getHours();
        var minutes = dateWithTimePart.getMinutes();
        var result = new Date(dateWithDatePart.getTime());
        result.setHours(hours, minutes, 0, 0);
        return result;
    }

    private getActivityResourceTypeId(activity: any, update: boolean): number {
        if (activity == null) return -1;
        // convert null to -1 for the activities resourceTypeId
        if (activity.resourceTypeId == null) {
            activity.resourceTypeId = -1;
            activity.originalActivity.resourceTypeId = -1; // also for the original so this will not trigger a send to webapi change
        }
        // if there is only one selectable item for the activity type and none is selected, then select that one item
        if (activity.resourceTypeId < 0) {
            var actType = this.getActivityType(activity.activityTypeId);
            if (actType != null && actType.resourceTypeIdList.length === 1) {
                if (!update) return actType.resourceTypeIdList[0];
                activity.resourceTypeId = actType.resourceTypeIdList[0];
                activity.originalActivity.resourceTypeId =
                    actType.resourceTypeIdList[0]; // also for the original so this will not trigger a send to webapi change
            }
        }
        return activity.resourceTypeId;
    }

    private getSelectedActivityResourceTypeId(): number {
        return this.getActivityResourceTypeId(this.selectedActivity, true);
    }

    private objectsDifferent(leadingObject: object, otherObject: object): boolean {
        if (leadingObject == null || otherObject == null) return true;
        for (var key in leadingObject)
            if (Object.prototype.hasOwnProperty.call(leadingObject, key)) {
                var prop1 = leadingObject[key];
                var prop2 = otherObject[key];
                if (prop1 !== prop2)
                    if (prop1 == null ||
                        prop2 == null ||
                        prop1.getTime == undefined ||
                        prop2.getTime == undefined ||
                        prop1.getTime() !== prop2.getTime()) return true;
            }
        return false;
    }

    private propagateLockedStatusToChildren(node: any): void {
        if (node.nodes && node.nodes.length > 0)
            for (var i = 0; i < node.nodes.length; i++) {
                // if the activity has the "not required" status it can not be locked
                if (node.nodes[i].status != Constants.StatusActivityNotRequired)
                    node.nodes[i].status = node.status;
                if (node.nodes[i].nodes && node.nodes[i].nodes.length > 0)
                    this.propagateLockedStatusToChildren(node.nodes[i]);
            }
    }

    private addToPlanboardMemory(activities: Array<any>): void {
        if (this.planboard.activities != null &&
            this.planboard.controller != null &&
            activities != null &&
            activities.length > 0) {
            this.planboard.addActivitiesToMemory(activities, true);
            this.planboard.activities.checkFilled(activities[0].id);
            this.planboard.viewChanged();
            this.planboard.redrawAll();
        }
    }

    private addPendingWebApiAction(url: string, postData: any, actionType: string, successCallbackFunction: (action, success) => void , errorCallbackFunction: (action, error) => void): void {
        this.pendingWebApiActions.push({
            url: url,
            postData: postData,
            actionType: actionType,
            successCallback: successCallbackFunction,
            errorCallback: errorCallbackFunction
        });
    }

    private clearPendingWebApiActions(): void {
        this.pendingWebApiActions.length = 0;
        this.currentWebApiActionIndex = 0;
        this.userService.deleteUserVariable("childViewPending"); // no actions are pending
    }

    private handlePendingWebApiAction(): void {
        if (this.currentWebApiActionIndex >= this.pendingWebApiActions.length) return;
        var action = this.pendingWebApiActions[this.currentWebApiActionIndex];
        if (action.url === "" || action.actionType === "") {
            if (action.successCallback != null) action.successCallback(action, null);
            this.currentWebApiActionIndex++;
            this.handlePendingWebApiAction();
        } else if (action.actionType === "post") {
            this.commonSvc.post(action.url,
                action.postData,
                (success) => {
                    if (action.successCallback != null) action.successCallback(action, success);
                    this.currentWebApiActionIndex++;
                    this.handlePendingWebApiAction();
                },
                (error) => {
                    if (action.errorCallback != null) action.errorCallback(action, error);
                    var title = this.$scope.textLabels.ACTIVITY_SAVE_FAILED;
                    var failureReasons = this.planboard.getActivityFailureReasons(error && error.data ? error.data : error);
                    var postDataIsArray = action.postData.length != undefined && action.postData.length > 0;
                    var alreadyRetriedIgnoreResourceRestrictions =
                        (postDataIsArray
                            ? action.postData[0].ignoreResourceRestrictions
                            : action.postData.ignoreResourceRestrictions) ||
                        (postDataIsArray
                            ? action.postData[0].ignoreSkillCheck
                            : action.postData.ignoreSkillCheck);
                    var alreadyRetriedIgnoreOWSUnavailability = postDataIsArray
                        ? action.postData[0].ignoreOWSUnavailability
                        : action.postData.ignoreOWSUnavailability

                    var textlabel = "";
                    if (error.data.canIgnoreResourceRestrictions && !alreadyRetriedIgnoreResourceRestrictions) {
                        textlabel = this.$scope.textLabels.ACTIVITY_SAVE_LESS_CHECKS;
                    } else if (error.data.canIgnoreOWSUnavailability && !alreadyRetriedIgnoreOWSUnavailability) {
                        textlabel = this.$scope.textLabels.ACTIVITY_SAVE_OVERRULE_OWS;
                    }

                    if (textlabel != "") {
                        this.commonSvc.showYesNoDialog(title,
                            failureReasons + "\n\n" + textlabel,
                            () => {
                                if (textlabel === this.$scope.textLabels.ACTIVITY_SAVE_LESS_CHECKS) {
                                    var isFailureReasonResourceSkill = true;
                                    if (error.data && error.data.failureReasons) {
                                        for (var i = 0; i < error.data.failureReasons.length; i++) {
                                            var reason = error.data.failureReasons[i].reason;
                                            if (reason != Constants.activitySaveFailureResourceNotSkilled) {
                                                isFailureReasonResourceSkill = false;
                                                break;
                                            }
                                        }
                                    }

                                    // yes action: retry with less restrictions
                                    if (postDataIsArray) {
                                        for (var i = 0; i < action.postData.length; i++) {
                                            if (isFailureReasonResourceSkill) action.postData[i].ignoreSkillCheck = true;
                                            else action.postData[i].ignoreResourceRestrictions = true;
                                        }
                                    } else {
                                        if (isFailureReasonResourceSkill) action.postData.ignoreSkillCheck = true;
                                        else action.postData.ignoreResourceRestrictions = true;
                                    }
                                    this.handlePendingWebApiAction();
                                } else if (textlabel === this.$scope.textLabels.ACTIVITY_SAVE_OVERRULE_OWS) {
                                    // yes action: retry with less restrictions
                                    if (postDataIsArray) {
                                        for (var i = 0; i < action.postData.length; i++) {
                                            action.postData[i].ignoreOWSUnavailability = true;
                                        }
                                    } else {
                                        action.postData.ignoreOWSUnavailability = true;
                                    }
                                    this.handlePendingWebApiAction();
                                }
                                
                            },
                            () => {
                                // no action: do not retry
                                this.clearPendingWebApiActions();
                                this.commonSvc.hidePendingOperationInfo(); // decrease the overall pending increment
                                this.commonSvc.showDialog(title,
                                    failureReasons,
                                    this.$scope.textLabels.OK,
                                    () => { this.loadGroupFromWebApi(); }); // reload activites from webapi when clicked on OK
                            });

                    } else {
                        // failed and retry is not possible
                        this.handleFailedWebApiAction(title, failureReasons);
                    }
                },
                true);
        } else if (action.actionType === "delete") {
            this.commonSvc.deleteData(action.url,
                (success) => {
                    if (action.successCallback != null) action.successCallback(action, success);
                    this.currentWebApiActionIndex++;
                    this.handlePendingWebApiAction();
                },
                (error) => {
                    if (action.errorCallback != null) action.errorCallback(action, error);
                    var title = this.$scope.textLabels.ACTIVITY_DELETE_FAILED;
                    var failureReasons = this.planboard.getActivityFailureReasons(error && error.data ? error.data : error);

                    // failed and retry is not possible
                    this.handleFailedWebApiAction(title, failureReasons);
                },
                true);
        }
    }

    private handleFailedWebApiAction(title: string, failureReasons: any): void {
        this.clearPendingWebApiActions();
        this.commonSvc.hidePendingOperationInfo(); // decrease the overall pending increment
        this.commonSvc.showDialog(title,
            failureReasons,
            this.$scope.textLabels.OK,
            () => { this.loadGroupFromWebApi(); }); // reload activites from webapi when clicked on OK
    }

    private addPendingDetails(): void {
        var changedDetails = [];
        for (var i = 0; i < this.activityDetails.length; i++) {
            // take value from $scope.activityDescription
            if (this.activityDetails[i].detailType === Constants.activityDetailDescription &&
                this.activityDescription !== this.originalActivityDescription)
                this.activityDetails[i].value = this.activityDescription;
            // take value from $scope.activityNrSubjects
            if (this.activityDetails[i].detailType === Constants.activityNumberOfSubjects &&
                this.activityNrSubjects !== this.originalActivityNrSubjects)
                this.activityDetails[i].value = this.activityNrSubjects;
            // take value from $scope.activityLinkText and $scope.activityLinkUrl
            if (this.activityDetails[i].detailType === Constants.activityDetailHyperlink &&
                (this.activityLinkText !== this.originalActivityLinkText || this.activityLinkUrl !== this.originalActivityLinkUrl))
                this.activityDetails[i].value = JSON.stringify({ "text": this.activityLinkText, "url": this.activityLinkUrl });
            // take value from $scope.activityRisk
            if (this.activityDetails[i].detailType === Constants.activityDetailRisk &&
                this.activityRisk !== this.originalActivityRisk)
                this.activityDetails[i].value = this.activityRisk ? "1" : "0";
            // add if changed
            if (this.activityDetails[i].originalValue !== this.activityDetails[i].value) {
                changedDetails.push({
                    id: this.activityDetails[i].id,
                    value: this.activityDetails[i].value,
                    detailType: this.activityDetails[i].detailType,
                    activityId: this.rootActivity.id
                });
            }
        }
        if (changedDetails.length > 0) {
            this.addPendingWebApiAction("api/Activities/Details",
                changedDetails,
                "post",
                (action, response) => {
                    // reset details so it will be reloaded
                    if (action && action.postData && action.postData.length > 0)
                        this.planboard.activities.removeActivityDetails(action.postData[0].activityId);
                },
                null);
        }
    }

    private addPendingChange(changedActivities: Array<any>): void {
        this.addPendingWebApiAction("api/Activities/ChangeActivityGroup",
            changedActivities,
            "post",
            (action, response) => {
                if (action && action.postData)
                    this.addToPlanboardMemory(action.postData);

                // reset memo so it will be reloaded
                if (this.planboard.activities != null && this.planboard.controller != null && this.rootActivity != null) {

                    var anyMemoChanged = changedActivities.some(function (activity) {
                        return activity.updateMemoText
                    });

                    // need to reload activity group if new memo has been added to retrieve memoId
                    // making a change to an existing memo can also lead to a new memoId
                    if (anyMemoChanged) {
                        this.planboard.readActivityGroup(this.rootActivity.id);

                        // clear memo cache if there was a change made to a memo
                        this.planboard.activities.clearAllMemoTexts();
                    }
                }
            },
            null);
    }

    private addPendingNew(newActivities: Array<any>): void {
        this.addPendingWebApiAction("api/Activities/AddSubtrees",
            newActivities,
            "post",
            (action, success) => {
                if (success && success.data && success.data.insertedActivities)
                    this.addToPlanboardMemory(success.data.insertedActivities);
            },
            (action, error) => {
                var title = this.$scope.textLabels.ACTIVITY_ADD_FAILED;
                var failureReasons = this.planboard.getActivityFailureReasons(error && error.data ? error.data : error);

                // failed and retry is not possible
                this.handleFailedWebApiAction(title, failureReasons);
            });
    }

    private addPendingDelete(): void {
        const isRootActivity = this.deletedActivities[0].parentId == null || this.deletedActivities[0].parentId < 0;

        // add web api call to delete the first activity
        this.addPendingWebApiAction("api/Activities/" + this.deletedActivities[0].id,
            null,
            "delete",
            (action, success) => {
                if (this.planboard.activities != null && this.planboard.controller != null) {
                    if (isRootActivity) {
                        // remove the root and all children
                        var group = this.planboard.getAllActivitiesInGroup(this.deletedActivities[0].id);
                        for (var j = 0; j < group.length; j++) this.planboard.activities.removeActivity(group[j]);
                    } else {
                        // remove sub group
                        for (var i = 0; i < this.deletedActivities.length; i++) {
                            var deleteActivity = this.planboard.activities.getActivity(this.deletedActivities[i].id);
                            if (deleteActivity != null) this.planboard.activities.removeActivity(deleteActivity, true);
                        }
                        this.planboard.activities.checkFilled(this.deletedActivities[0].id);
                    }
                    this.planboard.redrawAll();
                }
            },
            (action, error) => {
                this.deletedActivities.length = 0;
            });

        if (isRootActivity) return;

        // add web api calls to delete the other activities
        for (var i = 1; i < this.deletedActivities.length; i++) {
            this.addPendingWebApiAction("api/Activities/" + this.deletedActivities[i].id,
                null,
                "delete",
                (action, success) => {
                    // we do not have to do anything here, all response actions are done in the first delete web api call response
                },
                (action, error) => {
                    this.deletedActivities.length = 0;
                });
        }
    }

    private executePendingActions(changedActivities: Array<any>, newActivities: Array<any>): void {
        // reset pending actions
        this.clearPendingWebApiActions();
        // start with an overall pending increment
        this.commonSvc.newPendingOperation();
        // save in userService that planboard may not close the child view because it has pending actions
        this.userService.setUserVariable("childViewPending", true);
        // add changedActivities to pending list
        if (changedActivities != null && changedActivities.length > 0)
            this.addPendingChange(changedActivities);
        // add newActivities to pending list
        if (newActivities != null && newActivities.length > 0)
            this.addPendingNew(newActivities);
        // add deletedActivities to pending list
        if (this.deletedActivities.length > 0)
            this.addPendingDelete();
        // add details to pending list
        this.addPendingDetails();
        // add a close action to the end of pending list
        this.addPendingWebApiAction("",
            null,
            "",
            (action, success) => {
                ActivityInfo.refresh();
                this.userService.deleteUserVariable("childViewPending"); // no actions are pending
                this.commonSvc.hidePendingOperationInfo(); // decrease the overall pending increment
                this.toPreviousState(); // close the activity properties view
            },
            null);
        // execute pending actions in order
        this.handlePendingWebApiAction();
    }

    private createNewActivity(activityTypeId: number, parentId: number, startDate: Date, endDate: Date): any {
        var activityType = this.getActivityType(activityTypeId);
        var newAct = Object.create(null);
        newAct.scenarioId = this.rootActivity ? this.rootActivity.scenarioId : 1;
        newAct.id = this.uniqueNewId--;
        newAct.parentId = parentId < 0 ? null : parentId;
        newAct.memoId = null;
        newAct.activityTypeId = activityTypeId;
        newAct.startDate = startDate;
        newAct.endDate = endDate;
        newAct.resourceId = null;
        newAct.status = activityType.notRequired ? Constants.StatusActivityNotRequired : Constants.StatusActivityPlanned;
        var copyAct = this.createActivityNodeCopy(newAct);
        this.activityDict[copyAct.id] = copyAct; // add to activity dictionary
        return copyAct;
    }

    private addNewActivityWithType(parentNode: any, activityTypeId: number, parentId: number, startDate: Date, endDate: Date): void {
        var newNode = this.createNewActivity(activityTypeId, parentId, startDate, endDate);
        newNode.parentNode = parentNode;
        if (parentNode.nodes == null) parentNode.nodes = [];
        parentNode.nodes.push(newNode);
        // recursively add child activityTypes
        var childTypeIds = this.getChildActivityTypeIds(newNode.activityTypeId);
        for (var j = 0; j < childTypeIds.length; j++)
            this.addNewActivityWithType(newNode, childTypeIds[j], newNode.id, newNode.startDate, newNode.endDate);
    }

    private removeFromActivityDict(node: any, addToDeletedActivites: boolean): void {
        if (addToDeletedActivites && node.id > 0)
            this.deletedActivities.push(this.planboard.prepareActivityForWebApi(node));
        delete this.activityDict[node.id];
        if (node.nodes && node.nodes.length > 0)
            for (var i = 0; i < node.nodes.length; i++)
                this.removeFromActivityDict(node.nodes[i], true);
    }

    private applyResourceCooperations(activity: any): void {
        if (this.planboard.resourceCooperations.count <= 1) return; // there are no cooperations
        var preferredId = Globals.maxInt; // category id of the preferred list in the dropdowntree
        var skilledId = Globals.maxInt + 1; // category id of the skilled list in the dropdowntree

        // make an array with all planned resource Ids on this group
        var resourceIdList = [];
        for (var activityId in this.activityDict) {
            var act = this.activityDict[activityId];
            if (act.resourceId != null && act.resourceId > 0)
                resourceIdList.push(act.resourceId);
        }

        // loop over all candidates in resourceTree
        for (var resourceId in activity.resourceTree) {
            var item = activity.resourceTree[resourceId];
            if (item.id <= 0 || item.id >= preferredId) continue; // only for resource items
            // make the item visible again if it was hidden because of cooperation avoidance
            item.visible = true;
            // move the item back from preferred to skilled
            if (item.parentId === preferredId) item.parentId = skilledId;
            // apply cooperations
            var cooperations = this.planboard.resourceCooperations.value(item.id);
            if (cooperations == undefined) continue; // there are no cooperations for this resource item
            cooperations.forEach((key, cooperation) => {
                if (resourceIdList.indexOf(cooperation.otherResourceId) >= 0) {
                    // preferred: move this item from skilled to preferred
                    if (cooperation.value === 1 && item.parentId === skilledId)
                        item.parentId = preferredId;
                    // avoid cooperation: make this item invisible
                    if (cooperation.value === 2)
                        item.visible = false;
                }
            });
        }
    }

    private changeDateTime(d: Date, timestr: string, setToDate: Date = null): boolean {
        var index = timestr.indexOf(":");
        var hours = index < 0 ? Number(timestr) : Number(timestr.substring(0, index));
        var minutes = index < 0 ? 0 : Number(timestr.substring(index + 1));
        if (isNaN(hours) || isNaN(minutes)) return false;
        if (hours > 24) {
            minutes = Math.floor(hours) % 100;
            hours = Math.floor((hours - minutes) / 100);
        }
        if (hours < 0 || hours > 24 || minutes < 0 || minutes > 59 || (hours > 23 && minutes > 0)) return false;
        if (setToDate != undefined) d.setTime(setToDate.getTime());
        d.setHours(hours, minutes, 0, 0);
        return true;
    }

    private endTimeAfterStartTime(node: any): void {
        if (node.startDate.getTime() >= node.endDate.getTime()) { // end must be after start, so add a day
            while (node.startDate.getTime() >= node.endDate.getTime())
                node.endDate.setDate(node.endDate.getDate() + 1);
            node.endDateCopy = new Date(node.endDate.getTime()); // update copy for datepicker
        }
    }

    private recursiveChangeStartDateTime(node: any, timestr: string, newDateValue: Date, updateNode: boolean): void {
        if (newDateValue != null) { // only change the date, keep the current time
            node.startDate = this.getDateWithTime(newDateValue, node.startDate);
        } else if (timestr != null) { // only change the time
            if (!this.changeDateTime(node.startDate, timestr, node.originalActivity.startDate)) return; // not a valid time
            node.startDateCopy = new Date(node.startDate.getTime()); // update copy for datepicker
        }
        if (updateNode) {
            node.startTimeStr = this.dateToDisplayStr(node.startDate, false, true); // update value for time textbox
            node.startDateCopy = new Date(node.startDate.getTime()); // update copy for datepicker
        }
        // recursively update the child nodes if they had the same startDate
        var orgDate = node.originalActivity.startDate;
        if (node.nodes && node.nodes.length > 0)
            for (var i = 0; i < node.nodes.length; i++) {
                var currDate = node.nodes[i].originalActivity.startDate;
                if (currDate.getTime() === orgDate.getTime() ||
                    (newDateValue != null && TimeSpan.sameDayNr(currDate, orgDate)))
                    this.recursiveChangeStartDateTime(node.nodes[i], timestr, newDateValue, true);
            }
    }

    private recursiveChangeEndDateTime(node: any, timestr: string, newDateValue: Date, updateNode: boolean): void {
        if (newDateValue != null) { // only change the date, keep the current time
            node.endDate = this.getDateWithTime(newDateValue, node.endDate);
        } else if (timestr != null) { // only change the time
            if (!this.changeDateTime(node.endDate, timestr, node.originalActivity.endDate)) return; // not a valid time
            node.endDateCopy = new Date(node.endDate.getTime()); // update copy for datepicker
            this.endTimeAfterStartTime(node);
        }
        if (updateNode) {
            node.endTimeStr = this.dateToDisplayStr(node.endDate, false, true); // update value for time textbox
            node.endDateCopy = new Date(node.endDate.getTime()); // update copy for datepicker
        }
        // recursively update the child nodes if they had the same endDate
        var orgDate = node.originalActivity.endDate;
        if (node.nodes && node.nodes.length > 0)
            for (var i = 0; i < node.nodes.length; i++) {
                var currDate = node.nodes[i].originalActivity.endDate;
                if (currDate.getTime() === orgDate.getTime() ||
                    (newDateValue != null && TimeSpan.sameDayNr(currDate, orgDate)))
                    this.recursiveChangeEndDateTime(node.nodes[i], timestr, newDateValue, true);
            }
    }

}