import { PictureControl } from './../controls/pictureControl';
import { SplitterControl } from './../controls/splitterControl';

import { ActivityInfo } from './../drawing/activityInfo';

import * as Globals from './../utils/globals';

import { ActivityType } from './../entities/activitytype';
import { Planboard } from './../entities/planboard';

import { PlannedActivities } from './plannedActivities';

export class PlanboardSplitters {
    static splitResourceWidth = 200;
    static splitUnplannedHeight = 50;
    static splitCountersWidth = 80;

    static timelineHeight(): number {
        return Math.floor(20 + Globals.fontHeight * 1.5);
    }

    static initialize() {
        const timelineHeight = PlanboardSplitters.timelineHeight();
        const split1 = Planboard.split1 = new SplitterControl(Planboard.controller.desktop, 20, 450, 600, 400, 3, 3);
        const timelineRight = new PictureControl(split1, 0, 0, Globals.scrollBarSize + 1, timelineHeight);
        timelineRight.drawPicture = (t: PictureControl, ctx: CanvasRenderingContext2D, contextX: number, contextY: number, width: number, height: number) => {
            ctx.fillStyle = Globals.face3DColor;
            ctx.fillRect(contextX, contextY, width, height);
            ctx.fillStyle = Globals.light3DColor;
            ctx.fillRect(contextX, contextY, 1, height);
            ctx.fillStyle = Globals.dark3DColor;
            ctx.fillRect(contextX, contextY + height - 1, width, 1);
        }
        split1.borderSize = 0;
        split1.setAlign(0, 0, 0, 0);
        split1.setHorizontalSize(0, this.splitResourceWidth, 0).setHorizontalSize(2, this.splitCountersWidth, 0).setHorizontalSize(1, -1, Globals.scrollBarSize);
        split1.setVerticalSize(2, this.splitUnplannedHeight).setVerticalSize(1, -1, Globals.scrollBarSize);
        split1.setSplitSize(6);
        split1.setVerticalSize(0, timelineHeight, timelineHeight, timelineHeight, 0);
        split1.sizeChanged = (t: SplitterControl) => {
            const pos = split1.getSplitArea(1, 0).position;
            timelineRight.position.top = Math.max(0, pos.top - 1);
            timelineRight.position.left = pos.left + pos.width - Globals.scrollBarSize;
            timelineRight.position.height = split1.borderSize === 0 ? timelineHeight : timelineHeight + 1;
            PlannedActivities.moveIndicators(null, null, null);
        }
        split1.mouseMoveCustom = (t: SplitterControl, x: number, y: number, button: number) => {
            PlannedActivities.moveIndicators(t, x, y);
        }
        split1.mouseDownCustom = (t: SplitterControl, x: number, y: number, button: number) => {
            Planboard.onMouseDownCell(null, -1, -1, -1, -1, -1, -1, -1);
        }
        // hide most right grids
        split1.setHorizontalSize(2, 0, 0, 0, 0).setHorizontalSize(1, -1, Globals.scrollBarSize, undefined, 0);

        const split2 = Planboard.split2 = new SplitterControl(split1.getSplitArea(1, 2), 0, 0, 100, 100, 1, 2);
        split2.borderSize = 0;
        split2.setAlign(0, 0, 0, 0);
        split2.setVerticalSize(0, 30, 30, 30, 0); // header of lower plan screen
        split2.setVerticalSize(0, 0, 0, 0, 0); // hide header of lower plan screen
        split2.mouseDownCustom = (t: SplitterControl, x: number, y: number, button: number) => {
            Planboard.onMouseDownCell(null, -1, -1, -1, -1, -1, -1, -1);
        }

        const horizontalIndicator = Planboard.horizontalIndicator = new PictureControl(Planboard.controller.desktop, 0, 0, 11, 11);
        horizontalIndicator.drawPicture = (t: PictureControl, ctx: CanvasRenderingContext2D, contextX: number, contextY: number, width: number, height: number) => {
            ctx.beginPath();
            ctx.arc(Math.floor(contextX + width * 0.5), Math.floor(contextY + height * 0.5), Math.floor(width * 0.5) - 1, 0, 2 * Math.PI, false);
            ctx.closePath();
            ctx.fillStyle = "red";
            ctx.fill();               
        }
        const verticalIndicator = Planboard.verticalIndicator = new PictureControl(Planboard.controller.desktop, 0, 0, 11, 11);
        verticalIndicator.drawPicture = horizontalIndicator.drawPicture;
        const verticalIndicatorCounters = Planboard.verticalIndicatorCounters = new PictureControl(Planboard.controller.desktop, 0, 0, 11, 11);
        verticalIndicatorCounters.drawPicture = horizontalIndicator.drawPicture;
        verticalIndicatorCounters.visible = false;

        const toolTipControl = Planboard.toolTipControl = new PictureControl(Planboard.controller.desktop, 400, 200, 300, 200);
        toolTipControl.visible = false;
        toolTipControl.drawPicture = (t: PictureControl, ctx: CanvasRenderingContext2D, contextX: number, contextY: number, width: number, height: number) => {
            ActivityInfo.draw(ctx, contextX, contextY);
        }

        const dragDropControl = Planboard.dragDropControl = new PictureControl(Planboard.controller.desktop, 0, 0, 100, 100);
        dragDropControl.name = "dragDropControl";
        dragDropControl.visible = false;
        dragDropControl.drawPicture = (t: PictureControl, ctx: CanvasRenderingContext2D, contextX: number, contextY: number, width: number, height: number) => {
            if (Planboard.selectedActivity != null) {
                const activityType = Planboard.activityTypes.getObject(Planboard.selectedActivity.activityTypeId) as ActivityType;
                ctx.fillStyle = Globals.windowTextColor;
                ctx.globalAlpha = 0.2;
                if (Planboard.selectedActivity.filled === 1) {
                    ctx.fillRect(contextX + 3, contextY + 3, width - 4, height - 4);
                    ctx.fillRect(contextX + 4, contextY + 4, width - 6, height - 6);
                } else {
                    ctx.fillRect(contextX + 3, contextY + 3, width - 4, 3);
                    ctx.fillRect(contextX + 3, contextY + height - 4, width - 4, 3);
                    ctx.fillRect(contextX + 3, contextY + 6, 4, height - 10);
                    ctx.fillRect(contextX + width - 5, contextY + 6, 4, height - 10);
                    ctx.fillRect(contextX + 4, contextY + 4, width - 6, 1);
                    ctx.fillRect(contextX + 4, contextY + height - 4, width - 6, 2);
                    ctx.fillRect(contextX + 4, contextY + 5, 2, height - 9);
                    ctx.fillRect(contextX + width - 4, contextY + 5, 2, height - 9);
                }
                ctx.globalAlpha = 1;
                if (activityType != undefined)
                    activityType.draw(ctx, contextX, contextY, width - 4, height - 4, Planboard.selectedActivity.filled === 1);
            }
        }
    }

}