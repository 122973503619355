import  * as Timezone from './../../utils/timezone';

import { AreaControl } from './../controls/areaControl';

import * as CachedObjectIds from './../drawing/cachedObjectIds';
import * as Drawing from './../drawing/timeline';

import * as Globals from './../utils/globals';
import { TimeSpan } from './../utils/timespan';

import { Planboard } from './../entities/planboard';

import { PlanboardSplitters } from './planboardSplitters';
import { PlannedActivities } from './plannedActivities';

export class PlanboardDate {
    static initialize() {
        const areaDate = Planboard.areaDate = new AreaControl(Planboard.split1.getSplitArea(1, 0), 0, 0, 100, 100, 3000, 2000);
        areaDate.name = "areaDate";
        areaDate.setUseBackbuffer(true);
        areaDate.setAlign(0, 0, 0, 0);
        areaDate.backcolor = "";
        areaDate.gridColor = "";
        areaDate.clearColor = areaDate.backcolor;
        areaDate.cols.setSize(Planboard.areaMain.cols.count - 1, Planboard.areaMain.cols.getSize(0), 16);
        areaDate.rows.setSize(0, PlanboardSplitters.timelineHeight(), 0);
        areaDate.linkHorizontal(Planboard.areaMain);
        areaDate.hBarVisible = false;
        areaDate.vBar.enabled = false;
        areaDate.drawCell = (t: AreaControl, ctx: CanvasRenderingContext2D, col: number, row: number, colWidth: number, rowHeight: number, contextX: number, contextY: number) => {
            const colDate = TimeSpan.fromDate(Planboard.leftDate).addDays(col).toDate();
            const dayOfWeek = (col + Planboard.firstDayOfWeek) % 7; // 0 = sunday, 1 = monday, etc...
            Drawing.drawTimeLine(CachedObjectIds.timeLineId, ctx, contextX, contextY, colWidth, rowHeight, Planboard.dayStartHour, Planboard.dayEndHour);
            ctx.fillStyle = Globals.darker3DColor;
            ctx.textAlign = Globals.alignLeft;
            ctx.textBaseline = Globals.alignTop;
            if (Planboard.isHoliday(colDate)) { // draw holiday symbol
                ctx.font = Globals.largeFontBold;
                ctx.fillStyle = Globals.holidayTextColor;
                const holidayText = String.fromCodePoint(parseInt(Globals.holidayTextSymbol.substr(3), 16));
                const holidayWidth = ctx.measureText(holidayText).width + 6;
                ctx.fillText(holidayText, Math.floor(contextX + colWidth - holidayWidth + 2), Math.floor(contextY + 2));
                colWidth -= holidayWidth;
                ctx.font = Globals.defaultFont;
                ctx.fillStyle = Globals.dark3DColor; // make the text color of the day a bit lighter
            }
            if (colWidth / 4 > 20) {
                const dateText = Globals.dateToDisplayStr(colDate, true, false);
                if (dayOfWeek === Planboard.firstDayOfWeek) {
                    const weekText = Planboard.getTextLabel("WEEK").toLowerCase() + " " + Timezone.getWeekNumber(colDate).week.toString();
                    const weekTextWidth = ctx.measureText(weekText).width + 4;
                    const dateTextWidth = ctx.measureText(dateText).width + 4;
                    if (weekTextWidth + dateTextWidth + 4 < colWidth)
                        ctx.fillText(weekText, Math.floor(contextX + colWidth - weekTextWidth), Math.floor(contextY + 2));
                }
                ctx.fillText(dateText, Math.floor(contextX + 4), Math.floor(contextY + 2));
            } else {
                const monthText = Globals.dateFormat(colDate, "mediumDate").replace(colDate.getFullYear().toString(), "").replace(",", "").replace(".", "").trim();
                ctx.fillText(Globals.dateFormat(colDate, "EEE"), Math.floor(contextX + 4), Math.floor(contextY + 2));
                ctx.fillText(monthText, Math.floor(contextX + 4), Math.floor(contextY + 2 + Globals.fontHeight));
            }
        }
        areaDate.dragDropCell = Planboard.areaMain.dragDropCell;
        areaDate.scrollEnd = Planboard.areaMain.scrollEnd;
        areaDate.mouseDownCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            Planboard.onMouseDownCell(t, col, row, celX, celY, button, mouseX, mouseY);
        }
        areaDate.mouseUpCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            Planboard.onMouseUpCell(t, col, row, celX, celY, button, mouseX, mouseY);
        }
        this.initMouseMoveCell();
    }

    private static initMouseMoveCell() {
        Planboard.areaDate.mouseMoveCell = (t: AreaControl, col: number, row: number, celX: number, celY: number, button: number, mouseX: number, mouseY: number) => {
            PlannedActivities.moveIndicators(t, mouseX, mouseY);
        }
    }

}