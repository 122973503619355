/**
    * Membership period for a resource.
    */
export class MembershipPeriod {
    /**
        * Id of this membership period.
        */
    id: number;

    /**
        * Id of the resource to which this membership belongs.
        */
    resourceId: number;

    /**
        * Organization unit to which this membership belongs.
        */
    organizationUnitId: number;

    /**
        * Displayname of the organization unit with organizationUnitId.
        */
    organizationUnitDisplayName: string;

    /**
        * Optional start date for this membership period.
        */
    start: Date;

    /**
        * Optional end date for this membership period.
        */
    end: Date;

    /**
        * Flags that denotes whether this membership period has been modified.
        */
    modified: boolean;
}