export const modalWithCheckbox: angular.IComponentOptions = {
    template: require("./modalWithCheckbox.html"),
    controller: modalWithCheckboxController,
    bindings: {
        modalTitle: "@",
        modalQuestion: "@",
        modalCheckboxText: "@",
        modalYesButtonText: "@",
        modalNoButtonText: "@",
        permissionChangeCallback: "&"
    }
}

function modalWithCheckboxController() {
    this.modalCheckbox = false;

    this.onYesClick = () => {
        this.permissionChangeCallback({ didConfirm: true, checkboxValue: this.modalCheckbox });
    }
    this.onNoClick = () => {
        this.permissionChangeCallback({ didConfirm: false, checkboxValue: this.modalCheckbox });
    }
    this.onDimmedClick = () => {
        this.permissionChangeCallback({ didConfirm: false, checkboxValue: false });
    }
}