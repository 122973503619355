import { IConfigurationService } from "./configurationService";

export interface IAngularRedirectService {
    redirectToAngularPage(url: string): void;
}

export var angularRedirectService = [
    "configurationService",
    "$window",
    function (
        configurationService: IConfigurationService,
        $window: ng.IWindowService,
    ) {
        this.redirectToAngularPage = (url: string) => {
            configurationService.getAppConfiguration(() => {
                const useAngularScreens = configurationService.appConfiguration.featureManagement.useAngularScreens;
                const redirectToCorrectAngularMigrationScreen = configurationService.appConfiguration.featureManagement.redirectToCorrectAngularMigrationScreen;
                if (useAngularScreens && redirectToCorrectAngularMigrationScreen) {
                    $window.location.href = url;
                }
            });
        }
    }
];