/**
    * internal bucket class used by ObjectList
    */
export class ObjectBucket {
    count: number;
    minId: number;
    items: any[];

    /**
        * create a new bucket with a specific size
        * @param minId the lower bound id
        * @param size the size of the bucket
        */
    constructor(minId: number, size: number) {
        this.count = 0;
        this.minId = minId;
        this.items = new Array(size);
    }

    /**
        * retrieve an item from the bucket without any sanity checks
        * @param id the item id
        */
    getItem(id: number): any {
        return this.items[id - this.minId];
    }

    /**
        * add an item to the bucket
        * @param id the item id
        * @param item the item object
        */
    addItem(id: number, item: any) {
        if (this.items[id - this.minId] != undefined) this.count--;
        if (item != undefined) this.count++;
        this.items[id - this.minId] = item;
    }
}

/**
    * can store multiple objects with an unique number id
    */
export class ObjectList {
    private buckets: Object;
    private len: number;

    /**
        * usage: objLst.forEach((id, item) => { console.log(id, " = ", item); });
        * @param action the action to take for each item
        */
    forEach(action: (id: number, item: any) => void) {
        for (let bucketId in this.buckets) {
            const bucket = this.buckets[bucketId] as ObjectBucket;
            for (let i = 0; i < bucket.items.length; i++)
                if (bucket.items[i] != undefined)
                    action(i + bucket.minId, bucket.items[i]);
        }
    }

    /**
        * get a copy of all keys in this list as an array of number
        */
    getKeys(): Array<number> {
        let result = [];
        for (let bucketId in this.buckets) {
            const bucket = this.buckets[bucketId] as ObjectBucket;
            for (let i = 0; i < bucket.items.length; i++)
                if (bucket.items[i] != undefined)
                    result.push(i + bucket.minId);
        }
        return result;
    }

    /**
        * get the minimum id in all buckets that contain items
        */
    getMinBucketId(): number {
        var minId = null;
        for (let bucketId in this.buckets) {
            const bucket = this.buckets[bucketId] as ObjectBucket;
            if (bucket.count > 0 && (minId == null || bucket.minId < minId))
                minId = bucket.minId;
        }
        return minId;
    }

    /**
        * get the maximum id in all buckets that contain items
        */
    getMaxBucketId(): number {
        var maxId = null;
        for (let bucketId in this.buckets) {
            const bucket = this.buckets[bucketId] as ObjectBucket;
            if (bucket.count > 0 && (maxId == null || bucket.minId + bucket.items.length - 1 > maxId))
                maxId = bucket.minId + bucket.items.length - 1;
        }
        return maxId;
    }

    /**
        * get the number of stored objects
        */
    get count(): number {
        return this.len;
    }

    /**
        * get a bucket for a specific Id
        * @param objId the id of an item
        */
    private getBucket(objId: number): ObjectBucket {
        return this.buckets[Math.floor(objId / 256)];
    }

    /**
        * create a new bucket for a specific Id
        * @param objId the id of an item
        */
    private addBucket(objId: number): ObjectBucket {
        const buckerNr = Math.floor(objId / 256);
        const bucket = new ObjectBucket(buckerNr * 256, 256);
        this.buckets[buckerNr] = bucket;
        return bucket;
    }

    /**
        * retrieve an object with a specific Id
        * @param objId the id of an item
        */
    getObject(objId: number): any {
        const bucket = this.getBucket(objId);
        if (bucket != undefined)
            return bucket.getItem(objId);
        return undefined;
    }

    /**
        * remove an object with a specific Id
        * @param objId the id of an item
        */
    removeObject(objId: number) {
        const bucket = this.getBucket(objId);
        if (bucket != undefined) {
            this.len -= bucket.count;
            bucket.addItem(objId, undefined);
            this.len += bucket.count;
        }
    }

    /**
        * add an object with a specific Id, or replace an existing object with the same Id
        * @param objId the id of the item
        * @param obj the item to add
        */
    addObject(objId: number, obj: any) {
        let bucket = this.getBucket(objId);
        if (bucket == undefined)
            bucket = this.addBucket(objId);
        this.len -= bucket.count;
        bucket.addItem(objId, obj);
        this.len += bucket.count;
    }

    /**
        * clears all items
        */
    clear() {
        if (this.len !== 0) this.buckets = Object.create(null); // create new object that has 0 inherited properties
        this.len = 0;
    }

    /**
        * create a new empty object list
        */
    constructor() {
        this.buckets = Object.create(null); // create new object that has 0 inherited properties
        this.len = 0;
    }
}